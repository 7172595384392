import logo1 from "./assets/images/home_company_logo/1.png";
import logo2 from "./assets/images/home_company_logo/2.png";
import logo3 from "./assets/images/home_company_logo/3.png";
import logo4 from "./assets/images/home_company_logo/4.png";
import logo5 from "./assets/images/home_company_logo/5.jpeg";

export const logoData = [logo1, logo2, logo3, logo4, logo5];

export const categoryCardDetails = [];

export const cusineList = [
  {
    image: "https://cdn.dineorder.com/cuisine/arabian.png",
    title: "Arabian",
  },
  { image: "https://cdn.dineorder.com/cuisine/bakery.png", title: "Bakery" },
  {
    image: "https://cdn.dineorder.com/cuisine/bangladeshi.png",
    title: "Bangladeshi",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/beverages.png",
    title: "Beverages",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/biryani.png",
    title: "Biryani",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/breakfast.png",
    title: "Breakfast",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/burgers.png",
    title: "Burgers",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/calzone.png",
    title: "Calzone",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/chicken.png",
    title: "Chicken",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/chinese.png",
    title: "Chinese",
  },
  { image: "https://cdn.dineorder.com/cuisine/curry.png", title: "Curry" },
  {
    image: "https://cdn.dineorder.com/cuisine/desserts.png",
    title: "Desserts",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/english.png",
    title: "English",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/fishandchips.png",
    title: "Fish & Chips",
  },
  { image: "https://cdn.dineorder.com/cuisine/halal.png", title: "Halal" },
  { image: "https://cdn.dineorder.com/cuisine/indian.png", title: "Indian" },
  {
    image: "https://cdn.dineorder.com/cuisine/italian.png",
    title: "Italian",
  },
  { image: "https://cdn.dineorder.com/cuisine/kebab.png", title: "Kebab" },
  {
    image: "https://cdn.dineorder.com/cuisine/noodles.png",
    title: "Noodles",
  },
  {
    image: "https://cdn.dineorder.com/cuisine/periperi.png",
    title: "Peri Peri",
  },
  { image: "https://cdn.dineorder.com/cuisine/pizza.png", title: "Pizza" },
  { image: "https://cdn.dineorder.com/cuisine/rice.png", title: "Rice" },
  { image: "https://cdn.dineorder.com/cuisine/steak.png", title: "Steak" },
  {
    image: "https://cdn.dineorder.com/cuisine/tandoori.png",
    title: "Tandoori",
  },
];

export const bestSellerDetails = [];
