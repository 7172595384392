import { useEffect } from "react";

export default function AddressCardLoader({
  className,
  subClassName,
  visible,
}) {
  useEffect(() => {
    const body = document.getElementById("addressCard");
    body.style.overflow = visible ? "hidden" : "auto";
  }, [visible]);

  return (
    visible && (
      <div
        className={`animate-pulse space-y-4 pb-1 overflow-x-hidden ${className}`}
        onClick={(event) => {
          event.preventDefault();
        }}
      >
        <div className={subClassName}>
          {[...Array(6)].map((_, index) => (
            <div key={index} className="h-24 bg-gray-300 rounded-lg"></div>
          ))}
        </div>
      </div>
    )
  );
}
