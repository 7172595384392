const CheckoutLoader = ({ className }) => {
  return (
    <div className={`${className}`}>
      <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center mb-4 mt-4">
        <div className="w-[70%] items-baseline pr-4 sm:pr-0">
          <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
          <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
        </div>
        <div
          className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
        ></div>
      </div>
      <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center  mb-4">
        <div className="w-[70%] items-baseline pr-4 sm:pr-0">
          <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
          <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
        </div>
        <div
          className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
        ></div>
      </div>
      <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center  mb-4">
        <div className="w-[70%] items-baseline pr-4 sm:pr-0">
          <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
          <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
        </div>
        <div
          className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
        ></div>
      </div>
      <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center  mb-4">
        <div className="w-[70%] items-baseline pr-4 sm:pr-0">
          <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
          <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
        </div>
        <div
          className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
        ></div>
      </div>
      <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center  mb-4">
        <div className="w-[70%] items-baseline pr-4 sm:pr-0">
          <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
          <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
        </div>
        <div
          className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
        ></div>
      </div>
    </div>
  );
};

export default CheckoutLoader;
