import LazyLoadImageComponent from "../Image/LazyLoadImage";
import logo_cover from "../../assets/images/default_images/logo_cover.jpg";

const SearchData = ({
  searchData,
  handleSelection,
  listHeight,
  locationIcon,
}) => {
  return (
    <div className="absolute w-[100%] left-0 top-11 z-10 bg-white divide-y divide-gray-100 shadow">
      <ul
        className={`py-2 text-sm text-gray-700 cursor-pointer shadow-2xl ${
          listHeight === "18rem" ? "overflow-y-auto" : ""
        }`}
        aria-labelledby="dropdownDefaultButton"
        style={{ height: listHeight }}
      >
        {searchData.map((val) => (
          <li
            onClick={() => {
              handleSelection(val);
            }}
            className="my-3 mx-2"
            key={val?.product_Id}
          >
            <div className="flex items-center hover:bg-gray-100">
              <div className={`${val.store_Logo ? "w-10" : "w-8 pl-2"}`}>
                <img
                  src={val?.store_Logo || locationIcon}
                  alt=""
                  srcSet=""
                  className="rounded-md"
                />
                {/* <LazyLoadImageComponent
                  rc={val.store_Logo || locationIcon}
                  alt=""
                  className="rounded-md"
                  onErrorSrc={logo_cover}
                  loading="lazy"
                  placeholderSrc={logo_cover}
                /> */}
              </div>
              <div className="pl-4">
                <p className="text-black-shade-1">
                  {" "}
                  {val?.store_Name || val.postcode}
                </p>
                <p className="text-chip-text-color text-xs">
                  {val?.store_Address_Line_2 || val.street}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchData;

export const SearchDataHeader = ({
  searchData,
  handleSelection,
  listHeight,
  locationIcon,
}) => {
  return (
    <div className="absolute w-full left-0 top-11 z-10 bg-white divide-y divide-gray-100 rounded-md shadow ">
      <ul
        className="py-2 text-sm text-gray-700 cursor-pointer"
        aria-labelledby="dropdownDefaultButton"
      >
        {searchData.map((val) => (
          <li
            onClick={() => {
              handleSelection(val);
            }}
            className={`where(.menu li) px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer ${
              listHeight === "18rem" ? "overflow-y-auto" : ""
            }`}
            key={val?.product_Id}
            aria-labelledby="dropdownDefaultButton"
          >
            <div className="flex items-center">
              <div className={`${val?.store_Logo ? "w-10" : "w-8 pl-2"}`}>
                <img
                  src={val?.store_Logo || locationIcon}
                  alt=""
                  srcSet=""
                  className="rounded-md"
                />
              </div>
              <div className="pl-4">
                <p className="text-black-shade-1">
                  {" "}
                  {val?.store_Name || val?.postcode}
                </p>
                <p className="text-chip-text-color text-xs">
                  {val?.store_Address_Line_2 || val?.street}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
