import { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import DownArrowLeftChevron from "../Icon/DownArrowLeftChevron";
import { useNavigate } from "react-router-dom";
import { DefaultPostCode, Takeaways } from "../../routes/RoutesPath";
import { useDispatch } from "react-redux";
import { cleanString } from "../../utils/helper";
import {
  setSearchText,
  setFilterItems,
  setHomeLoader,
} from "../../store/basicData/basicDataSlice";
import { setDineConfigSession } from "../../utils";

const Accordian = ({ title, className, dataArray, totalArray, postcode }) => {
  const [currentArray, setcurrentArray] = useState([]);
  const [lengthCheck, setlengthCheck] = useState();
  const [lessShowText, setlessShowText] = useState("Show More");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    let data = [];
    let indexCheck = 0;
    dataArray?.data?.forEach((val, index) => {
      indexCheck = index > 11 ? 0 : 1;

      data.push({
        Id: val.Id,
        Name: val.Name,
        Icon: val.Icon,
        toggle: indexCheck,
      });
    });
    setcurrentArray(data);
    setlengthCheck(dataArray?.data?.length);
  }, [dataArray]);

  const showMoreFunction = (name) => {
    let data = [];
    let indexCheck = 0;

    totalArray?.forEach((val, ind) => {
      if (val.heading == dataArray?.heading) {
        currentArray?.forEach((vals, indexs) => {
          if (indexs <= 11) {
            indexCheck = 1;
          } else {
            indexCheck = vals.toggle == 1 ? 0 : 1;
            if (indexCheck == 1) {
              setlessShowText("Show Less");
            } else {
              setlessShowText("Show More");
            }
          }
          data.push({
            Id: vals.Id,
            Name: vals.Name,
            Icon: vals.Icon,
            toggle: indexCheck,
          });
        });
      }
    });
    setcurrentArray(data);
  };

  const handleOnFilterChange = async (title, id, name) => {
    const apiPayload = {};
    try {
      if (postcode) {
        apiPayload.postcode = postcode;
      } else {
        apiPayload.postcode = DefaultPostCode;
      }

      apiPayload.searchLat = "";
      apiPayload.searchLon = "";
      apiPayload.tags = [];
      apiPayload.offers = [];
      apiPayload.cuisines = [];

      if (title === "Popular tags near me") {
        apiPayload.tags = [id];
      } else if (title === "Popular offers near me") {
        apiPayload.offers = [id];
      } else if (title === "Popular cuisines near me") {
        apiPayload.cuisines = [id];
      }

      let namePath = cleanString(name);
      namePath = namePath.replace("/", "-");

      dispatch(setFilterItems(""));
      dispatch(setHomeLoader(true));

      navigate(Takeaways + namePath);

      // if (postcode) {
      //   await fetchData(postcode);
      // } else {
      //   dispatch(setSearchText(name.toLowerCase()));
      //   setDineConfigSession({
      //     postcode: DefaultPostCode,
      //     heading_img: "",
      //     locationData: {
      //       latitude: "53.048988",
      //       longitude: "-2.198013",
      //       cityName: "Stoke-on-trent",
      //       street: "Scotia Road",
      //       county: "",
      //       frm: "addr",
      //       postcodePretty: "st6 4ep",
      //     },
      //   });
      //   await fetchData(DefaultPostCode);
      // }
    } catch (error) {
      console.error(error.message);
      // Optionally display the error message to the user
    } finally {
      setTimeout(() => {
        dispatch(setHomeLoader(false));
      }, 1500);
    }
  };

  return (
    <Layout
      id="popularTags"
      className={`${className} flex flex-col items-center py-8 sm:pt-10 sm:pb-12 xl:pt-10 xl:pb-12`}
    >
      <div className="flex justify-between text-center sm:text-left sm:w-full pb-6 sm:pb-8 2xl:pb-12">
        <h2 className="  sm:w-[600px] text-black text-[32px] sm:text-[26px] 2xl:text-[32px] font-semibold leading-9">
          {title}
        </h2>
        {lengthCheck > 12 && (
          <h2
            onClick={() => {
              showMoreFunction(dataArray?.heading);
            }}
            className="hidden sm:flex space-x-1 items-center cursor-pointer text-primary justify-end text-center sm:w-full sm:text-left sm:pl-27"
          >
            <span className="font-semibold text-[17px] 2xl:text-[20px] pr-1.5 w-[100px]">
              {lessShowText}
            </span>
            <DownArrowLeftChevron
              className={
                "w-5 h-5 fill-primary" +
                (lessShowText == "Show More" ? " rotate-0" : " rotate-180	")
              }
            />
          </h2>
        )}
      </div>

      <div className="w-full grid gap-[25px] grid-cols-2 sm:grid-cols-3 lg:sm:grid-cols-4 2xl:grid-cols-4 2xl:gap-y-[25px] 2xl:gap-x-[25px]">
        {currentArray.map((val, index) => {
          return (
            val.toggle === 1 && (
              <button
                onClick={() => handleOnFilterChange(title, val.Id, val.Name)}
                key={val + index}
                className="hover:bg-primary hover:text-white border hover:border-primary	 border-dark-shade-1 rounded-lg w-full h-12 2xl:h-16 text-dark-shade-1 text-[16px] 2xl:text-[26px] 2xl:px-0 2xl:py-3.5 font-semibold 2xl:font-medium font-inter sm:px-4 leading-4 "
              >
                {val?.Name}
              </button>
            )
          );
        })}
      </div>

      {lengthCheck > 12 && (
        <h2
          onClick={() => {
            showMoreFunction(dataArray?.heading);
          }}
          className="flex sm:hidden pt-8 pb-0 space-x-1 items-center text-primary justify-end text-lg text-center sm:w-full sm:text-left sm:pl-27 "
        >
          <span className="font-semibold text-md sm:text-lg pr-1.5">
            {lessShowText}
          </span>
          <DownArrowLeftChevron
            className={
              "w-5 h-5 fill-primary" +
              (lessShowText == "Show More" ? " rotate-0" : " rotate-180	")
            }
          />
        </h2>
      )}
    </Layout>
  );
};

export default Accordian;
