/* eslint-disable react/prop-types */
import IcoMoon from "react-icomoon";
import iconSet from "../../selection.json";

const Icon = ({ icon, color, className }) => {
  return (
    <IcoMoon
      icon={icon}
      color={color}
      iconSet={iconSet}
      className={className}
    />
  );
};

export default Icon;
