import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatTotal, formatTimeAMPMDayMonthYear } from "../../utils/helper";
import PastCardLoader from "../Loader/ProfilePageLoaders/PastCardLoader/PastCardLoader";
import { checkEmpty } from "../../utils/helper";
import NoteIcon from "../../assets/images/profile_section/notes.png";
import DrawerHeader from "../Header/DrawerHeader";
import StoreBanner from "../Elements/StoreBanner";

const OrderModal = ({
  orderDetails,
  basketStoreDetails,
  className,
  isOpen,
  onClose,
  isLoader,
  key,
  toggleDrawer,
  formWhere = 0,
}) => {
  const currency = basketStoreDetails?.store_Currency || "£";

  return (
    <div
      key={key}
      className={`   ${className} 
       ${formWhere == "1" ? "w-[100%]  m-auto" : "w-[100%]  pb-12  mdl:pb-8"}
      `}
    >
      {isLoader ? (
        <PastCardLoader
          subClassName={`grid grid-cols-1 gap-4`}
          subsubClassName={`h-36`}
          numberShimmerLine="6"
        />
      ) : (
        <>
          <DrawerHeader
            title={"Your Order Details"}
            description={` 
               
            
              ${
                orderDetails &&
                orderDetails.pos_basket_Delivery_Type == "Delivery"
                  ? formatTimeAMPMDayMonthYear(
                      orderDetails?.pos_basket_Delivery_Due_Date
                    )
                  : formatTimeAMPMDayMonthYear(
                      orderDetails?.pos_basket_Delivery_Due_Date
                    )
              }`}
            onClose={toggleDrawer}
            formWhere={formWhere}
          />

          <div className="text-sm font-semibold ml-2 pt-1 pb-5 px-6">
            <div className="block text-slate-950 text-xl font-semibold font-inter cursor-pointer">
              <StoreBanner
                onClick={() => {
                  toggleDrawer();
                }}
              />
            </div>
          </div>

          <div className="flex justify-between mb-4 text-md text-black-shade-4 px-6">
            <p>Order ID</p>
            <p className="mr-2">
              {orderDetails && orderDetails?.pos_basket_Id}
            </p>
          </div>
          <div className="flex justify-between text-md text-black-shade-4 px-6 mb-4">
            <p>Payment Mode</p>
            <p className="mr-2">
              {orderDetails && orderDetails?.pos_basket_Payment_Type}
            </p>
          </div>

          <div className="border-dashed border-b-2 mx-6"></div>

          <div className="grid grid-cols-1 text-greyshade px-6">
            {orderDetails &&
              orderDetails?.basketProducts?.map(
                (basketProduct, indexProduct) => (
                  <div key={indexProduct}>
                    <div
                      key={basketProduct?.pos_basket_Id}
                      className="flex justify-between pl-2 pr-2 pt-4 text-[16px] text-dark-shade-8 font-['Inter']"
                    >
                      <p className="pr-4">
                        {basketProduct?.pos_basketproduct_Quantity}x
                        <span className="pr-2"></span>
                        {basketProduct?.pos_basketproduct_Name}
                      </p>
                      <p>
                        {currency}
                        {formatTotal(
                          basketProduct?.pos_basketproduct_Total_Price
                        )}
                      </p>
                    </div>

                    {basketProduct?.basketProductlines?.map(
                      (basketProductline, index) => (
                        <div key={index}>
                          {basketProduct?.pos_basketproduct_Name !=
                          basketProductline?.pos_basketproductline_Name ? (
                            <div
                              key={basketProductline?.pos_basketproductline_Id}
                              className="flex justify-between pl-6 pr-2 pt-3 text-[16px] text-dark-shade-8 font-['Inter']"
                            >
                              <p className="pr-4">
                                {basketProductline?.pos_basketproductline_Name}
                              </p>
                              <p>
                                {currency}
                                {formatTotal(
                                  basketProductline?.pos_basketproductline_Total_Price
                                )}
                              </p>
                            </div>
                          ) : null}

                          {basketProductline?.basketComponents?.map(
                            (basketComponent, index) => (
                              <div
                                key={index}
                                className="flex justify-between pl-11 pr-2 pt-2 text-[14px] text-dark-shade-8 font-['Inter']"
                              >
                                <p className="pr-4">
                                  {basketComponent?.pos_basketcomponent_Name}
                                </p>
                                <p>
                                  {currency}
                                  {formatTotal(
                                    basketComponent?.pos_basketcomponent_Total_Price
                                  )}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                )
              )}
          </div>

          {!checkEmpty(orderDetails?.pos_basket_Customer_Note) &&
            formWhere == "0" && (
              <div className="pt-8">
                <div className="p-3 mt-0 mx-6 border border-card-border rounded-lg">
                  <div className="flex items-center">
                    <div className="w-4">
                      <img src={NoteIcon} alt="" srcSet="" />
                    </div>
                    <p className="text-black-shade-4 font-semibold text-md pl-2">
                      Note
                    </p>
                  </div>
                  <p className="text-gray-shade-10 font-medium	text-xs pt-2">
                    {orderDetails?.pos_basket_Customer_Note}
                  </p>
                </div>
              </div>
            )}

          <div className="border-dashed border-b-2 pt-7 mx-6"></div>

          <div className="text-greyshade2 font-semibold text-xs pt-6 pb-1 px-6">
            {orderDetails &&
              orderDetails?.pos_basket_Charges?.map((thisChargeItem, index) => (
                <div
                  key={`${thisChargeItem?.label}-${index}`}
                  className="flex justify-between mb-4 ml-2 mr-2"
                >
                  <p className="text-md md:text-mdl text-dark-shade-8 font-semibold">
                    {thisChargeItem?.label}
                  </p>
                  <p className="text-md md:text-mdl font-semibold text-black-shade-4">
                    {thisChargeItem?.value}
                  </p>
                </div>
              ))}
          </div>

          <div className="border-dashed border-t-2 mx-6"></div>
          {orderDetails && orderDetails?.pos_basket_Total !== "0.00" && (
            <div className="flex justify-between items-center text-md md:text-mdl text-black-shade-4 py-6 px-6 font-semibold">
              <p className="ml-3">Total</p>
              <h3 className="mr-3">
                {currency}
                {orderDetails?.pos_basket_Total}
              </h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OrderModal;
