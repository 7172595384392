import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import Button from "../Elements/Button";
import Cross from "../../assets/icons/cross.svg";
import useWindowDimensions from "../../hooks/useWindowsDimensons";
import {
  checkEmpty,
  objectSize,
  strtolower,
  resetObject,
  checkFloat,
  checkInt,
  copyObject,
} from "../../utils/helper";
import { error } from "../notify";
import Checkbox from "../Elements/Checkbox";
import RadioButton from "../Elements/RadioButton";
import MenuCheck from "../Icon/MenuCheck";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

const CustomizeCart = ({
  visible,
  onClose,
  tempProduct,
  finalProduct,
  submitProduct,
  calculateFinalProductTotal,
  setTempProduct,
  setFinalProduct,
}) => {
  const parentRef = useRef(null);
  const { width, height } = useWindowDimensions();
  const [curGroup, setCurGroup] = useState([]);
  const [showGroup, setShowGroup] = useState(true);
  const [curStaticLabel, setCurStaticLabel] = useState("");
  const [curGroupKey, setCurGroupKey] = useState(0);
  const [curLabels, setCurLabels] = useState({});
  const [lblModel, setLblModel] = useState({});
  const [lblCmpMdl, setLblCmpMdl] = useState({});
  const [compModel, setCompModel] = useState({});
  // const [cartConfig, setCartConfig] = useState("");
  const currency = useSelector(
    (state) => state?.basicdata?.storedetails?.store_Currency
  );
  const basket = useSelector((state) => state?.basicdata?.basket);

  // useEffect(() => {
  //   setCartConfig(getCartConfigSession());
  // }, []);

  useEffect(() => {
    if (!checkEmpty(tempProduct?.groups)) {
      setCurGroup(tempProduct?.groups[0]);
    }
  }, [tempProduct]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = visible ? "hidden" : "auto";
  }, [visible]);

  const getComponentLabel = function (gKey, groupItem) {
    let lbl = "";
    if (finalProduct?.hasOwnProperty("groups")) {
      Object.keys(finalProduct?.groups[gKey] ?? {})?.forEach((g) => {
        if (
          finalProduct?.groups[gKey][g]?.inventory_component_Name ==
          groupItem?.inventory_component_Name
        ) {
          if (!checkEmpty(finalProduct.groups[gKey][g].label)) {
            lbl = finalProduct?.groups[gKey][g]?.label + " ";
            return;
          }
        }
      });
    }
    return lbl;
  };

  const setGroupMultiComponent = (act, compt) => {
    let component = copyObject(compt);
    setLblModel({});
    let found = 0;
    setLblCmpMdl({});
    lblCmpMdl.cmp = "";
    if (!finalProduct.hasOwnProperty("groups")) {
      finalProduct.groups = {};
      finalProduct.groups[curGroupKey] = {};
    }

    if (act == "sub") {
      let res = copyObject(finalProduct);
      Object.keys(res?.groups[curGroupKey] ?? {})?.forEach((k) => {
        const {
          inventory_groupsline_Id,
          inventory_component_Id,
          inventory_groups_Id,
          inventory_component_Name,
        } = res.groups[curGroupKey][k];
        if (
          inventory_groupsline_Id == component?.inventory_groupsline_Id &&
          inventory_component_Id == component?.inventory_component_Id &&
          inventory_groups_Id == component?.inventory_groups_Id
        ) {
          let nmsplit = inventory_component_Name.split(" x ");
          if (nmsplit.length > 0) {
            nmsplit = checkInt(nmsplit[0]);
            if (nmsplit > 1) {
              nmsplit -= 1;
              res.groups[curGroupKey][k].inventory_component_Name =
                nmsplit + " x " + nmsplit[1];
              res.groups[curGroupKey][k].inventory_groupsline_Collection_Price =
                checkFloat(
                  finalProduct?.groups[curGroupKey][k]
                    ?.inventory_groupsline_Collection_Price
                ) * nmsplit;
              res.groups[curGroupKey][k].inventory_groupsline_Delivery_Price =
                checkFloat(
                  finalProduct?.groups[curGroupKey][k]
                    ?.inventory_groupsline_Delivery_Price
                ) * nmsplit;
              res.groups[curGroupKey][k].inventory_groupsline_Restaurant_Price =
                checkFloat(
                  finalProduct?.groups[curGroupKey][k]
                    ?.inventory_groupsline_Restaurant_Price
                ) * nmsplit;
              res.groups[curGroupKey][k].inventory_groupsline_Waiting_Price =
                checkFloat(
                  finalProduct?.groups[curGroupKey][k]
                    ?.inventory_groupsline_Waiting_Price
                ) * nmsplit;
            } else {
              delete finalProduct.groups[curGroupKey][k];
            }
          }
          delete finalProduct.groups[curGroupKey][k];
          found = 1;
          return;
        }
      });
      res.groups[curGroupKey] = resetObject(finalProduct?.groups[curGroupKey]);
      setFinalProduct(res);
    } else if (act == "add") {
      let tmpcmp = component;
      // delete tmpcmp.labels;
      finalProduct.groups[curGroupKey] = resetObject(
        finalProduct?.groups[curGroupKey]
      );
      finalProduct.groups[curGroupKey][
        objectSize(finalProduct.groups[curGroupKey])
      ] = tmpcmp;

      if (!checkEmpty(component.labels)) {
        let curLabelss = component.labels;
        setCurLabels({});
        curLabelss?.forEach((l, ind) => {
          if (l?.inventory_label_Name?.toLowerCase().trim() != "free") {
            curLabels[ind] = l;
          }
        });
        curLabels.checkId =
          component.inventory_component_Id +
          "-" +
          component.inventory_groupsline_Id;
        setCurLabels(curLabels);
        setLblModel({});
      }
    }
  };

  const setComponentLabel = (label) => {
    let lbl = copyObject(label);
    if (objectSize(finalProduct.groups[curGroupKey]) - 1 < 0) {
      return false;
    }
    let res = copyObject(finalProduct);
    if (
      finalProduct.groups[curGroupKey][
        objectSize(finalProduct.groups[curGroupKey]) - 1
      ].label === lbl.inventory_label_Name
    ) {
      res.groups[curGroupKey][
        objectSize(finalProduct.groups[curGroupKey]) - 1
      ].label = "";
      setLblCmpMdl(lblCmpMdl);
    } else {
      res.groups[curGroupKey][
        objectSize(finalProduct.groups[curGroupKey]) - 1
      ].label = lbl.inventory_label_Name;
    }
    setFinalProduct(res);
  };

  const getComponentLabelForButton = (label) => {
    let lbl = copyObject(label);
    if (objectSize(finalProduct.groups[curGroupKey]) - 1 < 0) {
      return false;
    }
    if (
      finalProduct.groups[curGroupKey][
        objectSize(finalProduct.groups[curGroupKey]) - 1
      ].label === lbl.inventory_label_Name
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setGroupComponent = function (component) {
    setLblModel({});
    setCurLabels({});
    setLblCmpMdl({});
    let found = 0;
    lblCmpMdl.cmp = "";
    if (component.inventory_groups_Type == "Single") {
      if (!finalProduct.hasOwnProperty("groups")) {
        finalProduct.groups = {};
      }
      let tmpcmp = component;

      if (!checkEmpty(curStaticLabel)) {
        tmpcmp.label = curStaticLabel;
      }

      if (!finalProduct.groups.hasOwnProperty[curGroupKey]) {
        finalProduct.groups[curGroupKey] = {};
      }
      finalProduct.groups[curGroupKey][0] = tmpcmp;
    } else {
      if (finalProduct.hasOwnProperty("groups")) {
        Object.keys(finalProduct?.groups[curGroupKey] ?? {})?.forEach((k) => {
          const {
            inventory_groupsline_Id,
            inventory_component_Id,
            inventory_groups_Id,
          } = finalProduct.groups[curGroupKey][k];
          if (
            inventory_groupsline_Id === component.inventory_groupsline_Id &&
            inventory_component_Id === component.inventory_component_Id &&
            inventory_groups_Id === component.inventory_groups_Id
          ) {
            delete finalProduct.groups[curGroupKey][k];
            found = 1;
          }
        });
        finalProduct.groups[curGroupKey] = resetObject(
          finalProduct?.groups?.[curGroupKey]
        );
      } else {
        finalProduct.groups = {};
        finalProduct.groups[curGroupKey] = {};
      }
      if (found == 0) {
        let tmpcmp = copyObject(component);
        if (!checkEmpty(curStaticLabel)) {
          tmpcmp.label = curStaticLabel;
        }
        finalProduct.groups[curGroupKey] = resetObject(
          finalProduct.groups[curGroupKey]
        );
        finalProduct.groups[curGroupKey][
          objectSize(finalProduct.groups[curGroupKey])
        ] = tmpcmp;

        if (!checkEmpty(component.labels)) {
          const { labels, inventory_component_Id, inventory_groupsline_Id } =
            component;
          let curLabelss = labels;
          setCurLabels({});
          curLabelss?.forEach((l, ind) => {
            if (l?.inventory_label_Name.toLowerCase().trim() !== "free") {
              curLabels[ind] = l;
            }
          });
          curLabels.checkId = `${inventory_component_Id}-${inventory_groupsline_Id}`;
          setCurLabels(curLabels);
          setLblModel({});
        }
      }
    }
  };

  const submitGroup = function () {
    // if (parentRef.current) {
    //   parentRef.current.scrollTop = 0;
    // }
    setCurStaticLabel("");
    if (
      curGroup.inventory_groups_Type == "Single" &&
      (checkEmpty(finalProduct.groups) ||
        checkEmpty(finalProduct.groups[curGroupKey]))
    ) {
      error("Please select an item", true);
    } else {
      setCompModel({});
      setLblModel({});
      setCurLabels({});
      if (!checkEmpty(tempProduct.groups[curGroupKey + 1])) {
        setCurGroup(tempProduct.groups[curGroupKey + 1]);
        if (!finalProduct.hasOwnProperty("groups")) {
          finalProduct.groups = {};
        }
        finalProduct.groups[curGroupKey + 1] = {};
        setFinalProduct(finalProduct);
        setCurGroupKey(curGroupKey + 1);
      } else {
        resetData();
        submitProduct();
      }
    }
  };

  const resetData = () => {
    setCurGroup({});
    setCurGroupKey(0);
    setCurLabels({});
    setCurStaticLabel("");
    setCompModel({});
    setLblModel({});
    setTempProduct({});
    setFinalProduct({});
    onClose();
  };

  const countGroupMultiComponent = (component) => {
    let count = 0;
    if (!finalProduct.hasOwnProperty("groups")) {
      finalProduct.groups = {};
    }

    Object.keys(finalProduct?.groups[curGroupKey] ?? {}).forEach((k) => {
      const {
        inventory_groupsline_Id,
        inventory_component_Id,
        inventory_groups_Id,
      } = finalProduct.groups[curGroupKey][k];
      if (
        inventory_groupsline_Id == component?.inventory_groupsline_Id &&
        inventory_component_Id == component?.inventory_component_Id &&
        inventory_groups_Id == component?.inventory_groups_Id
      ) {
        count++;
      }
    });
    return count;
  };

  const getComponentChecked = (groupsItem) => {
    let found = 0;
    Object.keys(finalProduct?.groups?.[curGroupKey] ?? {}).forEach((val) => {
      if (
        groupsItem?.inventory_component_Id ===
        finalProduct?.groups?.[curGroupKey][val]?.inventory_component_Id
      ) {
        found = 1;
        return;
      }
    });
    return found;
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={resetData}
      className="flex justify-center items-center h-full px-0"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
    >
      <div
        style={{ maxHeight: width < 769 ? height : height * 0.8 }}
        className="h-full md:h-auto md:max-h-[80vh] my-0 bg-white md:rounded-lg w-full md:w-3/4 xl:w-2/5 flex flex-col"
      >
        <header className="fixed w-full md:w-3/4 xl:w-2/5 flex items-center justify-between px-7 py-2 rounded-t-lg border-b">
          <span className="text-mdl md:text-lg font-semibold">
            {finalProduct?.inventory_product_Name}
          </span>
          <Button
            onClick={() => resetData()}
            // className="hover:bg-light-shade-5"
          >
            <img src={Cross} className="w-10 h-10" alt="cross-button" />
          </Button>
        </header>

        <main ref={parentRef} className="flex-1 overflow-y-auto mt-14 mb-0">
          {finalProduct?.inventory_product_Description?.trim() && (
            <div className="mx-7">
              <span className="text-black-shade-11 text-sm mt-4">
                {finalProduct?.inventory_product_Description}
              </span>
            </div>
          )}
          {!checkEmpty(finalProduct?.groups) && (
            <div className="max-w-full px-7 flex flex-col">
              {Object.keys(finalProduct?.groups)?.map((grpFnl, key) => {
                if (
                  !checkEmpty(finalProduct?.groups[grpFnl]) &&
                  key != curGroupKey
                ) {
                  let tmpgrpcmp = [];
                  Object.keys(finalProduct?.groups[grpFnl]).forEach(
                    (grpFnl2) => {
                      const { label, inventory_component_Name } =
                        finalProduct.groups[grpFnl][grpFnl2];
                      tmpgrpcmp.push(
                        (label ?? "") + " " + inventory_component_Name
                      );
                    }
                  );
                  return (
                    <div
                      key={"hrpFnl2" + grpFnl}
                      className="flex items-center border-dark-shade-11 border bg-gray-shade-7 rounded-[4px] px-2 w-max max-w-full mb-4 first:mt-4 gap-x-2.5"
                    >
                      <div className="flex flex-wrap">
                        <p className="text-xs leading-5">
                          {tmpgrpcmp.join(", ")}
                        </p>
                      </div>
                      <MenuCheck />
                    </div>
                  );
                }
              })}
            </div>
          )}

          <div className="">
            <div className="bg-white-shade-7 px-7 py-4">
              <h3 className="text-sm font-medium">
                {curGroup?.inventory_groups_Heading}
                {curGroup?.inventory_groups_Default_Selection_Limit > 0 && (
                  <span>
                    (
                    {checkEmpty(
                      curGroup[
                        "inventory_groups_Default_Selection_" +
                          basket?.pos_basket_Delivery_Type +
                          "_Price"
                      ]
                    ) ? (
                      <span>Free</span>
                    ) : (
                      <span>
                        {currency}
                        {
                          curGroup[
                            "inventory_groups_Default_Selection_" +
                              basket?.pos_basket_Delivery_Type +
                              "_Price"
                          ]
                        }
                      </span>
                    )}{" "}
                    upto {curGroup?.inventory_groups_Default_Selection_Limit}{" "}
                    items)
                  </span>
                )}
              </h3>
            </div>

            <div className="mt-2">
              <div className="">
                {curGroup?.inventory_groups_Static_Labels == 1 && (
                  <div className="px-7">
                    {showGroup && (
                      <div className="flex space-x-2.5 my-3 md:my-5">
                        {curGroup?.staticLabels?.map((label, index) => {
                          return (
                            strtolower(label.inventory_label_Name) != "free" &&
                            !checkEmpty(label.inventory_label_Name) && (
                              <div
                                key={"cusomlabel" + index}
                                className={`${
                                  label.inventory_label_Name === curStaticLabel
                                    ? "border-primary text-primary"
                                    : "border-primary-shade-4 text-primary-shade-4"
                                } border p-2 rounded-[100px] bg-white text-sm md:text-md`}
                                onClick={() =>
                                  setCurStaticLabel(label?.inventory_label_Name)
                                }
                              >
                                <span>{label?.inventory_label_Name}</span>
                              </div>
                            )
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-y-4 md:gap-y-4 mb-4 md:mb-8 px-7 ">
                  {showGroup &&
                    curGroup?.groupitems?.map((groupsItem, index) => {
                      const label = getComponentLabel(curGroupKey, groupsItem);
                      return (
                        <div
                          key={"cartsingle" + index}
                          className={`space-y-2 ${curGroup?.inventory_groups_Type}`}
                        >
                          {curGroup?.inventory_groups_Type == "Multiple" &&
                          groupsItem.inventory_groupsline_Selection_Type ==
                            "Multiple" ? (
                            <div className="flex justify-between items-center">
                              <label
                                className={`text-xs ${
                                  label?.toLowerCase().trim() === "no"
                                    ? "line-through text-primary"
                                    : "text-black"
                                }`}
                              >
                                {label}
                                {groupsItem?.inventory_component_Name}
                              </label>
                              <div className="flex items-center justify-end">
                                <Button
                                  onClick={() =>
                                    setGroupMultiComponent("sub", groupsItem)
                                  }
                                >
                                  <div className="border rounded-lg px-1 py-1 w-min border-gray-shade-8 cursor-pointer">
                                    <MinusIcon className="w-3 h-3 md:w-5 md:h-5 text-center" />
                                  </div>
                                </Button>
                                <div className="text-xs md:text-sm font-medium w-6 md:w-8 text-center rounded-lg text-black-shade-6 cursor-default">
                                  {countGroupMultiComponent(groupsItem)}
                                </div>
                                <Button
                                  onClick={() =>
                                    setGroupMultiComponent("add", groupsItem)
                                  }
                                >
                                  <div className="border rounded-lg px-1 py-1 border-gray-shade-8 cursor-pointer">
                                    <PlusIcon className="w-3 h-3 md:w-5 md:h-5 text-center" />
                                  </div>
                                </Button>
                                <div className="ml-3">
                                  {objectSize(
                                    finalProduct?.groups?.[curGroupKey]
                                  ) >=
                                    groupsItem?.inventory_groups_Default_Selection_Limit && (
                                    <div className="text-xs">
                                      {currency}
                                      {
                                        groupsItem[
                                          "inventory_groupsline_" +
                                            basket?.pos_basket_Delivery_Type +
                                            "_Price"
                                        ]
                                      }
                                    </div>
                                  )}
                                  {objectSize(
                                    finalProduct?.groups?.[curGroupKey]
                                  ) <
                                    groupsItem?.inventory_groups_Default_Selection_Limit && (
                                    <div className="text-xs">
                                      {currency}
                                      {
                                        groupsItem[
                                          "inventory_groups_Default_Selection_" +
                                            basket?.pos_basket_Delivery_Type +
                                            "_Price"
                                        ]
                                      }
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-span-1">
                              <div
                                className="flex gap-1 items-center cursor-pointer"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setGroupComponent(groupsItem);
                                }}
                              >
                                {curGroup.inventory_groups_Type == "Single" ? (
                                  <div className="mr-2">
                                    <RadioButton
                                      checked={getComponentChecked(groupsItem)}
                                      onChange={() => {}}
                                    />
                                  </div>
                                ) : (
                                  <div className="mr-2">
                                    <Checkbox
                                      checked={getComponentChecked(groupsItem)}
                                      onChange={() => {}}
                                    />
                                  </div>
                                )}
                                <div className="flex w-full justify-between items-center">
                                  <label
                                    className={`text-sm font-medium ${
                                      label?.toLowerCase().trim() === "no"
                                        ? "line-through text-primary"
                                        : "text-black"
                                    }`}
                                  >
                                    {label}
                                    {groupsItem?.inventory_component_Name}
                                  </label>
                                  <span
                                    className={`text-sm font-medium ${
                                      label?.toLowerCase().trim() === "no"
                                        ? "line-through text-primary"
                                        : "text-black"
                                    }`}
                                  >
                                    {objectSize(
                                      finalProduct?.groups?.[curGroupKey]
                                    ) >=
                                      groupsItem?.inventory_groups_Default_Selection_Limit && (
                                      <div className="">
                                        {currency}
                                        {
                                          groupsItem[
                                            "inventory_groupsline_" +
                                              basket?.pos_basket_Delivery_Type +
                                              "_Price"
                                          ]
                                        }
                                      </div>
                                    )}
                                    {objectSize(
                                      finalProduct?.groups?.[curGroupKey]
                                    ) <
                                      groupsItem?.inventory_groups_Default_Selection_Limit && (
                                      <div className="">
                                        {currency}
                                        {
                                          groupsItem[
                                            "inventory_groups_Default_Selection_" +
                                              basket?.pos_basket_Delivery_Type +
                                              "_Price"
                                          ]
                                        }
                                      </div>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {curGroup?.inventory_groups_Static_Labels != 1 && (
                            <div className="block sm:hidden ">
                              {!checkEmpty(curLabels) &&
                                curLabels.checkId ==
                                  groupsItem?.inventory_component_Id +
                                    "-" +
                                    groupsItem?.inventory_groupsline_Id && (
                                  <div className="flex w-full space-x-6 justify-between my-4">
                                    {Object.keys(curLabels)?.map(
                                      (labelButton, index) => {
                                        if (
                                          curLabels[
                                            labelButton
                                          ]?.inventory_label_Name?.toLowerCase() !=
                                            "free" &&
                                          !checkEmpty(
                                            curLabels[labelButton]
                                              ?.inventory_label_Name
                                          )
                                        ) {
                                          return (
                                            <div
                                              className="flex"
                                              key={"labelButton" + index}
                                            >
                                              <div
                                                className="w-auto h-5 flex gap-x-1 md:gap-x-3 items-center cursor-pointer"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  setComponentLabel(
                                                    curLabels[labelButton]
                                                  );
                                                }}
                                              >
                                                <RadioButton
                                                  checked={getComponentLabelForButton(
                                                    curLabels[labelButton]
                                                  )}
                                                  onChange={() => {}}
                                                />
                                                <label>
                                                  {
                                                    curLabels[labelButton]
                                                      ?.inventory_label_Name
                                                  }
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="clear-both"></div>
              {curGroup?.inventory_groups_Static_Labels != 1 && (
                <div className="">
                  {!checkEmpty(curLabels) && (
                    <div className="border-orderDetailBD h-1 border-b hidden sm:block" />
                  )}
                  <div className="hidden sm:block w-full px-6">
                    {!checkEmpty(curLabels) && (
                      <div className="flex w-full space-x-6 justify-between my-4">
                        {Object.keys(curLabels)?.map((labelButton) => {
                          return (
                            curLabels[
                              labelButton
                            ]?.inventory_label_Name?.toLowerCase() != "free" &&
                            !checkEmpty(
                              curLabels[labelButton]?.inventory_label_Name
                            ) && (
                              <div
                                className="w-auto h-5 flex gap-x-1 md:gap-x-3 items-center cursor-pointer"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setComponentLabel(curLabels[labelButton]);
                                }}
                              >
                                <RadioButton
                                  checked={getComponentLabelForButton(
                                    curLabels[labelButton]
                                  )}
                                  onChange={() => {}}
                                />
                                <label>
                                  {curLabels[labelButton]?.inventory_label_Name}
                                </label>
                              </div>
                            )
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="clear-both"></div>
            </div>
          </div>
        </main>

        <div className="border-orderDetailBD h-1 border-b" />

        <footer className="flex justify-between items-center px-6 my-2.5">
          <Button
            onClick={() => submitGroup()}
            className="text-white w-1/2 bg-primary hover:bg-primary font-medium rounded-lg text-md inline-flex justify-center items-center p-3 text-center"
          >
            {checkEmpty(finalProduct?.groups?.[curGroupKey] ?? {}) &&
            curGroup?.inventory_groups_Type == "Single"
              ? "Select an item"
              : checkEmpty(finalProduct?.groups?.[curGroupKey] ?? {})
              ? "Skip Extras"
              : " Add to cart"}
          </Button>
          <div className="flex flex-col items-end w-1/2">
            <label className="text-sm sm:text-sm/[13px] text-dark-shade-10">
              Total
            </label>
            <label className="text-base sm:text-base font-semibold">
              {currency}
              {calculateFinalProductTotal()}
            </label>
          </div>
        </footer>
      </div>
    </Modal>
  );
};

export default CustomizeCart;
