const Card = ({
  cardunique,
  className,
  children,
  header,
  footer,
  disabled,
  val,
  onClick,
}) => {
  return (
    <div
      key={"kol" + cardunique}
      onClick={onClick}
      className={`${className} border-card-border border rounded-lg relative overflow-hidden cursor-pointer`}
    >
      {disabled && (
        <>
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-black opacity-70 flex flex-col items-center justify-center"></div>
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex flex-col items-center justify-center">
            <span className="text-white z-20 text-[16px] sm:text-[18px] 2xl:text-[20px]">
              Store Closed
            </span>
            {val?.store_Open_Delivery_Time && (
              <span className="text-white z-20 text-[14px] sm:text-[16px]">
                Opens at {val?.store_Open_Delivery_Time}
              </span>
            )}
          </div>
        </>
      )}
      {header && (
        <p className="text-[14px] sm:text-md mt-3 sm:mt-4 font-semibold text-black-shade-1">
          {header}
        </p>
      )}
      {children}
      {footer}
    </div>
  );
};

export default Card;
