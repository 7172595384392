import { configureStore } from "@reduxjs/toolkit";
import CreateSagaMiddleware from "redux-saga";
// import { rootSaga } from "../saga/rootSaga";
import { menuReduce } from "./menu/menuSlice";
import { checkoutReduce } from "./checkout/checkoutSlice";
import { basicDataReducer } from "./basicData/basicDataSlice";
import {
  profileDataReducer,
  walletDataReducer,
} from "./profileData/profileDataSlice";
import progressDataReducer from "./progressData/progressDataSlice";
import { apiLoaderDataReducer } from "./apiLoaderData/apiLoaderDataSlice";
import { pendingOrderReducer } from "./pendingOrders/pendingOrderSlice";
import userReducer from "./userData/userDataSlice";
import currentPageDataSlice from "./currentPage/currentPageDataSlice";
import analyticsDataSlice from "./analyticsData/analyticsDataSlice";

const sagaMiddleware = CreateSagaMiddleware();

const store = configureStore({
  reducer: {
    menu: menuReduce,
    checkoutStep: checkoutReduce,
    basicdata: basicDataReducer,
    userdata: userReducer,
    profiledata: profileDataReducer,
    walletData: walletDataReducer,
    progressdata: progressDataReducer,
    apiLoaderData: apiLoaderDataReducer,
    pendingOrder: pendingOrderReducer,
    curPage: currentPageDataSlice,
    analyticsData: analyticsDataSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

// sagaMiddleware.run(rootSaga);

export default store;
