import { useState, useEffect } from "react";
import { Breadcrumbs } from "../../components/Navigation/BreadCrumbs";
import React, { useRef } from "react";
import Layout from "../../layout/Layout";

import { useProgress } from "../../hooks/useProgress";

const TermsOfUse = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 80);
  };

  return (
    <div className="overflow-hidden	h-max font-[inter]">
      <Breadcrumbs
        className={`${
          isFixed
            ? "fixed animate-fadedown top-[76px] left-0 shadow-xl"
            : "animate-fadeup"
        } bg-white w-full z-50 flex items-center px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] overflow-hidden`}
      />

      <Layout className="carousel w-full bg-slate-200 	  space-x-2 lg:space-x-5  2xl:md:pb-8 xl:pd-8 md:pb-6 pb-7 xl:pl-[45px] pl-[15px] sm:pl-5 md:pl-[25px]">
        <div className=" w-full lg:px-40 lg:py-4">
          <div className="col-md-12 colmd_order">
            <h2 className="text-3xl	mb-2.5 mt-8 font-medium	">Terms of use</h2>
            <p className="mb-2.5 text-sm	text-gray-600">
              The conditions of use for this website, https://Kuick.com/, are
              set forth in these Website Terms of Use, along with any and all
              other documents referred to in them. Please read these Website
              Terms of Service carefully and make sure you comprehend them. When
              you use our Site for the first time, you agree to comply with and
              be bound by these Website Terms of Use. You must immediately cease
              using our Site if you do not agree to comply with and be governed
              by these Website Terms of Use.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              1.Introduction to Definitions and Interpretation
            </h3>
            <p className="mb-2.5 text-sm	text-gray-600">
              1.1 The following expressions have the following meanings in these
              Website Terms of Use, unless the context necessitates otherwise:
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Any and all text, photos, audio, video, scripts, code, software,
              databases, and any other form of information capable of being
              stored on a computer that appears on, or constitutes part of, our
              Site is referred to as "Content."
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Kuick, a corporation whose registered address is 539A Leek Road,
              Stoke-on-Trent, ST1 3ER. Tel: +44 1782 817644, is referred to as
              "Takeaway/We/Us/Our."{" "}
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              2. Our Website's Access
            </h3>
            <p className="mb-2.5 text-sm	text-gray-600">
              2.1 There is no charge to use our website.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              2.2 You are solely responsible for making any and all arrangements
              required to use our Site.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              2.3 Our Site is accessible on a "as is" and "as available" basis.
              We reserve the right to change, suspend, or terminate our Site (or
              any portion of it) at any time and without prior notice. If our
              Site (or any part of it) is inaccessible at any time or for any
              period, we will not be liable to you in any manner.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              3. Rights to Intellectual Property
            </h3>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.1 Unless otherwise stated, all Content on our Site, as well as
              the copyright and other intellectual property rights in that
              Content, belong to us or have been licenced by us. All content is
              protected by intellectual property laws and treaties in the United
              Kingdom and around the world.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.2 Except as provided in sections 3.3, you may not duplicate,
              copy, distribute, sell, rent, sub-license, store, or use Content
              from our Site in any other way unless we have given you express
              written permission to do so.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.3 You have the option to:
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.3.1 Use a web browser to access, display, and use our Site
              (including any web surfing functionality integrated into other
              types of software or apps);
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.3.2 Save a copy of our site (or a portion of it) for caching;
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.3.3 Download extracts from pages on our Site;{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.3.4 Print pages from our Site; and{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.3.5 You can save pages from our site to view later and/or
              offline.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.4 We must always indicate our status as the owner and author of
              the Content on our Site (or that of identified licensors, as
              applicable).
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3.5 You must obtain a licence from us (or our licensors, as
              applicable) before using any Content saved or downloaded from our
              Site for commercial purposes. This does not preclude business
              users or consumers from accessing, viewing, and using our Site for
              general information purposes.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              4. Our Website's Links
            </h3>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.1 You may link to our Site if you comply with the following
              conditions:
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.1.1 You do so in a fair and legal manner;{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.1.2 You do not do so in a way that implies any form of
              association, endorsement, or approval on our part where none
              exists;
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.1.3 You do not use any logos or trademarks displayed on our Site
              without our express written permission; and{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.1.4 You do not do so in a way that is intended to harm or take
              undue advantage of our reputation.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.2 Without our prior written permission, framing or embedding our
              Site on other websites is not permitted.{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3 You may not create a connection to our Site from any other
              website whose main content comprises any of the following:
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.1 is profane, intentionally insulting, abusive, or otherwise
              inflammatory;{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.2 advocates violence;{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.3 supports or assists in any illegal conduct
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.4 discriminates against or defames any individual, group, or
              class of individuals on the basis of race, sex, religion,
              nationality, handicap, sexual orientation, or age;
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.5 is calculated or otherwise likely to deceive another person;{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.6 is meant or otherwise likely to threaten, harass, annoy,
              frighten, inconvenience, upset, or embarrass another person;
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.7 is intended or likely to infringe (or threaten to infringe)
              on the privacy of another person;
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.8 impersonates a person in a deceptive manner or otherwise
              misrepresents a person's identity or connection in a deceptive
              manner (obvious parodies are not included in this definition if
              they do not fall under any of the other sections of this clause
              4.3);
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.9 suggests that you are affiliated with us even if you are
              not;
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.10 infringes on, or facilitates in the infringement of,
              another party's intellectual property rights (including, but not
              limited to, copyright, trademarks, and database rights); or{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.3.11 violates any legal responsibility owed to a third party,
              including, but not limited to, contractual and confidence duties.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4.4 The content limits in clause 4.3 do not apply to content
              submitted to sites by other users as long as the site's principal
              purpose complies with article 4.3. For example, you are not
              forbidden from publishing links on general-purpose social
              networking sites just because another user has done so. However,
              publishing links to websites that focus on or encourage the
              submission of such content from users is forbidden.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              5. Hypertext Links to Other Websites
            </h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              Our Site may include links to other websites. These sites are not
              under our control unless otherwise noted. We do not take any
              responsibility or obligation for the content of third-party
              websites.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">6. Disclaimers</h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              6.1 Our Site does not provide advice on which you should depend.
              It is just supplied for informational purposes.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              6.2 We make no assurance, warranty, or guarantee that our Site
              will satisfy your expectations, that it will not infringe on
              third-party rights, that it will be compatible with all software
              and hardware, or that it will be secure, to the extent permitted
              by law.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              6.3 We make reasonable measures to ensure that the Content on our
              Site is accurate, complete, and current. We do not, however, make
              any express or implied representations, warranties, or guarantees
              that the Content is complete, accurate, or up-to-date.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              7. Our Responsibility
            </h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              7.1 To the fullest extent permitted by law, we accept no liability
              to any user for any loss or damage, whether foreseeable or not,
              arising out of or in connection with the use of (or inability to
              use) our Site or the use of or reliance upon any Content included
              on our Site, in contract, tort (including negligence), for breach
              of statutory duty, or otherwise, arising out of or in connection
              with the use of (or inability to use) our Site or the use of (or
              inability to use
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              7.2 We disclaim any representations, warranties, and guarantees
              (whether express or implied) that may apply to our Site or any
              Content published on our Site to the fullest extent permitted by
              law.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              7.3 If you are a business user, we will not be responsible for any
              loss of profits, sales, business, or revenue; loss of business
              opportunity, goodwill, or reputation; loss of anticipated savings;
              business interruption; or any other indirect or consequential loss
              or damage.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              7.4 We take reasonable precautions to guarantee that our Site is
              free of viruses and other malware. We accept no responsibility for
              any loss or damage caused by a virus or other malware, a
              distributed denial of service attack, or any other harmful
              material or event that may harm your hardware, software, data, or
              other material as a result of your use of our Site (including
              downloading any Content from it) or any other site referred to on
              our Site.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              7.5 We do not assume or accept responsibility or liability for any
              disruption or non-availability of our Site due to external causes
              such as equipment failure by internet service providers, host
              equipment failure, communications network failure, natural events,
              acts of war, or legal restrictions and censorship.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              7.6 Nothing in these Website Terms of Use limits or excludes our
              liability for fraud or fraudulent misrepresentation, death or
              personal injury caused by negligence, or any other types of
              liability that cannot be limited or excluded by law. If you are a
              consumer using our Site, please contact your local Citizens Advice
              Bureau or Trading Standards Office for a complete list of your
              legal rights, including those relating to digital content.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              8. Viruses, Malware, and Security Viruses, Malware, and Security
            </h3>
            <p className="mb-2.5 text-sm	text-gray-600">
              8.1 We utilise all reasonable care and skill to keep our Site
              secure and free of viruses and other malware.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              8.2 You are responsible for preventing viruses, malware, and other
              internet security threats from infecting your hardware, software,
              data, and other materials.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              8.3 You must not intentionally introduce viruses or other malware,
              or any other malicious or technologically damaging content to or
              through our Site.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              8.4 You must not attempt to gain unauthorised access to any part
              of our Site, our Site's server, or any other server, computer, or
              database connected to our Site.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              8.5 You may not attack our Site using a denial-of-service attack,
              a distributed denial-of-service attack, or any other method.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              8.6 You may be committing a criminal offence under the Computer
              Misuse Act 1990 if you violate the provisions of sections 8.3 to
              8.5. Any and all breaches will be notified to the appropriate law
              enforcement agencies, and we will fully comply with them by
              disclosing your identity. In the event of such a breach, your
              permission to use our Site will be immediately terminated.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              9. Acceptable Usage Policy{" "}
            </h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.1 You may only use our Site in a lawful manner. Specifically:
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.1.1 You must abide by all applicable local, national, and
              international laws and regulations;{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.1.2 You must not use our Site in any way or for any purpose that
              is illegal or fraudulent.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.1.3 You shall not use our Site to send, upload, or otherwise
              transfer data that contains any virus or other malware, or any
              other code designed to harm computer hardware, software, or data
              of any sort; and
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.1.4 You may not use our Site in any form or for any purpose that
              is intended to cause harm to any person or individuals.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2 If you materially infringe the requirements of this clause 9
              or any other condition of these Website Terms of Use, we reserve
              the right to suspend or cancel your access to our Site. We could,
              for example, take one or more of the following steps:
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2.1 revoke your permission to visit our Site, whether
              temporarily or permanently;
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2.2 issue you with a written warning;{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2.3 initiate legal action against you for reimbursement of any
              and all relevant costs incurred as a result of your breach on an
              indemnity basis;{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2.2 issue you with a written warning;{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2.3 pursue legal action against you for reimbursement of any and
              all relevant costs incurred as a result of your breach on an
              indemnity basis;{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2.4 pursue additional legal action against you as necessary;
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2.5 disclose such information to law enforcement authorities as
              required or as we deem reasonable; and/or{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.2.6 Any other actions we deem reasonable (and lawful).
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              9.3 We disclaim all liability arising from any actions (including,
              but not limited to, those listed above) taken in response to
              violations of these Website Terms of Use.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              10. Cookies and Privacy
            </h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              Our Privacy Policy, which can be found at Privacy Policy, also
              governs your use of our Site. By this reference, our Privacy
              Policy is included into these Website Terms of Use.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              11. Modifications to the Website's Terms of Service
            </h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              11.1 We reserve the right to change these Website Terms of Service
              at any time. Any such modifications shall bind you upon your first
              use of our Site following the implementation of the changes. As a
              result, you should revisit this page from time to time.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              11.2 If there is a discrepancy between the current version of
              these Website Terms of Use and any previous version(s), the
              current and effective provisions will take precedence unless
              otherwise stated.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              12. How to Contact Us
            </h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              Please send us an email at support@redoq.com, call us at +44 1782
              817644, or write to us at 539A Leek Road, Stoke-on-Trent, ST1 3ER.
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              13. Information from Us
            </h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              13.1 If we have your email address, we may send you important
              messages from time to time. These notices may include, but are not
              limited to, service adjustments and updates to the Website Terms
              of Service.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              13.2 Without your express permission, we will never send you
              marketing materials of any type. You can withdraw your consent at
              any moment if you give it. We include an unsubscribe link in all
              marketing texts we send. It may take up to 7 business days for us
              to comply with your request if you opt out of receiving emails
              from us at any time. You may continue to receive messages from us
              throughout that period. Our Privacy Policy has additional
              information.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              13.3 Please contact us using the information in paragraph 12 if
              you have any queries or complaints regarding our communications
              (including, but not limited to, marketing emails).
            </p>

            <h3 className="text-2xl	mb-2.5 mt-8 font-medium	">
              14. Jurisdiction and Law
            </h3>

            <p className="mb-2.5 text-sm	text-gray-600">
              14.1 These Website Terms of Use, as well as any contractual or
              other relationship between you and us, shall be governed by and
              construed in accordance with the laws of England and Wales.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              14.2 As a consumer, you shall be protected by any obligatory legal
              provisions in your country of residence. Nothing in section 14.1
              affects or eliminates your consumer rights to rely on such
              provisions.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              14.3 If you are a consumer, any dispute, controversy, proceedings,
              or claim between you and us relating to these Website Terms of
              Use, or our relationship (whether contractual or otherwise), will
              be decided by the courts of England, Wales, Scotland, or Northern
              Ireland, as determined by your residence.
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default TermsOfUse;
