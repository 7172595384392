import { useRef, useState, useEffect } from "react";
import ArrowLeft from "../Icon/ArrowLeft";
import ArrowRight from "../Icon/ArrowRight";
import Layout from "../../layout/Layout";

const ImageSlider = ({
  title,
  data,
  renderItem,
  className,
  subclassName,
  type = "carousel",
}) => {
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const carousel = useRef(null);

  const updateButtonStates = (scrollLeft, offsetWidth, scrollWidth) => {
    setIsPrevDisabled(scrollLeft === 0);
    setIsNextDisabled(Math.ceil(scrollLeft + offsetWidth) >= scrollWidth);
  };

  const movePrev = () => {
    if (carousel.current) {
      const newIndex = Math.max(currentIndex - 1, 0);
      const scrollToPosition = carousel.current.offsetWidth * newIndex;
      setCurrentIndex(newIndex);
      carousel.current.scrollTo({
        left: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const moveNext = () => {
    if (carousel.current) {
      const newIndex = Math.min(
        currentIndex + 1,
        Math.floor(carousel.current.scrollWidth / carousel.current.offsetWidth)
      );
      const scrollToPosition = carousel.current.offsetWidth * newIndex;
      setCurrentIndex(newIndex);
      carousel.current.scrollTo({
        left: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (carousel.current) {
      updateButtonStates(
        carousel.current.scrollLeft,
        carousel.current.offsetWidth,
        carousel.current.scrollWidth
      );
    }
  }, [currentIndex]);

  useEffect(() => {
    const carouselElement = carousel.current;

    const handleScroll = () => {
      if (carouselElement) {
        const newCurrentIndex = Math.round(
          carouselElement.scrollLeft / carouselElement.offsetWidth
        );
        setCurrentIndex(newCurrentIndex);
        updateButtonStates(
          carouselElement.scrollLeft,
          carouselElement.offsetWidth,
          carouselElement.scrollWidth
        );
      }
    };

    if (carouselElement) {
      maxScrollWidth.current =
        carouselElement.scrollWidth - carouselElement.offsetWidth;
      carouselElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const shouldHideButtons = isPrevDisabled && isNextDisabled;

  return (
    <div className={`h-max ${className}`}>
      <Layout className="flex justify-between items-center">
        {title}
        {!shouldHideButtons && type === "carousel" && (
          <>
            <div className={`sm:hidden flex space-x-2 ${subclassName}`}>
              <button
                onClick={movePrev}
                className="w-5"
                disabled={isPrevDisabled}
              >
                <ArrowLeft
                  className={`${
                    isPrevDisabled ? "fill-black opacity-40" : "fill-black"
                  }`}
                />
              </button>
              <button
                onClick={moveNext}
                className="w-5"
                disabled={isNextDisabled}
              >
                <ArrowRight
                  className={`${
                    isNextDisabled ? "fill-black opacity-40" : "fill-black"
                  }`}
                />
              </button>
            </div>
            <div
              className={`hidden sm:flex space-x-2 min-[1920px]:hidden ${subclassName}`}
            >
              <button
                onClick={movePrev}
                className="w-9"
                disabled={isPrevDisabled}
              >
                <ArrowLeft
                  height={30}
                  width={30}
                  className={`${
                    isPrevDisabled ? "fill-black opacity-40" : "fill-black"
                  }`}
                />
              </button>
              <button
                onClick={moveNext}
                className="w-9"
                disabled={isNextDisabled}
              >
                <ArrowRight
                  height={30}
                  width={30}
                  className={`${
                    isNextDisabled ? "fill-black opacity-40" : "fill-black"
                  }`}
                />
              </button>
            </div>
            <div className="hidden space-x-2 min-[1920px]:flex">
              <button onClick={movePrev} disabled={isPrevDisabled}>
                <ArrowLeft
                  height={55}
                  width={55}
                  className={`${
                    isPrevDisabled ? "fill-black opacity-40" : "fill-black"
                  }`}
                />
              </button>
              <button onClick={moveNext} disabled={isNextDisabled}>
                <ArrowRight
                  height={55}
                  width={55}
                  className={`${
                    isNextDisabled ? "fill-black opacity-40" : "fill-black"
                  }`}
                />
              </button>
            </div>
          </>
        )}
      </Layout>

      {/* Category cards */}
      <div
        ref={carousel}
        className={
          type === "carousel"
            ? "px-[14px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] flex opt-6 md:lg:xl:2xl:pb-8 space-x-3 xl:space-x-8 2xl:space-x-9 overflow-y-hidden overflow-x-auto no-scrollbar"
            : "px-[14px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] grid grid-cols-2 gap-3 md:gap-5 md:grid-cols-3  md:pt-9 pt-4  lg:grid-cols-4"
        }
      >
        {data.map((val, index) => (
          <div key={`banner${val.id || index}`}>{renderItem(val)}</div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
