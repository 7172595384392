export default function LoyaltyLoader({ className }) {
  return (
    <div className={`animate-pulse grid grid-cols-1 gap-4 ${className}`}>
      {[...Array(3)].map((_, index) => (
        <div key={index} className="bg-gray-300 w-full h-32 rounded-lg p-4">
          <div className="justify-between flex">
            <p className="bg-gray-400 w-40 h-2 rounded-sm"></p>
            <p className="bg-gray-400 w-16 h-4 rounded-sm"></p>
          </div>
          <p className="bg-gray-400 w-28 h-2 rounded-sm mb-2"></p>
          <p className="bg-gray-400 w-20 h-2 rounded-sm mb-2"></p>
          <p className="bg-gray-400 w-28 h-2 rounded-sm mb-2"></p>
        </div>
      ))}
    </div>
  );
}
