const WalletIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 33 34"
    >
      <path d="M12 11H11.25C11.25 11.4142 11.5858 11.75 12 11.75V11ZM6 17V16.25C5.80109 16.25 5.61032 16.329 5.46967 16.4697C5.32902 16.6103 5.25 16.8011 5.25 17H6ZM6 21H5.25C5.25 21.4142 5.58579 21.75 6 21.75V21ZM10 17V17.75V17ZM10 21V21.75V21ZM26 10.25H10V11.75H26V10.25ZM5.25 15V23H6.75V15H5.25ZM10 27.75H22V26.25H10V27.75ZM26.75 23V11H25.25V23H26.75ZM22 27.75C24.6234 27.75 26.75 25.6234 26.75 23H25.25C25.25 24.7949 23.7949 26.25 22 26.25V27.75ZM5.25 23C5.25 25.6234 7.37665 27.75 10 27.75V26.25C8.20507 26.25 6.75 24.7949 6.75 23H5.25ZM10 10.25C7.37665 10.25 5.25 12.3766 5.25 15H6.75C6.75 13.2051 8.20507 11.75 10 11.75V10.25ZM22 6.25H16V7.75H22V6.25ZM12 11.75H26V10.25H12V11.75ZM16 6.25C13.3766 6.25 11.25 8.37665 11.25 11H12.75C12.75 9.20507 14.2051 7.75 16 7.75V6.25ZM22 7.75C23.7949 7.75 25.25 9.20507 25.25 11H26.75C26.75 8.37665 24.6234 6.25 22 6.25V7.75ZM5.25 17L5.25 21H6.75L6.75 17H5.25ZM6 21.75H10V20.25H6V21.75ZM10 16.25H6V17.75H10V16.25ZM12.75 19C12.75 17.4812 11.5188 16.25 10 16.25V17.75C10.6904 17.75 11.25 18.3096 11.25 19H12.75ZM10 21.75C11.5188 21.75 12.75 20.5188 12.75 19H11.25C11.25 19.6904 10.6904 20.25 10 20.25V21.75Z" />
    </svg>
  );
};

export default WalletIcon;
