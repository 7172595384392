import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DrawerHeader from "../Header/DrawerHeader";
import Button from "./Button";
import ApplyIcon from "../../assets/images/apply.png";
import { checkCoupon, getCoupons } from "../../services";
import { checkEmpty } from "../../utils/helper";
import { error, success } from "../notify";
import { setCartConfigSession } from "../../utils";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";

const OfferSection = ({ isDrawer, onClose }) => {
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [code, setCode] = useState("");
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    getCoupons().then((res) => {
      const { status, data } = res;
      if (status === 200) {
        if (data?.status === "success") {
          setCoupons(data?.couponList);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (loading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);
      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      clearInterval(intervalData);
      dispatch(setProgressData(100));
    }
  }, [loading]);

  const applyCoupon = async (barcode) => {
    setLoading(true);
    const { status, data } = await checkCoupon(barcode);
    setLoading(false);
    if (status === 200) {
      if (data?.status === "success") {
        success(data?.msg);
        dispatch(setBasketData(data?.basket));
        setCartConfigSession(data?.cartConfig);
        onClose();
      } else if (data?.status === "error") {
        error(data?.msg);
      }
    }
  };

  return (
    <div>
      {!isDrawer && <DrawerHeader title={"Coupon"} onClose={onClose} />}
      <div className="px-6 space-y-2.5 pb-5">
        <div className="">
          <input
            type="text"
            id="email-address-icon"
            className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full  ps-3.5 p-2.5"
            placeholder="Enter coupon"
            value={code}
            autoComplete="off"
            onChange={(event) => setCode(event.target.value)}
            maxLength={50}
          />
        </div>
        <Button
          type="primary"
          className="w-full py-2 bg-primary rounded-lg  text-white text-base font-semibold font-inter text-center 2xl:text-x mb-4"
          onClick={() => {
            if (!checkEmpty(code.trim())) applyCoupon(code);
          }}
        >
          APPLY COUPON
        </Button>
        {coupons?.map((value, index) => {
          const {
            common_coupon_Barcode,
            common_coupon_Quantity,
            common_coupon_Heading,
            common_coupon_Description,
          } = value;
          return (
            common_coupon_Quantity !== 0 && (
              <div
                key={`coupon${index}`}
                className="rounded-lg p-3 md:p-5 bg-secondary flex justify-between shadow-md"
              >
                <div className="flex flex-col">
                  <span className="text-mdl font-semibold text-primary">
                    {common_coupon_Barcode}
                  </span>
                  <span className="text-sm font-semibold mt-3">
                    {common_coupon_Heading}
                  </span>
                  <span className="text-xs font-normal text-coupon-description">
                    {common_coupon_Description}
                  </span>
                </div>
                <div>
                  <Button
                    onClick={() => applyCoupon(common_coupon_Barcode)}
                    className="flex w-max items-center gap-x-2.5 py-1.5 px-3 bg-white border border-coupon-border rounded-[4px]"
                  >
                    <img
                      src={ApplyIcon}
                      alt="apply"
                      className="w-3 h-3 mdl:w-4 mdl:h-4"
                    />
                    <span className="text-primary text-xs font-semibold">
                      Apply
                    </span>
                  </Button>
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default OfferSection;
