const ArrowDown = ({ className, width, height }) => {
  return (
    // <svg
    //   className={className}
    //   width={width ? width : 18}
    //   height={height ? height : 18}
    //   viewBox="0 0 18 18"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g id="Layer_1" clipPath="url(#clip0_6142_51423)">
    //     <path
    //       id="Vector"
    //       d="M9.00021 18C13.9707 18 18 13.9707 18 9.00021C18 4.02972 13.9707 0 9.00021 0C4.02972 0 0 4.02972 0 9.00021C0.00548363 13.9682 4.03183 17.9945 9.00021 18ZM9.00021 0.900276C13.4737 0.900276 17.1001 4.52668 17.1001 9.00021C17.1001 13.4737 13.4737 17.1001 9.00021 17.1001C4.52668 17.1001 0.900276 13.4737 0.900276 9.00021C0.905338 4.52879 4.52879 0.905338 9.00021 0.900276Z"
    //     />
    //     <path
    //       id="Vector_2"
    //       d="M10.0545 12.4883C10.2418 12.652 10.5262 12.633 10.6894 12.4457C10.8531 12.2584 10.8337 11.974 10.6468 11.8108L7.43387 8.99982L10.6468 6.18847C10.8342 6.02478 10.8531 5.74044 10.6894 5.55355C10.5258 5.36624 10.2414 5.34726 10.0545 5.51094L6.4547 8.66106C6.35683 8.74628 6.30114 8.86988 6.30114 8.99982C6.30114 9.12976 6.35725 9.25294 6.4547 9.33858L10.0545 12.4883Z"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_6142_51423">
    //       <rect
    //         width="18"
    //         height="18"
    //         fill="white"
    //         transform="matrix(-1 0 0 -1 18 18)"
    //       />
    //     </clipPath>
    //   </defs>
    // </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={width ? width : 24}
    //   height={height ? height : 24}
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   className={className}
    // >
    //   <mask
    //     id="mask0_10968_37566"
    //     style="mask-type:alpha"
    //     maskUnits="userSpaceOnUse"
    //     x="0"
    //     y="0"
    //     width="24"
    //     height="24"
    //   >
    //     <rect
    //       y="24"
    //       width="24"
    //       height="24"
    //       transform="rotate(-90 0 24)"
    //       fill="#D9D9D9"
    //     />
    //   </mask>
    //   <g mask="url(#mask0_10968_37566)">
    //     <path
    //       d="M22 8L12 18L2 8L3.775 6.225L12 14.45L20.225 6.225L22 8Z"
    //       fill="#FF0A38"
    //     />
    //   </g>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_10968_40907)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.05781 4.98335C1.58281 4.45835 2.44115 4.45835 2.97448 4.98335L10.1411 12.15L17.3078 4.98335C17.8328 4.45835 18.6911 4.45835 19.2245 4.98335C19.7495 5.50835 19.7495 6.36668 19.2245 6.90002L11.0995 15.025C10.5745 15.55 9.71615 15.55 9.18281 15.025L1.05781 6.89168C0.532812 6.36668 0.532812 5.50835 1.05781 4.98335Z"
          fill="#FF0A38"
        />
      </g>
      <defs>
        <clipPath id="clip0_10968_40907">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.140625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowDown;
