import { useEffect } from "react";
// import { FallingLines } from "react-loader-spinner";

export default function Loader({ visible }) {
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = visible ? "hidden" : "auto";
  }, [visible]);

  return (
    visible && (
      <div
        onClick={(event) => {
          event.preventDefault();
        }}
        className={`fixed inset-0 overflow-auto flex justify-center items-center transition-colors z-50 visible bg-black/10 rounded-lg`}
      >
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className="relative p-4 max-h-full"
        >
          <div className="relative">
            {/* <FallingLines
              color="#FF0A38"
              width="100"
              visible={visible}
              ariaLabel="falling-circles-loading"
            /> */}
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
