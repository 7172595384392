const ArrowRightChevron = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 6 12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.781506 0.414376C0.45806 0.673133 0.405619 1.1451 0.664376 1.46855L4.28956 6.00003L0.664376 10.5315C0.405618 10.855 0.458059 11.3269 0.781506 11.5857C1.10495 11.8444 1.57692 11.792 1.83568 11.4685L5.83568 6.46855C6.05481 6.19464 6.05481 5.80542 5.83568 5.53151L1.83568 0.531506C1.57692 0.20806 1.10495 0.155619 0.781506 0.414376Z"
        fill=""
      />
    </svg>
  );
};

export default ArrowRightChevron;
