export default function BankCardLoader({
  className,
  subClassName,
  subsubClassName,
}) {
  return (
    <div className={`animate-pulse  `}>
      <div
        className={` sm:bg-neutral-100 sm:w-full sm:rounded-lg relative ${className}`}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 my-10 md:my-8 gap-4">
          <div className="h-24 bg-gray-300 rounded"></div>
          <div className="h-24 bg-gray-300 rounded"></div>
          <div className="h-24 bg-gray-300 rounded"></div>
          <div className="h-24 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  );
}
