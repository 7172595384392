import ReactPixel from "react-facebook-pixel";
import { checkEmpty } from "../utils/helper";
import { useDispatch } from "react-redux";
import { setPXL } from "../store/analyticsData/analyticsDataSlice";

const useSetupFAnalytics = () => {
  const dispatch = useDispatch();
  const setupFAnalytics = async ({ settings }) => {
    if (checkEmpty(settings) || checkEmpty(settings.pixel) || checkEmpty(settings.pixel[0])) {
      dispatch(setPXL(0));
      return false;
    }
    const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: import.meta.env.MODE == 'development'?true:false, // enable logs
    };
    ReactPixel.init(settings.pixel[0], advancedMatching, options);
    dispatch(setPXL(1));
    ReactPixel.pageView({ hitType: "pageview", page: window.location.pathname });
  };

  return setupFAnalytics;
};

export default useSetupFAnalytics;
