import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Button from "../../components/Elements/Button";
import { emailIdrRegEx } from "../../utils";

import { unsubscribe } from "../../services";
import { success, error } from "../../components/notify";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useDispatch } from "react-redux";
import DrawerHeader from "../Header/DrawerHeader";
import Layout from "../../layout/Layout";

import { HomePage } from "../../routes/RoutesPath";
import { capitalizeFirstWord } from "../../utils";
import GreaterThan from "../../assets/images/chevron-right.png";
import SubHeader from "../../components/Loader/MenuLoaders/SubHeader";
import { Link } from "react-router-dom";

const UnsubscribeModal = ({
  className,
  isOpen,
  onClose,
  isLoader,
  key,
  fromWhere = "",
}) => {
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [emailOrPhoneValid, setEmailOrPhoneValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [phoneValid, setphoneValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [intervalData, setintervalData] = useState(0);
  const { progressFunction } = useProgress();
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 100);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading]);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const unsubscribeFun = async () => {
    checkEmailOrPhoneChange();
  };

  const checkEmailOrPhoneChange = async () => {
    if (emailOrPhone.length === 0) {
      setEmailOrPhoneValid(true);
      setEmailValid(false);
      setphoneValid(false);
    } else {
      setEmailOrPhoneValid(false);
      if (isNaN(emailOrPhone) === true) {
        setphoneValid(false);
        if (emailIdrRegEx.test(emailOrPhone) === false) {
          setEmailValid(true);
          setphoneValid(false);
        } else {
          setEmailValid(false);

          if (
            emailOrPhoneValid == false &&
            emailValid == false &&
            phoneValid == false &&
            emailOrPhone != ""
          ) {
            setIsLoading(true);
            try {
              const { status, data } = await unsubscribe(emailOrPhone);
              if (status === 200) {
                if (data.status === "success") {
                  success(data.msg);
                  setEmailOrPhone("");
                }
                setIsLoading(false);
              }
            } catch (e) {
              setIsLoading(false);
              error("error msg");
            } finally {
              setIsLoading(false);
            }
          }
        }
      } else {
        if (emailOrPhone.length < 11 || emailOrPhone.length > 11) {
          setphoneValid(true);
          setEmailValid(false);
        } else if (emailOrPhone.length == 11) {
          setphoneValid(false);
          setEmailValid(false);

          if (
            emailOrPhoneValid == false &&
            emailValid == false &&
            phoneValid == false &&
            emailOrPhone != ""
          ) {
            setIsLoading(true);
            try {
              const { status, data } = await unsubscribe(emailOrPhone);
              if (status === 200) {
                // alert();
                if (data.status === "success") {
                  success(data.msg);
                  setEmailOrPhone("");
                }
                setIsLoading(false);
              }
            } catch (e) {
              setIsLoading(false);
              error("error msg");
            } finally {
              setIsLoading(false);
            }
          }
        }
      }
    }
  };

  const handleEmailOrPhoneChange = (event) => {
    const nameOrPhone = event.target.value;
    if (nameOrPhone.length <= 50) {
      setEmailOrPhone(nameOrPhone);

      if (event.target.value.length === 0) {
        setEmailOrPhoneValid(true);
        setEmailValid(false);
        setphoneValid(false);
      } else {
        setEmailOrPhoneValid(false);

        setEmailValid(false);
        setphoneValid(false);
      }
    }
  };

  return fromWhere == "footer" ? (
    <div
      key={key}
      className={`w-full md:w-[448px] ${className} pb-12 mdl:pb-8`}
    >
      <DrawerHeader
        title={"Unsubscription"}
        onClose={onClose}
        description={
          "Please enter your details to unsubscribe from promotional sms/email"
        }
      />

      <div className="mt-4 px-6 h-[90px] relative">
        <div className="relative">
          <label htmlFor="email-address-icon" className="block mb-2 text-sm">
            Enter Email/Phone
            <span className="text-primary text-sm">*</span>
          </label>
          <input
            value={emailOrPhone}
            onChange={handleEmailOrPhoneChange}
            type="text"
            className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500  focus:outline-none block w-full  ps-3.5 p-2.5"
            placeholder="Enter Email/Phone"
            autoComplete="off"
          />
          <span className="text-primary text-sm absolute top-0 right-0">
            {emailOrPhoneValid && "This field is required"}
          </span>
          <span className="text-primary text-sm absolute top-0 right-0">
            {emailValid && "Invalid email"}
          </span>
          <span className="text-primary text-sm absolute top-0 right-0">
            {phoneValid && "Invalid mobile number"}
          </span>
        </div>

        <Button
          onClick={() => {
            unsubscribeFun();
          }}
          className={`text-white w-full mt-5 bg-primary focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center`}
        >
          Unsubscribe
        </Button>
      </div>
    </div>
  ) : (
    <div>
      <Layout
        className={`bg-secondary ${
          isFixed
            ? "fixed animate-fadedown top-[76px] left-0 shadow-xl px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px]"
            : "animate-fadeup"
        } bg-white w-full z-50 mdl:flex items-center hidden`}
      >
        <Link to={HomePage} className="flex items-center">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              {capitalizeFirstWord("Home")}
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </Link>

        <Link to={location.pathname} className="flex items-center">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-active-route">
              {capitalizeFirstWord("Unsubscribe")}
            </span>
          )}
        </Link>
      </Layout>
      <Layout className="bg-slate-100	min-h-screen w-full">
        <div className="min-h-screen bg-white 2xl:px-4 2xl:mx-auto 2xl:w-[1170px]">
          <div className="px-4 mx-auto lg:w-3/5 w-full ">
            <div className="m-0 p-4 bg-slate-100 2xl:h-[100px] h-[100px] md:h-[80px]">
              <div className="2xl:text-3xl font-bold	2xl:leading-relaxed text-xl	">
                Unsubscription
              </div>
              <div className="2xl:text-sm	font-bold text-xs	">
                Please enter your details to unsubscribe from promotional
                sms/email
              </div>
            </div>

            <div className="mt-4  relative">
              <label
                htmlFor="email-address-icon"
                className="block mb-2 text-sm"
              >
                Enter Email/Phone
                <span className="text-primary text-sm">*</span>
              </label>

              <input
                value={emailOrPhone}
                onChange={handleEmailOrPhoneChange}
                type="text"
                className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full  ps-3.5 p-2.5"
                placeholder="Enter Email/Phone"
                autoComplete="off"
              />

              <span className="text-primary text-sm absolute top-0 right-0">
                {emailOrPhoneValid && "This field is required"}
              </span>
              <span className="text-primary text-sm absolute top-0 right-0">
                {emailValid && "Invalid email"}
              </span>
              <span className="text-primary text-sm absolute top-0 right-0">
                {phoneValid && "Invalid mobile number"}
              </span>
            </div>
            <Button
              onClick={() => {
                unsubscribeFun();
              }}
              className={`text-white w-full mt-3 bg-primary focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center`}
            >
              Unsubscribe
            </Button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default UnsubscribeModal;
