const Payment = ({ width, height, className }) => {
  return (
    <svg
      width={width ? width : "40"}
      height={height ? height : "40"}
      className={className}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3457_6490)">
        <path d="M38.2812 7.73438C38.2812 6.22422 37.057 5 35.5469 5H2.73438C1.22422 5 0 6.22422 0 7.73438V10.1234H38.2812V7.73438Z" />
        <path d="M22.8125 20.0258C22.8148 18.0851 24.3875 16.5125 26.3281 16.5101C27.4062 16.5117 28.4578 16.1765 29.3359 15.5515L30.543 14.6922C31.7617 13.8265 33.3953 13.8265 34.6141 14.6922L35.8203 15.5515C36.5461 16.0695 37.3945 16.3906 38.2812 16.4844V12.4672H0V28.8461C0 30.3562 1.22422 31.5804 2.73438 31.5804H24.4133C23.3602 29.6554 22.8094 27.4961 22.8125 25.3015V20.0258ZM5.35078 17.9539H6.11641C6.76328 17.9539 7.28828 18.4789 7.28828 19.1258C7.28828 19.7726 6.76328 20.2976 6.11641 20.2976H5.35078C4.70391 20.2976 4.17891 19.7726 4.17891 19.1258C4.17891 18.4789 4.70391 17.9539 5.35078 17.9539ZM15.3797 25.3125H5.35078C4.70391 25.3125 4.17891 24.7875 4.17891 24.1406C4.17891 23.4937 4.70391 22.9687 5.35078 22.9687H15.3797C16.0266 22.9687 16.5516 23.4937 16.5516 24.1406C16.5516 24.7875 16.0266 25.3125 15.3797 25.3125ZM15.3797 20.2976H10.7133C10.0664 20.2976 9.54141 19.7726 9.54141 19.1258C9.54141 18.4789 10.0664 17.9539 10.7133 17.9539H15.3797C16.0266 17.9539 16.5516 18.4789 16.5516 19.1258C16.5516 19.7726 16.0266 20.2976 15.3797 20.2976Z" />
        <path d="M38.8281 18.8539C37.2641 18.8562 35.7383 18.3703 34.4641 17.4625L33.2562 16.6031C32.85 16.3148 32.3063 16.3148 31.9 16.6031L30.6922 17.4625C29.418 18.3703 27.8922 18.8562 26.3281 18.8539C25.6813 18.8539 25.1562 19.3789 25.1562 20.0258V25.3015C25.1562 29.8008 27.9531 33.8258 32.1695 35.3953C32.4328 35.4937 32.7234 35.4937 32.9867 35.3953C37.2031 33.8258 40 29.8008 40 25.3015V20.0258C40 19.3789 39.475 18.8539 38.8281 18.8539ZM35.8016 25.0922L32.9625 28.3148C32.5344 28.8008 31.7945 28.8476 31.3086 28.4195C31.2719 28.3867 31.2367 28.3523 31.2039 28.3148L29.3547 26.2164C28.9266 25.7305 28.9742 24.9898 29.4602 24.5625C29.9461 24.1351 30.6867 24.182 31.1141 24.668L32.0828 25.768L34.0422 23.5437C34.4703 23.0578 35.2109 23.0109 35.6961 23.4383C36.1813 23.8656 36.2289 24.607 35.8016 25.0922Z" />
      </g>
      <defs>
        <clipPath id="clip0_3457_6490">
          <rect
            width={width ? width : "40"}
            height={height ? height : "40"}
            transform="translate(0 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Payment;
