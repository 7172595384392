import { useState, useEffect } from "react";
import Calendar from "../Icon/Calendar";
import { getReview } from "../../services";
import ReviewLoader from "../../components/Loader/MenuLoaders/ReviewLoader";
import { StarIcon } from "@heroicons/react/24/solid";
import { checkEmpty, completeDateFormatter } from "../../utils/helper";
import nodata_found from "../../assets/images/no-data-found/profile/pastorders/nodata.png";
import ReadMore from "../Elements/ReadMore";
const Reviewcard = () => {
  const [reviewsData, setReviewsData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);
  const [hideLoadMore, setHideLoadMore] = useState(false);
  const take = 5;

  useEffect(() => {
    getReviewsData(skip, take);
  }, [skip]);

  const loadMoreReviews = () => {
    setIsFirstTimeLoad(false);
    setSkip((prev) => prev + take);
  };

  const getReviewsData = async (skip, take) => {
    isFirstTimeLoad ? setIsLoader(true) : setIsLoadMore(true);
    try {
      const { status, data } = await getReview(skip, take);
      if (status === 200) {
        if (data.status === "success") {
          setIsLoader(false);
          if (data.reviews.length < 5) {
            setHideLoadMore(true);
          }
          setReviewsData((prevItems) => [...prevItems, ...data.reviews]);
        }
      }
    } catch (error) {
      console.error("error msg", error);
    } finally {
      isFirstTimeLoad ? setIsLoader(false) : setIsLoadMore(false);
    }
  };

  // const starLoop = [1, 2, 3, 4, 5];

  return (
    <div className="w-full">
      <div className={`h-auto ${reviewsData.length == 0 ? "pb-0" : "pb-4"}`}>
        {isLoader ? (
          <ReviewLoader />
        ) : checkEmpty(reviewsData) ? (
          <div className="flex justify-center items-center w-full h-[400px] sm:min-h-[calc(100vh_-_17rem)] sm:h-auto mdl:hidden">
            <div className="lg:w-[350px]">
              <img src={nodata_found} alt="No data found image" />
            </div>
          </div>
        ) : (
          <div className="">
            {" "}
            {reviewsData.map((review) => (
              <div
                key={review.store_feedback_Id}
                className="flex align-center mb-4 bg-neutral-50 rounded-lg border border-gray-200 px-3 py-5 sm:pl-3 sm:pr-5 xl:pl-4 xl:pr-7"
              >
                <div className="mr-3 w-[34.14px] sm:w-[48px] rounded-full">
                  <img
                    className="object-cover"
                    loading="lazy"
                    src={
                      review?.store_feedback_user_image_Url ||
                      "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
                    }
                    alt="pic"
                    height={51}
                    width={51}
                  />
                </div>
                <div className="flex-1">
                  <div className="flex justify-between">
                    <h1 className="text-[14px] md:text-[17px] mdl:text-[14px] font-inter font-semibold leading-tight break-words">
                      {review?.store_feedback_First_Name}
                    </h1>
                  </div>
                  <div className="flex items-center mt-2">
                    <Calendar className="w-[11px] h-[10px] md:w-[13px] md:h-[13px] mdl:w-[13.45px] mdl:h-[13.45px]" />
                    <p className="text-[10px] md:text-xs mdl:text-[10px] font-semibold text-black-shade-9 pl-1">
                      {completeDateFormatter(review?.store_feedback_Date)}
                    </p>
                  </div>
                  <div className="">
                    <div className="text-gray-shade-5 text-[12px] md:text-[14px] lg:text-[12px] leading-[18px] font-inter py-2">
                      <ReadMore
                        text={review?.store_feedback_Text}
                        limit={200}
                      />
                    </div>
                    <div className="flex flex-row items-center mdl:flex mdl:flex-row mdl:items-center justify-between w-full xs:w-[70%] sm:w-1/2 mdl:w-full">
                      <div className="flex items-center space-x-1 text-[10px] mt-3 mdl:mt-0">
                        <div className="font-semibold text-gray-shade-17 md:text-[13px] lg:text-[10px]">
                          Quality:
                        </div>
                        <div>{review?.store_feedback_Quality}</div>
                        <div className="flex items-center">
                          <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-sub-color" />
                        </div>
                        {/* <div className="flex items-center space-x-0.5 pl-6 mdl:pl-0">
                          {starLoop.map((item) =>
                            item <=
                            Math.round(review.store_feedback_Quality) ? (
                              <span key={item}>
                                <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-primary" />
                              </span>
                            ) : (
                              <span key={item}>
                                <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-gray-shade-13" />
                              </span>
                            )
                          )}{" "}
                        </div> */}
                      </div>
                      <div className="w-0.5 h-2 bg-gray-shade-18 mt-3 mdl:mt-0 block mdl:hidden"></div>
                      <div className="flex items-center space-x-1 text-[10px] mt-3 mdl:mt-0">
                        <div className="font-semibold text-gray-shade-17 md:text-[13px] lg:text-[10px]">
                          Quantity:
                        </div>
                        <div>{review?.store_feedback_Quantity}</div>
                        <div className="flex items-center">
                          <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-sub-color" />
                        </div>
                        {/* <div className="flex items-center space-x-0.5 pl-4 mdl:pl-0">
                          {starLoop.map((item) =>
                            item <=
                            Math.round(review.store_feedback_Quantity) ? (
                              <span key={item}>
                                <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-primary" />
                              </span>
                            ) : (
                              <span key={item}>
                                <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-gray-shade-13" />
                              </span>
                            )
                          )}{" "}
                        </div> */}
                      </div>
                      <div className="w-0.5 h-2 bg-gray-shade-18 mt-3 mdl:mt-0 block mdl:hidden"></div>
                      <div className="flex items-center space-x-1 text-[10px] mt-3 mdl:mt-0">
                        <div className="font-semibold text-gray-shade-17 md:text-[13px] lg:text-[10px]">
                          Service:
                        </div>
                        <div>{review?.store_feedback_Service}</div>
                        <div className="flex items-center">
                          <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-sub-color" />
                        </div>
                        {/* <div className="flex items-center space-x-0.5 pl-5 mdl:pl-0">
                          {starLoop.map((item) =>
                            item <=
                            Math.round(review.store_feedback_Service) ? (
                              <span key={item}>
                                <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-primary" />
                              </span>
                            ) : (
                              <span key={item}>
                                <StarIcon className="h-3 w-3 mdl:h-3 mdl:w-3 text-gray-shade-13" />
                              </span>
                            )
                          )}{" "}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {reviewsData?.length == 0 ? (
              <div className="flex justify-center items-center w-full h-[400px] sm:min-h-[calc(100vh_-_17rem)] sm:h-auto mdl:hidden">
                <div className="lg:w-[350px]">
                  <img src={nodata_found} alt="No data found image" />
                </div>
              </div>
            ) : reviewsData?.length > 0 && !isLoadMore ? (
              <div
                className={`text-primary text-[12px] cursor-pointer w-fit ${
                  hideLoadMore ? "hidden" : "block"
                }`}
                onClick={loadMoreReviews}
              >
                Load More Reviews
              </div>
            ) : (
              <div className={`animate-pulse`}>
                <div className="sm:bg-neutral-100 sm:w-full sm:rounded-lg relative">
                  <div className="grid grid-cols-1">
                    <div className="h-36 bg-gray-300 rounded "></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviewcard;
