import { Link } from "react-router-dom";
import mobilePreview from "../../assets/images/home/mobile_preview.png";
import AutoplayCarousel from "../../components/Navigation/AutoplayCarousel";
import { logoData } from "../../data";
import { downloadAppStoreUrl, downloadGooglePlayStoreUrl } from "../../utils";
let base_url = "https://imgcdn.kuick.com/kuickwebsiteimg";

const HomeFooter = () => {
  return (
    <div>
      <div className="relative mt-40 w-full h-[400px] md:h-[340px] xl:h-auto bg-gradient-to-tr from-light-shade-4 to-light-shade-5 flex flex-col md:items-center justify-center md:flex-row md:justify-between xl:justify-center px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] md:mt-16 2xl:mt-8  min-[1920px]:mt-64 ">
        <div className="flex leading-tight flex-col justify-center items-center md:justify-center md:items-start pt-52 md:pt-0 mb-10 md:mb-0">
          <div className="w-max font-bold text-black text-xl md:text-[32px] 2xl:text-[44px] 3xl:text-[64px] md:font-semibold md:leading-[48px] lg:text-4xl xl:text-[44px]">
            Kuick In Your Mobile!
          </div>
          <div className="font-extrabold text-[20px] md:text-[32px] xl:text-[50px] 3xl:text-[64px] text-primary">
            100% Responsive.
          </div>
          <div className="text-[14px] md:text-[16px] lg:text-lg 2xl:text-xl md:w-max font-semibold text-border-shade-4 pt-2 md:pt-4 lg:pt-4 xl:pt-5 xl:pb-5 3xl:pb-6 text-center">
            Kuick, it&apos;s the fastest way to order food on the go.
          </div>
          <div className="pt-3 md:pt-5 space-y-2">
            <div className="text-base font-bold text-center md:text-left text-border-shade-3">
              Download App
            </div>
            <div className="flex justify-center space-x-3 pt-1 xl:space-x-6 ">
              <Link
                to={downloadAppStoreUrl}
                target="_blank"
                rel="noreferrer"
                className=" cursor-pointer"
              >
                <img
                  loading="lazy"
                  src={`${base_url}/landing_page/appstore.png`}
                  className="h-10 w-[136px] xl:w-[202px] xl:h-[60px] cursor-pointer  "
                  alt=""
                />
              </Link>

              <Link
                to={downloadGooglePlayStoreUrl}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer"
              >
                <img
                  loading="lazy"
                  src={`${base_url}/landing_page/googleplay.png`}
                  className="h-10 w-[136px] xl:w-[202px] xl:h-[60px] cursor-pointer "
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="left-0 absolute xl:relative w-full 2xl:w-auto 2xl:left-10 -top-32 md:top-5 md:bottom-5 md:right-10 2xl:-top-10 xl:-top-10 md:flex justify-end">
          <img
            loading="lazy"
            src={mobilePreview}
            className="w-[280px] md:w-[260px] mx-auto md:mx-0 h-auto xl:w-[450px] 2xl:w-[450px] md:mr-10 xl:mr-0"
          />
        </div>
      </div>
      <AutoplayCarousel
        data={[
          ...logoData,
          ...logoData,
          ...logoData,
          ...logoData,
          ...logoData,
          ...logoData,
          ...logoData,
          ...logoData,
          ...logoData,
          ...logoData,
          ...logoData,
        ]}
      />
    </div>
  );
};

export default HomeFooter;
