import { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import kuickLogo from "../../assets/kuick_logo.svg";
import OtpInput from "../../components/Elements/OtpInput";
import Button from "../../components/Elements/Button";
import Dropdown from "../../components/Elements/Dropdown";
import Loader from "../../components/Elements/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Edit } from "../../components/Icon/Edit";
import { VerifyButton } from "../../components/Icon/VerifyButton";
import { RightArrow } from "../../components/Icon/RightArrow";
import {
  isNumeric,
  removeAllSpaceFromString,
  setSession,
  getTimeRemaining,
  setSessionUserId,
  setSessionCCId,
} from "../../utils";
import { loginCall } from "../../services";
import { error } from "../../components/notify";
import {
  setUserData,
  setUserAddress,
} from "../../store/userData/userDataSlice";

import { LandingPage, RegistrationPage } from "../../routes/RoutesPath";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";

const Login = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = location?.pathname?.includes("login");
  const settings = useSelector((state) => state.basicdata.settings);
  const [loader, setLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState(
    settings?.countryCode ? settings.countryCode[0] : "+44"
  );
  const [step, setStep] = useState(1);
  const [ctoken, setCToken] = useState(null);
  const [otpLen, setOtpLen] = useState(4);
  const [showResend, setShowResend] = useState(false);
  const [otp, setOtp] = useState("");
  const [counterCount, setCounterCount] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [referalCode, setReferalCode] = useState("");
  // The state for our timer
  const [timer, setTimer] = useState("00:00");

  const timerRef = useRef(null);

  const { progressFunction } = useProgress();
  const [isLoading, setIsLoading] = useState(false);
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    if (loader) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 1000);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [loader]);

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (total === 0) {
        setShowResend(true);
        clearInterval(timerRef.current);
      }
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    const { minutes, seconds } = getTimeRemaining(e);
    setTimer(
      (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    timerRef.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(
      deadline.getSeconds() +
        (counterCount === 1 ? 15 : (counterCount - 1) * 30 + 15)
    );
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  useEffect(() => {
    setShowResend(false);
    clearTimer(getDeadTime());
  }, [counterCount]);

  function onChange(value) {
    setCToken(value);
  }

  const getLogin = async (pageName, resend = "No", mode = "SMS") => {
    if (mobileNumber.length === 11 && mobileNumber.charAt(0) !== "0") {
      error("Invalid mobile number. Please enter a valid number", true);
    } else {
      setLoader(true);
      setTimeout(async () => {
        const res = await loginCall(
          countryCode,
          mobileNumber,
          otp,
          resend,
          mode,
          pageName,
          firstName,
          referalCode,
          ctoken
        );

        if (res.status === 200) {
          setLoader(false);
          const { status, msg, bk } = res.data;
          if (status === "success") {
            if (bk) {
              error("Something went wrong!! Please try again", true);
            } else {
              if (step === 1) {
                const { otplen } = res.data;
                if (otplen) {
                  clearTimer(getDeadTime());
                  setOtpLen(otpLen);
                  setStep(2);
                  setCToken(null);
                } else {
                  // server side error
                }
              } else if (step === 2) {
                const { customer } = res.data;
                if (customer?.common_customer_First_Name === "") {
                  setStep(3);
                } else {
                  setSessionUserId(res?.data?.config?.usrId);
                  setSessionCCId(res?.data?.config?.ccid);
                  if (res?.data?.customer?.common_customer_Address?.length) {
                    dispatch(
                      setUserAddress({
                        selected:
                          res?.data?.customer?.common_customer_Address[0],
                        address: res?.data?.customer?.common_customer_Address,
                      })
                    );
                  }
                  dispatch(setUserData(res?.data));
                  setSession(customer);
                }
              } else if (step === 3) {
                const { customer } = res.data;
                setSessionUserId(res?.data?.config?.usrId);
                setSessionCCId(res?.data?.config?.ccid);
                dispatch(setUserData(res?.data));
                setSession(customer);
              }
            }
          } else if (status === "valid_error") {
            error(msg, true);
          } else if (status === "error") {
            error(msg, true);
          } else {
            error(msg, true);
          }
        } else {
          setLoader(false);

          error("Something went wrong !! Please try again");
        }
      }, 3000);
    }
  };

  const getHeader = () => {
    switch (step) {
      case 1:
        return "Welcome";
      case 2:
        return "One Time Password";
      default:
        return "";
    }
  };

  const getSubHeader = () => {
    switch (step) {
      case 1:
        return "Enter Your Number";
      case 2:
        return (
          <span>
            We have sent the OTP verification to
            <span className="flex space-x-2 items-center">
              <span>{` ${countryCode} ${mobileNumber}`}</span>
              <Button
                onClick={() => {
                  setStep(1);
                  setCounterCount(1);
                  setCToken(null);
                  setShowResend(false);
                }}
              >
                <Edit className="cursor-pointer" />
              </Button>
            </span>
          </span>
        );
      case 3:
        return <span>Please Update Your Details.</span>;
      default:
        return "";
    }
  };

  const getBody = () => {
    switch (step) {
      case 1:
        return (
          <div className="border border-gray-300 p-5 md:p-1 w-full rounded-lg flex mt-6 md:mt-1">
            <div className="flex items-center w-full">
              <Dropdown
                data={settings?.countryCode}
                value={countryCode}
                className="px-1 w-12 md:w-14"
                onChange={(value) => {
                  setCountryCode(value);
                }}
              />
              <input
                type="text"
                maxLength={11}
                placeholder="XXXXXXXXXX"
                value={mobileNumber}
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-full md:h-[40px] text-base font-normal font-inter leading-normal sm:w-[22rem] focus:outline-none"
                onChange={(e) => {
                  const { value } = e.target;
                  let typed = removeAllSpaceFromString(value);
                  if (isNumeric(typed)) {
                    setMobileNumber(typed);
                  } else if (value === "") {
                    setMobileNumber("");
                  }
                }}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="pt-6 space-x-2">
            <OtpInput length={otpLen} onOtpSubmit={setOtp} />
          </div>
        );
      case 3:
        return (
          <div className="p-5 md:p-1 w-full rounded-lg mt-6 md:mt-1">
            <div className="text-neutral-900 text-base sm:text-lg font-normal font-inter leading-normal pt-4 sm:pt-6">
              Enter Your Full Name
            </div>
            <div className=" border border-black rounded-lg flex mt-3 items-center px-3 space-x-[6px] py-4 sm:py-3 w-full">
              <input
                type="text"
                placeholder="Name"
                value={firstName}
                className="text-base font-normal font-inter leading-normal w-full sm:w-[22rem] focus:outline-none"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
            <div className="text-neutral-900 text-base sm:text-lg font-normal font-inter leading-normal pt-4 sm:pt-6">
              Referral Code (If Any){" "}
            </div>
            <div className="border  border-black rounded-lg mt-3 flex items-center px-3 space-x-[6px] py-4 sm:py-3 w-full">
              <input
                type="text"
                placeholder="Code"
                value={referalCode}
                className="text-base font-normal font-inter leading-normal  w-full sm:w-[22rem] focus:outline-none"
                onChange={(e) => {
                  setReferalCode(e.target.value);
                }}
              />
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  const onSubmit = () => {
    switch (step) {
      case 1:
        return (
          <Button
            onClick={() => {
              getLogin("username");
            }}
            disabled={
              !loader &&
              ((mobileNumber?.charAt(0) === "0"
                ? mobileNumber?.length <= 10
                : mobileNumber?.length < 10) ||
                !ctoken)
            }
            type="primary"
            title="Get OTP"
            className="w-full mt-4 md:mt-6 h-12 md:h-14 inline-flex justify-center items-center"
          >
            <RightArrow />
          </Button>
        );
      case 2:
        return (
          <Button
            onClick={() => getLogin("otp")}
            disabled={otp.length !== otpLen}
            type="primary"
            className="w-full py-1 mt-4 md:mt-6 h-12 md:h-14 gap-1 inline-flex justify-center items-center cursor-pointer"
          >
            <VerifyButton />
            <div className="text-center text-white text-base font-semibold font-inter leading-normal">
              Verify
            </div>
          </Button>
        );
      case 3:
        return (
          <Button
            onClick={() => getLogin("details")}
            disabled={!firstName.length}
            type="primary"
            className="w-full py-1 mt-4 md:mt-6 h-12 md:h-14 gap-1 inline-flex justify-center items-center"
          >
            <div className="text-center text-white text-base font-semibold font-inter leading-normal">
              Submit
            </div>
          </Button>
        );
      default:
        return "";
    }
  };

  return (
    <div className="w-screen h-screen bg-login-backdrop-mb xl:bg-login-backdrop bg-cover bg-repeat-x	bg-bottom xl:bg-center">
      <Loader visible={loader} />
      <div className="absolute xl:bg-black xl:opacity-50 h-screen w-screen" />
      <div className="w-full h-full px-4 flex justify-center items-center absolute top-0 bottom-0 left-0 right-0">
        <div
          className={`bg-white xl:w-[619px] rounded-2xl
           ${step === 2 ? "md:px-28" : "md:px-6 mx-6 md:mx-28 xl:mx-0"}`}
        >
          <div
            className={`relative ${
              step === 2 ? "-mr-24 mt-2" : " mt-2 md:-mr-[10px]"
            } `}
          >
            <Button
              type="button"
              className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => {
                if (location.key === "default") {
                  navigation(LandingPage);
                } else {
                  navigation(-1);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
              >
                <path
                  d="M23.25 7.63354L7.75 23.366"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.75 7.63354L23.25 23.366"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </Button>
          </div>
          <img
            src={kuickLogo}
            className="flex mx-auto mt-8 md:mt-14 w-[104px] md:w-[133px]"
            loading="lazy"
          />
          <div
            className={`px-5 md:${step > 1 ? "px-0" : "px-24"} pt-6 sm:mt-auto`}
          >
            <div className="text-2xl sm:text-3xl font-bold leading-[38px] font-inter">
              {getHeader()}
            </div>
            <div className="text-neutral-900 text-base sm:text-lg font-normal font-inter leading-normal sm:pt-6">
              {getSubHeader()}
            </div>
            {getBody()}

            {step === 1 && (
              <div className="mt-4 md:mt-6">
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_CAPTCHA_SITE_KEY}
                  onChange={onChange}
                />
              </div>
            )}

            {step === 2 &&
              (showResend ? (
                <div className="flex justify-between pt-[23px]">
                  <div className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex">
                    <div
                      className="text-center text-slate-500 text-base font-semibold font-inter leading-normal"
                      onClick={() => {
                        getLogin("otp", "Yes");
                        setCounterCount(counterCount + 1);
                      }}
                    >
                      Resend OTP
                    </div>
                  </div>
                  <div className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex">
                    <div
                      className="text-center text-slate-500 text-base font-semibold font-inter leading-normal"
                      onClick={() => {
                        getLogin("otp", "Yes", "VOICE");
                        setCounterCount(counterCount + 1);
                      }}
                    >
                      Voice OTP
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center mt-4 md:mt-6">
                  <span className="text-p6 font-bold">{timer}</span>
                </div>
              ))}

            {/* get otp button */}
            {onSubmit()}

            <div className="flex space-x-1 sm:justify-center mt-6 md:mt-16 mb-8 md:mb-14">
              <div className="text-gray-500 text-base font-semibold font-inter leading-normal">
                {isLoggedIn?.pathname?.includes("login")
                  ? "Already have an account?"
                  : "Don’t have an account?"}
              </div>
              <div
                onClick={() => {
                  navigation(RegistrationPage);
                }}
                className="text-blue-700 text-base font-semibold font-inter leading-normal cursor-pointer"
              >
                {isLoggedIn?.pathname?.includes("login") ? "Login" : "Sign Up"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
