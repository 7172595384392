import logo_cover from "../../../assets/images/default_images/logo_cover.jpg";
import blue_placeholder_square from "../../../assets/images/default_images/blue_placeholder_square.png";

import Layout from "../../../layout/Layout";
export default function MenuLoader({ className="", subClassName }) {
  return (
    <div className={`${className} animate-pulse`}>
      <div className={`font-medium  ${subClassName}`}>
        <div className="relative w-full ">
          <img
            loading="lazy"
            src={blue_placeholder_square}
            alt="Menu image"
            className="w-full object-cover h-[160px] sm:h-60 lg:h-60 xl:h-72 "
          />
          <div className="bg-black w-full h-40 sm:h-60 lg:h-64 xl:h-72 absolute top-0 opacity-30" />
        </div>

        <Layout>
          <img
            loading="lazy"
            src={logo_cover}
            alt="Store Logo"
            className="absolute  bg-white shad rounded-lg w-auto h-24 sm:h-36 sm:w-36 top-8 xl:top-16 sm:top-16 md:top-10 lg:top-16  xl:h-[150px] xl:w-[150px]  opacity-70 object-cover sm:left-8 lg:left-[60px]"
          />
        </Layout>

        <div className="z-10 absolute top-10 mdl:top-[105px] left-[130px] sm:left-[215px] md:top-10 lg:w-[50%] lg:top-16 lg:left-56 xl:left-64">
          <div className="bg-gray-500 w-48 sm:w-72 h-2 rounded-lg mb-3"></div>
          <div className="bg-gray-500 w-48 sm:w-60 h-2 rounded-lg mb-2"></div>
          <div className="bg-gray-500 w-48 sm:w-60 h-1 rounded-lg mb-2"></div>
          <div className="bg-gray-500 w-48 sm:w-60 h-1 rounded-lg mb-2"></div>
          <div className="bg-gray-500 w-48 sm:w-60 h-1 rounded-lg mb-2"></div>
          <div className="bg-gray-500 w-48 sm:w-60 h-1 rounded-lg "></div>
          <div className="hidden sm:block bg-gray-500 w-48 h-1 rounded-lg mt-8"></div>
        </div>
      </div>
    </div>
  );
}
