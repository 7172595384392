export default function ProfileLoader({ className }) {
  return (
    <div className={`animate-pulse  `}>
      <div
        className={`sm:px-7 sm:py-9 sm:bg-neutral-100 sm:w-full sm:rounded-lg relative ${className}`}
      >
        <div className="flex items-center gap-6">
          <div className="w-16 h-16 bg-gray-300 rounded-full"></div>
          <div className="h-6 bg-gray-300 rounded w-48"></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 my-10 md:my-8 gap-4">
          <div className="h-12 bg-gray-300 rounded"></div>
          <div className="h-12 bg-gray-300 rounded"></div>
          <div className="h-12 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  );
}
