const FabFood = ({ width, height, className }) => {
  return (
    <svg
      width={width ? width : "40"}
      height={height ? height : "40"}
      viewBox="0 0 40 40"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7659_7225)">
        <path d="M23.8203 11.4812C23.0195 11.4812 22.3438 12.5062 22.3438 13.7203C22.3438 14.9343 23.0203 15.9593 23.8203 15.9593C24.6203 15.9593 25.2969 14.9343 25.2969 13.7203C25.2969 12.5062 24.6211 11.4812 23.8203 11.4812Z" />
        <path d="M36.6219 5.37888C32.118 0.874195 24.8148 0.873413 20.3102 5.37732C20.3102 5.37732 20.3094 5.3781 20.3086 5.37888L20 5.68748L19.6914 5.37888C17.5328 3.20857 14.5961 1.99216 11.5352 1.99998C5.16406 1.99998 0 7.16404 0 13.5351C0 16.5945 1.21563 19.5281 3.37891 21.6914L19.4766 37.7898C19.7656 38.0789 20.2344 38.0789 20.5234 37.7898L36.6211 21.6914C41.1258 17.1867 41.1258 9.88357 36.6211 5.37888H36.6219ZM15.4383 10.7406C15.4383 10.3312 15.7703 9.99998 16.1789 9.99998C16.5875 9.99998 16.9195 10.332 16.9195 10.7406V13.6086C16.9195 14.0179 16.5875 14.3492 16.1789 14.3492C15.7703 14.3492 15.4383 14.0172 15.4383 13.6086V10.7406ZM19.1375 14.482C19.1336 15.8265 18.2219 16.9992 16.9203 17.3351V25.5554C16.9203 25.9648 16.5883 26.2961 16.1797 26.2961C15.7711 26.2961 15.4391 25.964 15.4391 25.5554V17.3351C14.1375 16.9992 13.2258 15.8265 13.2219 14.482V10.7406C13.2219 10.3312 13.5539 9.99998 13.9625 9.99998C14.3711 9.99998 14.7031 10.332 14.7031 10.7406V14.482C14.7008 15.2976 15.3602 15.9601 16.1758 15.9625C16.9914 15.9648 17.6539 15.3054 17.6563 14.4898V10.7414C17.6563 10.332 17.9883 10.0008 18.3969 10.0008C18.8055 10.0008 19.1375 10.3328 19.1375 10.7414V14.4828V14.482ZM24.5625 17.3078V25.5554C24.5625 25.9648 24.2305 26.2961 23.8219 26.2961C23.4133 26.2961 23.0813 25.964 23.0813 25.5554V17.3078C21.8094 16.8914 20.8641 15.4476 20.8641 13.7203C20.8641 11.6687 22.1914 9.99998 23.8227 9.99998C25.4539 9.99998 26.7805 11.6687 26.7805 13.7203C26.7805 15.4484 25.8344 16.8914 24.5633 17.3078H24.5625Z" />
      </g>
      <defs>
        <clipPath id="clip0_7659_7225">
          <rect
            width={width ? width : "40"}
            height={height ? height : "36.0062"}
            transform="translate(0 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FabFood;
