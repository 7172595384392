import CrossButton from "../../../components/Elements/CrossButton";
import { Cross } from "../../../components/Icon/Cross";
import Quality from "../../../assets/images/quality.svg";
import Service from "../../../assets/images/service.svg";
import Quantity from "../../../assets/images/quantity.svg";
import { StarIcon } from "@heroicons/react/24/solid";
import Button from "../../../components/Elements/Button";
import { useEffect, useState } from "react";
import { submitFeedback } from "../../../services/index";
import { success, error } from "../../../components/notify";
import {
  emailRegEx,
  telephoneRegEx,
  isNumeric,
  removeAllSpaceFromString,
} from "../../../utils";
import ArrowLeftChevron from "../../../components/Icon/ArrowLeftChevron";
import { checkEmpty } from "../../../utils/helper";
import Rating from "@mui/material/Rating";
import DrawerHeader from "../../../components/Header/DrawerHeader";

const AboutFeedback = ({
  onSendData,
  onNotification,
  reviewCardSelector,
  onClose,
}) => {
  const starLoop = [1, 2, 3, 4, 5];
  const [isStarQuality, setIsStarQuality] = useState([1, 2, 3, 4, 5]);
  const [isQuality, setIsQuality] = useState(5);
  const [isQuantity, setIsQuantity] = useState(5);
  const [isService, setIsService] = useState(5);
  const [name, setName] = useState("");
  const [isNameFieldEmpty, setIsNameFieldEmpty] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneFieldEmpty, setIsPhoneFieldEmpty] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailFieldEmpty, setIsEmailFieldEmpty] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isFeedbackFieldEmpty, setIsFeedbackFieldEmpty] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [isFormubmit, setisFormubmit] = useState(false);

  useEffect(() => {}, []);

  const checkQualityRating = (item) => {
    if (item == 1) {
      setIsStarQuality();
    } else if (item == 2) {
      setIsStarQuality();
    } else if (item == 3) {
      setIsStarQuality();
    } else if (item == 4) {
      setIsStarQuality();
    } else {
      setIsStarQuality();
    }
  };

  const [formData, setFormData] = useState({
    store_feedback_First_Name: "",
    store_feedback_Email: "",
    store_feedback_Telephone_Number: "",
    store_feedback_Text: "",
    store_feedback_Quality: isQuality,
    store_feedback_Quantity: isQuantity,
    store_feedback_Service: isService,
  });

  const handleChangeName = (event) => {
    const name = event.target.value;
    setName(name);
    setFormData({ ...formData, store_feedback_First_Name: name });
    if (name.length === 0) {
      setIsNameFieldEmpty(true);
    } else {
      setIsNameFieldEmpty(false);
    }
  };

  const handleChangePhone = (event) => {
    setIsInvalidPhone(false);
    const { value } = event.target;
    let typed = removeAllSpaceFromString(value);
    if (isNumeric(typed)) {
      setPhone(value);
      setFormData({ ...formData, store_feedback_Telephone_Number: value });
    } else if (value === "") {
      setPhone("");
    }
    const phone = event.target.value;
    if (phone.length == 0) {
      setIsPhoneFieldEmpty(true);
    } else {
      setIsPhoneFieldEmpty(false);
    }
  };

  const handleChangeEmail = (event) => {
    setIsInvalidEmail(false);
    const email = event.target.value.toLowerCase();
    setEmail(email);
    setFormData({ ...formData, store_feedback_Email: email });
    if (email.length == 0) {
      setIsEmailFieldEmpty(true);
    } else {
      setIsEmailFieldEmpty(false);
    }
  };

  const handleChangeFeedback = (event) => {
    const feedback = event.target.value;
    setFeedback(feedback);
    setFormData({ ...formData, store_feedback_Text: feedback });
    if (feedback.length == 0) {
      setIsFeedbackFieldEmpty(true);
    } else {
      setIsFeedbackFieldEmpty(false);
    }
  };

  const submitFeedbackData = async (device) => {
    let formIsValid = true;
    setIsNameFieldEmpty(false);
    setIsEmailFieldEmpty(false);
    setIsInvalidEmail(false);
    setIsFeedbackFieldEmpty(false);
    setIsInvalidPhone(false);
    setIsPhoneFieldEmpty(false);

    if (checkEmpty(name)) {
      setIsNameFieldEmpty(true);
      formIsValid = false;
    }

    if (checkEmpty(email)) {
      setIsInvalidEmail(false);
      setIsEmailFieldEmpty(true);
      formIsValid = false;
    } else if (!emailRegEx.test(email)) {
      setIsEmailFieldEmpty(false);
      setIsInvalidEmail(true);
      formIsValid = false;
    }

    if (checkEmpty(feedback)) {
      setIsFeedbackFieldEmpty(true);
      formIsValid = false;
    }

    if (checkEmpty(phone)) {
      setIsInvalidPhone(false);
      setIsPhoneFieldEmpty(true);
      formIsValid = false;
    } else if (!telephoneRegEx.test(phone)) {
      setIsPhoneFieldEmpty(false);
      setIsInvalidPhone(true);
      formIsValid = false;
    }

    if (formIsValid) {
      setisFormubmit(true);
      const { status, data } = await submitFeedback(formData);
      if (status === 200) {
        if (data.status === "success") {
          success("feedback submitted successfully");
          if (device == "mobile") {
            reviewCardSelector("Reviews");
          } else {
            onNotification(true);
          }
        } else {
          error("Something went wrong! Please try again");
        }
      }
    }
  };

  const handleClick = () => {
    onSendData(true);
  };

  return (
    <div className="flex flex-col overflow-y-auto no-scrollbar">
      <div className="hidden mdl:block">
        <DrawerHeader
          title={"FeedBack"}
          onClose={onClose}
          description={" Let us know what you think about us."}
        />
      </div>
      <div
        className="flex flex-row items-center mt-5 md:mt-0 md:pt-10 mdl:pt-4 mdl:px-4 cursor-pointer"
        onClick={handleClick}
      >
        <div className="flex items-center mdl:hidden">
          <span className="block ">
            <ArrowLeftChevron className="w-3 h-3" />{" "}
          </span>
          <span className="ml-2 mdl:ml-0 text-[13.5px] md:text-[16px] font-inter font-medium mdl:font-semibold">
            Feedback
          </span>
        </div>
      </div>
      <div className="text-xs md:text-md mdl:px-4 mdl:text-md mdl:mt-2 font-inter font-medium text-black-shade-11 mdl:hidden">
        Let us know what you think about us.
      </div>
      <div className="mt-5 mdl:mt-0 flex items-center justify-between mdl:px-6">
        <div className="flex items-center">
          <img
            className="w-10 h-10 md:w-11 md:h-11 mdl:w-[46px] mdl:h-[46px] rounded-full object-cover "
            loading="lazy"
            src={Quality}
          />
          <div className="ml-2 text-sm md:text-[15px]">Quality</div>
        </div>
        <div className="flex items-center">
          <Rating
            name="quality-rating"
            icon={<StarIcon className="h-6 w-6 text-primary" />}
            emptyIcon={<StarIcon className="h-6 w-6" />}
            precision={1}
            value={isQuality}
            onChange={(event, newValue) => {
              if (!checkEmpty(newValue)) {
                setIsQuality(newValue);
                setFormData({ ...formData, store_feedback_Quality: newValue });
              }
            }}
          />

          <div className="flex text-xs ml-2 text-right w-[65px]">
            ({isQuality}.0) {isQuality == 1 ? "Star" : "Stars"}
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-center justify-between mdl:px-6">
        <div className="flex items-center">
          <img
            className="w-10 h-10 md:w-11 md:h-11 mdl:w-[46px] mdl:h-[46px] rounded-full object-cover "
            loading="lazy"
            src={Quantity}
          />
          <div className="ml-2 text-sm md:text-[15px]">Quantity</div>
        </div>
        <div className="flex items-center">
          <Rating
            name="quantity-rating"
            icon={<StarIcon className="h-6 w-6 text-primary" />}
            emptyIcon={<StarIcon className="h-6 w-6" />}
            precision={1}
            value={isQuantity}
            onChange={(event, newValue) => {
              if (!checkEmpty(newValue)) {
                setIsQuantity(newValue);
                setFormData({ ...formData, store_feedback_Quantity: newValue });
              }
            }}
          />
          <div className="flex text-xs ml-2 text-right w-[65px]">
            ({isQuantity}.0) {isQuantity == 1 ? "Star" : "Stars"}
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-center justify-between mdl:px-6">
        <div className="flex items-center">
          <img
            className="w-10 h-10 md:w-11 md:h-11 mdl:w-[46px] mdl:h-[46px] rounded-full object-cover "
            loading="lazy"
            src={Service}
          />
          <div className="ml-2 text-sm md:text-[15px]">Service</div>
        </div>
        <div className="flex items-center">
          <Rating
            name="service-rating"
            icon={<StarIcon className="h-6 w-6 text-primary" />}
            emptyIcon={<StarIcon className="h-6 w-6" />}
            precision={1}
            value={isService}
            onChange={(event, newValue) => {
              if (!checkEmpty(newValue)) {
                setIsService(newValue);
                setFormData({ ...formData, store_feedback_Service: newValue });
              }
            }}
          />
          <div className="flex text-xs ml-2 text-right w-[65px]">
            ({isService}.0) {isService == 1 ? "Star" : "Stars"}
          </div>
        </div>
      </div>
      <div className="border-b mt-7 mb-8 mdl:mx-2"></div>
      <div className="font-inter text-sm mdl:px-6 relative">
        <label className="font-medium">
          Name<span className="text-primary  ml-0.5">*</span>
        </label>
        <input
          className="w-full h-10 px-2.5 mt-[10px] bg-white rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
          name="store_feedback_First_Name"
          value={name}
          onChange={handleChangeName}
          maxLength={50}
        />
        <label
          className={`${
            isNameFieldEmpty ? "block" : "hidden"
          } font-inter text-primary text-sm absolute top-0 right-0 mdl:pr-7`}
        >
          This field is required
        </label>
      </div>
      <div className="font-inter text-sm mt-8 mdl:mt-4 mdl:px-6 relative">
        <label className="font-medium">
          Mobile<span className="text-primary  ml-0.5">*</span>
        </label>
        <input
          className="w-full h-10 px-2.5 mt-[10px] bg-white rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
          name="store_feedback_Telephone_Number"
          value={phone}
          onChange={handleChangePhone}
          keyboardType="number-pad"
          inputmode="numeric"
          maxLength={11}
        />
        <label
          className={`${
            isPhoneFieldEmpty ? "block" : "hidden"
          } font-inter text-primary text-sm absolute top-0 right-0 mdl:pr-7`}
        >
          This field is required
        </label>
        <label
          className={`${
            isInvalidPhone ? "block" : "hidden"
          } font-inter text-primary text-sm absolute top-0 right-0 mdl:pr-7`}
        >
          Invalid mobile number
        </label>
      </div>
      <div className="font-inter text-sm mt-8 mdl:mt-4 mdl:px-6 relative">
        <label className="font-medium">
          Email<span className="text-primary  ml-0.5">*</span>
        </label>
        <input
          className="w-full h-10 px-2.5 mt-[10px] bg-white rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
          name="store_feedback_Email"
          value={email}
          onChange={handleChangeEmail}
          maxLength={50}
        />
        <label
          className={`${
            isEmailFieldEmpty ? "block" : "hidden"
          } font-inter text-primary text-sm absolute top-0 right-0 mdl:pr-7`}
        >
          This field is required
        </label>
        <label
          className={`${
            isInvalidEmail ? "block" : "hidden"
          } font-inter text-primary text-sm absolute top-0 right-0 mdl:pr-7`}
        >
          Invalid email
        </label>
      </div>
      <div className="text-sm font-inter mt-8 mdl:mt-4 mdl:px-6 relative">
        <h3 className="font-medium">
          Your Feedback <span className="text-primary  ml-0.5">*</span>
        </h3>
        <textarea
          className="w-full rounded-lg outline-none p-3 mt-[10px] border border-gray-300 focus:border-blue-500 focus:outline-none resize-none"
          name="store_feedback_Text"
          value={feedback}
          onChange={handleChangeFeedback}
          maxLength={300}
        ></textarea>
        <label
          className={`${
            isFeedbackFieldEmpty ? "block" : "hidden"
          } font-inter text-primary text-sm absolute top-0 right-0 mdl:pr-7`}
        >
          This field is required
        </label>
      </div>
      <Button
        disabled={isFormubmit}
        className="bg-primary py-2 mb-5 mt-8 mdl:mt-6 font-inter border text-white text-[16px] mdl:mx-4 cursor-pointer block mdl:hidden"
        onClick={() => submitFeedbackData("mobile")}
      >
        Post Comments
      </Button>
      <Button
        disabled={isFormubmit}
        className="bg-primary py-2 mb-5 mt-8 mdl:mt-6 font-inter border text-white text-[16px] mdl:mx-4 cursor-pointer hidden mdl:block"
        onClick={() => submitFeedbackData("web")}
      >
        Post Comments
      </Button>
    </div>
  );
};

export default AboutFeedback;
