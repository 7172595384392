import { useState, useEffect } from "react";
import offer1 from "../../assets/images/offers/offer1.png";
import offer2 from "../../assets/images/offers/offer2.png";
import offer3 from "../../assets/images/offers/offer3.png";
import offer4 from "../../assets/images/offers/offer4.png";
import Taco from "../../assets/images/offers/taco.png";
import discount_store1 from "../../assets/images/offers/discount_store1.png";
import { Breadcrumbs } from "../../components/Navigation/BreadCrumbs";
import Banner from "../../components/Elements/Banner";
import ImageSlider from "../../components/Navigation/ImageSlider";
import StoreCard from "../../components/Cards/StoreCard";
import { useRef } from "react";
import Layout from "../../layout/Layout";
import StoreCardLoader from "../../components/Loader/StoreCardLoader";

const categoryCardDetails = new Array(24).fill({
  id: 1,
  image: Taco,
  title: "Pasta",
  description: "lorem ipsum is sim dummy text",
});

const discountCard = new Array(12).fill({
  id: 1,
  name: "Oven Story Pizza",
  image: discount_store1,
  title: "Pasta",
  description: "1157, Broadway, New York, United States (US)",
  distance: "3.0",
  distanceUnit: "Miles",
  rating: "4.0",
  time: "45",
  timeUnit: "M",
  discount: "45% Off! Upto £10",
});

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";

const Offers = () => {
  const [discountCardDetails, setdiscountCardDetails] = useState(discountCard);
  const [loader, setLoader] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const observerTarget = useRef(null);

  const [loaderP, setLoaderP] = useState(false);

  const { progressFunction } = useProgress();
  const dispatch = useDispatch();
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    if (loaderP) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(100));
          setLoaderP(false);
        }
        count = count + 10;
        // console.log(count);
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [loaderP]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (loader == false) {
          setLoader(true);
          setLoaderP(true);

          setTimeout(() => {
            setdiscountCardDetails((discountCardDetails) =>
              discountCardDetails.concat([
                ...new Array(8).fill({
                  id: 1,
                  name: "Oven Story Pizza",
                  image: discount_store1,
                  title: "Pasta",
                  description: "1157, Broadway, New York, United States (US)",
                  distance: "3.0",
                  distanceUnit: "Miles",
                  rating: "4.0",
                  time: "45",
                  timeUnit: "M",
                  discount: "45% Off! Upto £10",
                }),
              ])
            );
            setLoader(false);
            setLoaderP(false);
          }, 100);
        }
      }
    }, {});

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    window.addEventListener("scroll", isSticky);
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
      window.removeEventListener("scroll", isSticky);
    };
  }, [observerTarget]);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 12);
  };

  return (
    <div className="overflow-hidden	h-max font-[inter]">
      {/* pl-4 pt-4 pb-4 pr-4 sm:pr-8 md:lg:pl-8 md:lg:pt-8 md:lg:pb-8  md:lg:pr-14 */}

      <Breadcrumbs
        className={`${
          isFixed ? "fixed top-16 left-0 shadow-xl py-3" : "relative py-4"
        } bg-white w-full z-40 flex items-center px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px]`}
      />

      <Layout className="carousel w-full  space-x-2 lg:space-x-5  2xl:md:pb-8 xl:pd-8 md:pb-6 pb-7 xl:pl-[45px] pl-[15px] sm:pl-5 md:pl-[25px]">
        <div key="1" className="">
          <Banner
            image={`${offer1}`}
            className="w-[335px] xl:w-[400px] 2xl:w-[468px] min-[1920px]:w-[585px] px-2 xl:px-0"
            imageClassName="w-[328px] xl:w-[377px] 2xl:w-[468px] min-[1920px]:w-[585px] h-[164px] xl:h-[204px] min-[1920px]:h-[274px] 2xl:h-[234px] object-contain rounded-md aspect-[3/2] "
          />
        </div>
        <div key="2" className="">
          <Banner
            image={`${offer2}`}
            className="w-[335px] xl:w-[400px] 2xl:w-[468px] min-[1920px]:w-[585px] px-2 xl:px-0"
            imageClassName="w-[328px] xl:w-[377px] 2xl:w-[468px] min-[1920px]:w-[585px] h-[164px] xl:h-[204px] min-[1920px]:h-[274px] 2xl:h-[234px] object-contain rounded-md aspect-[3/2] "
          />
        </div>
        <div key="3" className="">
          <Banner
            image={`${offer3}`}
            className="w-[335px] xl:w-[400px] 2xl:w-[468px] min-[1920px]:w-[585px] px-2 xl:px-0"
            imageClassName="w-[328px] xl:w-[377px] 2xl:w-[468px] min-[1920px]:w-[585px] h-[164px] xl:h-[204px] min-[1920px]:h-[274px] 2xl:h-[234px] object-contain rounded-md aspect-[3/2] "
          />
        </div>
        <div key="4" className="">
          <Banner
            image={`${offer4}`}
            className="w-[335px] xl:w-[400px] 2xl:w-[468px] min-[1920px]:w-[585px] px-2 xl:px-0"
            imageClassName="w-[328px] xl:w-[377px] 2xl:w-[468px] min-[1920px]:w-[585px] h-[164px] xl:h-[204px] min-[1920px]:h-[274px] 2xl:h-[234px] object-contain rounded-md aspect-[3/2] "
          />
        </div>
      </Layout>

      <ImageSlider
        grid
        data={categoryCardDetails}
        renderItem={(item) => (
          <div
            key={item?.id}
            className="carousel-item flex flex-col items-center py-8 pb-3.5"
          >
            <img
              src={item?.image}
              className="w-24 h-24 2xl:w-24 2xl:h-24"
              loading="lazy"
            />

            <span className="decoration-black text-center font-Inter text-md not-italic leading-normal font-medium xl:text-base w-[100px]	">
              {item?.title}
            </span>
          </div>
        )}
        title={
          <div className="text-base sm:text-p6 2xl:text-p4 font-semibold sm:font-medium flex flex-col ">
            <span className="decoration-black text-left	font-Inter text-md not-italic	font-semibold	leading-normal md:text-p6 md:pb-2">
              Discount items
            </span>

            <span className="decoration-black text-left	font-Inter text-xs not-italic font-normal	leading-normal md:text-base	 ">
              Choose your goods from here
            </span>
          </div>
        }
        type="carousel"
        key="1"
      />

      <ImageSlider
        key="2"
        data={discountCardDetails}
        renderItem={(item) => (
          <div key={item?.id} className="">
            <StoreCard
              image={item?.image}
              name={item?.name}
              desc={item?.description}
              distance={item?.distance}
              distanceUnit={item?.distanceUnit}
              rating={item?.rating}
              time={item?.time}
              timeUnit={item?.timeUnit}
              discount={item?.discount}
            />
          </div>
        )}
        title={
          <div className="text-base sm:text-lg  2xl:text-p4 font-semibold sm:font-medium flex flex-col pt-1.5">
            <span className="decoration-black text-left	font-Inter text-md not-italic	font-semibold	leading-normal md:text-p6 md:pb-2 ">
              Discount stores
            </span>
            <span className="decoration-black text-left	font-Inter text-xs not-italic font-normal	leading-normal md:text-md xl:text-base		">
              Choose your goods from here
            </span>
          </div>
        }
        type="grid"
      />

      <div
        className="flex justify-center py-3.5 px-6	md:p-8 md:mt-[-10px] lg:p-12 lg:mt-[-30px] xl:px-[60px] gap-1 cursor-pointer relative loadings"
        ref={observerTarget}
      >
        {loader == true && (
          // <div className=" absolute top-3">
          //   <ColorRing
          //     visible={true}
          //     height="80"
          //     width="80"
          //     ariaLabel="color-ring-loading"
          //     wrapperStyle={{}}
          //     wrapperClass="color-ring-wrapper"
          //     colors={["#FF4040", "#FF4040", "#FF4040", "#FF4040", "#FF4040"]}
          //   />
          // </div>

          <StoreCardLoader />
        )}

        {loader == false && (
          <>
            <div className=" decoration-black font-Inter text-xs font-semibold leading-normal underline md:text-mdl underline-offset-4	">
              Load More
            </div>
            <div className="inline-flex	items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
              >
                <path
                  d="M-4.36587e-07 10.0121C-1.95859e-07 15.5193 4.48073 20 9.98795 20C15.1645 20 19.4936 16.0112 19.9359 10.8815L20.9383 12.8587C21.0726 13.1025 21.3737 13.2486 21.6114 13.1032L21.7919 13.0337C22.0302 12.8883 22.1143 12.5735 21.9806 12.3303L20.2907 9.02966C20.288 9.02552 20.2887 9.02139 20.2859 9.01657L20.1647 8.79474C20.0971 8.6728 19.9876 8.5915 19.8657 8.55913C19.7437 8.52537 19.6073 8.54052 19.4888 8.61355L19.2732 8.74513C19.2691 8.7472 19.2663 8.75133 19.2629 8.7534L16.0773 10.7919C15.8389 10.9366 15.7549 11.2507 15.8885 11.4953L16.0001 11.6551C16.1338 11.899 16.4783 11.9155 16.7152 11.7709L18.5815 10.568C18.5815 10.5742 18.5788 10.5804 18.5781 10.5866C18.2825 15.0921 14.5086 18.6208 9.98726 18.6208C5.23992 18.6208 1.37715 14.758 1.37715 10.0107C1.37715 5.26265 5.23923 1.37853 9.98726 1.37853C12.7209 1.37853 15.232 2.63442 16.8758 4.82312C17.1045 5.12762 17.5364 5.18825 17.8409 4.96021C18.1454 4.73149 18.2067 4.29954 17.978 3.99504C16.0711 1.45568 13.1583 -5.7517e-07 9.98794 -4.36587e-07C4.48073 -1.95859e-07 -6.77405e-07 4.50277 -4.36677e-07 10.01L-4.36587e-07 10.0121Z"
                  fill="black"
                />
              </svg>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Offers;
