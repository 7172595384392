const Mail = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 56 56"
      fill="none"
    >
      <circle
        cx="28"
        cy="28"
        r="27"
        fill="white"
        stroke="#D8D8DF"
        strokeWidth="2"
      />
      <path
        d="M22 24L25.7812 26.5208C27.1248 27.4165 28.8752 27.4165 30.2188 26.5208L34 24M22 37H34C36.2091 37 38 35.2091 38 33V23C38 20.7909 36.2091 19 34 19H22C19.7909 19 18 20.7909 18 23V33C18 35.2091 19.7909 37 22 37Z"
        stroke="#7B7D8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Mail;
