export const Edit = ({ className }) => {
  return (
    // <svg
    //   className={`w-[13px] h-[13px] sm:w-[18px] sm:h-[18px] ${className}`}
    //   viewBox="0 0 13 13"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     id="edit"
    //     d="M1.34384 10.9187H2.28452L8.07981 5.12337L7.13913 4.18269L1.34384 9.97798V10.9187ZM10.9523 4.14909L8.09661 1.32704L9.0373 0.386353C9.29486 0.128784 9.61123 0 9.98638 0C10.3615 0 10.6779 0.128784 10.9355 0.386353L11.8762 1.32704C12.1337 1.58461 12.2681 1.89537 12.2793 2.25932C12.2905 2.62328 12.1673 2.93404 11.9097 3.19161L10.9523 4.14909ZM9.97798 5.14017L2.85565 12.2625H0V9.40685L7.12233 2.28452L9.97798 5.14017Z"
    //     fill="#1C1B1F"
    //   />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={`w-[13px] h-[13px] sm:w-[18px] sm:h-[18px] ${className}`}
    >
      <g clipPath="url(#clip0_11458_13724)">
        <path
          d="M18.3327 9.99996V15C18.3327 16.8409 16.8403 18.3333 14.9993 18.3333H4.99935C3.1584 18.3333 1.66602 16.8409 1.66602 15V4.99996C1.66602 3.15901 3.1584 1.66663 4.99935 1.66663H9.99935M13.0713 3.35225C13.0713 3.35225 13.0713 4.54416 14.2632 5.73608C15.4551 6.92799 16.6471 6.92799 16.6471 6.92799M7.62824 13.3246L10.1313 12.967C10.4923 12.9154 10.8269 12.7482 11.0848 12.4903L17.839 5.73608C18.4973 5.0778 18.4973 4.01052 17.839 3.35225L16.6471 2.16033C15.9888 1.50206 14.9215 1.50206 14.2632 2.16033L7.50905 8.91451C7.25116 9.17241 7.08386 9.50699 7.03229 9.86805L6.67471 12.3711C6.59525 12.9273 7.07202 13.4041 7.62824 13.3246Z"
          stroke="#28303F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11458_13724">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
