import Cross from "../../assets/images/CrossIcon.png";

const DrawerHeader = ({ title, description, onClose, formWhere=0 }) => {
  return (
    <div className="text-slate-950 px-6 text-xl font-semibold font-inter my-4 mdl:my-6 flex items-center justify-between">
      <div className="flex flex-col">
        <span>{title}</span>
        {description && (
          <span className="text-xs font-normal leading-3 pl-1">{description}</span>
        )}
      </div>
      {
        formWhere==0 && (
          <div className="cursor-pointer" onClick={() => onClose()}>
          <img src={Cross} className="w-6 mdl:w-8" />
        </div>
        )
      }
      
    </div>
  );
};

export default DrawerHeader;
