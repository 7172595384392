import Reviewcard from "../../../components/Cards/ReviewCard";
import ArrowLeftChevron from "../../../components/Icon/ArrowLeftChevron";

const AboutReview = ({ onSendData }) => {
  const handleClick = () => {
    onSendData(true);
  };

  return (
    <div className="flex flex-col">
      <div
        className="text-[16px] font-inter font-medium flex items-center py-5 md:pt-10 md:pb-5 cursor-pointer"
        onClick={handleClick}
      >
        <div>
          <ArrowLeftChevron className="w-[12px] h-[12px]" />
        </div>
        <div className="ml-1 text-[13.5px] md:text-[16px] font-inter font-medium">
          Reviews
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center">
          <Reviewcard />
        </div>
      </div>
    </div>
  );
};

export default AboutReview;
