const AddressCard = ({
  Icon,
  iconClassName,
  borderColorClassName,
  textColor,
  addressType,
  address,
  backgroundColor,
}) => {
  return (
    <div
      className={`cursor-pointer w-full p-5 h-[125px] ${
        backgroundColor && backgroundColor
      }  rounded-lg border ${
        borderColorClassName ? borderColorClassName : "border-gray-300"
      }  flex items-center space-x-3 `}
    >
      <div
        className={`w-12 h-12 2xl:w-16 2xl:h-16 bg-white rounded-full border flex justify-center items-center ${
          borderColorClassName ? borderColorClassName : "border-gray-300"
        } `}
      >
        <Icon className={iconClassName} />
      </div>
      <div className="space-y-6 flex-1">
        <div className="space-y-2">
          <div className="justify-between flex items-center">
            <div
              className={`text-[18px]  font-medium ${
                textColor ? textColor : "text-black"
              } `}
            >
              {addressType}
            </div>
          </div>
          <div className=" text-zinc-500 line-clamp-2 text-sm 2xl:text-base font-normal font-inter capitalize leading-[18px]">
            {address}
          </div>
        </div>
        {/* <div
          className={`py-2 px-4 w-max ${
            textColor ? textColor : "text-gray-500"
          }  font-semibold text-sm 2xl:text-xl rounded-lg border ${
            borderColorClassName ? borderColorClassName : "border-gray-500"
          } `}
        >
          Deliver Here
        </div> */}
      </div>
    </div>
  );
};

export default AddressCard;
