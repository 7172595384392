const EveryMood = ({ width, height, className }) => {
  return (
    <svg
      className={className}
      width={width ? width : "40"}
      height={height ? height : "40"}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3457_6474)">
        <path d="M20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0ZM27 12C28.6 12 30 13.4 30 15C30 16.6 28.6 18 27 18C25.4 18 24 16.6 24 15C24 13.4 25.4 12 27 12ZM13 12C14.6 12 16 13.4 16 15C16 16.6 14.6 18 13 18C11.4 18 10 16.6 10 15C10 13.4 11.4 12 13 12ZM20 32C14.8 32 10.4 28.6 8.8 24H31.2C29.6 28.6 25.2 32 20 32Z" />
      </g>
      <defs>
        <clipPath id="clip0_3457_6474">
          <rect width={width ? width : "40"} height={height ? height : "40"} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EveryMood;
