export default function AutoplayCarousel({ data }) {
  return (
    <div className="overflow-hidden h-32 md:h-40 xl:h-[191px] min-[1920]:w-[291px] relative w-full border-dark-shade-6">
      <div className="flex absolute left-0 justify-center items-center gap-4 md:gap-10 xl:gap-28 w-max h-full animate-slide">
        {data.map((val, ind) => (
          <img
            key={"autoplay" + ind}
            className="w-24 h-16 md:w-32 md:h-[76px] xl:h-[85px] sm:h-20 object-cover sm:w-32  sm:object-cover rounded-lg"
            src={val}
            loading="lazy"
          />
        ))}
      </div>
    </div>
  );
}
