import React from "react";
const styles = {
  container: {
    width: "800px",
    height: "600px",
    margin: 0,
    padding: "16px",
    border: "1px solid #c2c2c2",
    overflow: "hidden",
    background: "#ffffff",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
};
const PaymentIframe = ({ paymentFrameUrl }) => {
  return (
    <div style={styles.container}>
      <iframe
        src={paymentFrameUrl}
        title="FullScreenIframe"
        style={styles.iframe}
      ></iframe>
    </div>
  );
};

export default PaymentIframe;
