import React from "react";
import { useEffect, useState } from "react";
import InputField from "../../components/Elements/InputField";
import AddressCard from "../../components/Cards/AddressCard";
import {
  checkEmpty,
  prepareAddressFormat,
  capitalizeString,
} from "../../utils/helper";
import Briefcase from "../../components/Icon/Briefcase";
import Home from "../../components/Icon/Home";
import Other from "../../components/Icon/Other";
import Plus from "../../components/Icon/Plus";
import { getPostCode, cartSubmitAddress } from "../../services";
import { postcodeRegEx } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import CheckCircle from "../../components/Icon/CheckCircle";
import { error, success } from "../../components/notify";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import CheckoutDetaisEdit from "../../components/Icon/CheckoutDetailsEdit";
import CheckoutAddressIcon from "../../components/Icon/CheckoutAddressIcon";
const CheckoutAddress = ({
  cartDetails,
  inputError,
  setInputError,
  checkoutStepper,
  setIsLoadingTopBar,
}) => {
  const basket = useSelector((state) => state?.basicdata?.basket);
  const checkoutStep = useSelector((state) => state?.checkoutStep);
  const dispatch = useDispatch();
  const [isChangeAddressDetails, setIsChangeAddressDetails] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [postcodeError, setPostcodeError] = useState(false);
  const [postcodeValidError, setPostcodeValidError] = useState(false);
  const [streetNameError, setStreetNameError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [houseNoError, setHouseNoError] = useState(false);
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [newAddress2, setNewAddress2] = useState(true);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [selectedLat, setSelectedLat] = useState(null);
  const [selectedLng, setSelectedLng] = useState(null);
  const getAddressIcon = (type) => iconMapping[type] || Other; // DefaultIcon can be a fallback icon
  const iconMapping = {
    work: Briefcase,
    home: Home,
    plus: Plus,
    other: Other,
  };
  const editViewHide = () => {
    setIsChangeAddressDetails(false);
    setIsAddNewAddress(false);
  };
  const handlePostcodeChange = async (event) => {
    const postcode = event.target.value;
    setPostcode(postcode);
    if (postcode.length === 0) {
      setPostcodeError(true);
      setInputError("This field is required");
      setPostcodeValidError(false);
    } else if (postcode.length <= 10) {
      setPostcodeError(false);
      setPostcodeValidError(false);
      if (postcodeRegEx.test(postcode)) {
        getPostcodeData(postcode);
      }
    } else {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const handlePostcodeBlur = async (event) => {
    let thisPostcode = postcode.replace(/ /g, "");
    getPostcodeData(thisPostcode);
  };
  const getPostcodeData = async (thisPostcode) => {
    if (postcodeRegEx.test(thisPostcode)) {
      try {
        setIsLoadingTopBar(true);
        const { status, data } = await getPostCode(thisPostcode);
        if (status === 200) {
          const {
            pos_basket_Customer_Billing_Address_Line_2,
            pos_basket_Customer_Billing_City,
            pos_basket_Customer_Billing_Latitude,
            pos_basket_Customer_Billing_Longitude,
          } = data;
          setStreetName(pos_basket_Customer_Billing_Address_Line_2);
          setCity(pos_basket_Customer_Billing_City);
          setSelectedLat(pos_basket_Customer_Billing_Latitude);
          setSelectedLng(pos_basket_Customer_Billing_Longitude);
          setInputError("");
        } else {
          console.error("Failed to fetch postcode data", status);
        }
      } catch (error) {
        console.error("Postcode Error", error);
      } finally {
        setTimeout(() => {
          setIsLoadingTopBar(false);
        }, 500);
      }
    }
  };
  const handleStreetNameChange = (event) => {
    const streetName = event.target.value;
    setStreetName(streetName);
    if (streetName.length === 0) {
      setStreetNameError(true);
      setInputError("This field is required");
    } else if (streetName.length <= 50) {
      setStreetNameError(false);
    }
  };
  const handleCityChange = (event) => {
    const city = event.target.value;
    setCity(city);
    if (city.length === 0) {
      setCityError(true);
      setInputError("This field is required");
    } else if (city.length <= 20) {
      setCityError(false);
    }
  };
  const handleHouseNumberChange = (event) => {
    const houseNo = event.target.value;
    setHouseNo(houseNo);
    if (houseNo.length === 0) {
      setHouseNoError(true);
      setInputError("This field is required");
    } else if (houseNo.length <= 10) {
      setHouseNoError(false);
    }
  };
  const submitAddress = (e) => {
    e?.preventDefault();
    let hasError = false;

    if (checkEmpty(postcode)) {
      setPostcodeError(true);
      setPostcodeValidError(false);
      setInputError("This field is required");
      hasError = true;
    }

    if (postcodeRegEx.test(postcode) === false) {
      setPostcodeValidError(true);
      setPostcodeError(false);
      hasError = true;
    }

    if (checkEmpty(houseNo)) {
      setHouseNoError(true);
      setInputError("This field is required");
      hasError = true;
    } else {
      setHouseNoError(false);
    }

    if (checkEmpty(streetName)) {
      setStreetNameError(true);
      setInputError("This field is required");
      hasError = true;
    } else {
      setStreetNameError(false);
    }

    if (checkEmpty(city)) {
      setCityError(true);
      setInputError("This field is required");
      hasError = true;
    } else {
      setCityError(false);
    }
    if (!hasError) {
      cartAddressSubmit();
    }
  };
  const cartAddressSubmit = async () => {
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await cartSubmitAddress(
        capitalizeString(houseNo),
        capitalizeString(streetName),
        capitalizeString(city),
        postcode
      );
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          } else {
            success("Cart updated successfully");
          }
          dispatch(setBasketData(data?.basket));
          editViewHide();
          checkoutStepper("payment");
        } else {
          setSelectedAddressIndex(null);
          if (!checkEmpty(data.msg)) {
            error(data.msg);
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const handleAddressClick = async (address, index) => {
    try {
      setIsLoadingTopBar(true);
      let tempHouseNo = address?.common_customer_address_Line_1;
      let tempStreetName = address?.common_customer_address_Line_2;
      let tempCity = address?.common_customer_address_City;
      let tempPostcode = address?.common_customer_address_Postcode;
      setPostcode(tempPostcode);
      setHouseNo(tempHouseNo);
      setStreetName(tempStreetName);
      setCity(tempCity);
      const { status, data } = await cartSubmitAddress(
        capitalizeString(tempHouseNo),
        capitalizeString(tempStreetName),
        capitalizeString(tempCity),
        tempPostcode
      );
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
          dispatch(setBasketData(data?.basket));
          setSelectedAddressIndex(index);
          editViewHide();
        } else {
          setSelectedAddressIndex(null);
          if (!checkEmpty(data.msg)) {
            error(data.msg);
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const editAddressData = () => {
    if (cartDetails?.pageContent?.common_customer_Address.length < 1) {
      setIsAddNewAddress(true);
      setNewAddress2(true);
    }
    setIsChangeAddressDetails(true);
    clearFormData();
  };
  const clearFormData = async () => {
    setPostcode("");
    setHouseNo("");
    setStreetName("");
    setCity("");
    setHouseNoError(false);
    setStreetNameError(false);
    setCityError(false);
    setPostcodeValidError(false);
    setPostcodeError(false);
    setNewAddress2(true);
  };
  const sectionStepper = (value) => {
    if (value == "next") {
      checkoutStepper("payment");
    } else if (value == "prev") {
      checkoutStepper("basicDetails");
    }
  };
  useEffect(() => {
    if (
      checkEmpty(basket?.pos_basket_Customer_Billing_Address_Line_1) ||
      checkEmpty(basket?.pos_basket_Customer_Billing_Address_Line_2) ||
      checkEmpty(basket?.pos_basket_Customer_Billing_City) ||
      checkEmpty(basket?.pos_basket_Customer_Billing_Postcode)
    ) {
      editAddressData();
    }
  }, [basket]);
  return (
    <>
      {checkoutStep?.addressDetails?.summary === true ? (
        <>
          <div
            className={`flex w-full items-start justify-between border-t-2 py-5 ${
              checkoutStep?.addressDetails?.summary === true &&
              "animate-fadedown"
            }`}
          >
            <div className="sm:w-full pr-4 sm:pr-0">
              <div className="stepType flex justify-start">
                <CheckCircle className="fill-green-400 mr-2 mt-[4px]" />
                <div className="sm:w-full sm:flex">
                  <p className="text-black w-[200px] font-medium font-inter text-xl xl:text[22px] 2xl:text-[26px]">
                    Delivery Address
                  </p>
                  <div className="flex flex-col justify-center  sm:w-full sm:flex sm:items-center flex-1">
                    <p className="text-gray-500 text-sm font-semibold sm:w-[200px] md:w-[300px] capitalize">
                      {prepareAddressFormat(
                        basket?.pos_basket_Customer_Billing_Address_Line_1,
                        basket?.pos_basket_Customer_Billing_Address_Line_2,
                        basket?.pos_basket_Customer_Billing_Postcode,
                        basket?.pos_basket_Customer_Billing_City
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="text-primary underline underline-offset-4 text-sm 2xl:text-lg cursor-pointer"
              onClick={() => {
                checkoutStepper("addressDetails");
              }}
            >
              Change
            </div>
          </div>
        </>
      ) : checkoutStep.addressDetails.disp === true ? (
        <>
          <div
            className={`w-full 2xl:w-full h-auto border-t-2 py-5 ${
              checkoutStep.addressDetails.disp === true && "animate-fadedown"
            }`}
          >
            <div>
              <div className="text-black text-xl 2xl:text-[26px] font-medium font-inter">
                {!isChangeAddressDetails ? (
                  <span>Delivery Address</span>
                ) : isAddNewAddress ? (
                  <span>Add new delivery address</span>
                ) : (
                  <span>Choose a delivery address</span>
                )}
                {!isChangeAddressDetails ? (
                  <div className="flex items-center text-gray-500 text-sm font-normal mt-2">
                    <p>Do we have the right details?</p>
                    {!isChangeAddressDetails && (
                      <div
                        className="text-primary underline underline-offset-4 ml-2 2xl:text-lg cursor-pointer"
                        onClick={() => {
                          editAddressData();
                        }}
                      >
                        <CheckoutDetaisEdit />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>

              {isChangeAddressDetails && (
                <div className="flex items-center text-gray-500 text-sm font-normal mt-2">
                  <span>Where to send your food?</span>
                </div>
              )}

              <div className="pt-5 pb-2">
                {!isChangeAddressDetails ? (
                  <div className="flex flex-col space-y-4">
                    <div className="flex items-center space-x-[9px]">
                      <CheckoutAddressIcon className="w-12 h-12" />
                      <div className="text-gray-500 text-sm sm:text-md font-normal capitalize">
                        {prepareAddressFormat(
                          basket?.pos_basket_Customer_Billing_Address_Line_1,
                          basket?.pos_basket_Customer_Billing_Address_Line_2,
                          basket?.pos_basket_Customer_Billing_Postcode,
                          basket?.pos_basket_Customer_Billing_City
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {isAddNewAddress ? (
                      <div>
                        {newAddress2 && (
                          <div className="sm:gap-x-4 sm:gap-y-9 pb-5 space-y-5 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0">
                            <div className="relative">
                              <InputField
                                placeholder="Postcode"
                                title="Postcode"
                                type="text"
                                required
                                value={postcode}
                                onChange={handlePostcodeChange}
                                onBlur={handlePostcodeBlur}
                                autoComplete="new-password"
                              />
                              {postcodeError && (
                                <span className="text-primary text-sm absolute top-0 right-0">
                                  {inputError}
                                </span>
                              )}
                              {postcodeValidError && (
                                <span className="text-primary text-sm absolute top-0 right-0">
                                  Enter Vaild Postcode
                                </span>
                              )}
                            </div>
                            <div className="relative">
                              <InputField
                                placeholder="House Number"
                                type="text"
                                title="House Number"
                                autoComplete="off"
                                value={houseNo}
                                onChange={handleHouseNumberChange}
                                required
                              />
                              {houseNoError && (
                                <span className="text-primary text-sm absolute top-0 right-0">
                                  {inputError}
                                </span>
                              )}
                            </div>
                            <div className="relative">
                              <InputField
                                placeholder="Street Name"
                                type="text"
                                title="Street Name"
                                autoComplete="off"
                                required
                                value={streetName}
                                onChange={handleStreetNameChange}
                              />
                              {streetNameError && (
                                <span className="text-primary text-sm absolute top-0 right-0">
                                  {inputError}
                                </span>
                              )}
                            </div>
                            <div className="relative">
                              <InputField
                                placeholder="Town/City"
                                type="text"
                                title="Town/City"
                                value={city}
                                required
                                autoComplete="off"
                                onChange={handleCityChange}
                              />
                              {cityError && (
                                <span className="text-primary text-sm absolute top-0 right-0">
                                  {inputError}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="space-y-5 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0">
                        <div
                          onClick={() => {
                            setIsAddNewAddress(true);
                            setNewAddress2(true);
                            clearFormData();
                          }}
                          className={`cursor-pointer bg-green-50`}
                        >
                          <AddressCard
                            Icon={getAddressIcon("plus")}
                            addressType={"Add New"}
                            address={"Enter new delivery address"}
                            borderColorClassName={"border-green-400"}
                            iconClassName={
                              "fill-dark-shade-8 mx-auto w-4 h-4 my-3.5 2xl:w-7 2xl:h-7 2xl:my-4"
                            }
                          />
                        </div>
                        {cartDetails &&
                          cartDetails?.pageContent?.common_customer_Address.map(
                            (address, index) =>
                              !checkEmpty(
                                address?.common_customer_address_Line_1
                              ) &&
                              !checkEmpty(
                                address?.common_customer_address_Line_2
                              ) &&
                              !checkEmpty(
                                address?.common_customer_address_City
                              ) &&
                              !checkEmpty(
                                address?.common_customer_address_Postcode
                              ) && (
                                <div
                                  key={address?.common_customer_address_Id}
                                  onClick={() =>
                                    handleAddressClick(address, index)
                                  }
                                  className={`cursor-pointer hover:bg-pink-50 ${
                                    index === selectedAddressIndex
                                      ? "bg-pink-50"
                                      : ""
                                  }`}
                                >
                                  <AddressCard
                                    Icon={getAddressIcon(
                                      address?.common_customer_address_Label.toLowerCase()
                                    )}
                                    addressType={
                                      address?.common_customer_address_Label
                                    }
                                    address={
                                      address?.common_customer_address_Full_Address
                                    }
                                    borderColorClassName={`${
                                      index === selectedAddressIndex
                                        ? "border-primary"
                                        : "border-gray"
                                    } hover:border-primary`}
                                    textColor={
                                      index === selectedAddressIndex
                                        ? "text-primary"
                                        : "text-gray"
                                    }
                                    iconClassName={`${
                                      index === selectedAddressIndex
                                        ? "fill-primary border-primary textColor"
                                        : "fill-dark-shade-8"
                                    } capitalize mx-auto w-4 h-4 my-3.5 2xl:w-7 2xl:h-7 2xl:my-4`}
                                  />
                                </div>
                              )
                          )}
                      </div>
                    )}
                    {isAddNewAddress ? (
                      <div className="flex justify-between sm:pt-5">
                        {cartDetails?.pageContent?.common_customer_Address
                          .length > 1 ? (
                          <div
                            onClick={() => {
                              setIsAddNewAddress(false);
                            }}
                            className="flex items-center justify-center p-4 border w-[155px] h-[46px] sm:h-max sm:py-4 text-md font-medium rounded-lg  cursor-pointer"
                          >
                            <div className="text-gray-500">Saved Address</div>
                          </div>
                        ) : (
                          <span></span>
                        )}
                        <div
                          onClick={() => {
                            submitAddress();
                          }}
                          className="flex items-center justify-center bg-green-shade-4 rounded-lg w-[155px] h-[46px] sm:h-max sm:py-4  cursor-pointer text-white text-md font-medium"
                        >
                          <div className="">Deliver Here</div>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>

            {!isAddNewAddress && !isChangeAddressDetails && (
              <div className="flex justify-between pt-4 pb-4">
                <button
                  onClick={() => sectionStepper("prev")}
                  className="border border-[#28303F] sm:w-[135px] w-[48%] h-[46px] sm:h-max sm:py-4  text-md font-medium text-[#28303F] rounded-lg"
                >
                  Previous
                </button>
                <button
                  onClick={() => sectionStepper("next")}
                  className="bg-primary sm:w-[135px] w-[48%] h-[46px] sm:h-max sm:py-4 text-md font-medium text-white rounded-lg"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

export default CheckoutAddress;
