export const trimString = (string) => {
  let trimmedString = string.trim("");
  return trimmedString;
};

export const checkEmpty = (mixedVar) => {
  if (mixedVar == null) {
    return true;
  } else if (typeof mixedVar === "object") {
    return Object.getOwnPropertyNames(mixedVar).length === 0;
  } else {
    let undef;
    let i;
    let len;
    let emptyValues = [
      undef,
      null,
      "null",
      false,
      0,
      "",
      "0",
      "0.00",
      "0000-00-00 00:00:00",
      "0.0",
      "empty",
      undefined,
      "undefined",
    ];
    try {
      mixedVar = mixedVar?.trim();
    } catch (e) {
      // console.log(e);
    }
    for (i = 0, len = emptyValues.length; i < len; i++) {
      if (mixedVar === emptyValues[i]) {
        return true;
      }
    }
  }
  return false;
};

export const isNumber = (numb) => {
  return !isNaN(parseFloat(numb)) && isFinite(numb);
};

export const objectSize = (obj) => {
  let size = 0,
    key;
  for (key in obj) {
    if (Object.getOwnPropertyNames(key)) size++;
  }
  return size;
};

export const objectNext = (obj, key) => {
  let keys = Object.keys(obj),
    i = keys.indexOf(key);
  return i !== -1 && keys[i + 1] && obj[keys[i + 1]];
};

export const numberFormat = (number, decimals, dec_point, thousands_sep) => {
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  let n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec);
      return "" + Math.round(n * k) / k;
    };
  // Fix for IE checkFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

export const formatTotal = (num) => {
  return numberFormat(Math.round(num * 100) / 100, 2, ".", "");
};

export const checkInt = (num) => {
  num = parseInt(num);
  if (isNaN(num) || num == "" || num == 0 || num == "0") {
    return 0;
  } else {
    return num;
  }
};

export const checkFloat = (num) => {
  num = parseFloat(num);
  if (isNaN(num) || num == 0.0 || num == "" || num == 0 || num == "0") {
    return 0.0;
  } else {
    num = formatTotal(num);
    num = parseFloat(num);
    if (isNaN(num) || num == 0.0 || num == "" || num == 0 || num == "0") {
      return 0.0;
    } else {
      return num;
    }
  }
};

export const getNumber = (num) => {
  num = checkInt(num);
  return new Array(num);
};

export const splitUKPostcode = (basketpostcode) => {
  let posOfSpace;
  let str = basketpostcode;
  if ((posOfSpace = str.indexOf(" ")) !== -1) {
    return str.substr(0, posOfSpace);
  }
  if (str.length < 5) {
    return basketpostcode;
  }
  let shortened = str.substr(0, 5);
  if (
    String(parseInt(shortened.substr(4, 1))) === String(shortened.substr(4, 1))
  ) {
    return shortened.substr(0, 4);
  } else {
    if (
      String(parseInt(shortened.substr(3, 1))) ===
      String(shortened.substr(3, 1))
    ) {
      return shortened.substr(0, 3);
    } else {
      return shortened.substr(0, 2);
    }
  }
};

export const substrReplace = (str, replace, start, length) => {
  if (start < 0) {
    // start position in str
    start = start + str?.length;
  }
  length = length !== undefined ? length : str?.length;
  if (length < 0) {
    length = length + str?.length - start;
  }

  return [
    str?.slice(0, start),
    replace?.substr(0, length),
    replace?.slice(length),
    str?.slice(start + length),
  ].join("");
};

export const stringrpl = (x, r, str) => {
  let out = "";
  let temp = str?.substr(x);
  out = substrReplace(str, r, x);
  out += temp;
  return out;
};

export const splitUKPostcodeFormat = (mypostcode) => {
  let postcode = mypostcode?.trim();
  postcode = postcode?.replace(/ /g, "");
  var test = postcode;
  if (test?.substr(-3) == " ") {
    // eslint-disable-next-line no-self-assign
    postcode = postcode;
  } else {
    test = stringrpl(-3, " ", test);
    postcode = test;
  }
  return postcode;
};

export const formatPostcode = (mypostcode) => {
  mypostcode = splitUKPostcodeFormat(mypostcode);
  if (!checkEmpty(mypostcode)) {
    return mypostcode.toUpperCase();
  } else {
    return "";
  }
};

export const getDateField = (prefix, suffix) => {
  let d = new Date();
  let weekday = new Array(7);
  weekday[0] = prefix + "Sunday" + suffix;
  weekday[1] = prefix + "Monday" + suffix;
  weekday[2] = prefix + "Tuesday" + suffix;
  weekday[3] = prefix + "Wednesday" + suffix;
  weekday[4] = prefix + "Thursday" + suffix;
  weekday[5] = prefix + "Friday" + suffix;
  weekday[6] = prefix + "Saturday" + suffix;
  return weekday[d.getDay()];
};

export const getCur = (type, separator) => {
  let d = new Date();
  let n = "0";
  if (type == "day") {
    let weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    n = weekday[d.getDay()];
  } else if (type == "date") {
    let month = d.getUTCMonth() + 1; //months from 1-12
    let day = d.getUTCDate();
    let year = d.getUTCFullYear();
    n = year + separator + month + separator + day;
  } else if (type == "time") {
    let h = d.getHours(); // => 9
    let m = d.getMinutes(); // =>  30
    let s = d.getSeconds(); // => 51
    n = h + separator + m + separator + s;
  }
  return n;
};

export const showMessageBox = (message, type) => {
  let showMessage = false;
  let showMessageText = "";
  let showMessageStyle = "";
  //alert(message);
  let closeBox = "";

  if (type == "error") {
    showMessageStyle = { "background-color": "red" };
  } else if (type == "success") {
    showMessageStyle = { "background-color": "#8ecc00" };
  } else {
    showMessageStyle = { "background-color": "" };
  }

  showMessageText = closeBox + message;
  showMessage = true;

  setTimeout(function () {
    showMessageText = "";
    showMessage = false;
  }, 2000);
};

export const minutesBetween = (date1, date2) => {
  //Get 1 day in milliseconds
  let one_hour = 1000 * 60; //*60*24;
  date1 = new Date(date1);
  date2 = new Date(date2);
  // Convert both dates to milliseconds
  let date1_ms = date1.getTime();
  let date2_ms = date2.getTime();
  // Calculate the difference in milliseconds
  let difference_ms = date2_ms - date1_ms;
  // Convert back to days and return
  return Math.round(difference_ms / one_hour);
};

export const increaseBrightness = (hex, percent) => {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, "");

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, "$1$1");
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);

  return (
    "#" +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
};

export const hideModal = (modal) => {
  if (modal == 0) {
    let modal = { visible: 0 };
    if (modal.windows.length > 0) {
      for (var i in modal.windows) {
        [modal.windows[i]] = "";
      }
    }
    modal.windows = [];
  } else {
    modal.windows.pop();
    if (modal.windows.length <= 0) {
      modal.visible = 0;
    }
    [modal] = "";
  }
};

export const showModal = (window) => {
  let modal = { visible: 1 };
  modal.windows.push(window);
};

export const capitalizeFirstLetter = (string) => {
  if (string.length > 0) {
    return string[0].toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const getDistanceFromLatLonInMile = (lat1, lon1, lat2, lon2) => {
  let R = 6371; // Radius of the earth in km
  let M = 3959; // Radius of the earth in mile
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = M * c; // Distance in km
  return d;
};

export const jsUcfirst = (string) => {
  var ret = "";
  try {
    ret = string.charAt(0).toUpperCase() + string.slice(1);
  } catch (e) {
    /* empty */
  }
  return ret;
};

export const cleanString = (str) => {
  if (checkEmpty(str)) {
    return str;
  }

  if (parseInt(str)) {
    return str;
  }

  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap Ã± for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes
    .replace(/\//g, "-"); // collapse dashes
  str = str.replace("/", "-");
  //store_name = store_name.trim();
  str = str.toLowerCase();
  //store_name = store_name.replace("![^a-z0-9]+!i", "-")
  return str;
};

export const cleanPostcode = (string) => {
  string = string?.trim();
  string = string?.split(" ").join("");
  string = string?.replace(" ", "");
  string = string?.replace("/[^A-Za-z0-9]/", "");
  string = string?.replace("/-+/", "");
  string = strtolower(string);
  return string;
};

export const clearConsole = () => {
  console.clear();
};

export const strtolower = (string) => {
  string = string?.trim();
  string = string.toLowerCase();
  return string;
};

export const objectKeys = (obj) => {
  return Object.keys(obj);
};

export const resetObject = (obj) => {
  let tmpObj = {};
  let r = 0;
  Object.keys(obj ?? {}).forEach((k) => {
    tmpObj[r] = obj[k];
    r++;
  });
  return tmpObj;
};

export const validatePostCode = (postcode) => {
  let postcodeRegEx =
    /^(GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4})$/i;
  postcodeRegEx =
    /^(GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4})$/i;
  if (postcodeRegEx.test(postcode)) {
    return true;
  }
  return false;
};

export const adjustTextareaHeight = ($event) => {
  let o = $event.currentTarget;
  //console.log(o.value);
  if (o.value == "") {
    o.style.height = "46px";
  } else {
    o.style.height = "1px";
    o.style.height = 5 + o.scrollHeight + "px";
  }
};

export const getMobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const getRandomImageInt = () => {
  let min = 1;
  var max = 4;
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const copyObject = (object) => JSON.parse(JSON.stringify(object));

export const completeDateFormatter = (data) => {
  var currentTime = new Date();
  var dateItem = data.split(" ");
  var shortDay = "";
  var shortMonth = "";
  var conditionalYear = "";
  var finalDateString = "";
  for (var i = 0; i < dateItem.length; i++) {
    if (i === 2) {
      shortDay = dateItem[i].substr(0, 3);
    }
    if (i === 4) {
      shortMonth = dateItem[i].substr(0, 3);
    }
    if (i === 5) {
      if (currentTime.getFullYear().toString() === dateItem[i]) {
        conditionalYear = "";
      } else {
        conditionalYear = dateItem[i];
      }
    }
  }
  finalDateString = shortDay + ", " + shortMonth + " " + dateItem[3];
  if (!checkEmpty(conditionalYear)) {
    finalDateString = finalDateString + ", " + conditionalYear;
  }
  finalDateString =
    finalDateString + ", " + dateItem[0] + " " + dateItem[1].toUpperCase();

  return finalDateString;
};

export const formatTimeAMPMDayMonthYear = (date) => {
  var d = new Date(date);
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let day = d.getDay();
  let finalDay = "";
  if (day > 3 && day < 21) {
    finalDay = day + "th";
  } else {
    switch (day % 10) {
      case 1:
        finalDay = day + "st";
        break;
      case 2:
        finalDay = day + "nd";
        break;
      case 3:
        finalDay = day + "rd";
        break;
      default:
        finalDay = day + "th";
    }
  }
  var str = dayNames[d.getDay()];

  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return completeDateFormatter(
    strTime +
      " " +
      str.slice(0, 3) +
      " " +
      finalDay +
      " " +
      monthNames[d.getMonth()] +
      " " +
      d.getFullYear()
  );
};

export const prepareAddressFormat = (
  line1 = "",
  line2 = "",
  city = "",
  postcode = ""
) => {
  var address = "";
  if (!checkEmpty(line1)) {
    address += line1;
  }
  if (!checkEmpty(line2)) {
    if (!checkEmpty(address)) {
      address += ", ";
    }
    address += line2;
  }

  if (!checkEmpty(city)) {
    if (!checkEmpty(address)) {
      address += ", ";
    }
    address += city;
  }

  if (!checkEmpty(postcode)) {
    if (!checkEmpty(address)) {
      address += ", ";
    }
    address += postcode;
  }
  return address;
};

export const prepareProfileAddressFormat = (address) => {
  console.log(address);
  let common_customer_address_Full_Address =
    address.common_customer_address_Full_Address;
  common_customer_address_Full_Address =
    common_customer_address_Full_Address.trim();
  common_customer_address_Full_Address = strRightTrim(
    common_customer_address_Full_Address,
    ","
  );
  common_customer_address_Full_Address =
    common_customer_address_Full_Address.trim();
  let common_customer_address_Country = address.common_customer_address_Country;
  common_customer_address_Country = strRightTrim(
    common_customer_address_Country,
    "'"
  );
  let formattedAddress = common_customer_address_Full_Address;
  if (
    !checkEmpty(common_customer_address_Full_Address) &&
    !checkEmpty(common_customer_address_Country)
  ) {
    formattedAddress += ", " + address.common_customer_address_Country;
  }
  return formattedAddress;
};
export const strRightTrim = (str, charToRemove) => {
  while (str.endsWith(charToRemove)) {
    str = str.slice(0, -1);
  }
  return str;
};

export const prepareOrderTracing = (orderStatus, deliveryType) => {
  // console.log(orderStatus);
  let checkArray = ["LIVE", "DONE"];
  if (deliveryType == "Delivery") {
    if (checkArray.includes(orderStatus.delivered)) {
      return 4;
    } else if (checkArray.includes(orderStatus.pickedup)) {
      return 3;
    } else if (checkArray.includes(orderStatus.prepared)) {
      return 2;
    } else if (checkArray.includes(orderStatus.accepted)) {
      return 1;
    } else {
      return 0;
    }
  } else {
    if (checkArray.includes(orderStatus.delivered)) {
      return 3;
    } else if (checkArray.includes(orderStatus.prepared)) {
      return 2;
    } else if (checkArray.includes(orderStatus.accepted)) {
      return 1;
    } else {
      return 0;
    }
  }
};

export const isValidDateTime = (dateTimeString) => {
  // Regular expression to match the date-time format
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

  // Check if the string matches the format
  if (!regex.test(dateTimeString)) {
    return false;
  }

  // Parse the date components
  const [datePart, timePart] = dateTimeString.split(" ");
  const [year, month, day] = datePart.split("-").map(Number);
  const [hour, minute, second] = timePart.split(":").map(Number);

  // Create a Date object
  const date = new Date(year, month - 1, day, hour, minute, second);

  // Check if the date is valid
  if (
    date.getFullYear() !== year ||
    date.getMonth() + 1 !== month ||
    date.getDate() !== day ||
    date.getHours() !== hour ||
    date.getMinutes() !== minute ||
    date.getSeconds() !== second
  ) {
    return false;
  }

  return true;
};

export const checkDoSticky = () => {
  const viewportHeight = window.innerHeight;
  const mainDiv = document.getElementById("main_div");
  if (mainDiv !== null) {
    const elementHeight = mainDiv ? mainDiv.offsetHeight : 0;
    if (checkInt(elementHeight) > checkInt(viewportHeight) + 40) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const addLeadingZero = (mobileNumber) => {
  if (
    mobileNumber != undefined &&
    mobileNumber != null &&
    mobileNumber.length != 0
  ) {
    mobileNumber = mobileNumber.toString();
    if (!mobileNumber.startsWith("0")) {
      mobileNumber = "0" + mobileNumber;
    }

    return mobileNumber;
  }
};

export const capitalizeString = (string) => {
  let text = string.replaceAll("-", " ");
  let splittedText = text.split(" ");
  let capitalizedString = "";
  splittedText.forEach((split) => {
    capitalizedString = capitalizedString + " " + capitalizeFirstLetter(split);
  });
  return capitalizedString;
};
