export const LandingPage = "/";
export const RestaurantsPage = "/stores/restaurants";
export const OffersPage = "/stores/offers";
export const BookmarksPage = "/profile/bookmarks";
export const ProfilePage = "/profile";
export const LoginPage = "/login";
export const RegistrationPage = "/registration";
export const AddressPage = "/profile/address";
export const PastOrdersPage = "/profile/past-orders";
export const RewardsPage = "/profile/rewards";
export const PreferencePage = "/profile/preference";
export const WalletPage = "/profile/wallet";
export const HomePage = "/stores/";
export const BankcardsPage = "/profile/bankcards";
export const menuscroll = "/menuscroll";
export const Faq = "frequently/asked/questions";
export const TermsOfUse = "terms-of-use";
export const TermsConditions = "terms-conditions";
export const PrivacyPolicy = "privacy-policy";
export const DefaultPostCode = "St64ep";
export const Takeaways = "takeaways/";
export const AboutPage = (data, id) =>
  `/about/${data.split(" ").join("_").toLowerCase()}/${id}`;

export const MenuPage = (data, id) =>
  `/order/${data.split(" ").join("_").toLowerCase()}/${id}`;
export const CheckoutPage = () => `/store/cart`;
export const ConfirmPage = "/store/confirm/";
export const TrackFoodPage = "/store/orderstatus/";
export const PaymentPage = "/store/payment";
export const PaymentProcessingPage = (id1, id2, id3) =>
  `/store/paymentprocessing/${id1}/${id2}/${id3}`;
export const PaymentSuccess = "/payment/success";
