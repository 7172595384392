export default function TrackFoodLoader({
  className,
  subClassName,
  subsubClassName,
}) {
  return (
    <div className={`animate-pulse  `}>
      <div
        className={`hidden md:block pb-4 pt-4 md:h-[230px] mdl:h-[242px] w-full rounded-lg  h-[600px] border border-gray-shade-13 mb-[25px] md:px-4 md:py-1 md-pb-[10px] sm:bg-neutral-100 sm:w-full sm:rounded-lg relative ${className}`}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 my-10 md:my-8 gap-4">
          <div className="h-8 bg-gray-300 rounded"></div>
        </div>

        <div className="h-1 bg-gray-300 rounded m-10"></div>

        <div className="grid grid-cols-5 sm:grid-cols-5  lg:my-4 lg:gap-36 lg:m-10   md:my-2 md:gap-10 md:m-6">
          <div className="h-12 bg-gray-300 rounded"></div>
          <div className="h-12 bg-gray-300 rounded"></div>
          <div className="h-12 bg-gray-300 rounded"></div>
          <div className="h-12 bg-gray-300 rounded"></div>
          <div className="h-12 bg-gray-300 rounded"></div>
        </div>
      </div>

      <div
        className={`block md:hidden pb-4 pt-4 md:h-[230px] mdl:h-[242px] w-full rounded-lg  h-[525px] border border-gray-shade-13 mb-[25px] md:px-4 md:py-1 md-pb-[10px] sm:bg-neutral-100 sm:w-full sm:rounded-lg relative ${className}`}
      >
        <div className="grid grid-cols-2 sm:grid-cols-2  gap-4 ml-4">
          <div className="h-8 bg-gray-300 rounded"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1  lg:my-4 lg:gap-36 lg:m-10   md:my-2 md:gap-10 md:m-6">
          <div className="h-[400px] w-[5px] bg-gray-300 rounded m-10 "></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 absolute top-[90px] left-[65px]">
          <div className="h-12 w-[50px] bg-gray-300 rounded"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 absolute top-[175px] left-[65px]">
          <div className="h-12 w-[50px] bg-gray-300 rounded"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1  absolute top-[265px] left-[65px]">
          <div className="h-12 w-[50px] bg-gray-300 rounded"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 absolute top-[350px] left-[65px]">
          <div className="h-12 w-[50px] bg-gray-300 rounded"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 absolute top-[435px] left-[65px]">
          <div className="h-12 w-[50px] bg-gray-300 rounded"></div>
        </div>
      </div>

      <div className="grid grid-cols-3 sm:grid-cols-3 my-4  md:my-8 md:gap-20  relative gap-4">
        <div className="md:h-32 h-20 bg-gray-300 rounded"></div>
        <div className="md:h-32 h-20 bg-gray-300 rounded"></div>
        <div className="md:h-32 h-20 bg-gray-300 rounded flex justify-center items-center">
          <div className="md:h-[86px] h-[60px]  border-l-[2px] bg-gray-300 rounded	"></div>
        </div>
      </div>


      <div className="grid grid-cols-2 sm:grid-cols-2 my-4 md:my-8 md:gap-10  relative gap-4">
        <div className="md:h-72 h-32 bg-gray-300 rounded"></div>
        <div className="md:h-72 h-32 bg-gray-300 rounded"></div>
       
      </div>

    </div>
  );
}
