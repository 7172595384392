import cookiesImage from "../../../assets/images/cookies.svg";
import { setCookiesAlert } from "../../../utils/index";
import { setUserCookies } from "../../../store/userData/userDataSlice";
import { useDispatch } from "react-redux";

const CookiesAlert = () => {
  const dispatch = useDispatch();
  const acceptCookies = () => {
    setCookiesAlert("cookies", true);
    dispatch(setUserCookies(true));
  };

  return (
    <div className="flex items-start justify-center h-24 z-50">
      <div className="bg-black p-4 rounded-lg shadow-lg w-[361px] h-[80px] md:w-[425px] md:h-[94.2px] lg:w-[485px] lg:h-[107.5px] 2xl:w-[582px] 2xl:h-[129px] flex justify-between items-center">
        {/* Your content goes here */}
        <div className="flex flex-row flex-1">
          <img
            className="w-12 md:w-[60px] lg:w-[67.5px] 2xl:w-[81px]"
            loading="lazy"
            src={cookiesImage}
            alt=""
          />
          <div className="ml-4 md:ml-5">
            <div className="text-[10px] md:text-[11px]  lg:text-[12.5px] 2xl:text-[15px] text-white font-semibold flex flex-start leading-4 2xl:leading-5 md:mt-1 lg:mt-2 2xl:mt-3">
              This website uses cookies to give <br />
              you an incredible experience.
            </div>
            <p className="text-gray-500 text-[9px] md:text-[10px] lg:text-[11px] 2xl:text-[13px] md:mt-1">
              By using this website you agree to the terms.
            </p>
          </div>
        </div>
        <button
          onClick={acceptCookies}
          className="bg-primary text-[10px] lg:text-[11px] 2xl:text-[14px] inline-flex items-center justify-center text-white lg:mr-6 md:mr-4 2xl:mr-7 py-[6.2px] px-[9.3px] md:py-[7.3px] md:px-[11px] lg:py-[8.3px] lg:px-[12.5px] 2xl:py-[10px] 2xl:px-[15px] rounded"
        >
          I agree
        </button>
      </div>
    </div>
  );
};

export default CookiesAlert;
