// import React from "react";

const GreenRoundMarker = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-7 md:w-8"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="6" fill="#05B011" />
      <circle cx="10" cy="10" r="9" stroke="#05B011" strokeWidth="2" />
    </svg>
  );
};

export default GreenRoundMarker;
