const TrackOrderId = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      className={className}
    >
      <circle cx="23" cy="23" r="22.5" fill="#F5F5F5" stroke="#768993" />
      <path
        d="M28.9199 28.8368L23.3402 31.9125C23.2361 31.9699 23.1191 32 23 32C22.8809 32 22.7639 31.9699 22.6598 31.9125L17.0801 28.8368C16.7528 28.6564 16.48 28.3924 16.2901 28.0723C16.1002 27.7521 16.0001 27.3874 16 27.016V18.8055C16.0011 17.885 16.3702 17.0025 17.0264 16.3515C17.6825 15.7006 18.5721 15.3344 19.5 15.3333H26.5C27.4279 15.3344 28.3175 15.7006 28.9736 16.3515C29.6298 17.0025 29.9989 17.885 30 18.8055V27.016C29.9999 27.3874 29.8998 27.7521 29.7099 28.0723C29.52 28.3924 29.2472 28.6564 28.9199 28.8368ZM28.6 18.8055C28.6 18.253 28.3788 17.7231 27.9849 17.3324C27.5911 16.9417 27.057 16.7222 26.5 16.7222H19.5C18.943 16.7222 18.4089 16.9417 18.0151 17.3324C17.6212 17.7231 17.4 18.253 17.4 18.8055V27.016C17.3997 27.1402 17.433 27.2622 17.4964 27.3693C17.5598 27.4764 17.651 27.5647 17.7605 27.625L23 30.5111L28.2402 27.625C28.3496 27.5646 28.4406 27.4763 28.5039 27.3692C28.5672 27.2621 28.6004 27.1401 28.6 27.016V18.8055Z"
        fill=""
      />
      <path
        d="M23 26.0972C23.5799 26.0972 24.05 26.5636 24.05 27.1389C24.05 27.7142 23.5799 28.1805 23 28.1805C22.4201 28.1805 21.95 27.7142 21.95 27.1389C21.95 26.5636 22.4201 26.0972 23 26.0972Z"
        fill=""
      />
    </svg>
  );
};

export default TrackOrderId;
