import Location from "../../assets/images/my_location.png";
import { error } from "../notify";
import { getLocationFromLatLng } from "../../services";
import { useState } from "react";

const Map = ({ onLocationUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const getCurrentLocation = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const { status, data } = await getLocationFromLatLng(
          latitude,
          longitude
        );
        if (status === 200) {
          const { locationData } = data;
          onLocationUpdate(locationData);
        } else {
          error("Failed to fetch location data", true);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        switch (err.code) {
          case 1:
            error("Permission denied. Please allow permission", true);
            break;
          case 2:
            error("Unable to get location.", true);
            break;
          case 3:
            error("Timeout. Unable to fetch location", true);
            break;
          default:
            error("Something went wrong!! Please try again", true);
        }
      }
    );
  };

  return (
    <div
      className="w-full xl:w-1/2 px-3 md:px-0 xl:pr-3 mb-6 md:mb-8 cursor-pointer text-textPrimary"
      onClick={getCurrentLocation}
    >
      <label htmlFor="email-address-icon" className="block mb-2 text-sm">
        Locate Me<span className="text-primary">*</span>
      </label>

      <div className=" bg-white border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg w-full p-[9px]">
        {isLoading ? (
          <div className="flex items-center">
            <svg
              className="animate-spin h-6 w-6 text-primary"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <p className="pl-2">Loading...</p>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                loading="lazy"
                src={Location}
                className="w-6 h-6"
                alt="Location icon"
              />
              <p className="pl-2">Detect My Location</p>
            </div>
            <div>Using GPS</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Map;
