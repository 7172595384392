export const sessionId = "kuick-userdata";
export const userSessionId = "kuick-userid";
export const userSessionCCId = "kuick-ccid";
export const cartConfigSession = "kuick-cartconfig";
export const configSession = "KuickWeb.config";
export const ssipSession = "kuick-ssip";
export const dineConfigSession = "KuickWeb.dineConfig";
export const recentLocations = "KuickWeb.locations";
export const recentStores = "KuickWeb.stores";
export const recentLocation = "KuickWeb.location";
export const recentAddressId = "KuickWeb.address";
export const dismissPendingOrders = "pending-orders";
export const versioncontrol = "webVersion";

// Api end points
export const basicDineData = "api/product/basicdinedata";
export const basicData = "api/product/basicdata";
export const kuickAnalytics =
  import.meta.env.VITE_LOGIN_URL + "extapi/kuickanalytics";
export const login = import.meta.env.VITE_LOGIN_URL + "extapi/customer/login";
export const sendDownloadLinkSMS =
  import.meta.env.VITE_LOGIN_URL + "extapi/share/sendsms";
export const sendLinkMAIL =
  import.meta.env.VITE_LOGIN_URL + "extapi/share/sendemail";
export const addressFromLatLng = "api/profile/address/latlng";
export const pageContent = "api/store/pagecontent";
export const walletstmt = "api/profile/walletstmt";
export const pastorders = "api/profile/pastorders";
export const getpostcode = "api/cart/getpostcodedata";
export const addAddress = "api/profile/update/address";
export const addresslatlng = "api/profile/address/latlng";
export const updatePreference = "api/profile/update/preference";
export const addCardDetails = "api/cart/cardpaymentprocessing";
export const storeFilters = "api/dine/storefilter";
export const service = "api/store/service";
export const findStore = "api/dine/findstore";
export const updateBookmark = "api/profile/update/bookmark";
export const deleteAddress = "api/profile/delete/address";
export const viewOrder = "api/profile/basketdetails";
export const storeData = "api/dine/storedata";
export const storeMenu = "api/product/storemenu";
export const reviews = "api/store/reviews";
export const bookmark = "api/profile/update/bookmark";
export const postCode = "api/cart/getpostcodedata";
export const address = "api/cart/submit/address";
export const insertOnlyProduct = "api/product/insertonlyproduct";
export const insertSingleProduct = "api/product/insertsingleproduct";
export const insertComplexProduct = "api/product/insertcomplexproduct";
export const actBusket = "api/basket/actBasketProduct";
export const productGroups = "api/product/groups";
export const complexGroups = "api/product/complexgroups";
export const complexsubmenu = "api/product/complexsubmenu";
export const submenu = "api/product/submenu";
export const complexmenu = "api/product/complexmenu";
export const allergyList = "api/store/allergylist";
export const addAllergy = "api/cart/submit/allergy";
export const applyCustomerNote = "api/basket/applyCustomerNote";
export const applyCoupon = "api/basket/applybasketcoupon";
export const cardList = "/api/cart/savedcardlist";
export const pointstmt = "api/profile/pointstmt";
export const basicDetails = "api/cart/submit/basicdetails";
export const changeBasketOption = "api/basket/changeBasketOption";
export const finishOrder = "api/cart/finishorder";
export const basketdetails = "api/cart/basketdetails";
export const orderstatus = "api/order/status";
export const deleteBankCard = "api/profile/delete/card";
export const updateCardDefault = "api/profile/default/card";
export const couponChecked = "api/account/readcoupon";
export const deleteBasket = "api/basket/deleteBasketComponent";
export const poinstorestmt = "api/profile/pointstorestmt";
export const profileUpdate = "api/profile/update/basic";
export const submitBasketOtp = "api/cart/submitbasketotp";
export const submitCheckoutResendOtp = "api/cart/submitcheckout_resendotp";
export const updateDispatchTime = "api/cart/dispatchtime";
export const pendingOrders = "api/profile/pendingorders";
export const submitReviews = "api/store/submitreview";
export const unsubscribeUrl = "api/user/unsubscribe";
export const initCardPayment = "api/cart/initcardpayment";
export const processCardPayment = "api/cart/cardpaymentprocessing";
export const paymentStatus = "api/cart/cardpaymentprocessing";
export const submitOrderReview = "api/profile/submitorderreview";
export const getcoupons = "api/account/getcoupons";
export const checkcoupon = "api/basket/checkcoupon";
export const versioncheck = "api/external/product/version";
// /api/cart/savedcardlist BanK Card List
