import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import MenuLoader from "../../components/Loader/MenuLoaders/MenuLoader";
import MenuBanner from "../menu/MenuBanner";
import {
  AboutPage,
  ConfirmPage,
  HomePage,
  LandingPage,
  MenuPage,
  PaymentPage,
  PaymentSuccess,
} from "../../routes/RoutesPath";
import Layout from "../../layout/Layout";
import Cart from "../../components/Elements/Cart";
import { error, success } from "../../components/notify";
import checkoutNoDataImage from "../../assets/images/no-data-found/checkout/no-data.svg";
import {
  orderFinish,
  basketDetails,
  orderStatus,
  getBasicData,
  fetchMenuItem,
  cartData,
} from "../../services";

import { checkEmpty } from "../../utils/helper";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";
import CheckoutDispatch from "./Dispatch";
import CheckoutBasicInfo from "./BasicInfo";
import CheckoutAddress from "./Address";
import CheckoutPayment from "./Payment";
import {
  resetCheckoutStep,
  storageCheckoutStep,
} from "../../store/checkout/checkoutSlice";
import SubHeader from "../../components/Loader/MenuLoaders/SubHeader";
import GreaterThan from "../../assets/images/chevron-right.png";
import {
  capitalizeFirstWord,
  getStorageJSON,
  setCartConfigSession,
  setStorageJSON,
} from "../../utils";
import useBasketValidateAction from "../../hooks/useBasketValidate";
import useAnalyticsEvents from "../../hooks/useAnalyticsEvents";
import MenuCards from "../../components/Loader/MenuLoaders/MenuCards";
import CartLoader from "../../components/Loader/MenuLoaders/CartLoader";
import useWindowDimensions from "../../hooks/useWindowsDimensons";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import Button from "../../components/Elements/Button";

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTopBar, setIsLoadingTopBar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);
  const [inputError, setInputError] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [cartDetails, setCartDetails] = useState("");
  const checkoutStep = useSelector((state) => state?.checkoutStep);
  const userLocation = useSelector((state) => state?.basicdata?.locationData);
  const basket = useSelector((state) => state?.basicdata?.basket);
  const storeDetails = useSelector((state) => state?.basicdata?.storedetails);
  const storeSlug = storeDetails?.store_Slug;
  const productId = storeDetails?.product_Id;
  const storeName = storeDetails?.store_Name;
  const storeCity = storeDetails?.store_City;
  const storeBannerImage = storeDetails?.store_Banner_Image;
  const [basketOption, setBasketOption] = useState("");
  const [paymentModalOpen, setpaymentModalOpen] = useState(false);
  const bottomRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const basketValidateAction = useBasketValidateAction();
  const analyticsEvents = useAnalyticsEvents();
  const [isSideBarFixed, setIsSideBareFixed] = useState(false);
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaOgDescription = document.querySelector(
    'meta[property="og:description"]'
  );
  const metaOgTitle = document.querySelector('meta[property="og:title"]');

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 80);
    setIsSideBareFixed(scrollTop > 320);
  };

  const getBasicDataAPI = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await getBasicData();
      if (status == 200) {
        if (data.status === "success") {
          if (!checkEmpty(data?.storedetails)) {
            setStoreData(data?.storedetails);
          } else if (
            checkEmpty(data?.storedetails) &&
            checkEmpty(data?.storedetails?.product_Id)
          ) {
            navigate(LandingPage);
          }
          if (
            checkEmpty(data?.basket?.pos_basket_Id) ||
            data?.basket?.status == "Invalid"
          ) {
            dispatch(setBasketData(data?.basket));
            setCartConfigSession(data?.cartConfig);
            if (!checkEmpty(storeSlug) && !checkEmpty(productId)) {
              navigate(MenuPage(storeSlug, productId));
            } else {
              navigate(LandingPage);
            }
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("error msg", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getCartPageContentData = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await cartData();
      if (status === 200) {
        if (data.status === "success") {
          setCartDetails(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("error msg", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFinishOrder = async () => {
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await orderFinish();
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
          let basketKey = data?.pos_basket_Key;
          if (data.paymentmode == "Card") {
            setIsLoadingTopBar(false);
            navigate(PaymentPage);
          } else if (data.paymentmode == "Cash") {
            setIsLoadingTopBar(false);
            navigate(ConfirmPage + basketKey);
          }
        } else if (data.status == "error") {
          if (!checkEmpty(data.msg)) {
            error(data.msg);
          }
          if (!checkEmpty(data.err_type)) {
            basketValidateAction(data);
          }
        }
      }
    } catch (error) {
      console.log("error msg", error);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const handleVisitNearestStore = () => {
    if (
      !checkEmpty(userLocation) &&
      !checkEmpty(userLocation?.postcodePretty)
    ) {
      let thisPostcode = userLocation?.postcodePretty;
      thisPostcode = thisPostcode.replace(/\s+/g, "");
      navigate(HomePage + thisPostcode);
    } else {
      navigate(LandingPage);
    }
  };
  const checkoutStepper = (step) => {
    dispatch(
      resetCheckoutStep({ step: step, basketId: basket?.pos_basket_Id })
    );
  };
  useEffect(() => {
    if (isLoading || isLoadingTopBar) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 1000);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading, isLoadingTopBar]);
  useEffect(() => {
    if (paymentModalOpen === true) {
      bottomRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [paymentModalOpen]);
  useEffect(() => {
    setIsLoading(true);
    getBasicDataAPI();
    getCartPageContentData();
  }, [storeSlug, productId]);

  useEffect(() => {
    if (!checkEmpty(storeName)) {
      document.title = `Checkout | ${storeName} - ${
        import.meta.env.VITE_PRODUCT_NAME
      }`;
      metaOgTitle.setAttribute(
        "content",
        `Checkout | ${storeName} - ${import.meta.env.VITE_PRODUCT_NAME}`
      );
      metaDescription.setAttribute(
        "content",
        `ssssOrder food online from ${storeName}, ${storeCity}. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
      metaOgDescription.setAttribute(
        "content",
        `ssssOrder food online from ${storeName}, ${storeCity}. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
    }
  }, [storeName, storeCity, storeBannerImage]);
  useEffect(() => {
    analyticsEvents("InitiateCheckout", { page_path: location?.pathname });
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  useEffect(() => {
    let kuickCheckoutStep = getStorageJSON("kuick-checkout-step");
    if (
      !checkEmpty(kuickCheckoutStep) &&
      !checkEmpty(basket?.pos_basket_Id) &&
      kuickCheckoutStep.basketId != basket?.pos_basket_Id
    ) {
      dispatch(
        resetCheckoutStep({ step: "dispatch", basketId: basket?.pos_basket_Id })
      );
    }
  }, [basket]);
  return (
    <div>
      <Layout
        className={`${
          isFixed
            ? "fixed animate-fadedown top-[76px] left-0 shadow-xl px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] overflow-hidden"
            : "animate-fadeup"
        } bg-white w-full z-50 mdl:flex items-center hidden`}
      >
        <Link to={HomePage} className="flex items-center cursor-pointer">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              {capitalizeFirstWord("Home")}
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </Link>
        <div
          onClick={() => {
            navigate(AboutPage(storeSlug, productId));
          }}
          className="flex items-center cursor-pointer"
        >
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              {capitalizeFirstWord(storeName)}
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </div>
        <div
          onClick={() => {
            navigate(MenuPage(storeSlug, productId));
          }}
          className="flex items-center cursor-pointer"
        >
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              Menu
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </div>
        <div className="flex items-center">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-active-route">
              Cart
            </span>
          )}
        </div>
      </Layout>

      <div>
        {isLoading ? (
          <MenuLoader
            subClassName={`h-38 sm:h-56 md:h-56 lg:h-60 xl:h-72 relative -z-30`}
          />
        ) : (
          <MenuBanner
            storeSlug={storeSlug}
            productId={productId}
            setIsLoading={setIsLoading}
            storeData={storeData}
          />
        )}

        <div className="flex flex-col mdl:grid mdl:grid-cols-12 mdl:space-x-0 mdl:px-4 xl:px-[60px] menu-scroll sm:mt-6 mdl:mt-8">
          <div className="flex mdl:col-span-8 w-full ">
            {isLoading ? (
              <div className="flex flex-col items-center gap-y-4 w-full px-4 md:w-full md:px-5 mt-4 mdl:mt-0">
                <MenuCards />
                <MenuCards />
                <MenuCards />
                <MenuCards />
              </div>
            ) : (
              <div className="px-4 w-full md:w-full md:px-5 mt-4 mdl:mt-0">
                {storeDetails?.store_Open_Status == "Close" ? (
                  <div className="w-full flex flex-col items-center justify-center h-auto gap-5">
                    <img
                      className="w-[200px] mdl:w-[300px] h-auto m-auto"
                      src={checkoutNoDataImage}
                      alt="error image"
                    />
                    <span className="text-black[800] font-medium text-lg md:text-p6">
                      Store Closed
                      {!checkEmpty(storeDetails?.store_Open_Time) && (
                        <span className="text-black[800]">
                          , next opens at{" "}
                          <span className="text-primary">
                            {storeDetails?.store_Open_Time}
                          </span>
                        </span>
                      )}
                    </span>
                    <div>
                      <Button
                        onClick={() => {
                          handleVisitNearestStore();
                        }}
                        className="w-full md:w-max px-6 py-2 md:py-4 text-white text-md font-semibold"
                        type={"primary"}
                      >
                        <span>Visit Nearest Open Store</span>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <CheckoutDispatch
                      storeDetails={storeDetails}
                      cartDetails={cartDetails}
                      inputError={inputError}
                      setInputError={setInputError}
                      checkoutStepper={checkoutStepper}
                      basketOption={basketOption}
                      setBasketOption={setBasketOption}
                      setIsLoading={setIsLoading}
                      setIsLoadingTopBar={setIsLoadingTopBar}
                      isLoadingTopBar={isLoadingTopBar}
                    />

                    <CheckoutBasicInfo
                      checkoutStepper={checkoutStepper}
                      setIsLoadingTopBar={setIsLoadingTopBar}
                    />

                    {basket.pos_basket_Delivery_Type == "Delivery" &&
                    storeDetails.store_Delivery == 1 ? (
                      <CheckoutAddress
                        cartDetails={cartDetails}
                        inputError={inputError}
                        setInputError={setInputError}
                        checkoutStepper={checkoutStepper}
                        setIsLoadingTopBar={setIsLoadingTopBar}
                      />
                    ) : null}

                    <CheckoutPayment
                      storeDetails={storeDetails}
                      cartDetails={cartDetails}
                      handleFinishOrder={handleFinishOrder}
                      setIsLoadingTopBar={setIsLoadingTopBar}
                      checkoutStepper={checkoutStepper}
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <div className="hidden mdl:block mdl:col-span-4">
            {isLoading ? (
              <CartLoader className={` relative mb-8`} />
            ) : (
              <div className={` relative mb-8 hidden mdl:block w-full`}>
                <Cart
                  isCheckout
                  instructions={cartDetails?.pageContent?.instructionAddon}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Checkout;
