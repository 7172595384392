import { useEffect, useState } from "react";
import { getSearchFilter } from "../../services";
import { useSelector, useDispatch } from "react-redux";
import blue_placeholder_circle from "../../assets/images/default_images/blue_placeholder_circle.png";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import {
  setStoreData,
  setFilterItems,
  setSearchItems,
  setHomeLoader,
} from "../../store/basicData/basicDataSlice";
import { copyObject } from "../../utils/helper";

const CuisineCard = ({
  image,
  title,
  itemList,
  id,
  postcode,
  className,
  subclassName,
  // setIsLoading,
}) => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state?.basicdata?.filter);
  const [filterList, setFilterList] = useState({});

  useEffect(() => {
    if (filter.length) {
      let res = {};
      filter.forEach((val) => {
        res[val.key] = [];
        val.data.forEach((v) => {
          res[val.key].push({ id: v.Id, checked: false, name: v.Name });
        });
      });
      setFilterList(res);
    }
  }, [filter]);

  const handleOnFilterChange = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(setHomeLoader(true));
    let res = copyObject(filterList);
    const item = res?.cuisines.findIndex((value) => value.id === id);
    if (item >= 0) {
      res.cuisines[item].checked = true;
    }
    dispatch(setFilterItems(res));

    const apiPayload = {};
    apiPayload.postcode = postcode;
    apiPayload.searchLat = "";
    apiPayload.searchLon = "";
    apiPayload.tags = [];
    apiPayload.offers = [];

    itemList.map((val) => {
      if (title == val?.common_cuisine_Name) {
        apiPayload.cuisines = [val.common_cuisine_Id];
      }
    });
    const { status, data } = await getSearchFilter(apiPayload);
    if (status == 200) {
      data.storeList.map((val, ind) => {
        if (val.heading == "Nearest") {
          dispatch(setStoreData(data?.storeList));
          dispatch(setSearchItems(""));
          dispatch(setHomeLoader(false));
        }
      });
    }
  };

  return (
    <div
      className={`cursor-pointer h-[150px] sm:h-60 2xl:h-72 w-36 sm:w-60 2xl:w-[267px] bg-white-shade-1 rounded-lg flex justify-center items-center ${className}`}
      onClick={() => handleOnFilterChange()}
    >
      <div className={`${subclassName}`}>
        <LazyLoadImageComponent
          src={image}
          alt=""
          className="h-24 sm:h-40 w-24 sm:w-40 2xl:h-44 2xl:w-44 mx-auto "
          onErrorSrc={blue_placeholder_circle}
          loading="lazy"
          placeholderSrc={blue_placeholder_circle}
        />
        <div className="text-[14px] font-semibold text-center sm:text-base 2xl:text-[26px] pt-[6px] 2xl:pt-[26px] text-dark-shade-1">
          {title}
        </div>
      </div>
    </div>
  );
};

export default CuisineCard;
