import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/Navigation/BreadCrumbs";
import React, { useRef } from "react";
import Layout from "../../layout/Layout";
import FaqDesktop from "./FaqDesktop";
import FaqTab from "./FaqTab";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";

const Faq = () => {
  let { faqId } = useParams();
  const navigate = useNavigate();

  const { progressFunction } = useProgress();
  const dispatch = useDispatch();
  const [intervalData, setintervalData] = useState(0);
  const [loader, setLoader] = useState(true);

  const termsRef = useRef(null);
  const policyRef = useRef(null);
  const privacyRef = useRef(null);
  const paymentsAndRefundsRef = useRef(null);
  const orderCancellationRef = useRef(null);
  const howItWorksRef = useRef(null);
  const deliveryCollectionAndPreOrderRef = useRef(null);
  const registrationAndLoginRef = useRef(null);

  const [isFixed, setIsFixed] = useState(false);

  const [item, setitem] = useState();

  const [currentArray, setcurrentArray] = useState([]);

  const faqQuestionAnswer = [
    {
      title: "Terms & Conditions",
      urlPath: "terms-conditions",
      ref: termsRef,
      show: true,
      description: ` <div class="relative bg-white scroll-smooth rounded-tr-md	rounded-tl-md rounded-br-md rounded-bl-md 	" >
                <div class=" bg-white scroll-smooth	rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                  <div class="px-3 py-3">
                  <div class="hidden lg:block text-base	md:text-3xl	 font-semibold pb-2">
                    Terms & Conditions
                  </div>
                  <div>
                    <p class="text-[11px]	">
                      This website (the "Site") is owned and operated by RedoQ
                      Software Services Private Limited Ltd. Your access to, use
                      of, placing of an order, linking to the Site, use of
                      software or downloading software on the Site
                      (collectively, your "Access") is conditional upon your
                      acceptance and compliance with the terms, conditions,
                      notices and disclaimers contained on this page and
                      elsewhere on the Site ("Terms of Use"). If you do not
                      agree with the Terms of Use, exit this Site. Your Access
                      to the Site constitutes your agreement to be bound by the
                      Terms of Use. We reserve the right to amend the Terms of
                      Use at any time and you should periodically refer to them
                      on this page and elsewhere on this site. These Terms of
                      Use apply to the use of this website ("Site"), and by
                      accessing this Site and/or placing an order you agree to
                      be bound by the terms and conditions set out below. The
                      Terms of Use of this Site do not affect your statutory
                      rights as a consumer. "You" and "Your" means the person
                      using this Site to order goods and subject to these Terms
                      of Use. "RedoQ Software Services Private Limited Ltd.",
                      "We", "Us", or "Our" means: RedoQ Software Services
                      Private Limited Ltd. limited Registered in India at B178,
                      Survey Park, Santoshpur, Kolkata, India, 700 075. The
                      Corporate Identity Number of the company is
                      U72900WB2017PTC220615.
                    </p>
                  </div>
                  <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                    PURPOSE
                  </div>
                  <div class="text-[11px]	">
                    The purpose of this Site is to provide a simple, convenient
                    and cost-effective service to fastfood consumers, linking
                    them to restaurants in their area that offer takeaway food.
                    Interactive menus allow customers to build and submit orders
                    at the click of a button.
                    <div class="text-[11px] py-2	">
                      By joining, members can view their order history, rate
                      past orders, introduce friends and earn discounts.
                    </div>
                  </div>

                  <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                    DISCLAIMERS AND LIMITATION OF LIABILITY
                  </div>

                  <div class="text-[11px]	">
                    To the extent permitted by law, RedoQ Software Services
                    Private Limited Ltd. provides this Site and content on an
                    "as-is" and "as available" basis and we make no
                    representation or warranty of any kind, express or implied,
                    regarding the content or availability of this Site, or that
                    it will be timely or error-free, that defects will be
                    corrected, or that the Site or server that makes it
                    available are free of viruses or other harmful components.
                    <div class="text-[11px] py-2	">
                      Subject to the previous paragraph, neither RedoQ Software
                      Services Private Limited Ltd. nor RedoQ Software Services
                      Private Limited Ltd. directors, officers, agents,
                      employees or contractors shall have any liability for any
                      direct, indirect, special or consequential losses or
                      damages (including without limitation, damages for loss of
                      business or loss of profits), arising in contract, tort or
                      otherwise from the use or inability to use this Site.
                    </div>
                    <div class="text-[11px] py-2	">
                      Nothing in these terms shall exclude or limits liability
                      for death or personal injury resulting from our negligence
                      or that of our agents or employees.
                    </div>
                  </div>

                  <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                    INTELLECTUAL PROPERTY
                  </div>

                  <div class="text-[11px]	">
                    You may not print, copy, reproduce, download, republish,
                    broadcast, transmit, display, modify or re-use the materials
                    from this Site for anything other than personal,
                    non-commercial use, including in particular any purpose
                    which publicly re-sells or re-uses the materials, without
                    the prior written consent of the copyright holder.
                    <div class="text-[11px] py-2	">
                      All copyright, trade marks and other intellectual property
                      rights in this Site and its content (including without
                      limitation the Site design, text, graphics, logos, icons,
                      images and all software, databases and source code
                      connected with the Site) are owned by or licensed to RedoQ
                      Software Services Private Limited Ltd. or otherwise used
                      by RedoQ Software Services Private Limited Ltd. as
                      permitted by law.
                    </div>
                    <div class="text-[11px] py-2	">
                      RedoQ Software Services Private Limited and the Kuick logo
                      are trade marks of RedoQ Software Services Private Limited
                      Ltd.
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      INDEMNITY
                    </div>
                    <div class="text-[11px]	">
                      You must indemnify and hold harmless RedoQ Software
                      Services Private Limited Ltd., its directors, officers,
                      employees, agents, vendors, advertisers, partners and
                      suppliers and keep them indemnified against all loss,
                      actions, proceedings, costs, expenses (including legal
                      fees), claims and damages arising from your Access to and
                      use of this Site, any breach or violation of the Terms of
                      Use of this Site or your violation of any law or your
                      violation of the rights of any third party.
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      AVAILABILITY
                    </div>
                    <div class="text-[11px]	">
                      This Site may be unavailable at certain times to allow for
                      maintenance and upgrades. Although we will endeavour to
                      notify customers in advance of any service unavailability,
                      this cannot be guaranteed and we reserve the right to
                      alter or withdraw the service at any time.
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      THIRD PARTY SITES
                    </div>
                    <div class="text-[11px]	">
                      This Site contains links to other websites. Those websites
                      are not under the control of RedoQ Software Services
                      Private Limited Ltd. and RedoQ Software Services Private
                      Limited Ltd. is not responsible for the practices, content
                      or availability of such websites. A link does not imply
                      endorsement of, sponsorship of, or affiliation with the
                      linked site. We provide the links as a convenience only.
                      If you decide to access linked third party websites, you
                      do so at your own risk, and we will not be held
                      responsible or liable for any loss or damages caused by
                      use of or reliance on any contents, goods or services
                      available on such websites.
                      <div class="text-[11px] py-2	">
                        You may provide a non-framed link to this Site from any
                        other business or service website but not in any way
                        which gives the impression that we are associated with
                        or have approved such other site, unless expressly
                        agreed to by ourselves in writing.
                      </div>
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      LIMITATIONS OF USE
                    </div>
                    <div class="text-[11px]	">
                      This Site is intended to be accessed by persons located in
                      the UK and we make no representation that any information,
                      materials or functions included in this Site are
                      appropriate for use in any other jurisdiction.
                      <div class="text-[11px] py-2	">
                        By using this Site, you warrant that any personal
                        information provided by you is true, accurate, current
                        and complete in all respects and that you will notify us
                        immediately of any changes to your personal information
                        by updating your member profile on the Site or
                        contacting us.
                      </div>
                      <div class="text-[11px] py-2	">
                        You agree not to impersonate any other person or entity
                        or to use a false name or a name that you are not
                        authorised to use.
                      </div>
                      <div class="text-[11px] py-2	">
                        To use our service you must be over 18 years of age.
                      </div>
                      <div class="text-[11px] py-2	">
                        You are not allowed to register multiple memberships for
                        use by the same person. Violation of this clause may
                        result in termination of your membership.
                      </div>
                      <div class="text-[11px] py-2	">
                        Members' passwords should be kept confidential at all
                        times, and must not be shared or disclosed to anyone.
                        You are responsible for all activities and orders that
                        occur or are submitted through your membership. If you
                        know or suspect that someone has knowledge of your
                        password then you should contact us immediately.
                      </div>
                      <div class="text-[11px] py-2	">
                        You may not use the Site for any unlawful or
                        unauthorised purpose, including without limitation:
                      </div>
                      <div class="text-[11px] py-2	">
                        1. Distributing any unlawful, libellous, abusive,
                        threatening, harmful, obscene or otherwise objectionable
                        material
                        <br></br> 2. Transmitting material that constitutes a
                        criminal offence, results in civil liability or
                        otherwise breaches any laws, regulations or codes of
                        practice.
                        <br></br> 3. Interfering with any other persons use or
                        enjoyment of the Site.
                        <br></br> 4. Damaging, disabling or impairing the
                        operation of this Site or attempting to gain
                        unauthorised access to this Site or to networks
                        connected to it, through hacking, spoofing or other such
                        similar means.
                      </div>
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      FEEDBACK AND USER SUBMISSIONS
                    </div>
                    <div class="text-[11px]	">
                      We do not solicit nor wish to receive any confidential or
                      proprietary information from you. Nor do we wish to
                      receive any creative materials, ideas or suggestions other
                      than those we specifically request.
                      <div class="text-[11px] py-2	">
                        If you do send to us by email or otherwise, any
                        communication or material, you represent and warrant
                        that the information and material is original to you,
                        and you will be deemed to have granted us a perpetual,
                        world-wide, royalty-free license to use such
                        communications or material in any way we see fit and we
                        will be free to use any such material or information
                        contained in communications you send for any purpose
                        whatsoever.
                      </div>
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      PLACING AN ORDER
                    </div>
                    <div class="text-[11px]	">
                      RedoQ Software Services Private Limited Ltd. endeavours to
                      deal with reputable third parties who provide a quality
                      service, however RedoQ Software Services Private Limited
                      Ltd. accepts no responsibility or liability for the
                      quality or quantity of any goods delivered by or collected
                      from said third parties. It is the responsibility of the
                      customer to thoroughly check the supplied goods before
                      agreeing to pay for an order.
                      <div class="text-[11px] py-2	">
                        All questions regarding goods shown on this Site should
                        be directed to the partner restaurant.
                      </div>
                      <div class="text-[11px] py-2	">
                        After you submit an order to us, we will give you an
                        Order Number and send the details of this order in an
                        email. Please note that this is acknowledgement of your
                        order, not acceptance.
                      </div>
                      <div class="text-[11px] py-2	">
                        Acceptance of your order will only occur when we send
                        you a further email confirming that the restaurant has
                        received and accepted your order. If the restaurant is
                        unable to fulfil your order you will receive an email
                        stating that your order has been declined.
                      </div>
                      <div class="text-[11px] py-2	">
                        Please make sure the email address you provide is
                        correct and your mailbox is in proper working order, as
                        all correspondence regarding your order is sent to this
                        address.
                      </div>
                      <div class="text-[11px] py-2	">
                        All food preparation and deliveries are the sole
                        responsibility of the restaurant accepting the order.
                        Please be aware that the restaurant will do everything
                        they can to meet the delivery time you specified,
                        however delivery times may be affected by factors beyond
                        their control and therefore cannot be guaranteed. We
                        will inform you if we become aware of an unexpected
                        delay.
                      </div>
                      <div class="text-[11px] py-2	">
                        Incorrect personal details may lead to problems or
                        delays in delivery, so please ensure that you have
                        included your correct address (including postcode),
                        email address and contact telephone number when
                        ordering.
                      </div>
                      <div class="text-[11px] py-2	">
                        Members can track the progress of their orders by
                        viewing their Order History.
                        <br></br> 1. Alcohol and Cigarettes - Pursuant to the
                        Licensing Act 1964, it is an offence for any person
                        under the age of 18 to buy, or attempt to buy,
                        intoxicating liquor, or for any person over the age of
                        18 to buy intoxicating liquor on behalf of any person
                        under the age of 18. Cigarettes are not for sale to
                        minors under the age of 16. Orders containing either
                        alcohol or cigarettes can therefore not be accepted from
                        minors.
                        <br></br> 2. Payment - Once your order has been
                        accepted, this represents an agreement between you the
                        customer and the restaurant. The restaurant has sole
                        responsibility for this order. Cash payments are made
                        directly to the restaurant fulfilling the order. If you
                        have chosen to pay with credit or debit card on the Site
                        then the transaction is processed by RedoQ Software
                        Services Private Limited Ltd. as an authorised agent of
                        the restaurant. All prices are inclusive of VAT and
                        quoted in British (Sterling) Pounds. Delivery and/or
                        card charges are calculated per order and based on the
                        policy of the individual restaurant. Any delivery
                        charges or card fees will be displayed clearly in your
                        Order Summary. Prices are subject to change and all
                        goods are subject to availability.
                        <br></br> 3. Cancellation - You have the right to cancel
                        an order up to the point the order is sent to the
                        restaurant. Normally, this is approximately 45 minutes
                        before the time you requested delivery or collection of
                        the order. Members can cancel an order via their Order
                        History, while guests can use the "Cancel order" link in
                        the confirmation email they received. While every effort
                        is made to ensure that accurate pricing and descriptions
                        are maintained, we reserve the right to cancel any order
                        that is based on inaccurate information. An order may be
                        subsequently cancelled by a restaurant after you have
                        received an email stating the order has been accepted.
                        RedoQ Software Services Private Limited Ltd. and our
                        partner restaurants reserves the right to cancel any
                        order, before or after acceptance, and will notify you
                        immediately of any such cancellation.
                      </div>
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      CUSTOMER RATINGS
                    </div>
                    <div class="text-[11px]	">
                      The ratings contained on this Site are for informational
                      purposes only and do not constitute advice. Nor should you
                      rely on ratings to make (or refrain from making) or take
                      (or refrain from taking) any action. Ratings are the
                      opinions of customers who have ordered through the Site,
                      and any statements, advice or opinions made by such
                      customers are those of such customers only.
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      TASTE TESTS
                    </div>
                    <div class="text-[11px]	">
                      Members of the Site will be awarded 3 Taste Test special
                      offers, valid for 3 months from their date of joining.
                      <div class="text-[11px] py-2	">
                        Each Taste Test offer provides a member with a discount
                        on their customer's first purchase only from a
                        participating restaurant. The rate of discount offered
                        by participating restaurants may differ on a restaurant
                        by restaurant basis (20% is an indicative figure). The
                        actual discount on offer will be clearly displayed
                        alongside the search results of participating
                        restaurants.
                      </div>
                      <div class="text-[11px] py-2	">
                        Rates of discount may change without notice, and the
                        offer may be withdrawn either from individual
                        restaurants or from the whole of RedoQ Software Services
                        Private Limited Ltd. without prior notice and with
                        immediate effect. In such circumstances, all remaining
                        discounts will be void and no compensation will be due.
                      </div>
                      <div class="text-[11px] py-2	">
                        The offer is available to RedoQ Software Services
                        Private Limited Ltd. members only, and can only be
                        claimed by ordering through RedoQ Software Services
                        Private Limited Ltd.. Only 1 taste test may be used per
                        order, and the offer is non-exchangeable. The offer may
                        not be combined with any other promotional rate offer.
                        Additionally, the offer may not be available with
                        certain items on a restaurant's menu - typically, but
                        not limited to the normal "specials offers" (also
                        sometimes referred to as "meal deals") which can be
                        ordered from some participating restaurants.
                      </div>
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      LOYALTY STAMPS
                    </div>
                    <div class="text-[11px]	">
                      Members of the Site have the opportunity to collect
                      loyalty stamps when ordering from participating
                      restaurants (indicated by the presence of a special icon).
                      <div class="text-[11px] py-2	">
                        A loyalty stamp is earned when an order is placed with a
                        participating restaurant on the Site and the order is
                        Accepted and filled by the given restaurant. Stamps are
                        collected against individual restaurants, so a stamp
                        from restaurant A applies only to restaurant A, and may
                        not be redeemed at restaurant B, or any restaurant other
                        than restaurant A.
                      </div>
                      <div class="text-[11px] py-2	">
                        You may earn a maximum of 1 stamp per restaurant per
                        day. Once you have collected a total of 5 or more stamps
                        against a single restaurant, you are able to exchange
                        them for a 50% discount on your next order from that
                        restaurant. Please note that whilst you can earn stamps
                        on any day of the week that you order, they can only be
                        redeemed Monday - Thursday.
                      </div>
                      <div class="text-[11px] py-2	">
                        Rates of discount may change without notice, and the
                        offer may be withdrawn either from individual
                        restaurants or from the whole of the Site without prior
                        notice and with immediate effect. In such circumstances,
                        any discounts due and stamps collected will be void, and
                        neither the restaurants nor Kuick.co may be held liable
                        for any losses incurred as a result.
                      </div>
                      <div class="text-[11px] py-2	">
                        Stamps have no cash value. The offer is
                        non-exchangeable, and may only be used as described in
                        these Terms of Use.
                      </div>
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      TERMS AND CONDITIONS FOR REDOQ SOFTWARE SERVICES PRIVATE
                      LIMITED LTD. PROMOTIONS
                    </div>
                    <div class="text-[11px]	">
                      1. Winners will be responsible for ensuring they are able
                      to accept the prize, as set out and in accordance with
                      these terms and conditions.
                      <br></br> 2. Failure to claim a gift, prize or other
                      promotional item by the time or in the manner specified
                      for the particular promotion (or if no time is specified,
                      within 28 days from the promotion closing date) will make
                      any claim invalid and RedoQ Software Services Private
                      Limited Ltd. may re-allocate the prize. No responsibility
                      is accepted for entries damaged, lost or mislaid and proof
                      of posting will not be accepted as proof of delivery.
                      <br></br> 3. Competitions and promotions are open to
                      individual residents of the UK aged 18 or over, except
                      employees of RedoQ Software Services Private Limited Ltd,
                      their associated, affiliated or subsidiary companies, and
                      their families, agents, or anyone connected with this
                      competition, including the third-party promotional
                      partners.
                      <br></br> 4. Winners are selected at random, unless stated
                      other wise.
                      <br></br> 5. Completion and submission of an online
                      competition entry form will be deemed acceptance of these
                      terms and conditions.
                      <br></br> 6. The promoter reserves the right at any time
                      to cancel, modify or supersede the competition (including
                      altering prizes) if, at its sole discretion, a competition
                      cannot be conducted as specified. The promoter reserves
                      the right to substitute a prize of equal value in the
                      event that circumstances beyond its control make this
                      unavoidable.
                      <br></br> 7. These terms and conditions are governed by
                      the laws of India and are subject to the exclusive
                      jurisdiction of the West Bengal courts.
                      <br></br> 8. The Promoter of the competition is RedoQ
                      Software Services Private Limited Ltd. B178, Survey Park,
                      Santoshpur, Kolkata, India, 700 075. The prize fulfilment
                      is managed by RedoQ Software Services Private Limited Ltd.
                      All standard terms & conditions apply
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      TERMINATION
                    </div>
                    <div class="text-[11px]	">
                      We reserve the right to decline a new registration,
                      terminate your right to link to the Site, remove you as a
                      user of this Site, and/or prevent any further use if you
                      violate any of the Terms of Use. If we consider there to
                      be a risk of liability or loss to us or our partner
                      restaurants we may take any action deemed necessary to
                      prevent such a liability or loss from occurring.
                      <div class="text-[11px] py-2	">
                        If we terminate your right to link to the Site you must
                        cease linking to the Site immediately. Any person who
                        holds a suspended or terminated membership must not
                        order from or re-join this Site without our prior
                        written consent. We reserve the right to assign or
                        sub-contract any or all of our rights and obligations
                        under these terms.
                      </div>
                      <div class="text-[11px] py-2	">
                        Please note that we have the ability to trace your IP
                        address, and if necessary, contact your ISP in the event
                        of a suspected breach of these Terms of Use.
                      </div>
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      ENFORCEABILITY
                    </div>
                    <div class="text-[11px]	">
                      If any parts of these Terms of Use are deemed unlawful,
                      void or for any reason unenforceable, then that provision
                      shall be deemed severable from these Terms of Use and
                      shall not affect the validity and enforceability of any of
                      the remaining provisions of these Terms of Use.
                      <div class="text-[11px] py-2	">
                        No waiver by us shall be construed as a waiver of any
                        preceding or succeeding breach of any provision.
                      </div>
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      GOVERNING LAW
                    </div>
                    <div class="text-[11px]	">
                      The Terms of Use, Privacy Policy and any matter relating
                      to this Site shall be governed by West Bengal law and any
                      dispute shall be resolved exclusively in the courts of
                      India.
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      GIFT VOUCHERS
                    </div>
                    <div class="text-[11px]	">
                      1. Kuick.com gift vouchers must be redeemed on the
                      Kuick.com website or Kuick iPhone app.
                      <br></br> 2. Gift vouchers have no cash redemption value
                      and are not transferable or assignable. Kuick.com may
                      provide gift voucher purchasers with information about the
                      redemption status of gift vouchers that they purchase.
                      <br></br> 3. Any unused balance will be available on 'My
                      Account' on Kuick.com.
                      <br></br> 4. If the order exceeds the amount of the gift
                      voucher, the balance must be paid by credit or debit card.
                      <br></br> 5. Once redeemed, you must select Account
                      Balance during checkout to use the gift voucher for a
                      purchase.
                      <br></br> 6. Gift vouchers and unused portions of gift
                      vouchers expire one year from the date of issue, where
                      permissible under applicable law.
                      <br></br> 7. Normal RedoQ Software Services Private
                      Limited Ltd. terms and conditions of purchase and for
                      using the Kuick.com website apply.
                      <br></br> 8. Please note that some products available for
                      sale on the Kuick.com website may not be purchased by
                      persons under a specified age. If you are planning to give
                      a gift voucher to someone under 18 years of age, please
                      ensure that they are made aware that some products are
                      unavailable to them (e.g. alcohol).
                      <br></br> 9. Gift vouchers cannot be used to pay for gift
                      vouchers.
                      <br></br> 10. One or more gift vouchers can be redeemed to
                      an account. Also gift vouchers can be combined with other
                      Kuick.com offers and promotions.
                      <br></br> 11. Kuick.com is not responsible if a gift
                      voucher is lost, stolen, destroyed or used without
                      permission.
                    </div>
                    <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                      WE RECOMMEND YOU PRINT A COPY OF THESE TERMS OF USE FOR
                      YOUR FUTURE REFERENCE.<br></br>
                      IF YOU HAVE ANY QUESTIONS REGARDING THESE TERMS OF USE OR
                      ANY ASPECT OF OUR SERVICE PLEASE CONTACT US.
                    </div>
                  </div>
                  </div>
                </div>
                </div>`,
    },

    {
      title: "Cookies Policy",
      ref: policyRef,
      urlPath: "cookies-policy",
      show: false,
      description: ` <div ref={Policy} class=" bg-white scroll-smooth rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md ">
              <div class=" w-full mt-0 flex gap-2 md:gap-7">
                
                <div class=" bg-white scroll-smooth	rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                  <div class="px-3 py-3">
                    <div class="hidden lg:block  text-base	md:text-3xl font-semibold pb-2">
                      Cookies Policy
                    </div>
                    <div>
                      <div class="text-[11px]	">
                        <p class="text-[11px] py-2">
                          When you visit our site, our server sends your computer
                          a "cookie." This cookie is a small packet of information
                          that identifies you as a user of our system. Cookies
                          save you time by retaining your contact information.
                          Cookies do not deliver viruses, and can only be read by
                          the server that sent you the cookie.
                        </p>
                        <p class="text-[11px] py-2">
                          You have the ability to accept or decline cookies by
                          modifying the settings in your browser. Please bear in
                          mind however, that you may not be able to use all the
                          features of our site if cookies are disabled.
                        </p>
                        <p class="text-[11px] py-2">
                          We may use third-party cookies as part of remarketing
                          activities we conduct with Google and/or other
                          third-party vendors, to help provide you with relevant
                          promotional offers as you use the Internet. As part of
                          these remarketing activities, these third-party cookies
                          may record your visits to this Web site and Web sites
                          visited prior to using this Web site.
                        </p>
                        <p class="text-[11px] py-2">
                          Such cookies can then be used to serve advertisements to
                          you on other Web sites. You may choose to be exempt from
                          the use of Google remarketing cookies at the Google
                          advertising privacy page. Alternatively, you may opt-out
                          of remarketing on the Google network and similar
                          networks at the Network Advertising Initiative opt out
                          page.
                        </p>
                        <p class="text-[11px] py-2">
                          Opting out will not prevent advertisements from being
                          served to you on the Internet; it will only affect
                          advertisements that utilize remarketing cookies to serve
                          advertisements on the specified advertising networks. We
                          are not responsible for the activities of other parties
                          that may not comply with your opt-out requests.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                </div>`,
    },

    {
      title: "Privacy Policy",
      ref: privacyRef,
      urlPath: "privacy-policy",
      show: false,
      description: `<div ref={PrivacyPolicy} class=" bg-white scroll-smooth rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md ">
                    <div class=" w-full mt-0 flex gap-2 md:gap-7">
                      
                      <div class=" bg-white scroll-smooth	rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                        <div class="px-3 py-3">
                          <div class="hidden lg:block  text-base	md:text-3xl font-semibold pb-2">
                            Privacy
                          </div>
                          <div class="text-[11px]	">
                            <p class="text-[11px] py-2">
                              We are dedicated to protecting the privacy of people using
                              this website. We take the confidentiality of personal data
                              seriously. By accessing and using this website, you agree
                              to the terms of this Privacy Policy and consent to the
                              processing of your data as described in this Privacy
                              Policy.
                            </p>
                            <p class="text-[11px] py-2">
                              This Privacy Policy only relates to personal data
                              collected by RedoQ Software Services Private Limited Ltd.
                              through this website. It does not cover personal data
                              provided to RedoQ Software Services Private Limited Ltd.
                              by any other means or via any other website.
                            </p>
                            <p class="text-[11px] py-2">
                              RedoQ Software Services Private Limited Ltd. complies with
                              the principles of the 'Data Protection Act, 1998' and is
                              registered with the Information Commissioner's Office who
                              oversee this act.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              
                              Personal Information
                            </div>
                            <p class="text-[11px] py-2">
                              We only collect personal information from our visitors on
                              a voluntary basis. Personal information provided will only
                              be used for the purposes outlined at the time of
                              collection.
                            </p>
                            <p class="text-[11px] py-2">
                              You can access the Welcome page and browse our site
                              without disclosing your personal information.
                            </p>
                            <p class="text-[11px] py-2">Orders</p>
                            <p class="text-[11px] py-2">
                              Personal information required during the order process
                              includes name, address, phone number, email address and
                              other similar information used to identify you and
                              complete an order.
                            </p>
                            <p class="text-[11px] py-2">
                              Personal information collected at our site will be shared
                              with a restaurant when processing your order. We have an
                              agreement with all member restaurants, restricting
                              disclosure or further processing of personal information
                              provided to them by us.
                            </p>
                            <p class="text-[11px] py-2">
                              Your personal information will be used to notify you of
                              your order status.
                            </p>
                            <p class="text-[11px] py-2">
                              Personal information will not be shared with any of our
                              business partners or affiliates without your permission.
                              It is treated as confidential, and will not be disclosed
                              to outside parties, unless compelled by applicable
                              legislation.
                            </p>
                            <p class="text-[11px] py-2">
                              We only send marketing emails and/or SMS to those people
                              who have specifically requested to receive this
                              information.
                            </p>
                            <p class="text-[11px] py-2">Introductions</p>
                            <p class="text-[11px] py-2">
                              When a member introduces a friend, we store the friend's
                              email address so the member can track the status of their
                              introductions. This also allows reminders to be sent if
                              necessary. The person receiving the introduction can
                              remove their email address from our system at any time via
                              a link in the introduction email, or by sending an email
                              directly to us at help@RedoQ Software Services Private
                              Limited.com.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Verifying, updating and amending personal information
                            </div>
                            <p class="text-[11px] ">Members</p>
                            <p class="text-[11px] py-2">
                              Should your personal information change, you can correct
                              or update this information instantly by editing your
                              member profile at any time.
                            </p>
                            <p class="text-[11px] py-2">
                              When editing your member profile or submitting an order,
                              we only ever reveal the last 5 digits of any saved credit
                              card numbers.
                            </p>
                            <p class="text-[11px] py-2">
                              To remove your personal information and cancel your
                              membership, send an email from your registered email
                              address to help@RedoQ Software Services Private
                              Limited.com. Your membership and personal details will be
                              removed within 5 days.
                            </p>
                            <p class="text-[11px] py-2">Guests</p>
                            <p class="text-[11px] py-2">
                              Users who order as guests cannot view or change their
                              details on the website once an order has been submitted.
                              Any changes, which affect the fulfilment of an order,
                              should be communicated directly to the applicable
                              restaurant.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Cookies
                            </div>
                            <p class="text-[11px] py-2">
                              When you visit our site, our server sends your computer a
                              "cookie." This cookie is a small packet of information
                              that identifies you as a user of our system. Cookies save
                              you time by retaining your contact information. Cookies do
                              not deliver viruses, and can only be read by the server
                              that sent you the cookie.
                            </p>
                            <p class="text-[11px] py-2">
                              You have the ability to accept or decline cookies by
                              modifying the settings in your browser. Please bear in
                              mind however, that you may not be able to use all the
                              features of our site if cookies are disabled.
                            </p>
                            <p class="text-[11px] py-2">
                              We may use third-party cookies as part of remarketing
                              activities we conduct with Google and/or other third-party
                              vendors, to help provide you with relevant promotional
                              offers as you use the Internet. As part of these
                              remarketing activities, these third-party cookies may
                              record your visits to this Web site and Web sites visited
                              prior to using this Web site.
                            </p>
                            <p class="text-[11px] py-2">
                              Such cookies can then be used to serve advertisements to
                              you on other Web sites. You may choose to be exempt from
                              the use of Google remarketing cookies at the Google
                              advertising privacy page. Alternatively, you may opt-out
                              of remarketing on the Google network and similar networks
                              at the Network Advertising Initiative opt out page.
                            </p>
                            <p class="text-[11px] py-2">
                              Opting out will not prevent advertisements from being
                              served to you on the Internet; it will only affect
                              advertisements that utilize remarketing cookies to serve
                              advertisements on the specified advertising networks. We
                              are not responsible for the activities of other parties
                              that may not comply with your opt-out requests.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Security
                            </div>
                            <p class="text-[11px] py-2">
                              RedoQ Software Services Private Limited Ltd. holds
                              personal data in accordance with UK data protection
                              legislation.
                            </p>
                            <p class="text-[11px] py-2">
                              The technology that we use and the policies that we have
                              implemented are intended to safeguard your privacy from
                              unauthorised access and improper use.
                            </p>
                            <p class="text-[11px] py-2">
                              Where appropriate, we attempt to provide for the secure
                              transmission of information, such as credit card details,
                              from your computer to our server by utilising SSL (Secure
                              Sockets Layer) encryption software. However, due to the
                              inherent open nature of the Internet, we cannot guarantee
                              that all data transmitted will be secure. Use of this
                              website demonstrates your assumption of this risk.
                            </p>
                            <p class="text-[11px] py-2">
                              If you have any questions about the security of your
                              personal information please contact us.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Links to Third Party Websites
                            </div>
                            <p class="text-[11px] py-2">
                              This Privacy Policy applies solely to the personal data
                              collected on this website and does not apply to third
                              party websites you may access from here. We are not
                              responsible for the privacy policies of third party
                              websites and we advise customers to read the privacy
                              policies of other websites before registering any personal
                              data.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Minors
                            </div>
                            <p class="text-[11px] py-2">
                              No information may be submitted to this website by persons
                              under the age of 18 without the consent of a parent or
                              legal guardian, nor may persons under the age of 18 make
                              purchases or other legal acts on this site without such
                              consent, unless permitted by applicable legislation.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Third Party Advertising
                            </div>
                            <p class="text-[11px] py-2">
                              We may use a third party to serve advertisements on our
                              site. Cookies may be associated with these advertisements
                              to enable the advertiser to track the number of anonymous
                              users responding to the campaign. We do not access or
                              control cookies placed by third parties.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Changes
                            </div>
                            <p class="text-[11px] py-2">
                              This Privacy Policy may change at our discretion at any
                              time, and without notice. It is your responsibility to
                              periodically review this page for updates which shall come
                              into effect once posted. The date at the top of this
                              document indicates when the last change was made.
                            </p>

                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Contact
                            </div>
                            <p class="text-[11px] py-2">
                              For questions or concerns relating to privacy, we can be
                              contacted by email at
                              <a
                                href="mailto:help@RedoQ Software Services Private Limited.com"
                                ripple="ripple"
                              >
                                help@RedoQ Software Services Private Limited.com
                                <div class="ripple--container"></div>
                                <div class="ripple--container"></div>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`,
    },

    {
      title: "Payments and Refunds",
      ref: paymentsAndRefundsRef,
      urlPath: "payments-refunds",
      show: false,
      description: `<div ref={PaymentsAndRefunds} class=" bg-white scroll-smooth rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md ">
                    <div class=" w-full mt-0 flex gap-2 md:gap-7">
                      
                      <div class=" bg-white scroll-smooth	rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                        <div class="px-3 py-3">
                          <div class="hidden lg:block  text-base	md:text-3xl font-semibold pb-2">
                            Payments and Refunds
                          </div>
                          <div class="text-[11px]	">
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              I need a refund, what happens next?
                            </div>
                            <p class="text-[11px] py-2">
                              Sometimes you may require a refund. We are here to help
                              when this kind of stuff happens. If you require a refund
                              on a cash order the best thing to do is contact the
                              restaurant directly. The money was paid to the driver and
                              not to Kuick and we cannot issue a cash refund.
                            </p>
                            <p class="text-[11px] py-2">
                              If you have paid online then you are welcome to call us.
                              If your order is refunded on the same day the order is
                              placed the refunded money will stay on your Kuick account
                              for the remainder of the day. This is in case you would
                              like to place another order on that day. If no order is
                              placed the money will automatically be refunded to your
                              bank account. This may take up to 3 working days to appear
                              in your account.
                            </p>
                            <p>
                              If the refund is made after the day the order was placed
                              we will issue the refund to your bank account. The money
                              may take up to 3 working days to appear in your account.
                            </p>
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Why do I have to pay a card fee?
                            </div>
                            <p>
                              The restaurant is charged this fee by the credit card
                              company. Some restaurants choose to absorb this charge
                              while most choose to pass this on to the customer. The fee
                              is typically 50p - 75p per transaction. If you come across
                              an unusually high card charge then please do inform us
                              about this.
                            </p>
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              How can I pay for my order?
                            </div>
                            <p class="text-[11px] py-2">
                              There are three methods that Kuick accept. You can pay by
                              cash direct to the driver. We also accept credit cards and
                              have just begun accepting paypal.
                            </p>
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              I've paid with paypal and need a refund
                            </div>
                            <p>
                              If you have paid via Paypal with a credit or debit card
                              the refund will be applied to your bank account. This may
                              take up tp 3 working days to appear.
                            </p>
                            <p>
                              If you paid via Paypal with a bank account or Paypal
                              balance the refund goes into your paypal account. This is
                              instant. If you paid with a bank account and want this
                              returned to your bank account you will need to request
                              that paypal transfer this money back to your bank account.
                            </p>
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Why do I need to pay a delivery charge?
                            </div>
                            <p>
                              The delivery fee is decided by the restaurants. Some
                              restaurants offer free delivery. This can be found on the
                              menu listings. You can also search specifically for
                              restaurants that offer free delivery by using the filters
                              on the left hand side of the menu listings.
                            </p>
                            <p class="text-[11px] py-2">
                              If you think you have been overcharged on the delivery
                              then you can fill in the price guarantee claim form. We
                              require that all restaurants offer the same prices on
                              Kuick as they do in store. You may be entitled to a refund
                              of the delivery charge.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`,
    },

    {
      title: "Order Cancellation",
      ref: orderCancellationRef,
      urlPath: "order-cancellation",
      show: false,
      description: ` <div ref={OrderCancellation} class=" bg-white scroll-smooth rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md ">
                    <div class=" w-full mt-0 flex gap-2 md:gap-7">
                      
                      <div class=" bg-white scroll-smooth	rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                        <div class="px-3 py-3">
                          <div class=" hidden lg:block text-base	md:text-3xl font-semibold pb-2">
                            Order Cancellation
                          </div>
                          <div class="text-[11px]	">
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              I need a refund, what happens next?
                            </div>
                            <p class="text-[11px] py-2">
                              You have the right to cancel an order up to the point the
                              order is sent to the restaurant. Normally, this is
                              approximately 45 minutes before the time you requested
                              delivery or collection of the order.
                            </p>
                            <p class="text-[11px] py-2">
                              Members can cancel an order via their Order History, while
                              guests can use the "Cancel order" link in the confirmation
                              email they received.
                            </p>
                            <p class="text-[11px] py-2">
                              While every effort is made to ensure that accurate pricing
                              and descriptions are maintained, we reserve the right to
                              cancel any order that is based on inaccurate information.
                            </p>
                            <p class="text-[11px] py-2">
                              An order may be subsequently cancelled by a restaurant
                              after you have received an email stating the order has
                              been accepted. RedoQ Software Services Private Limited
                              Ltd. and our partner restaurants reserves the right to
                              cancel any order, before or after acceptance, and will
                              notify you immediately of any such cancellation.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`,
    },

    {
      title: "How it works",
      ref: howItWorksRef,
      urlPath: "how-it-works",
      show: false,
      description: `  <div ref={HowItWorks} class=" bg-white scroll-smooth rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md ">
                    <div class=" w-full mt-0 flex gap-2 md:gap-7">
                      
                      <div class=" bg-white scroll-smooth	rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                        <div class="px-3 py-3">
                          <div class=" hidden lg:block text-base	md:text-3xl font-semibold pb-2">
                            How it works
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Search By Address
                            </div>
                            <p class="text-[11px] py-2">
                              Find the diner in your area
                            </p>
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase  pb-[6px]">
                              Takeaway
                            </div>
                            <p class="text-[11px] py-2">
                              Order the food and schedule a pickup of your food at the
                              shop
                            </p>
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase  pb-[6px]">
                              Delivery
                            </div>
                            <p class="text-[11px] py-2">
                              Laze around in the comfort of your home and get your food
                              dropped off at your doorstep
                            </p>
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase   pb-[6px]">
                              Pay By Cash / Card
                            </div>
                            <p class="text-[11px] py-2">
                              Pay Safe and Secure anyway you prefer
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`,
    },

    {
      title: "Delivery, Collection & Pre Order",
      ref: deliveryCollectionAndPreOrderRef,
      show: false,
      urlPath: "delivery-collection-pre-order",
      description: ` <div ref={DeliveryCollectionAndPreOrder} class=" bg-white scroll-smooth rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md " >
                    <div class=" w-full mt-0 flex gap-2 md:gap-7">
                      
                      <div class=" bg-white scroll-smooth	rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                        <div class="px-3 py-3">
                          <div class=" hidden lg:block text-base	md:text-3xl font-semibold pb-2">
                            Delivery, Collection & Pre Order
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Delivery - How much time will the Kuick take to deliver?
                            </div>
                            <p class="text-[11px] py-2">
                              We try our best to give prompt service however the time
                              frame WILL vary depending on the policy of the Kuick. You
                              should see an estimated delivery time in the Eatery
                              listing page. During poor weather or traffic situations it
                              may take longer than usual. BUT WE WILL GET THERE! You can
                              always get in touch with Kuick.com directly to inquire
                              about the status of your order.
                            </p>
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Collection - How soon can I pick up my food?
                            </div>
                            <p class="text-[11px] py-2">
                              Once the order is submitted, Kuick.com starts processing
                              your order immediately. Orders are generally transmitted
                              automatically to the Kuick within seconds of submission.
                              You will receive a confirmation email after your order has
                              been received by the Kuick. After receiving a confirmation
                              email, please allow us 15 to 30 minutes, on average, to
                              prepare your order before picking it up.
                            </p>
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Delivery Charge
                            </div>
                            <p class="text-[11px] py-2">
                              Delivery costs, just like delivery time, are determined by
                              restaurants individually. Usually the ones closest to you
                              will charge a small delivery fee. If a delivery driver has
                              to travel a long way, they may charge a little extra for
                              the service. There are many restaurants offering free
                              delivery as well. You can easily check the delivery cost
                              for each restaurant whilst browsing our website.
                            </p>
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Order with us
                            </div>
                            <p class="text-[11px] py-2">
                              Takeaway Order with us online and pick up at your
                              convenience
                            </p>
                            <p class="text-[11px] py-2">
                              If you want to view all restaurants, just go to Restaurant
                              List. Then enter your location to check which restaurants
                              can deliver to you.
                            </p>
                            <p class="text-[11px] py-2">
                              Is the restaurant on your way to work or perhaps just down
                              the street? Feel like having a walk? You can place an
                              order online and pick it up from your restaurant of
                              choice. We have many restaurants that have Takeaway
                              feature so you can grab your favorite meal at a go.
                            </p>
                          </div>

                          <div class="faq-div">
                            <div class="text-sm	text-red-500 uppercase  pt-[12px] pb-[6px]">
                              Preorder when the shop is closed
                            </div>
                            <p class="text-[11px] py-2">
                              For the preorder if the takeaway is closed then customer
                              can order and select a different date and time and the
                              order will be delivered according to the date.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`,
    },

    {
      title: "Registration & Login",
      ref: registrationAndLoginRef,
      urlPath: "registration-login",
      show: false,
      description: `<div ref={RegistrationAndLogin} class=" bg-white scroll-smooth rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                    <div class=" w-full mt-0 flex gap-2 md:gap-7">
                      
                      <div class=" bg-white scroll-smooth	rounded-tr-md	rounded-tl-md rounded-br-md	rounded-bl-md">
                        <div class="px-3 py-3">
                          <div class=" hidden lg:block text-base	md:text-3xl font-semibold pb-2">
                            Registration & Login
                          </div>

                          <div class="text-[11px] py-2">
                            <p>
                              To Register with us just click on Register Option
                              available on the Top of the Website and fill in your
                              personal details.
                            </p>
                            <p class="text-[11px] py-2">
                              On Successful submission of the form, you will get an
                              Email with an Activation Link. Click on that link and you
                              will be automatically Redirected to Kuick and your Account
                              will be Confirmed.
                            </p>
                            <p class="text-[11px] py-2">
                              Note: Without Activating your account, you will not be
                              able to Log In to Kuick.
                            </p>
                            <p>
                              You can easily Register with your Social Accounts like
                              Facebook, Gmail and Twitter.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`,
    },

    {
      title: ` <div class="">
                              WhatsApp
                              <div class="text-[10px] text-gray-400	">
                                Any issue? Chat with us on WhatsApp
                              </div>
                            </div>`,
      description: "",
      show: false,
    },

    {
      title: ` <div class="w-[90%]">
                            Email Us 
                            <span class="text-[10px] text-gray-300">
                            <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=support@Kuick.com">support@Kuick.com</a>
                             .<br>
                            </span>
                          </div>`,
      description: "",
      show: false,
    },

    {
      title: ` <div class="">
                            Kuick Support
                            <div class="text-[10px] text-gray-400	">
                              <a href="tel:+44 178 281 7644">+44 178 281 7644</a>
                            </div>
                          </div>`,
      description: "",
      show: false,
    },
  ];

  useEffect(() => {
    if (faqId === undefined) {
      let data1 = [];
      faqQuestionAnswer?.forEach((val, index) => {
        data1.push({
          title: val.title,
          ref: val.ref,
          show: val.show,
          urlPath: val.urlPath,
          description: val.description,
        });
      });
      setcurrentArray(data1);
      setitem(-1);
    } else {
      let faqLower = faqId.toLowerCase();
      let data1 = [];
      const item1 = faqQuestionAnswer.findIndex(
        (value) => value.urlPath === faqLower
      );
      setitem(item1);
      if (item1 >= 0) {
        faqQuestionAnswer?.forEach((val, index) => {
          let ShowVar = false;
          if (val.urlPath === faqLower) {
            ShowVar = true;
          }
          data1.push({
            title: val.title,
            ref: val.ref,
            show: ShowVar,
            urlPath: val.urlPath,
            description: val.description,
          });
        });
        setcurrentArray(data1);
      } else {
        navigate("/home/faq");
      }
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 80);
  };

  return (
    <div
      className="lg:overflow-hidden	lg:h-max lg: font-[inter] sm:overflow-hidden
      "
    >
      <div className="hidden lg:block">
        <Breadcrumbs
          className={`${
            isFixed
              ? "fixed animate-fadedown top-[76px] left-0 shadow-xl"
              : "animate-fadeup"
          } bg-white w-full z-50 flex items-center px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] overflow-hidden`}
        />
      </div>

      <Layout className="carousel w-full  bg-white-shade-7  space-x-2 lg:space-x-5  2xl:md:pb-8 xl:pd-8 md:pb-6 pb-7 xl:pl-[45px] pl-[15px] sm:pl-5 md:pl-[25px]">
        <div className="hidden lg:grid w-full overflow-hidden">
          <FaqDesktop data={currentArray} item={item} />
        </div>
        <div className=" lg:hidden w-full">
          <FaqTab data={currentArray} item={item} />
        </div>
      </Layout>
    </div>
  );
};

export default Faq;
