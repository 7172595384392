import React from "react";
import { useEffect, useState } from "react";
import CheckCircle from "../../components/Icon/CheckCircle";
import InputField from "../../components/Elements/InputField";
import RadioButton from "../../components/Elements/RadioButton";
import Mobile from "../../components/Icon/Mobile";
import Modal from "react-modal";
import Mail from "../../components/Icon/Mail";
import Button from "../../components/Elements/Button";
import { Cross } from "../../components/Icon/Cross";
import OtpInput from "../../components/Elements/OtpInput";
import kuickLogo from "../../assets/kuick_logo.svg";
import { addLeadingZero, checkEmpty } from "../../utils/helper";
import {
  submitCheckoutBasketOtp,
  addBasicDetails,
  checkoutSubmitResendOtp,
} from "../../services";
import {
  ProperNameRegEx,
  mobileNumberRegEx,
  emailIdrRegEx,
  getTimeRemaining,
} from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { error, success } from "../../components/notify";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import CheckoutDetaisEdit from "../../components/Icon/CheckoutDetailsEdit";
import UserName from "../../components/Icon/UserName";
import { useRef } from "react";
const CheckoutBasicInfo = ({ checkoutStepper, setIsLoadingTopBar }) => {
  const basket = useSelector((state) => state?.basicdata?.basket);
  const checkoutStep = useSelector((state) => state?.checkoutStep);
  const dispatch = useDispatch();
  const [isNewProfileDetails, setIsNewProfileDetails] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedSMS, setSelectedSMS] = useState(1);
  const [selectedEMAIL, setSelectedEMAIL] = useState(1);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameErrorText, setNameErrorText] = useState("");
  const [mobileErrorText, setMobileErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [otpRequired, setOtpRequired] = useState(false);
  const options = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  const [visible, setVisible] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [otpLen, setOtpLen] = useState(4);
  const [otp, setOtp] = useState("");
  const timerRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [counterCount, setCounterCount] = useState(0);

  const handleRadioChangeSms = (option) => {
    setSelectedSMS(option);
  };

  const handleRadioChangeEmail = (option) => {
    setSelectedEMAIL(option);
  };
  const handleNameChange = (event) => {
    const name = event.target.value;
    setUserName(name);
    if (checkEmpty(name)) {
      setNameError(true);
      setNameErrorText("This field is required");
    } else if (name.length <= 50) {
      setNameError(false);
      setNameErrorText("");
    }
  };

  const handleMobileNoChange = (event) => {
    let thisValue = event.target.value;
    thisValue = thisValue.replace(/\D/g, "");
    if (checkEmpty(thisValue)) {
      setUserMobile(thisValue);
      setMobileError(true);
      setMobileErrorText("This field is required");
    } else if (thisValue.length <= 11) {
      setUserMobile(thisValue);
      setMobileError(false);
      setMobileErrorText("");
    }
  };
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setUserEmail(email);
    setEmailError(false);
    setEmailErrorText("");
  };

  const editViewHide = () => {
    setIsNewProfileDetails(false);
  };
  const clearFormError = () => {
    setNameError(false);
    setNameErrorText("");
    setMobileError(false);
    setMobileErrorText("");
    setEmailError(false);
    setEmailErrorText("");
  };
  const setFormData = () => {
    clearFormError();
    setUserName(basket?.pos_basket_Customer_First_Name);
    setUserMobile(basket?.pos_basket_Customer_Telephone);
    setUserEmail(basket?.pos_basket_Customer_Email);
  };
  const checkout_basicDetails_form = () => {
    let hasError = false;

    if (checkEmpty(userName)) {
      hasError = true;
      setNameError(true);
      setNameErrorText("This field is required");
    } else if (ProperNameRegEx.test(userName) === false) {
      setNameError(true);
      setNameErrorText("Invalid name");
      hasError = true;
    }

    if (checkEmpty(userMobile)) {
      hasError = true;
      setMobileError(true);
      setMobileErrorText("This field is required");
    } else if (mobileNumberRegEx.test(userMobile) === false) {
      setMobileError(true);
      setMobileErrorText("Invalid mobile number");
      hasError = true;
    }

    setEmailError(false);
    setEmailErrorText("");
    if (!checkEmpty(userEmail)) {
      if (emailIdrRegEx.test(userEmail) === false) {
        setEmailError(true);
        setEmailErrorText("Invalid email");
        hasError = true;
      }
    }

    if (!hasError) {
      clearFormError();
      submitBasicDetails();
      setCounterCount(counterCount + 1);
      setShowResend(false);
    }
  };
  const submitBasicDetails = async () => {
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await addBasicDetails(
        userName,
        userMobile,
        userEmail,
        selectedSMS,
        selectedEMAIL
      );
      if (status === 200) {
        if (data.status === "success") {
          dispatch(setBasketData(data?.basket));
          if (!checkEmpty(data.otp_required) && data.otp_required === "Yes") {
            setOtpRequired(true);
            openOTPModal();
          } else {
            editViewHide();
            if (!checkEmpty(data.msg)) {
              success(data.msg);
            }
            sectionStepper("next");
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const verifycheckoutResendOtp = async () => {
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await checkoutSubmitResendOtp(
        userName,
        userMobile,
        userEmail,
        selectedSMS,
        selectedEMAIL
      );
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
        } else {
          if (!checkEmpty(data.msg)) {
            error(data.msg);
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const onOtpSubmit = async () => {
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await submitCheckoutBasketOtp(otp);
      if (status === 200) {
        if (data.status === "success") {
          editViewHide();
          setOtpRequired(false);
          closeOTPModal();
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
        } else {
          if (!checkEmpty(data.msg)) {
            error(data.msg);
          }
        }
      }
    } catch (data) {
      error(data.msg);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const openOTPModal = () => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    document.body.style.setProperty("overflow", "hidden", "important");
    setVisible(true);
    verifycheckoutResendOtp();
  };
  const closeOTPModal = () => {
    document.body.style.overflow = "";
    document.body.style.height = "auto";
    setVisible(false);
    setTimer("00:00");
    setCounterCount(0);
    setShowResend(false);
  };

  const validateStepper = async () => {
    if (otpRequired) {
      openOTPModal();
      return false;
    }

    return true;
  };
  const sectionStepper = async (value) => {
    if (value == "next") {
      let checkValidateStepper = await validateStepper();
      if (checkValidateStepper) {
        basket.pos_basket_Delivery_Type == "Delivery"
          ? checkoutStepper("addressDetails")
          : checkoutStepper("payment");
      }
    } else if (value == "prev") {
      checkoutStepper("dispatch");
    }
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      if (total === 0) {
        setShowResend(true);
        clearInterval(timerRef.current);
      }
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    const { minutes, seconds } = getTimeRemaining(e);
    setTimer(
      (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (timerRef.current) clearInterval(timerRef.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    timerRef.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(
      deadline.getSeconds() +
        (counterCount === 1 ? 40 : (counterCount - 1) * 30 + 40)
    );
    return deadline;
  };

  useEffect(() => {
    setShowResend(false);
    clearTimer(getDeadTime());
  }, [counterCount]);

  useEffect(() => {
    if (!checkEmpty(basket)) {
      setUserName(basket?.pos_basket_Customer_First_Name);
      setUserMobile(basket?.pos_basket_Customer_Telephone);
      setUserEmail(basket?.pos_basket_Customer_Email);
    }
  }, [basket]);
  useEffect(() => {
    console.log(checkoutStep.verifyOtp.disp);
    if (checkoutStep.verifyOtp.disp === true) {
      setTimeout(() => {
        openOTPModal();
      }, 500);
    }
  }, [checkoutStep]);
  return (
    <>
      {checkoutStep?.basicDetails?.summary === true ? (
        <>
          <div
            className={`flex w-full items-start justify-between border-t-2 py-5 ${
              checkoutStep?.basicDetails?.summary === true && "animate-fadedown"
            }`}
          >
            <div className="sm:w-full">
              <div className="stepType flex justify-start">
                <CheckCircle className="fill-green-400 mr-2 mt-[4px]" />

                <div className="sm:w-full sm:flex">
                  <p className="text-black w-[200px] font-medium font-inter text-xl xl:text[22px] 2xl:text-[26px]">
                    Basic Details
                  </p>
                  <div className="flex flex-col justify-center  sm:w-full sm:flex sm:items-center flex-1">
                    <p className="text-gray-500 text-sm font-semibold sm:w-[200px] md:w-[300px] capitalize">
                      {basket?.pos_basket_Customer_First_Name}
                    </p>
                    <p className="text-gray-500 text-sm font-semibold sm:w-[200px] md:w-[300px]">
                      {addLeadingZero(basket?.pos_basket_Customer_Telephone)}
                    </p>
                    {!checkEmpty(basket?.pos_basket_Customer_Email) && (
                      <p className="text-gray-500 text-sm font-semibold sm:w-[200px] md:w-[300px]">
                        {basket?.pos_basket_Customer_Email}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="text-primary underline underline-offset-4 text-sm 2xl:text-lg cursor-pointer"
              onClick={() => {
                checkoutStepper("basicDetails");
              }}
            >
              Change
            </div>
          </div>
        </>
      ) : checkoutStep.basicDetails.disp === true ? (
        <>
          <div
            className={`w-full 2xl:w-full h-auto border-t-2 py-5 sm:pt-7 ${
              checkoutStep.basicDetails.disp === true && "animate-fadedown"
            }`}
          >
            <div className="flex justify-between items-center">
              <div className="text-black font-medium font-inter text-xl xl:text[22px] 2xl:text-[26px]">
                {isNewProfileDetails ? "Change Basic Details" : "Basic Details"}
                {!isNewProfileDetails ? (
                  <div className="flex items-center text-gray-500 text-sm font-normal mt-2">
                    <p>Do we have the right details?</p>
                    {!isNewProfileDetails && (
                      <div
                        className="text-primary underline underline-offset-4 ml-2 2xl:text-lg cursor-pointer"
                        onClick={() => {
                          setFormData();
                          setIsNewProfileDetails(true);
                        }}
                      >
                        <CheckoutDetaisEdit />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>

            {isNewProfileDetails ? (
              <>
                <div className="sm:gap-x-4 sm:gap-y-5 pt-5 space-y-5 sm:grid sm:grid-cols-2 sm:space-y-0">
                  <div className="relative">
                    <InputField
                      title="Name"
                      type="text"
                      placeholder="Full Name"
                      value={userName}
                      onChange={handleNameChange}
                    />
                    {nameError && (
                      <span className="text-primary text-sm absolute top-0 right-0">
                        {nameErrorText}
                      </span>
                    )}
                  </div>

                  <div className="relative">
                    <InputField
                      title="Mobile"
                      placeholder="Enter mobile number"
                      value={userMobile}
                      onChange={handleMobileNoChange}
                    />
                    {mobileError && (
                      <span className="text-primary text-sm absolute top-0 right-0">
                        {mobileErrorText}
                      </span>
                    )}
                  </div>
                  <div className="relative">
                    <InputField
                      title="Email"
                      placeholder="Enter You Email Id"
                      value={userEmail}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <span className="text-primary text-sm absolute top-0 right-0">
                        {emailErrorText}
                      </span>
                    )}
                  </div>
                </div>
                <div className="py-4">
                  <div className="flex items-center">
                    <div className="relative flex flex-row items-center gap-0">
                      {options.map((option, index) => (
                        <label
                          key={index}
                          className="py-3 ms-0 me-4 sm:text-md flex justify-between cursor-pointer"
                        >
                          <RadioButton
                            name="addressType"
                            value={option.value}
                            onChange={() => handleRadioChangeSms(option.value)}
                            checked={selectedSMS === option.value}
                          />
                          <span className="pt-0.5 pl-1.5">{option.label}</span>
                        </label>
                      ))}
                    </div>
                    <p>Receive Promotional SMS</p>
                  </div>
                  <div className="flex items-center">
                    <div className="relative flex flex-row items-center gap-0">
                      {options.map((option, index) => (
                        <label
                          key={index}
                          className="py-3 ms-0 me-4 sm:text-md flex justify-between cursor-pointer"
                        >
                          <RadioButton
                            name="addressType"
                            value={option.value}
                            onChange={() =>
                              handleRadioChangeEmail(option.value)
                            }
                            checked={selectedEMAIL === option.value}
                          />
                          <span className="pt-0.5 pl-1.5">{option.label}</span>
                        </label>
                      ))}
                    </div>
                    <p>Receive Promotional Email</p>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col space-y-4 pt-4">
                <div className="flex items-center space-x-[9px]">
                  <UserName className="w-12 h-12" />
                  <div className="text-gray-500 text-sm sm:text-md font-normal">
                    {basket && basket?.pos_basket_Customer_First_Name}
                  </div>
                </div>
                <div className="flex items-center space-x-[9px]">
                  <Mobile className="w-12 h-12" />
                  <div className="text-gray-500 text-sm sm:text-md font-normal">
                    {addLeadingZero(basket?.pos_basket_Customer_Telephone)}
                  </div>
                </div>
                {!checkEmpty(basket?.pos_basket_Customer_Email) && (
                  <>
                    <div className="flex items-center space-x-[9px]">
                      <Mail className="w-12 h-12" />
                      <div className="text-gray-500 text-sm sm:text-md font-normal">
                        {basket && basket?.pos_basket_Customer_Email}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            {isNewProfileDetails && (
              <div className="flex justify-between sm:mt-5">
                <div
                  className="flex items-center justify-center p-4 border w-[135px] h-[46px] sm:h-max sm:py-4 text-md font-medium rounded-lg  cursor-pointer"
                  onClick={() => {
                    editViewHide();
                  }}
                >
                  <div className="text-gray-500 ">Cancel</div>
                </div>
                <div
                  className="flex items-center justify-center bg-green-shade-4 rounded-lg w-[135px] h-[46px] sm:h-max sm:py-4  cursor-pointer text-white text-md font-medium"
                  onClick={() => {
                    checkout_basicDetails_form();
                  }}
                >
                  Save
                </div>
              </div>
            )}
            {!isNewProfileDetails && (
              <div className="flex justify-between mt-5 sm:mt-5">
                <button
                  onClick={() => sectionStepper("prev")}
                  className="border border-[#28303F] sm:w-[135px] w-[48%] h-[46px] sm:h-max sm:py-4  text-md font-medium text-[#28303F] rounded-lg"
                >
                  Previous
                </button>

                <button
                  onClick={() => sectionStepper("next")}
                  className="bg-primary sm:w-[135px] w-[48%] h-[46px] sm:h-max sm:py-4 text-md font-medium text-white rounded-lg"
                  data-type="collection"
                  data-check={basket?.pos_basket_Delivery_Type}
                >
                  Next
                </button>
              </div>
            )}
          </div>

          <Modal
            isOpen={visible}
            onRequestClose={closeOTPModal}
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50 cursor-pointer"
            className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-none border-none bg-white rounded-2xl`}
          >
            <>
              <Button
                type="button"
                className="xxxcv z-50 absolute top-3 right-3 text-gray-400 bg-transparent rounded-lg text-sm w-6 h-6 sm:w-8 sm:h-8 ms-auto inline-flex justify-center items-center"
                onClick={closeOTPModal}
              >
                <Cross />
                <span className="sr-only">Close modal</span>
              </Button>
              <div className={`relative px-4 sm:px-10 sm:px-12`}>
                <img
                  loading="lazy"
                  src={kuickLogo}
                  className="flex mx-auto w-[104px] md:w-[133px] my-6 md:my-10"
                />
                <div className="text-2xl sm:text-3xl font-bold leading-[38px] font-inter text-center">
                  One Time Password
                </div>
                <div className="text-center">
                  <p>We have sent the OTP verification to </p>
                  <span>{userMobile}</span>
                </div>
                <div className="pt-6 space-x-2">
                  <OtpInput length={otpLen} onOtpSubmit={setOtp} />
                </div>
                {showResend ? (
                  <div className="flex justify-between pt-[23px]">
                    <div
                      className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex"
                      onClick={() => {
                        setCounterCount(counterCount + 1);
                      }}
                    >
                      <div className="text-center text-slate-500 text-base font-semibold font-inter leading-normal">
                        Resend OTP
                      </div>
                    </div>
                    <div
                      className="cursor-pointer w-[131px] sm:w-[156px] h-11 px-4 py-1 bg-pink-50 rounded-lg justify-center items-center gap-1 inline-flex"
                      onClick={() => {
                        setCounterCount(counterCount + 1);
                      }}
                    >
                      <div className="text-center text-slate-500 text-base font-semibold font-inter leading-normal">
                        Voice OTP
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center mt-4 md:mt-6">
                    <span className="text-p6 font-bold">{timer}</span>
                  </div>
                )}
                <div className="m-auto w-[328px] mb-12">
                  <Button
                    onClick={() => onOtpSubmit()}
                    type="primary"
                    className="w-full py-1 mt-4 md:mt-6 h-12 md:h-14 gap-1 inline-flex justify-center items-center cursor-pointer"
                  >
                    <div className="text-center text-white text-base font-semibold font-inter leading-normal">
                      Verify
                    </div>
                  </Button>
                </div>
              </div>
            </>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default CheckoutBasicInfo;
