export const VerifyButton = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon Set 02/Outlined/checkmark-circle-2">
        <path
          id="&#240;&#159;&#142;&#168; Icon &#208;&#161;olor"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.497 10.9741H21.5C22.051 10.9741 22.499 11.4201 22.5 11.9711C22.508 14.6421 21.475 17.1571 19.591 19.0511C17.708 20.9451 15.2 21.9921 12.529 22.0001H12.5C9.83898 22.0001 7.33599 20.9681 5.44899 19.0911C3.55499 17.2081 2.50799 14.7001 2.49999 12.0291C2.49199 9.35712 3.52499 6.84312 5.40899 4.94912C7.29199 3.05512 9.79999 2.00812 12.471 2.00012C13.266 2.01212 14.076 2.09212 14.852 2.27812C15.388 2.40812 15.719 2.94812 15.589 3.48512C15.46 4.02112 14.917 4.35112 14.383 4.22312C13.762 4.07312 13.103 4.01012 12.477 4.00012C10.34 4.00612 8.33298 4.84412 6.82698 6.35912C5.31998 7.87412 4.49398 9.88612 4.49998 12.0231C4.50599 14.1601 5.34399 16.1661 6.85898 17.6731C8.36899 19.1741 10.371 20.0001 12.5 20.0001H12.523C14.66 19.9941 16.667 19.1561 18.173 17.6411C19.68 16.1251 20.506 14.1141 20.5 11.9771C20.499 11.4251 20.945 10.9751 21.497 10.9741ZM8.79298 11.293C9.18398 10.902 9.81598 10.902 10.207 11.293L12.451 13.537L18.748 6.34102C19.112 5.92802 19.743 5.88402 20.159 6.24802C20.574 6.61102 20.616 7.24302 20.252 7.65902L13.252 15.659C13.07 15.867 12.81 15.99 12.533 16H12.5C12.235 16 11.981 15.895 11.793 15.707L8.79298 12.707C8.40198 12.316 8.40198 11.684 8.79298 11.293Z"
          fill="white"
        />
        <mask
          id="mask0_2963_46533"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="21"
          height="21"
        >
          <path
            id="&#240;&#159;&#142;&#168; Icon &#208;&#161;olor_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.497 10.9741H21.5C22.051 10.9741 22.499 11.4201 22.5 11.9711C22.508 14.6421 21.475 17.1571 19.591 19.0511C17.708 20.9451 15.2 21.9921 12.529 22.0001H12.5C9.83898 22.0001 7.33599 20.9681 5.44899 19.0911C3.55499 17.2081 2.50799 14.7001 2.49999 12.0291C2.49199 9.35712 3.52499 6.84312 5.40899 4.94912C7.29199 3.05512 9.79999 2.00812 12.471 2.00012C13.266 2.01212 14.076 2.09212 14.852 2.27812C15.388 2.40812 15.719 2.94812 15.589 3.48512C15.46 4.02112 14.917 4.35112 14.383 4.22312C13.762 4.07312 13.103 4.01012 12.477 4.00012C10.34 4.00612 8.33298 4.84412 6.82698 6.35912C5.31998 7.87412 4.49398 9.88612 4.49998 12.0231C4.50599 14.1601 5.34399 16.1661 6.85898 17.6731C8.36899 19.1741 10.371 20.0001 12.5 20.0001H12.523C14.66 19.9941 16.667 19.1561 18.173 17.6411C19.68 16.1251 20.506 14.1141 20.5 11.9771C20.499 11.4251 20.945 10.9751 21.497 10.9741ZM8.79298 11.293C9.18398 10.902 9.81598 10.902 10.207 11.293L12.451 13.537L18.748 6.34102C19.112 5.92802 19.743 5.88402 20.159 6.24802C20.574 6.61102 20.616 7.24302 20.252 7.65902L13.252 15.659C13.07 15.867 12.81 15.99 12.533 16H12.5C12.235 16 11.981 15.895 11.793 15.707L8.79298 12.707C8.40198 12.316 8.40198 11.684 8.79298 11.293Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2963_46533)"></g>
      </g>
    </svg>
  );
};
