import { useState, useEffect } from "react";
import BookmarkCircle from "../Icon/BookmarkCircle";

import { useSelector, useDispatch } from "react-redux";
import { addBookMark } from "../../services";
import { success, error } from "../notify";
import { setUserData } from "../../store/userData/userDataSlice";
import { setCustomerData } from "../../store/basicData/basicDataSlice";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";

const Bookmark = ({ productId, className, onLoad }) => {
  const dispatch = useDispatch();
  const [bookmarked, setBookmarked] = useState(false);
  const loggedIn = useSelector((state) => state?.userdata?.loggedIn);
  const bookmarks = useSelector(
    (state) => state?.basicdata?.customer?.common_customer_Bookmark
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading]);

  useEffect(() => {
    if (bookmarks?.length && loggedIn) {
      const isExist = bookmarks.filter(
        (val) => String(val) === String(productId)
      );
      setBookmarked(isExist.length ? true : false);
    }
  }, [productId, bookmarks, loggedIn]);

  const onClick = async (event) => {
    event.stopPropagation();
    if (loggedIn) {
      setIsLoading(true);
      const { status, data } = await addBookMark(productId);
      if (status === 200) {
        if (data.status === "success") {
          setIsLoading(false);
          dispatch(setCustomerData(data?.customer));
          success(data?.msg, true);
          onLoad();
        } else {
          setIsLoading(false);
          error("Something went wrong!! Please try again", true);
        }
      }
    } else {
      setIsLoading(false);
      error(<>You are currently not logged in. Please log in</>);
    }
  };

  return (
    <div
      onClick={onClick}
      className="absolute rounded-full flex justify-center right-2.5 top-2.5 items-center shadow-md h-6 w-6 bg-white md:h-8 md:w-8 2xl:xl:lg:h-[32px] 2xl:xl:lg:w-[32px]"
    >
      <BookmarkCircle
        className={`z-20 text-primary-shade-1 justify-center items-center flex ${className}  ${
          bookmarked ? "fill-primary stroke-white" : ""
        }  cursor-pointer  `}
        bookmark={bookmarked}
      />
    </div>
  );
};

export default Bookmark;
