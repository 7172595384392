const MenuCards = () => {
  return (
    <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center">
      <div className="w-[70%] items-baseline pr-4 sm:pr-0">
        <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
        <div className="bg-gray-400 w-20 h-3 rounded-sm mb-2"></div>
        <div className="bg-gray-400 w-48 h-1 rounded-sm mb-2"></div>
        <div className="bg-gray-400 w-48 h-1 rounded-sm mb-2"></div>
        <div className="bg-gray-400 w-48 h-1 rounded-sm mb-2"></div>
        <div className="bg-gray-400 w-48 h-1 rounded-sm"></div>
        <div className="bg-gray-400 w-28 h-1 rounded-sm mt-10"></div>
      </div>
      <div
        className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
      ></div>
    </div>
  );
};

export default MenuCards;
