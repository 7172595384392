import { useState, useEffect } from "react";
import Button from "../Elements/Button";

const Tabs = ({ tabs, onChange, className }) => {
  const [selectedTab, setSelectedTab] = useState();
  useEffect(() => {
    setSelectedTab(tabs[0]?.key);
  }, [tabs]);
  return (
    <div className={`bg-neutral-100 w-full rounded-lg ${className} `}>
      <div className="flex justify-between mx-0.5">
        {tabs?.map((val, ind) => {
          const { heading, key } = val;
          return (
            <Button
              onClick={() => {
                setSelectedTab(key);
                onChange(key);
              }}
              key={"tabs" + ind}
              className={`text-center w-full mx-auto justify-center  p-2.5 m-0.5  ${
                selectedTab === key
                  ? "bg-white text-black"
                  : "bg-transparent text-neutral-400"
              }  rounded-[7px] text-[13px] font-medium font-inter  h-full flex items-center`}
            >
              {heading}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
