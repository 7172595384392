const AboutLandingDesktopLoader = ({ className }) => {
  return (
    <div className={`${className} mt-2`}>
      <div className="w-[48%] pb-4">
        <div className="animate-pulse p-5 rounded bg-gray-300 self-center mb-4 mt-4 w-auto h-[369px]"></div>
        <div className="animate-pulse rounded bg-gray-300 my-4 w-2/4 h-5"></div>
        <div className="animate-pulse p-5 bg-gray-300 rounded flex flex-col h-max w-full self-center">
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-1/4 h-3 rounded-sm mb-3"></div>
            <div className="bg-gray-400 w-2/4 h-3 rounded-sm mb-3"></div>
          </div>
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-1/4 h-3 rounded-sm mb-3"></div>
            <div className="bg-gray-400 w-2/4 h-3 rounded-sm mb-3"></div>
          </div>
        </div>
        <div className="animate-pulse rounded bg-gray-300 my-4 w-2/4 h-5"></div>
        <div className="animate-pulse p-5 bg-gray-300 rounded flex flex-col h-max w-full self-center">
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-1/4 h-3 rounded-sm mb-3"></div>
            <div className="bg-gray-400 w-2/4 h-3 rounded-sm mb-3"></div>
          </div>
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-1/4 h-3 rounded-sm mb-3"></div>
            <div className="bg-gray-400 w-2/4 h-3 rounded-sm mb-3"></div>
          </div>
        </div>
      </div>
      <div className="w-[52%] ml-4">
        <div className="animate-pulse rounded bg-gray-300 my-4 w-2/4 h-5"></div>
        <div className="animate-pulse p-5 my-4 bg-gray-300 rounded flex flex-col h-max w-full self-center">
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-full h-3 rounded-sm mb-3"></div>
          </div>
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-full h-3 rounded-sm mb-3"></div>
          </div>
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-full h-3 rounded-sm mb-3"></div>
          </div>
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-full h-3 rounded-sm mb-3"></div>
          </div>
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-full h-3 rounded-sm mb-3"></div>
          </div>
          <div className="w-full items-baseline pr-4 flex justify-between">
            <div className="bg-gray-400 w-full h-3 rounded-sm mb-3"></div>
          </div>
        </div>
        <div className="animate-pulse rounded bg-gray-300 my-4 w-full h-8 flex items-center justify-center">
          <div className="bg-gray-400 w-1/4 h-3 rounded-sm"></div>
        </div>
        <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center  mb-4">
          <div className="w-[70%] items-baseline pr-4 ">
            <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
            <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
          </div>
          <div
            className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
          ></div>
        </div>
        <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center  mb-4">
          <div className="w-[70%] items-baseline pr-4 ">
            <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
            <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
          </div>
          <div
            className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
          ></div>
        </div>
        <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center  mb-4">
          <div className="w-[70%] items-baseline pr-4 ">
            <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
            <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
          </div>
          <div
            className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
          ></div>
        </div>
        <div className="animate-pulse p-5 bg-gray-300 rounded-lg flex flex-col h-max w-full self-center  mb-4">
          <div className="w-[70%] items-baseline pr-4 ">
            <div className="bg-gray-400 w-40 h-5 rounded-sm mb-3"></div>
            <div className="bg-gray-400 w-20 h-3 rounded-sm"></div>
          </div>
          <div
            className={`w-[30%] sm:w-[30%] lg:w-[19%] 2xl:w-[13.5%] relative flex items-baseline`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default AboutLandingDesktopLoader;
