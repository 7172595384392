import { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import { BreadcrumbsProfile } from "../components/Navigation/BreadCrumbsProfile";
import ProfileNavBar from "../components/Navigation/ProfileNavBar";
import MotionWrapper from "../components/Animation/MotionWrapper";

export default function ProfileLayout() {
  const [isFixed, setIsFixed] = useState(false);
  const [isSideBarFixed, setIsSideBareFixed] = useState(false);
  const divRef = useRef(null);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 12);
    const scrollHeight =
      document.documentElement.scrollHeight - divRef.current.offsetHeight;

    setIsSideBareFixed(scrollTop > 12);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  return (
    <div className="mdl:mb-8 bg-secondary h-[calc(100vh_-_7.5rem)] sm:h-[calc(100vh-(-4rem))] mdl:h-auto">
      <div className="bg-secondary mdl:bg-white">
        <BreadcrumbsProfile
          className={`${
            isFixed
              ? "fixed top-16 left-0 z-40 py-1 shadow-xl"
              : "relative py-4  "
          }bg-secondary mdl:bg-white w-full mdl:flex items-center py-3 px-[25px] hidden mdl:px-8 lg:px-12 xl:px-[60px]`}
        />
        <div className="mdl:grid px-[25px] mdl:px-8 lg:px-12 xl:px-[60px]  profileLayout">
          <MotionWrapper>
            <div
              className={`${isSideBarFixed ? "sticky top-28" : "relative"}
               hidden mdl:bg-white mdl:block`}
            >
              <ProfileNavBar isSideBarFixed={isSideBarFixed} className={``} />
            </div>
          </MotionWrapper>
          <div
            className={` 
             w-full bg-secondary rounded-lg pt-6 mdl:pt-0`}
            ref={divRef}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
