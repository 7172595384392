import { useState, useEffect } from "react";
import RewardCouponModal from "../../components/Modal/RewardCoupon";
import scartch_card from "../../assets/images/rewards/viewCoupon.png";
import scratched from "../../assets/images/rewards/viewCoupon.png";
import { fetchBannerItem } from "../../services/index";
import giftBox from "../../assets/images/rewards/giftBox.png";
import giftRibbon from "../../assets/images/rewards/giftRibbon.png";
import Button from "../../components/Elements/Button";
import RewardLoader from "../../components/Loader/ProfilePageLoaders/RewardCardLoader/RewardCardLoader";
import MotionWrapper from "../../components/Animation/MotionWrapper";
import nodata_found from "../../assets/images/no-data-found/profile/rewards/nodata.png";
import { success } from "../../components/notify";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useDispatch } from "react-redux";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import giftRibbonBlue from "../../assets/images/rewards/giftRibbonBlue.png";
import giftBoxBlue from "../../assets/images/rewards/giftBoxBlue.png";

const Rewards = () => {
  const [coupon, setCoupon] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    getbasicdinedata();
  }, []);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading]);

  const getbasicdinedata = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await fetchBannerItem();
      if (status == 200) {
        if (data.status === "success") {
          setCoupon(data?.customer?.common_coupon);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("error msg", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScratchReveal = (commonCouponId) => {
    setCoupon((prevCoupons) =>
      prevCoupons.map((coupon) =>
        coupon.common_coupon_Id === commonCouponId
          ? { ...coupon, common_coupon_Checked: 1, isScratched: true }
          : coupon
      )
    );
  };

  return (
    <MotionWrapper>
      <div className="">
        <div className="mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto mdl:p-7 overflow-y-scroll h-auto no-scrollbar pb-5">
          {" "}
          <div className="text-lg font-bold sm:text-xl sm:font-semibold sm:font-inter">
            My Rewards
          </div>
          {isLoading ? (
            <RewardLoader />
          ) : coupon && coupon.length > 0 ? (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-3 3xl:grid-cols-4 2xl:gap-6 py-4">
              {coupon &&
                coupon.map((val) => (
                  <ScartchCard
                    key={val?.common_coupon_Id}
                    data={val}
                    onScratchReveal={() =>
                      handleScratchReveal(val?.common_coupon_Id)
                    }
                  />
                ))}
            </div>
          ) : (
            <div className="flex justify-center items-center w-full sm:min-h-[calc(100vh_-_17rem)] sm:h-auto">
              <div className=" lg:w-[350px] md:w-[200px]">
                <img src={nodata_found} alt="No data found image" />
              </div>
            </div>
          )}
        </div>
      </div>
    </MotionWrapper>
  );
};

const ScartchCard = ({ data, onScratchReveal }) => {
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const closeRewardModal = () => {
    setVisible(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    success(`Copied to clipboard! ${inputValue}`);
  };

  const openRewardModal = (data) => {
    if (data.common_coupon_Checked === 0) {
      setVisible(true);
    }
  };

  useEffect(() => {
    setInputValue(data.common_coupon_Barcode);
  }, [data.common_coupon_Barcode]);

  return (
    <>
      <div className="" onClick={() => openRewardModal(data)}>
        {data.common_coupon_Checked ? (
          data?.common_coupon_Status === "Expired" ? (
            <div className="bg-coupon-expire-backdrop bg-no-repeat bg-center bg-cover rounded-lg h-40  md:h-44  xl:h-52  2xl:h-[280px] cursor-pointer">
              <div className="Expired flex justify-end">
                <p className="bg-couponButton rounded-l-md relative top-4 pl-2 pr-1 py-0.5 text-[10px] xl:text-[12px] 2xl:text-[16px] text-white">
                  {data?.common_coupon_Status}
                </p>
              </div>
              <div className="w-full">
                <div className="w-12 xl:w-16 2xl:w-[87px] relative -top-2 m-auto">
                  <img src={giftRibbon} alt="" srcSet="" loading="lazy" />
                </div>
                <p className="text-center text-couponButton relative -top-5 text-sm md:text-md 2xl:text-[18px]">
                  {data?.common_coupon_Heading}
                </p>
                <div className="w-8 xl:w-12 2xl:w-[90px] relative -top-4 m-auto">
                  <img src={giftBox} alt="" srcSet="" loading="lazy" />
                </div>
                <div className="flex justify-center relative -top-1">
                  <div className="rounded-l-md py-0.5 px-2 bg-white ">
                    <p className="text-xs md:text-sm text-couponButton 2xl:text-[20px]">
                      {data?.common_coupon_Barcode}
                    </p>
                  </div>
                  <Button className="bg-couponButton rounded-md py-0.5 px-1.5 text-xs md:text-sm 2xl:text-[16px] text-white -ml-1">
                    Copy
                  </Button>
                </div>
                {/* <p className="underline text-center text-xxs 2xl:text-[16px]">
                  View More
                </p> */}
              </div>
            </div>
          ) : (
            <div className="bg-coupon-backdrop bg-no-repeat bg-center bg-cover rounded-lg  h-40  md:h-44  xl:h-52  2xl:h-[280px] cursor-pointer">
              <div className="w-full top-6 relative">
                <div className="w-12 xl:w-16 2xl:w-[87px] relative -top-3 m-auto">
                  <img src={giftRibbonBlue} alt="" srcSet="" loading="lazy" />
                </div>
                <p className="text-center text-black text-sm md:text-md 2xl:text-[18px] -top-5 relative">
                  {data?.common_coupon_Heading}
                </p>
                <div className="w-8 xl:w-12 2xl:w-[90px] relative -top-4 m-auto">
                  <img src={giftBoxBlue} alt="" srcSet="" loading="lazy" />
                </div>
                <div className="flex justify-center relative -top-1">
                  <div className="rounded-l-md py-0.5 px-2 bg-white ">
                    <p className="text-xs md:text-sm text-black 2xl:text-[20px]">
                      {data?.common_coupon_Barcode}
                    </p>
                  </div>
                  <Button
                    className="bg-black rounded-md py-0.5 px-1.5 text-xs  md:text-sm 2xl:text-[16px] text-white -ml-1"
                    onClick={handleCopy}
                  >
                    Copy
                  </Button>
                </div>
                {/* <p className="text-black underline text-center text-xxs 2xl:text-[16px]">
                  View More
                </p> */}
              </div>
            </div>
          )
        ) : data.isScratched ? (
          <div className="bg-no-repeat bg-center bg-cover rounded-lg  h-40  md:h-44  xl:h-52  2xl:h-[280px] cursor-pointer flex items-center">
            <LazyLoadImageComponent
              src={scratched}
              alt=""
              className="w-full h-full object-cover rounded-lg"
              loading="lazy"
            />
          </div>
        ) : (
          <div className="bg-no-repeat bg-center bg-cover rounded-lg  h-40  md:h-44  xl:h-52  2xl:h-[280px] cursor-pointer flex items-center">
            <LazyLoadImageComponent
              src={scartch_card}
              alt=""
              className="w-full h-full object-cover rounded-lg"
              loading="lazy"
            />
          </div>
        )}
      </div>
      <RewardCouponModal
        visible={visible}
        data={data}
        onClose={closeRewardModal}
        onScratchReveal={onScratchReveal}
      />
    </>
  );
};

export default Rewards;
