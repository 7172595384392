import { useState } from "react";
import Checkbox from "../Elements/Checkbox";
import LazyLoadImageComponent from "../Image/LazyLoadImage";
import blue_placeholder_circle from "../../assets/images/default_images/blue_placeholder_circle.png";

const AllergyItems = ({ img, title, desc, className, checked, onChange }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className="flex items-center cursor-pointer gap-x-2"
      onClick={onChange}
    >
      <Checkbox checked={checked} onChange={() => {}} />
      <div className="space-x-2 flex items-center w-full">
        <div className="h-16 flex items-center">
          <LazyLoadImageComponent
            src={img}
            className={className ? className : "w-14"}
            alt=""
            loading="lazy"
            onErrorSrc={blue_placeholder_circle}
            placeholderSrc={blue_placeholder_circle}
          />
        </div>

        <div className="flex-1 flex flex-col">
          <span className="text-gray-800 font-semibold leading-tight text-base font-inter">
            {title}
          </span>
          <div className="self-start text-gray-800 font-normal leading-tight text-xxs font-inter">
            {desc?.length > 200 ? (
              showMore ? (
                <div>
                  <p>{desc}</p>{" "}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMore(false);
                    }}
                    className="text-black text-xs font-semibold hidden"
                  >
                    Less
                  </button>
                </div>
              ) : (
                <div>
                  {desc?.slice(0, 200)}{" "}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMore(true);
                    }}
                    className="text-primary text-xs font-semibold"
                  >
                    ...more
                  </button>
                </div>
              )
            ) : (
              <div>{desc}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllergyItems;
