import { createSlice } from "@reduxjs/toolkit";
import { checkEmpty } from "../../utils/helper";
import { getStorageJSON, setStorageJSON } from "../../utils";

const initialState = {
  dispatch: { summary: false, changing: false, disp: true },
  basicDetails: { summary: false, changing: false, disp: false },
  verifyOtp: { changing: false, disp: false },
  addressDetails: {
    summary: false,
    changing: false,
    addnew: false,
    disp: false,
  },
  payment: { summary: false, changing: false, disp: false },
  basketId: 0,
};
const kuickCheckoutStep = getStorageJSON("kuick-checkout-step");

const checkoutStep = createSlice({
  name: "checkoutStep",
  initialState: !checkEmpty(kuickCheckoutStep)
    ? kuickCheckoutStep
    : initialState,
  reducers: {
    storageCheckoutStep(state, action) {
      state = action.payload;
    },
    resetCheckoutStep(state, action) {
      let step = action.payload.step;
      let basketId = action.payload.basketId;
      state.basketId = basketId;
      if (step === "dispatch") {
        state.dispatch = {
          summary: false,
          changing: false,
          disp: true,
        };
        state.basicDetails = {
          summary: false,
          changing: false,
          disp: false,
        };
        state.verifyOtp = { changing: false, disp: false };
        state.addressDetails = {
          summary: false,
          changing: false,
          addnew: false,
          disp: false,
        };
        state.payment = {
          summary: false,
          changing: false,
          disp: false,
        };
      } else if (step == "basicDetails") {
        state.dispatch = {
          summary: true,
          changing: false,
          disp: false,
        };
        state.basicDetails = {
          summary: false,
          changing: false,
          disp: true,
        };
        state.verifyOtp = { changing: false, disp: false };
        state.addressDetails = {
          summary: false,
          changing: false,
          addnew: false,
          disp: false,
        };
        state.payment = {
          summary: false,
          changing: false,
          disp: false,
        };
      } else if (step == "verifyOtp") {
        state.dispatch = {
          summary: true,
          changing: false,
          disp: false,
        };
        state.basicDetails = {
          summary: false,
          changing: false,
          disp: true,
        };
        state.verifyOtp = { changing: false, disp: true };
        state.addressDetails = {
          summary: false,
          changing: false,
          addnew: false,
          disp: false,
        };
        state.payment = {
          summary: false,
          changing: false,
          disp: false,
        };
      } else if (step == "addressDetails") {
        state.dispatch = {
          summary: true,
          changing: false,
          disp: false,
        };
        state.basicDetails = {
          summary: true,
          changing: false,
          disp: true,
        };
        state.verifyOtp = { changing: false, disp: false };
        state.addressDetails = {
          summary: false,
          changing: false,
          addnew: false,
          disp: true,
        };
        state.payment = {
          summary: false,
          changing: false,
          disp: false,
        };
      } else if (step == "payment") {
        state.dispatch = {
          summary: true,
          changing: false,
          disp: false,
        };
        state.basicDetails = {
          summary: true,
          changing: false,
          disp: true,
        };
        state.verifyOtp = { changing: false, disp: false };
        state.addressDetails = {
          summary: true,
          changing: false,
          addnew: false,
          disp: false,
        };
        state.payment = {
          summary: false,
          changing: false,
          disp: true,
        };
      }
      setStorageJSON("kuick-checkout-step", state);
    },
  },
});

export const checkoutReduce = checkoutStep.reducer;
export const { resetCheckoutStep, storageCheckoutStep } = checkoutStep.actions;
