/* eslint-disable react/prop-types */
import { useLocation } from "react-router-dom";
import { StarIcon } from "@heroicons/react/24/solid";
import CustomizeCart from "../Modal/CustomizeCart";
import NonvegIcon from "../../assets/icons/nonvegIcon.svg";
import VegIcon from "../../assets/icons/vegIcon.svg";
import { useState, useRef, useEffect } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductGroups,
  insertProductToCart,
  actBasketProduct,
  getComplexMenu,
} from "../../services";
import { setCartConfigSession, getCartConfigSession } from "../../utils";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import { error, success } from "../notify";
import Button from "../Elements/Button";
import CustomizeCartComplex from "../Modal/CustomizeCartComplex";
import {
  checkEmpty,
  numberFormat,
  checkFloat,
  checkInt,
  objectSize,
  copyObject,
} from "../../utils/helper";
import { addComplexProduct, addSingleProduct } from "../../services";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import useAnalyticsEvents from "../../hooks/useAnalyticsEvents";

const MenuCard = ({ data, openStatus, isVeg = false }) => {
  const {
    inventory_product_Id,
    inventory_product_Name,
    inventory_product_Description,
    is_bestseller,
    inventory_product_Thumbnail_Image,
    inventory_product_Type,
    inventory_product_Form,
  } = data;
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const currency = useSelector(
    (state) => state?.basicdata?.storedetails?.store_Currency
  );
  const [tempProduct, setTempProduct] = useState({});
  const [finalProduct, setFinalProduct] = useState({});
  const [cartConfig, setCartConfig] = useState("");
  const [addCount, setAddCount] = useState(0);
  const [customizeCartModal, setCustomizeCartModal] = useState(false);
  const [customizeCartComplexModal, setCustomizeCartComplexModal] =
    useState(false);
  const basket = useSelector((state) => state?.basicdata?.basket);
  const [isLoading, setIsLoading] = useState(false);
  const [intervalData, setintervalData] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const location = useLocation();
  const analyticsEvents = useAnalyticsEvents();

  useEffect(() => {
    setCartConfig(getCartConfigSession());
  }, []);

  useEffect(() => {
    if (basket?.basketProducts) {
      let count = 0;
      basket.basketProducts?.forEach((val) => {
        if (val.inventory_product_Id === inventory_product_Id) {
          count += val.pos_basketproduct_Quantity;
        }
      });
      setAddCount(count);
    } else {
      setAddCount(0);
    }
  }, [inventory_product_Id, basket]);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);
      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      clearInterval(intervalData);
      dispatch(setProgressData(100));
    }
  }, [isLoading]);

  const closeCustomizeModal = () => {
    setCustomizeCartModal(false);
    setFinalProduct({});
    setTempProduct({});
  };

  const handleGroups = async () => {
    setIsLoading(true);
    setTempProduct(data);
    setFinalProduct({});
    if (inventory_product_Form === "ComplexMenu") {
      // Call api for Complexmenu type
      const { status, data: response } = await getComplexMenu(
        inventory_product_Id
      );
      setIsLoading(false);
      if (status === 200) {
        if (response.status === "success") {
          setFinalProduct(copyObject(data));
          if (checkEmpty(response?.productline)) {
            submitProduct();
          } else {
            setCustomizeCartComplexModal(true);
          }
          tempProduct.productline = response?.productline;
          setTempProduct(tempProduct);
          analyticsEvents("AddToCart", {
            page_path: location?.pathname,
            product_Name: inventory_product_Name,
          });
          // dispatch(setTempProductLine(response.productline));
        }
      }
    } else if (inventory_product_Form === "OnlyMenu") {
      const { status, data } = await insertProductToCart(inventory_product_Id);
      setIsLoading(false);
      if (status === 200) {
        if (data.status === "success") {
          dispatch(setBasketData(data?.basket));
          setCartConfigSession(data?.cartConfig);
          analyticsEvents("AddToCart", {
            page_path: location?.pathname,
            product_Name: inventory_product_Name,
          });
        }
      }
    } else if (inventory_product_Form === "SubMenu") {
      // Call api for submenu
      // Add your api call here
    } else if (inventory_product_Form === "SingleMenu") {
      // Call api for SingleMenu
      const { status, data: response } = await getProductGroups(
        inventory_product_Id
      );
      setIsLoading(false);
      if (status === 200) {
        if (response.status === "success") {
          if (response.status == "success") {
            setFinalProduct(copyObject(data));
            if (checkEmpty(response.groups)) {
              submitProduct();
            } else {
              setCustomizeCartModal(true);
              // setGroups(data?.groups);
            }
            tempProduct.groups = response?.groups;
            setTempProduct(tempProduct);
            analyticsEvents("AddToCart", {
              page_path: location?.pathname,
              product_Name: inventory_product_Name,
            });
          } else if (data.status == "error") {
            {
              /* empty */
            }
          }
        }
      }
    }
  };

  const submitProduct = async () => {
    setIsLoading(true);
    if (!checkEmpty(finalProduct)) {
      if (finalProduct.inventory_product_Form == "SingleMenu") {
        let inputData = {};
        inputData.inventory_product_Id = finalProduct?.inventory_product_Id;
        if (Object.prototype.hasOwnProperty.call(finalProduct, "groups")) {
          inputData.groups = {};
          Object.keys(finalProduct.groups)?.forEach((groups) => {
            inputData.groups[groups] = {};
            Object.keys(finalProduct.groups[groups])?.forEach((pg) => {
              inputData.groups[groups][pg] = {};
              inputData.groups[groups][pg].inventory_groups_Id =
                finalProduct?.groups[groups][pg]?.inventory_groups_Id;
              inputData.groups[groups][pg].inventory_component_Id =
                finalProduct?.groups[groups][pg]?.inventory_component_Id;
              if (!checkEmpty(finalProduct.groups[groups][pg].label)) {
                inputData.groups[groups][pg].label =
                  finalProduct?.groups[groups][pg]?.label;
              }
            });
          });
        }
        const { status, data } = await addSingleProduct(inputData);
        setIsLoading(false);
        if (status === 200) {
          if (data.status === "success") {
            // update basket data and cartconfig
            dispatch(setBasketData(data?.basket));
            setCartConfigSession(data?.cartConfig);
          } else if (data.status === "error") {
            /* empty */
          }
        }
      } else {
        let inputData = {};
        inputData.inventory_product_Id = finalProduct?.inventory_product_Id;
        if (Object.prototype.hasOwnProperty.call(finalProduct, "productline")) {
          inputData.productline = {};
          Object.keys(finalProduct?.productline)?.forEach((val) => {
            inputData.productline[val] = {};
            inputData.productline[val].inventory_product_Id =
              finalProduct?.productline[val]?.inventory_product_Id;
            if (
              Object.prototype.hasOwnProperty.call(
                finalProduct.productline[val],
                "groups"
              )
            ) {
              inputData.productline[val].groups = {};
              Object.keys(finalProduct?.productline[val]?.groups)?.forEach(
                (groups) => {
                  inputData.productline[val].groups[groups] = {};
                  Object.keys(
                    finalProduct.productline[val].groups[groups]
                  ).forEach((pg) => {
                    inputData.productline[val].groups[groups][pg] = {};
                    inputData.productline[val].groups[groups][
                      pg
                    ].inventory_groups_Id =
                      finalProduct?.productline[val]?.groups[groups][
                        pg
                      ]?.inventory_groups_Id;
                    inputData.productline[val].groups[groups][
                      pg
                    ].inventory_component_Id =
                      finalProduct?.productline[val]?.groups[groups][
                        pg
                      ]?.inventory_component_Id;
                    if (
                      !checkEmpty(
                        finalProduct?.productline[val]?.groups[groups][pg].label
                      )
                    ) {
                      inputData.productline[val].groups[groups][pg].label =
                        finalProduct?.productline[val]?.groups[groups][
                          pg
                        ]?.label;
                    }
                  });
                }
              );
            }
          });
        }
        const { status, data } = await addComplexProduct(inputData);
        setIsLoading(false);
        if (status === 200) {
          if (data.status === "success") {
            // update basket data and cartconfig
            dispatch(setBasketData(data?.basket));
            setCartConfigSession(data?.cartConfig);
          } else if (data.status === "error") {
            /* empty */
          }
        }
      }
    } else {
      /* empty */
    }
  };

  const handleDeleteSubmit = async () => {
    let found = 0;
    basket.basketProducts?.forEach((val) => {
      if (val?.inventory_product_Id === inventory_product_Id) {
        found += 1;
      }
    });

    if (found === 1) {
      setIsLoading(true);
      const { status, data } = await actBasketProduct({
        data_inv_Id: inventory_product_Id,
        data_Act: "dec",
      });
      setIsLoading(false);
      if (status === 200) {
        if (data.status === "success") {
          success(data?.msg, true);
          setCartConfigSession(data?.cartConfig);
          dispatch(setBasketData(data?.basket));
        }
      }
    } else if (found > 1) {
      error(
        "This item has multiple customizations added. Remove the correct item from the cart.",
        true
      );
    }
  };

  const checkFoodType = () => {
    if (
      inventory_product_Type.toUpperCase() === "N" ||
      inventory_product_Type.toUpperCase() === "NON-VEG" ||
      inventory_product_Type.toUpperCase() === "Non-Veg"
    ) {
      return NonvegIcon;
    } else {
      return VegIcon;
    }
  };

  const sortVegOnly = () => {
    if (
      isVeg &&
      (inventory_product_Type.toUpperCase() === "N" ||
        inventory_product_Type.toUpperCase() === "NON-VEG" ||
        inventory_product_Type.toUpperCase() === "Non-Veg")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const calculateFinalProductTotal = () => {
    let total = 0.0;
    if (!checkEmpty(finalProduct)) {
      const { inventory_product_Form } = finalProduct;
      if (inventory_product_Form == "SingleMenu") {
        total += checkFloat(
          finalProduct[
            "inventory_product_" +
              cartConfig?.pos_basket_Delivery_Type +
              "_Price"
          ]
        );
        if (Object.prototype.hasOwnProperty.call(finalProduct, "groups")) {
          const { groups } = finalProduct;
          Object.keys(groups)?.forEach((g) => {
            if (objectSize(groups[g]) > 0) {
              let lmt = checkInt(
                groups[g][0]?.inventory_groups_Default_Selection_Limit
              );
              Object.keys(groups[g])?.forEach((c) => {
                if (lmt > 0) {
                  total += checkFloat(
                    groups[g][c][
                      "inventory_groups_Default_Selection_" +
                        cartConfig.pos_basket_Delivery_Type +
                        "_Price"
                    ]
                  );
                  lmt--;
                } else {
                  total += checkFloat(
                    groups[g][c][
                      "inventory_groupsline_" +
                        cartConfig.pos_basket_Delivery_Type +
                        "_Price"
                    ]
                  );
                }
              });
            }
          });
        }
      } else if (inventory_product_Form == "ComplexMenu") {
        total += checkFloat(
          finalProduct[
            "inventory_product_" +
              cartConfig.pos_basket_Delivery_Type +
              "_Price"
          ]
        );
        if (Object.prototype.hasOwnProperty.call(finalProduct, "productline")) {
          const { productline } = finalProduct;
          Object.keys(productline).forEach((l) => {
            total += checkFloat(
              productline[l][
                "inventory_product_" +
                  cartConfig.pos_basket_Delivery_Type +
                  "_Hidden_Price"
              ]
            );
            if (
              Object.prototype.hasOwnProperty.call(productline[l], "groups")
            ) {
              const { groups } = productline[l];
              Object.keys(groups)?.forEach((g) => {
                if (objectSize(groups[g]) > 0) {
                  let lmt = checkInt(
                    groups[g][0].inventory_groups_Default_Selection_Limit
                  );
                  Object.keys(groups[g])?.forEach((c) => {
                    if (lmt > 0) {
                      total += checkFloat(
                        groups[g][c][
                          "inventory_groups_Default_Selection_" +
                            cartConfig.pos_basket_Delivery_Type +
                            "_Price"
                        ]
                      );
                      lmt--;
                    } else {
                      total += checkFloat(
                        groups[g][c][
                          "inventory_groupsline_" +
                            cartConfig.pos_basket_Delivery_Type +
                            "_Price"
                        ]
                      );
                    }
                  });
                }
              });
            }
          });
        }
      }
    }

    total = numberFormat(total, 2, ".", "");
    return total;
  };

  return (
    sortVegOnly() && (
      <div
        key={inventory_product_Id}
        className="py-sub_menu_padding_top_bottom flex justify-between h-auto w-full pb-sub_menu_padding_bottom md:pb-md_sub_menu_padding_bottom border-solid border-b last:border-b-0 border-menu-item-border"
      >
        <CustomizeCartComplex
          visible={customizeCartComplexModal}
          tempProduct={tempProduct}
          finalProduct={finalProduct}
          calculateFinalProductTotal={() => calculateFinalProductTotal()}
          setTempProduct={setTempProduct}
          setFinalProduct={(data) => setFinalProduct(data)}
          onClose={() => {
            setCustomizeCartComplexModal(false);
            setFinalProduct({});
            setTempProduct({});
          }}
          submitProduct={() => submitProduct()}
        />
        <CustomizeCart
          visible={customizeCartModal}
          tempProduct={tempProduct}
          finalProduct={finalProduct}
          addCount={addCount}
          onClose={closeCustomizeModal}
          calculateFinalProductTotal={() => calculateFinalProductTotal()}
          submitProduct={() => submitProduct()}
          setTempProduct={setTempProduct}
          setFinalProduct={(data) => setFinalProduct(data)}
        />
        <div className="w-sub_menu_width md:w-4/5 lg:w-lg_sub_menu_width 2xl:w-two_xl_sub_menu_width items-baseline pr-sub_menu_padding_right sm:pr-sm_sub_menu_padding_right">
          <div className="text-black text-opacity-75 pb-sub_menu_title_padding_bottom text-sm md:text-mdl font-medium font-inter leading-tight flex items-center gap-x-sub_menu_title_column_gap">
            <img
              className="w-sub_menu_img_width h-sub_menu_img_height sm:w-auto sm:h-sm_sub_menu_img_height lg:h-lg_sub_menu_img_height right-sub_menu_img_right object-contain rounded-sm"
              loading="lazy"
              src={checkFoodType()}
            />
            <span
              className={`${is_bestseller === 1 ? "block ml-1" : "hidden"}`}
            >
              <div className="sm:space-y-0 w-7/12">
                <div className="flex space-x-0 items-center">
                  <div className="mr-1">
                    <StarIcon className="h-4 w-4 text-sub-color" />
                  </div>
                  <div className="w-max text-sub-color mt-0.5 text-xs sm:text-sm font-medium font-inter">
                    Bestseller
                  </div>
                </div>
              </div>
            </span>
            <span
              className={`${
                is_bestseller != 1
                  ? "block text-black text-opacity-75 text-sm md:text-mdl font-medium font-inter leading-tight ml-1"
                  : "hidden"
              }`}
            >
              {inventory_product_Name}
            </span>
          </div>
          <div
            className={`${
              is_bestseller == 1
                ? "block text-black text-opacity-75 text-sm md:text-mdl font-medium font-inter leading-tight ml-6"
                : "hidden"
            }`}
          >
            {inventory_product_Name}
          </div>
          {/* <div className="text-black text-opacity-75 text-sm mdl:text-mdl sm:text-opacity-75 font-semibold font-inter flex items-center gap-x-sub_menu_price_column_gap ">
            <span>
              {currency}
              {
                data[
                  `inventory_product_${basket.pos_basket_Delivery_Type}_Price`
                ]
              }
            </span>
            {is_bestseller === 1 && (
              <div className="sm:space-y-0 w-7/12">
                <div className="flex space-x-1 items-center">
                  <StarIcon className="h-4 w-4 text-sub-color" />
                  <div className="w-max text-sub-color mt-0.5 text-xs sm:text-sm font-medium font-inter">
                    Bestseller
                  </div>
                </div>
              </div>
            )}
          </div> */}
          <div className={`${is_bestseller === 1 ? "ml-6" : "ml-6"}`}>
            {inventory_product_Description?.trim() && (
              <div className="text-stone-500 sm:text-neutral-400 text-xs mdl:text-sm leading-[19px] font-normal font-inter w-full">
                {inventory_product_Description?.length > 100 ? (
                  showMore ? (
                    <div>
                      <p>{inventory_product_Description}</p>{" "}
                      <button
                        onClick={() => setShowMore(false)}
                        className="text-primary text-xs md:text-sm hidden"
                      >
                        Less
                      </button>
                    </div>
                  ) : (
                    <div>
                      {inventory_product_Description.slice(0, 100)}{" "}
                      <button
                        onClick={() => setShowMore(true)}
                        className="text-primary text-xs mdl:text-sm "
                      >
                        ...more
                      </button>
                    </div>
                  )
                ) : (
                  <div>{inventory_product_Description}</div>
                )}
              </div>
            )}
          </div>
          <div
            className={`text-black text-opacity-75 text-sm mdl:text-mdl sm:text-opacity-75 font-semibold font-inter flex items-center gap-x-sub_menu_price_column_gap ${
              is_bestseller == 1 ? "ml-6" : "ml-6"
            }`}
          >
            <span>
              {currency}
              {
                data[
                  `inventory_product_${basket?.pos_basket_Delivery_Type}_Price`
                ]
              }
            </span>
          </div>
        </div>
        <div
          ref={ref}
          className="w-[30%] md:w-1/5 2xl:w-[13.5%] flex flex-col justify-center items-end"
        >
          {inventory_product_Thumbnail_Image && (
            <img
              className={`w-20 md:w-24 object-contain rounded-sm`}
              loading="lazy"
              src={inventory_product_Thumbnail_Image}
            />
          )}
          {openStatus !== "Close" && (
            <div className="flex flex-col justify-end items-center h-max">
              <Button
                disabled={isLoading}
                onClick={() => {
                  if (addCount === 0) handleGroups();
                }}
                className={`${
                  inventory_product_Thumbnail_Image ? "-mt-4" : ""
                } group btn btn-sm px-1.5 border-0 rounded-lg justify-center items-center w-20 md:w-24 gap-2 z-1 float bg-light-shade-3 hover:bg-black hover:text-light-shade-3 text-black`}
              >
                <div className="justify-center items-center gap-1 flex">
                  <div
                    className={`  items-center flex space-x-2 md:space-x-4 text-sm font-semibold font-inter`}
                  >
                    {/* Minus Button */}
                    {addCount > 0 && (
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteSubmit();
                        }}
                      >
                        <MinusIcon className={`w-5 h-full `} />
                      </Button>
                    )}

                    {/* Show add or count */}
                    <span className={`capitalize `}>
                      {addCount === 0 ? "Add" : addCount}
                    </span>

                    {/* Add button */}
                    {addCount > 0 && (
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();
                          handleGroups();
                        }}
                      >
                        <PlusIcon className={`w-5 h-full`} />
                      </Button>
                    )}
                  </div>
                </div>
              </Button>
              <div className="text-xxs md:text-xs font-normal text-black cursor-pointer">
                {inventory_product_Form !== "OnlyMenu" ? "Customize" : ""}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default MenuCard;
