import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setFilterItems } from "../store/basicData/basicDataSlice";
import { HomePage } from "../routes/RoutesPath";
import { removeAllSpaceFromString } from "../utils";

const useHomeClick = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filter = useSelector((state) => state?.basicdata?.filter);
  const handleHomeRedirect = (postcode) => {
    if (filter.length) {
      let res = {};
      filter.forEach((val) => {
        res[val.key] = [];
        val.data.forEach((v) => {
          res[val.key].push({ id: v.Id, checked: false, name: v.Name });
        });
      });

      dispatch(setFilterItems(res));
    }
    navigate(HomePage + removeAllSpaceFromString(postcode?.toLowerCase()));
  };

  return { handleHomeRedirect };
};

export default useHomeClick;
