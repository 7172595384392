import React from "react";

const UserName = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 56 56"
      fill="none"
    >
      <circle
        cx="28"
        cy="28"
        r="27"
        fill="white"
        stroke="#D8D8DF"
        strokeWidth="2"
      />
      <path
        d="M35 33.5C35 35.433 31.866 37 28 37C24.134 37 21 35.433 21 33.5C21 31.567 24.134 30 28 30C31.866 30 35 31.567 35 33.5Z"
        stroke="#7B7D8F"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M32 23C32 25.2091 30.2091 27 28 27C25.7909 27 24 25.2091 24 23C24 20.7909 25.7909 19 28 19C30.2091 19 32 20.7909 32 23Z"
        stroke="#7B7D8F"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserName;
