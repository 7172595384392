import moment from "moment";

export const DayTime = ({ data, currentDay }) => {
  return (
    <div
      className={`py-3 first:pt-0 md:first:pt-0 mdl:py-0 border-b-[1px] rounded-none bg-transparent flex justify-between items-center last:border-none`}
    >
      <div
        className={`text-[13.5px] mdl:text-[12px] font-[400] ${
          currentDay === data?.store_time_Open_Day
            ? "text-red-500 border-red-500 "
            : "text-gray-400 border-gray-400 bg-white"
        }`}
      >
        {data?.store_time_Display_Name}
      </div>
      <p
        className={`px-1 py-1 text-[13.5px] w-max text-center rounded-md ${
          currentDay === data?.store_time_Open_Day
            ? "text-red-500 border-red-500 "
            : "text-gray-400 border-gray-400 bg-white"
        }`}
      >
        {moment(data?.store_time_Open_Time, "HH:mm:ss").format("hh:mm A")} -{" "}
        {moment(data?.store_time_Close_Time, "HH:mm:ss").format("hh:mm A")}
      </p>
    </div>
  );
};
