import { useEffect, useRef, useState } from "react";
import CheckCircle from "../../components/Icon/CheckCircle";
import RadioButton from "../../components/Elements/RadioButton";
import DropdownCart from "../../components/Elements/DropdownCart";
import { checkEmpty, isValidDateTime, copyObject } from "../../utils/helper";
import { changeBasketMode } from "../../services";
import { useSelector, useDispatch } from "react-redux";
import { dispatchTimeData } from "../../services";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import { setCartConfigSession } from "../../utils";
import { error, success } from "../../components/notify";
import DispatchInfo from "./DispatchInfo";
const CheckoutDispatch = ({
  storeDetails,
  cartDetails,
  checkoutStepper,
  setBasketOption,
  setIsLoadingTopBar,
}) => {
  const checkoutStep = useSelector((state) => state?.checkoutStep);
  const basket = useSelector((state) => state?.basicdata?.basket);
  const dispatch = useDispatch();
  const [isDelivery, setisDelivery] = useState(false);

  const [isImediate, setisImediate] = useState(true);
  const dateRef = useRef(null);
  const [checkoutPreOrderDate, setCheckOutPreOrderDate] =
    useState("Select Date");
  const [checkoutPreOrderTime, setCheckOutPreOrderTime] =
    useState("Select Time");
  const [selectDateTime, setSelectDateTime] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDateChanged, setIsDateChanged] = useState(false);

  useEffect(() => {
    // console.log("-----42-----checkout step changed-----");
  }, [checkoutStep]);

  const convertDate = (input) => {
    const datePart = input.split(" ")[1];
    const dateObj = new Date(datePart);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const convertTime = (input) => {
    return input.includes(":") && input.length === 5 ? `${input}:00` : input;
  };

  const dispatchTime = async (time, date) => {
    if (time === "Select Time" || date === "Select Date") {
      return;
    }
    let checkoutPreOrderDateTime = convertDate(date) + " " + convertTime(time);
    if (isValidDateTime(checkoutPreOrderDateTime)) {
      handleDispatchTimeChange(checkoutPreOrderDateTime);
    }
  };

  const handleDispatchTimeChange = async (checkoutPreOrderDateTime) => {
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await dispatchTimeData(checkoutPreOrderDateTime);
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
          let updatedBasket = copyObject(basket);
          updatedBasket.pos_basket_Delivery_Due_Date = checkoutPreOrderDateTime;
          dispatch(setBasketData(updatedBasket));
        } else {
          if (!checkEmpty(data.msg)) {
            error(data.msg);
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };
  const handleDateChange = (value) => {
    setCheckOutPreOrderDate(value);
    setIsDateChanged(true);
  };
  const handleTimeChange = (value) => {
    setCheckOutPreOrderTime(value);
    dispatchTime(value, checkoutPreOrderDate);
    setIsDateChanged(false);
  };

  const basketDispatchModeChange = async (thisDispatchValue) => {
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await changeBasketMode({
        data_Id: thisDispatchValue,
        data_Act: "dispatch",
      });
      if (status === 200) {
        if (data.status === "success") {
          success(data.msg);
          setDispatchSelection(data?.basket?.pos_basket_Delivery_Type);
          dispatch(setBasketData(data?.basket));
          setCartConfigSession(data?.cartConfig);
        } else if (data.status == "error") {
          if (!checkEmpty(data.msg)) {
            error(data.msg);
          }
        }
      }
    } catch (data) {
      error(data.msg);
    } finally {
      setTimeout(() => {
        setIsLoadingTopBar(false);
      }, 500);
    }
  };

  const setDispatchSelection = async (val) => {
    if (val === "Collection") {
      setBasketOption("Collection");
      setisDelivery(false);
    } else {
      setisDelivery(true);
      setBasketOption("Delivery");
    }
  };
  const setOrderTimePreference = async (pref) => {
    if (pref == "immediate") {
      setisImediate(true);
      handleDispatchTimeChange("0000-00-00 00:00:00");
    } else {
      setisImediate(false);
    }
  };

  const validateStepper = async () => {
    if (
      (storeDetails?.store_Open_Status == "Pre Order" || !isImediate) &&
      (checkoutPreOrderDate == "Select Date" ||
        checkoutPreOrderTime == "Select Time" ||
        isDateChanged)
    ) {
      error(
        "Please select Pre Order date and time as the store is currently closed"
      );
      return false;
    }

    return true;
  };
  const sectionStepper = async (value) => {
    let checkValidateStepper = await validateStepper();
    if (checkValidateStepper) {
      if (value == "next") {
        checkoutStepper("basicDetails");
      }
    }
  };

  useEffect(() => {
    // console.log("hello");
    // console.log("storeDetails?.store_Delivery", storeDetails?.store_Delivery);
    // console.log(
    //   "storeDetails?.store_Collection",
    //   storeDetails?.store_Collection
    // );
    // console.log("store_Open_Delivery", storeDetails?.store_Open_Delivery);
    // console.log("store_Open_Collection", storeDetails?.store_Open_Collection);
    // console.log(
    //   "store_Open_Delivery_Time",
    //   storeDetails?.store_Open_Delivery_Time
    // );
    // console.log(
    //   "store_Open_Collection_Time",
    //   storeDetails?.store_Open_Collection_Time
    // );
    if (storeDetails?.store_Open_Status == "Pre Order") {
      setisImediate(false);
    } else {
      setisImediate(true);
    }
  }, [storeDetails]);

  useEffect(() => {
    // console.log("basket.deliveryTime", basket.deliveryTime);
    // console.log("basket.collectionTime", basket.collectionTime);
    // console.log("basket.storeStatus", basket.storeStatus);
    // console.log(
    //   "basket?.pos_basket_Delivery_Type",
    //   basket?.pos_basket_Delivery_Type
    // );
    setDispatchSelection(basket?.pos_basket_Delivery_Type);
    !checkEmpty(basket?.pos_basket_Delivery_Due_Date)
      ? setisImediate(false)
      : setisImediate(true);
  }, [basket?.pos_basket_Delivery_Type]);
  useEffect(() => {
    if (checkoutPreOrderDate === "Select Date") {
      setCheckOutPreOrderTime("Select Time");
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [checkoutPreOrderDate]);

  return (
    <>
      {checkoutStep.dispatch.summary === true ? (
        <>
          <div
            className={`flex w-full items-start justify-between pb-5 ${
              checkoutStep.dispatch.summary === true && "animate-fadedown"
            }`}
          >
            <div className="sm:w-full">
              <div className="stepType flex justify-start">
                <CheckCircle className="fill-green-400 mr-2 mt-[4px]" />

                <div className="sm:w-full sm:flex">
                  <p className="text-black  w-[200px] font-medium font-inter text-xl xl:text[22px] 2xl:text-[26px]">
                    {isDelivery ? "Delivery" : "Collection"}
                  </p>

                  <div className="flex flex-col justify-center  sm:w-full sm:flex sm:items-center flex-1">
                    {basket?.pos_basket_Delivery_Due_Date !=
                      "0000-00-00 00:00:00" &&
                      !checkEmpty(basket?.pos_basket_Delivery_Due_Date) && (
                        <p className="text-gray-500 text-sm font-semibold sm:w-[200px] md:w-[300px]">
                          {basket?.pos_basket_Delivery_Type} due date:
                          <span className="text-gray-500 text-sm font-normal pl-2">
                            {basket?.pos_basket_Delivery_Due_Date} [Pre Order]
                          </span>
                        </p>
                      )}
                    <p className="text-gray-500 text-sm font-semibold sm:w-[200px] md:w-[300px]">
                      Order Time :
                      <span className="text-gray-500 text-sm font-normal pl-2">
                        {basket?.pos_basket_Delivery_Due_Date !=
                          "0000-00-00 00:00:00" &&
                        !checkEmpty(basket?.pos_basket_Delivery_Due_Date)
                          ? "Later"
                          : "Immediately"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="text-primary underline underline-offset-4 text-sm 2xl:text-lg cursor-pointer"
              onClick={() => {
                checkoutStepper("dispatch");
              }}
            >
              Change
            </div>
          </div>
        </>
      ) : checkoutStep.dispatch.disp === true ? (
        <div
          className={checkoutStep.dispatch.disp === true && "animate-fadedown"}
        >
          <div className="mdl:hidden">
            <DispatchInfo
              instructions={cartDetails?.pageContent?.instructionAddon}
            />
          </div>
          <div className="w-full 2xl:w-full h-auto">
            {storeDetails?.store_Open_Delivery == 1 &&
              storeDetails?.store_Open_Collection == 1 && (
                <div className="flex justify-between items-center">
                  <div className="text-black font-medium font-inter text-xl xl:text[22px] 2xl:text-[26px] mb-5">
                    Select Delivery or Collection
                  </div>
                </div>
              )}
            <div className="space-y-5  sm:flex sm:space-y-0 sm:space-x-6">
              {storeDetails?.store_Open_Delivery == 1 &&
                storeDetails?.store_Open_Collection != 1 && (
                  <div className="flex items-center">
                    <div>
                      <svg
                        width="21"
                        height="21"
                        className="fill-green-400 mr-2"
                        viewBox="0 0 21 21"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Group 1000006017">
                          <circle
                            id="Ellipse 2861"
                            cx="10.5"
                            cy="10.5"
                            r="10.5"
                            fill="#54B080"
                          ></circle>
                          <path
                            id="Vector"
                            d="M14 8L8.5 13.5L6 11"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <p className="text-black font-medium font-inter text-[22px]">
                      We have only{" "}
                      <span className="text-primary">delivery</span> option at
                      this moment
                    </p>
                  </div>
                )}

              {storeDetails?.store_Open_Delivery != 1 &&
                storeDetails?.store_Open_Collection == 1 && (
                  <div className="flex items-center">
                    <div>
                      <svg
                        width="21"
                        height="21"
                        className="fill-green-400 mr-2"
                        viewBox="0 0 21 21"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Group 1000006017">
                          <circle
                            id="Ellipse 2861"
                            cx="10.5"
                            cy="10.5"
                            r="10.5"
                            fill="#54B080"
                          ></circle>
                          <path
                            id="Vector"
                            d="M14 8L8.5 13.5L6 11"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <p className="text-black font-medium font-inter text-[22px]">
                      We have only{" "}
                      <span className="text-primary">collection</span> option at
                      this moment
                    </p>
                  </div>
                )}
              {storeDetails?.store_Open_Delivery == 1 &&
                storeDetails?.store_Open_Collection == 1 && (
                  <>
                    {storeDetails?.store_Delivery == 1 &&
                      !checkEmpty(basket.deliveryTime) &&
                      storeDetails?.store_Open_Delivery == 1 && (
                        <div
                          onClick={() => {
                            setisDelivery(true);
                            basketDispatchModeChange("Delivery");
                          }}
                          className={`cursor-pointer px-5 w-full xl:w-[350px] min-[1300px]:w-full 2xl:w-full h-[70px] sm:h-[106px] ${
                            isDelivery ? "bg-pink-50" : "bg-white"
                          }  rounded-lg border ${
                            isDelivery ? "border-primary" : "border-gray-300"
                          }  grid grid-cols-1 place-items-center`}
                        >
                          <div className="flex items-center justify-between w-full">
                            <div
                              className={`text-[20px] sm:text-[22px] font-semibold ${
                                isDelivery
                                  ? "text-primary"
                                  : "text-dark-shade-8"
                              } `}
                            >
                              Delivery
                            </div>

                            {basket?.storeStatus == "Add" && (
                              <div className="text-gray-shade-4 text-sm font-semibold font-inter capitalize leading-[18px]  pt-1">
                                {basket?.deliveryTime} Mins
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    {storeDetails?.store_Collection == 1 &&
                      !checkEmpty(basket?.collectionTime) &&
                      storeDetails?.store_Open_Collection == 1 && (
                        <div
                          onClick={() => {
                            setisDelivery(false);
                            basketDispatchModeChange("Collection");
                          }}
                          className={` cursor-pointer px-5 w-full xl:w-[350px] min-[1300px]:w-full 2xl:w-full h-[70px] sm:h-[106px] ${
                            !isDelivery
                              ? "bg-pink-50 border-primary"
                              : "bg-white border-gray-300"
                          } rounded-lg border  grid grid-cols-1 place-items-center`}
                        >
                          <div className="flex items-center justify-between w-full">
                            <div
                              className={`text-[22px] font-medium ${
                                !isDelivery ? "text-primary" : "text-gray-500"
                              } `}
                            >
                              Collection
                            </div>

                            {basket?.storeStatus == "Add" && (
                              <div className="text-gray-shade-4 text-sm font-semibold font-inter capitalize leading-[18px]  pt-1">
                                {basket?.collectionTime} Mins
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                  </>
                )}
            </div>
            {!checkEmpty(cartDetails?.pageContent?.advanced_preorder_data) &&
              !checkEmpty(
                cartDetails?.pageContent?.advanced_preorder_data[
                  basket?.pos_basket_Delivery_Type
                ]
              ) && (
                <div className="mt-8 mb-3 h-[1px] w-full bg-white-shade-6"></div>
              )}
            {!checkEmpty(cartDetails?.pageContent?.advanced_preorder_data) &&
            !checkEmpty(
              cartDetails?.pageContent?.advanced_preorder_data[
                basket?.pos_basket_Delivery_Type
              ]
            ) ? (
              <div className="w-full ">
                {storeDetails?.store_Open_Status != "Pre Order" && (
                  <div className="flex flex-col py-4     sm:flex-row sm:items-center sm:justify-between">
                    <div className="text-dark-shade-8 font-semibold font-inter mb-4      sm:mb-0 sm:text-[17px]">
                      When would you like your order?
                    </div>
                    <div className="flex space-x-8 items-center">
                      <div
                        onClick={() => setOrderTimePreference("immediate")}
                        className="flex space-x-1 items-center cursor-pointer "
                      >
                        <RadioButton
                          name=""
                          value=""
                          className="w-5 dark-shade-8 text-sm sm:text-[17px]"
                          checked={isImediate}
                          onChange={() => {}}
                        />
                        <label
                          className={`text-sm sm:text-[17px] font-medium cursor-pointer ${
                            isImediate ? "text-primary" : "text-dark-shade-8"
                          }`}
                        >
                          Immediate
                        </label>
                      </div>
                      <div
                        onClick={() => {
                          setOrderTimePreference("later");
                        }}
                        className="flex space-x-1 items-center cursor-pointer"
                      >
                        <RadioButton
                          name=""
                          value=""
                          className="w-5"
                          checked={!isImediate}
                          onChange={() => {}}
                        />
                        <label
                          className={`text-sm sm:text-[17px] font-medium cursor-pointer ${
                            !isImediate ? "text-primary" : "text-dark-shade-8"
                          }`}
                        >
                          Later
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                {(!isImediate ||
                  storeDetails?.store_Open_Status == "Pre Order") && (
                  <div className="flex flex-col py-4 sm:flex-row sm:justify-between sm:items-center jklm">
                    <div className="text-dark-shade-8 font-semibold font-inter mb-4 sm:mb-0 sm:text-[17px] ">
                      Select {basket?.pos_basket_Delivery_Type} date and time
                    </div>
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center sm:space-x-3">
                      <div
                        onClick={() => {
                          dateRef.current;
                          setSelectDateTime("Select Date");
                        }}
                        className="border rounded-lg flex items-center min-w-[200px] w-100 sm:w-max mt-0 sm:mb-0"
                      >
                        <DropdownCart
                          data={
                            cartDetails?.pageContent?.preOrderList
                              ?.preOrderDateListArr[
                              basket?.pos_basket_Delivery_Type
                            ]
                          }
                          type="date"
                          value={checkoutPreOrderDate}
                          className="pr-3"
                          onChange={handleDateChange}
                          selectDateTime={selectDateTime}
                        />
                      </div>
                      <div
                        className={`border rounded-lg flex items-center min-w-[200px] w-100 sm:w-max  mt-4 sm:mb-0 sm:mt-0 custom-div ${
                          isDisabled ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setSelectDateTime("Select Time");
                        }}
                      >
                        <DropdownCart
                          data={
                            cartDetails?.pageContent?.preOrderList
                              ?.preOrderDateListArr[
                              basket.pos_basket_Delivery_Type
                            ][checkoutPreOrderDate]
                          }
                          type="time"
                          value={checkoutPreOrderTime}
                          className="pr-3"
                          onChange={handleTimeChange}
                          selectDateTime={selectDateTime}
                          disabled={isDisabled}
                          dateChanged={isDateChanged}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex flex-col sm:flex-row justify-end sm:justify-between items-end sm:items-center pb-8 sm:py-4 pr-[1px]">
                  {/* {basket.pos_basket_Delivery_Due_Date !=
                    "0000-00-00 00:00:00" &&
                    !checkEmpty(basket.pos_basket_Delivery_Due_Date) && (
                      <p className="text-sm pt-2 pb-6 sm:p-0 w-full text-right sm:text-left text-primary sm:flex-1 font-medium">
                        {basket.pos_basket_Delivery_Type} due date:
                        <span className="pl-1 text-dark-shade-8 font-normal">
                          {basket.pos_basket_Delivery_Due_Date}
                        </span>
                      </p>
                    )} */}
                  <div></div>
                  <button
                    onClick={() => sectionStepper("next")}
                    className=" thisnext bg-primary sm:w-[135px] w-[48%] h-[46px] sm:h-max sm:py-4 text-md font-medium text-white rounded-lg"
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end pb-8 py-4">
                <button
                  onClick={() => sectionStepper("next")}
                  className="thisnext1 bg-primary sm:w-[135px] w-[48%] h-[46px] sm:h-max sm:py-4 text-md font-medium text-white rounded-lg"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CheckoutDispatch;
