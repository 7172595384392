import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storedetails: {},
  basket: {},
  settings: {},
  filter: [],
  customer: {},
  pageContent: {},
  status: "",
  msg: "",
  filterItems: {},
  locationData: {},
  searchText: "",
  takeAwayOpen: "",
  takeAwayClosed: "",
  searchItems: "",
  homeLoader: true,
};

const basicDataSlice = createSlice({
  name: "basicData",
  initialState,
  reducers: {
    setBasicDineData: (state, actions) => {
      const {
        settings,
        basket,
        filter,
        customer,
        storedetails,
        filterItems,
        searchItems,
        takeAwayOpen,
        takeAwayClosed,
        homeLoader,
      } = actions.payload;
      state.settings = settings ?? state.settings;
      state.basket = basket ?? state.basket;
      state.filter = filter ?? state.filter;
      // state.storedetails = storedetails ?? {};
      state.storedetails = storedetails ?? state.storedetails;
      state.customer = customer ?? state.customer;
      state.bookmarkStores = customer ?? state.bookmarkStores;
      state.filterItems = filterItems ?? state.filterItems;
      state.takeAwayOpen = takeAwayOpen ?? state.takeAwayOpen;
      state.takeAwayClosed = takeAwayClosed ?? state.takeAwayClosed;
      state.searchItems = searchItems ?? state.searchItems;
      state.homeLoader = homeLoader ?? state.homeLoader;
    },
    setBasicData: (state, actions) => {
      const { basket, settings, storedetails } = actions.payload;
      state.settings = settings;
      state.storedetails = storedetails;
      state.basket = basket;
    },
    getCuisinesData: (state, actions) => {
      state.cuisines = actions.payload.cuisines;
    },
    getTags: (state, actions) => {
      state.tags = actions.payload.tags;
    },
    getOffers: (state, actions) => {
      state.offers = actions.payload.offers;
    },
    setBasketData: (state, actions) => {
      state.basket = actions.payload;
    },
    setCustomerData: (state, actions) => {
      state.customer = actions.payload;
    },
    setStoreData: (state, actions) => {
      state.pageContent = actions.payload;
    },
    setLocationData: (state, actions) => {
      state.locationData = actions.payload;
    },
    setFilterItems: (state, actions) => {
      state.filterItems = actions.payload;
    },
    setSearchText: (state, actions) => {
      state.searchText = actions.payload;
    },
    setTakeAwayOpen: (state, actions) => {
      state.takeAwayOpen = actions.payload;
    },
    setTakeAwayClosed: (state, actions) => {
      state.takeAwayClosed = actions.payload;
    },
    setSearchItems: (state, actions) => {
      state.searchItems = actions.payload;
    },
    setHomeLoader: (state, actions) => {
      state.homeLoader = actions.payload;
    },
    setStoreDetails: (state, actions) => {
      state.storedetails = actions.payload;
    },
  },
});

export const basicDataReducer = basicDataSlice.reducer;
export const {
  setBasicDineData,
  setBasicData,
  getCuisinesData,
  getTags,
  getOffers,
  setBasketData,
  setStoreDetails,
  setCustomerData,
  setStoreData,
  setLocationData,
  setFilterItems,
  setSearchText,
  setTakeAwayOpen,
  setTakeAwayClosed,
  setSearchItems,
  setHomeLoader,
} = basicDataSlice.actions;
