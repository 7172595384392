import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { checkEmpty } from "../utils/helper";
import { useSelector } from "react-redux";
const useAnalyticsEvents = () => {
  const storeDetails = useSelector((state) => state.basicdata.storedetails);
  const analyticsSetup = useSelector(
    (state) => state.analyticsData.analyticsSetup
  );
  const analyticsEvents = async (evnt, eventData) => {
    if (evnt == "Purchase") {
      var gEvent = {};
      gEvent.transactionId = eventData?.pos_basket_Id;
      gEvent.transactionAffiliation = "";
      gEvent.transactionTotal = eventData?.pos_basket_Total;
      gEvent.transactionProducts = [];
      var vproducts = {};
      for (var k in eventData?.basketProducts) {
        vproducts = {};
        vproducts.sku = eventData?.basketProducts[k]?.inventory_product_Id;
        vproducts.name = eventData?.basketProducts[k]?.pos_basketproduct_Name;
        vproducts.price =
          eventData?.basketProducts[k]?.pos_basketproduct_Total_Price;
        vproducts.quantity =
          eventData?.basketProducts[k]?.pos_basketproduct_Quantity;
        gEvent.transactionProducts.push(vproducts);
      }
      gEvent.revenue = eventData?.pos_basket_Total;
      gEvent.uniqueEventId = eventData?.pos_basket_Id;
      gEvent.value = eventData?.pos_basket_Total;
      gEvent.currency = "GBP";
      eventData = gEvent;
    }
    if (!checkEmpty(storeDetails)) {
      eventData.store_Name = storeDetails?.store_Name;
    }

    if (analyticsSetup.gtm == 1) {
      if (evnt == "Purchase") {
        evnt = "ecomm_event";
      }
      ReactGA.event(evnt, eventData);
    }
    if (analyticsSetup.pxl == 1) {
      if (evnt == "ecomm_event") {
        evnt = "Purchase";
      }
      ReactPixel.trackCustom(evnt, eventData);
    }
  };

  return analyticsEvents;
};

export default useAnalyticsEvents;
