import { useEffect } from "react";
import RoutePaths from "./routes/Routes";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const body = document.querySelector("#root");

    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);

  useEffect(() => {
    dispatch({
      type: "BASIC_DINE_DATA",
    });
  }, []);

  return <RoutePaths />;
}

export default App;
