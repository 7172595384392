import { useLayoutEffect } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import {
  setBasicDineData,
  setHomeLoader,
  setLocationData,
} from "../store/basicData/basicDataSlice";
import Home from "../pages/home";
import LandingPage from "../pages/landing";
import Menu from "../pages/menu";
// import Restaurants from "../pages/restaurant/Restaurants";
import TrackFood from "../pages/trackfood";
import Checkout from "../pages/checkout";
import Profile from "../pages/profile/Profile";
import Bookmarks from "../pages/profile/Bookmarks";
import BankTab from "../pages/profile/BankTab";
import PastOrders from "../pages/profile/PastOrders";
import Preference from "../pages/profile/Preference";
import Wallet from "../pages/profile/wallet";
import Login from "../pages/auth/Login";
import Address from "../pages/profile/Address";
import Rewards from "../pages/profile/Rewards";
import Offers from "../pages/offer";
import RootLayout from "../layout/RootLayout";
import ProfileLayout from "../layout/ProfileLayout";
import HomeLayout from "../layout/HomeLayout";
import { getBasicDineData } from "../services";
import { getDineConfigSession, setCartConfigSession } from "../utils";
import { setUserAddress, setUserData } from "../store/userData/userDataSlice";
import FaqPage from "../pages/faq";
import MotionWrapper from "../components/Animation/MotionWrapper"; // Import the MotionWrapper
import TermsOfUse from "../pages/termsofuse";
import TermsConditions from "../pages/termsconditions";
import PrivacyPolicy from "../pages/privacypolicy";
import ScrollToTop from "../components/Navigation/ScrollToTop";
import About from "../pages/about";
import UnsubscribeModal from "../components/Modal/UnsubscribeModal";
import Payment from "../pages/payment";
import ErrorFallBack from "../ErrorFallBack";
import Confirm from "../pages/confirm";
import useSetupGAnalytics from "../hooks/useSetupGAnalytics";
import useSetupFAnalytics from "../hooks/useSetupFAnalytics";
import PaymentProcess from "../pages/paymentprocess";
import AppPrivacyPolicy from "../pages/appprivacypolicy";
import Privacy from "../pages/privacy";
import AppRefundPolicy from "../pages/apprefundpolicy";

const logError = (error, info) => {
  // Do something with the error, e.g. log to an external API
  console.log("=======ERORR=========", error, "------info------", info);
};

let router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="/"
        element={
          <ErrorBoundary FallbackComponent={ErrorFallBack} onError={logError}>
            <RootLayout />
          </ErrorBoundary>
        }
      >
        <Route
          index
          element={
            <MotionWrapper>
              <LandingPage />
            </MotionWrapper>
          }
        />
        <Route
          path="/takeaways/*"
          element={
            <MotionWrapper>
              <Home />
            </MotionWrapper>
          }
        />

        {/* <Route path="home" element={<Outlet />}> */}
        <Route path="stores" element={<Outlet />}>
          <Route
            index
            element={
              <MotionWrapper>
                <HomeLayout />
              </MotionWrapper>
            }
          />
          <Route
            path="offers"
            element={
              <MotionWrapper>
                <Offers />
              </MotionWrapper>
            }
          />
          {/* <Route path="restaurants" element={<Outlet />}>
            <Route
              index
              element={
                <MotionWrapper>
                  <Restaurants />
                </MotionWrapper>
              }
            />
          </Route> */}
          <Route path=":id" element={<Outlet />}>
            <Route
              index
              element={
                // <MotionWrapper>
                <Home />
                // </MotionWrapper>
              }
            />
          </Route>
        </Route>
        <Route path="order" element={<Outlet />}>
          <Route path=":id" element={<Outlet />}>
            <Route path=":id" element={<Outlet />}>
              <Route
                index
                element={
                  // <MotionWrapper>
                  <Menu />
                  // </MotionWrapper>
                }
              />
            </Route>
          </Route>
          {/* <Route
            path="/store/orderstatus/*"
            element={
              // <MotionWrapper>
              <TrackFood />
              // </MotionWrapper>
            }
          /> */}
        </Route>
        <Route path="about" element={<Outlet />}>
          <Route path=":id" element={<Outlet />}>
            <Route path=":id" element={<Outlet />}>
              <Route
                index
                element={
                  // <MotionWrapper>
                  <About />
                  // </MotionWrapper>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route path="store" element={<Outlet />}>
          <Route path="cart" element={<Checkout />} />
          <Route path="payment" element={<Payment />} />

          <Route path="orderstatus" element={<Outlet />}>
            <Route path=":id" element={<Outlet />}>
              <Route index element={<TrackFood />} />
            </Route>
          </Route>
          <Route path="confirm" element={<Outlet />}>
            <Route path=":id" element={<Outlet />}>
              <Route index element={<Confirm />} />
            </Route>
          </Route>

          <Route path="paymentprocessing" element={<Outlet />}>
            <Route path=":id1" element={<Outlet />}>
              <Route path=":id2" element={<Outlet />}>
                <Route path=":id3" element={<Outlet />}>
                  <Route index element={<PaymentProcess />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="profile" element={<ProfileLayout />}>
          <Route index element={<Profile />} />

          <Route path="address" element={<Address />} />
          <Route path="bookmarks" element={<Bookmarks />} />
          <Route path="bankcards" element={<BankTab />} />
          <Route path="past-orders" element={<PastOrders />} />
          <Route path="rewards" element={<Rewards />} />
          <Route path="preference" element={<Preference />} />
          <Route path="wallet" element={<Wallet />} />
        </Route>

        <Route path="frequently/asked/questions" element={<Outlet />}>
          <Route index element={<FaqPage />} />
          <Route path=":faqId" element={<Outlet />}>
            <Route index element={<FaqPage />} />
          </Route>
        </Route>
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="uns" element={<UnsubscribeModal />} />
        <Route path="unsubscribe" element={<UnsubscribeModal />} />
      </Route>
      <Route path="frequently/asked/questions" element={<Outlet />}>
        <Route index element={<FaqPage />} />
        <Route path=":faqId" element={<Outlet />}>
          <Route index element={<FaqPage />} />
        </Route>
      </Route>
      <Route path="terms-of-use" element={<TermsOfUse />} />
      <Route path="terms-conditions" element={<TermsConditions />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="appprivacypolicy" element={<AppPrivacyPolicy />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="apprefundpolicy" element={<AppRefundPolicy />} />

      <Route
        path="/login"
        element={
          <MotionWrapper>
            <Login />
          </MotionWrapper>
        }
      />
      <Route
        path="/registration"
        element={
          <MotionWrapper>
            <Login />
          </MotionWrapper>
        }
      />
    </Route>
  )
);

const RoutePaths = () => {
  const dispatch = useDispatch();
  const setupGAnalytics = useSetupGAnalytics();
  const setupFAnalytics = useSetupFAnalytics();
  useLayoutEffect(() => {
    getDineData();
  }, []);

  const getDineData = async () => {
    const { status, data } = await getBasicDineData();
    if (status === 200) {
      dispatch(setHomeLoader(false));
      setCartConfigSession(
        data?.cartConfig ?? {
          pos_basket_Delivery_Type: "Collection",
          pos_basket_Payment_Type: "Card",
          pos_basket_Id: 0,
        }
      );
      if (data?.customer?.length) {
        dispatch(setUserData(data?.customer));
      }
      if (data?.customer?.common_customer_Address?.length) {
        dispatch(
          setUserAddress({
            selected: data?.customer?.common_customer_Address[0],
            address: data?.customer?.common_customer_Address,
          })
        );
      }
      dispatch(setBasicDineData(data));
      dispatch(
        setLocationData(
          getDineConfigSession()?.locationData ?? {
            latitude: "53.048988",
            longitude: "-2.198013",
            cityName: "Stoke-on-trent",
            street: "Scotia Road",
            county: "",
            frm: "addr",
            postcodePretty: "st6 4ep",
          }
        )
      );
      setupGAnalytics(data);
      setupFAnalytics(data);
    }
  };

  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
};

export default RoutePaths;
