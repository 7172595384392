import { useEffect, useState } from "react";
import ArrowRightChevron from "../../../components/Icon/ArrowRightChevron";
import ArrowLeftChevron from "../../../components/Icon/ArrowLeftChevron";
import AboutInfo from "./aboutInfo";
import AboutCollectionStart from "./aboutCollectionStart";
import AboutDeliveryStart from "./aboutDeliveryStart";
import AboutReview from "./aboutReview";
import AboutFeedback from "./aboutFeedback";

const AboutOptions = ({ className, storeData, restaurantInfo }) => {
  const [option, setOption] = useState("");
  const [isViewList, setIsViewList] = useState(true);

  const optionRedirector = () => {
    if (option === "About") {
      return (
        <AboutInfo
          storeData={storeData}
          onSendData={isBackButtonPressed}
          restaurantInfo={restaurantInfo}
        />
      );
    } else if (option == "Delivery") {
      return (
        <AboutDeliveryStart
          onSendData={isBackButtonPressed}
          restaurantInfo={restaurantInfo}
        />
      );
    } else if (option == "Collection") {
      return (
        <AboutCollectionStart
          onSendData={isBackButtonPressed}
          restaurantInfo={restaurantInfo}
        />
      );
    } else if (option == "Reviews") {
      return <AboutReview onSendData={isBackButtonPressed} />;
    } else if (option === "Feedback") {
      return (
        <AboutFeedback
          onSendData={isBackButtonPressed}
          reviewCardSelector={reviewCardSelect}
        />
      );
    }
    return null;
  };

  const isBackButtonPressed = (data) => {
    setIsViewList(data);
  };

  const reviewCardSelect = (data) => {
    setOption(data);
  };

  return (
    <div className="overflow-auto no-scrollbar">
      <div
        className={`flex flex-col cursor-pointer pb-32 sm:pb-12 ${className} ${
          isViewList ? "block" : "hidden"
        }`}
      >
        <div
          className="h-9 mt-5 mb-8 md:mt-10 md:mb-10 flex flex-column items-center justify-between"
          onClick={() => {
            setIsViewList(false);
            setOption("About");
          }}
        >
          <div className="">
            <div className="text-sm md:text-md font-inter font-medium">
              About {storeData?.store_Name}
            </div>
            <div className="text-xs md:text-md font-inter text-loyalityDate">
              Do you want to know about us?
            </div>
          </div>
          <div>
            <ArrowRightChevron className="w-3 h-3 md:h-[14.3px] md:w-[14.3px]" />
          </div>
        </div>
        <div className="border-b"></div>
        <div
          className="h-[35px] mt-7 mb-8 md:mt-8 md:mb-10 flex flex-column items-center justify-between"
          onClick={() => {
            setIsViewList(false);
            setOption("Delivery");
          }}
        >
          <div className="">
            <div className="text-[13.5px] md:text-[16px] font-inter font-medium">
              Delivery Start Time
            </div>
            <div className="text-[12.6px] md:text-[15px] font-inter text-loyalityDate">
              Checkout when we start delivery.
            </div>
          </div>
          <div>
            <ArrowRightChevron className="w-3 h-3 md:h-[14.3px] md:w-[14.3px]" />
          </div>
        </div>
        <div className="border-b"></div>
        <div
          className="h-[35px] mt-7 mb-8 md:mt-8 md:mb-10 flex flex-column items-center justify-between"
          onClick={() => {
            setIsViewList(false);
            setOption("Collection");
          }}
        >
          <div className="">
            <div className="text-[13.5px] md:text-[16px] font-inter font-medium">
              Collection Start Time
            </div>
            <div className="text-[12.6px] md:text-[15px] font-inter text-loyalityDate">
              Checkout when we start collection.
            </div>
          </div>
          <div>
            <ArrowRightChevron className="w-3 h-3 md:h-[14.3px] md:w-[14.3px]" />
          </div>
        </div>
        <div className="border-b"></div>
        <div
          className="h-[35px] mt-7 mb-8 md:mt-8 md:mb-10 flex flex-column items-center justify-between"
          onClick={() => {
            setIsViewList(false);
            setOption("Reviews");
          }}
        >
          <div className="">
            <div className="text-[13.5px] md:text-[16px] font-inter font-medium">
              Our Reviews
            </div>
            <div className="text-[12.6px] md:text-[15px] font-inter text-loyalityDate">
              Read what others said about us.
            </div>
          </div>
          <div>
            <ArrowRightChevron className="w-3 h-3 md:h-[14.3px] md:w-[14.3px]" />
          </div>
        </div>
        <div className="border-b"></div>
        <div
          className="h-[35px] mt-7 mb-8 md:mt-8 md:mb-10 flex flex-column items-center justify-between"
          onClick={() => {
            setIsViewList(false);
            setOption("Feedback");
          }}
        >
          <div className="">
            <div className="text-[13.5px] md:text-[16px] font-inter font-medium">
              Give Feedback
            </div>
            <div className="text-[12.6px] md:text-[15px] font-inter text-loyalityDate">
              Let us know what&apos;s in your mind
            </div>
          </div>
          <div>
            <ArrowRightChevron className="h-3 w-3 md:h-[14.3px] md:w-[14.3px]" />
          </div>
        </div>
      </div>

      {/* {!isViewList && option != "Feedback" && (
        <div onClick={() => setIsViewList(true)}>
          <ArrowLeftChevron className="w-[12px] h-[12px]" />
        </div>
      )} */}
      {!isViewList && <div>{optionRedirector()}</div>}
    </div>
  );
};

export default AboutOptions;
