import React, { useState, useEffect, useRef } from "react";

import Location from "../../assets/images/my_location.png";
import { error } from "../notify";
import { getLocationFromLatLng } from "../../services";
import {
  GoogleMap,
  OverlayView,
  LoadScript,
  DirectionsService,
  DirectionsRenderer,
  useJsApiLoader,
  Polyline,
  Marker,
  InfoWindow,
  useLoadScript 
} from "@react-google-maps/api";
import {
  prepareAddressFormat,
  checkEmpty,
  prepareOrderTracing,
  formatTotal,
  formatTimeAMPMDayMonthYear,
} from "../../utils/helper";

import GreenRoundMarker from "../../components/Icon/GreenRoundMarker";
import GreenBellMarker from "../../components/Icon/GreenBellMarker";
import RedRoundMarker from "../../components/Icon/RedRoundMarker";
import RedBellMarker from "../../components/Icon/RedBellMarker";
import { number } from "prop-types";

const TrackMap = React.memo(
  ({ defaultCenter, pathCoordinates, start, end }) => {
    const mapRef = useRef(null);
    const mapStyles = {
      height: "600px",
      width: "100%",
    };
    const [endnew, setendnew] = useState();
    const [startnew, setstartnew] = useState();
    const [distance, setDistance] = useState(null);
    const [zoom, setZoom] = useState(8); // Default zoom level

    const onMapLoad = (map) => {
      mapRef.current = map;
      let calculateExtendedstartPoint;
      let calculateExtendedendPointss;
      if (
        !checkEmpty(end) &&
        end.lat != 0 &&
        end.lng != 0 &&
        !checkEmpty(start) &&
        start.lat != 0 &&
        start.lng != 0
      ) {
        let calculateBearings = calculateBearing(
          start.lat,
          start.lng,
          end.lat,
          end.lng
        );
        setTimeout(() => {
          calculateExtendedstartPoint = calculateExtendedPoint(
            start.lat,
            start.lng,
            calculateBearings,
            -350
          );
          setstartnew(calculateExtendedstartPoint);

          calculateExtendedendPointss = calculateExtendedPoint(
            end.lat,
            end.lng,
            calculateBearings,
            -350
          );
          setendnew(calculateExtendedendPointss);
        }, 1000);
      }

      setTimeout(() => {
        if (
          !checkEmpty(end) &&
          end.lat != 0 &&
          end.lng != 0 &&
          !checkEmpty(start) &&
          start.lat != 0 &&
          start.lng != 0
        ) {
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(calculateExtendedstartPoint);
          bounds.extend(calculateExtendedendPointss);
          // Fit the map to the bounds of these two lat/lng points
          map.fitBounds(bounds);

          // Store the map reference

          const zoomLevel = calculateZoom(
            calculateExtendedstartPoint.lat,
            calculateExtendedstartPoint.lng,
            calculateExtendedendPointss.lat,
            calculateExtendedendPointss.lng,
            100, // Map width in pixels
            600 // Map height in pixels
          );

          // Update the zoom level to fit both points
          setZoom(zoomLevel);
        }
      }, 1000);
    };

    const calculateZoom = (lat1, lng1, lat2, lng2, mapWidth, mapHeight) => {
      // Approximation of Earth's radius in meters
      const EARTH_RADIUS = 6371000;

      // Convert degrees to radians
      const toRadians = (deg) => deg * (Math.PI / 180);

      const lat1Rad = toRadians(lat1);
      const lat2Rad = toRadians(lat2);
      const deltaLatRad = toRadians(lat2 - lat1);
      const deltaLngRad = toRadians(lng2 - lng1);

      // Haversine formula to calculate the distance between the two points in meters
      const a =
        Math.sin(deltaLatRad / 2) * Math.sin(deltaLatRad / 2) +
        Math.cos(lat1Rad) *
          Math.cos(lat2Rad) *
          Math.sin(deltaLngRad / 2) *
          Math.sin(deltaLngRad / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = EARTH_RADIUS * c;

      // Using a pre-defined zoom to distance ratio to calculate zoom
      const distanceToZoom = 156543.03392; // This is a general approximation for zoom level at the equator

      // Find the zoom level that would fit the distance to the map's size
      const zoom = Math.floor(
        Math.log2((mapWidth * 156543.03392) / (distance * 2))
      );

      if (zoom >= 13) {
        return 15;
      } else {
        return zoom;
      }
    };

    const onUnmount = () => {
      mapRef.current = null;
    };
    const [startinfo, setstartinfo] = useState(true);
    const [endinfo, setendinfo] = useState(true);

    const startFun = () => {
      setstartinfo(startinfo == true ? false : true);
    };

    const endFun = () => {
      setendinfo(endinfo == true ? false : true);
    };

    const toRadians = (degree) => degree * (Math.PI / 180);

    const toDegrees = (radian) => radian * (180 / Math.PI);

    const R = 6371000; // in meters

    const calculateBearing = (startLat, startLng, endLat, endLng) => {
      const lat1 = toRadians(startLat);
      const lat2 = toRadians(endLat);
      const dLng = toRadians(endLng - startLng);

      const y = Math.sin(dLng) * Math.cos(lat2);
      const x =
        Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLng);
      const bearing = Math.atan2(y, x);

      return bearing;
    };

    const calculateExtendedPoint = (lat, lng, bearing, distance) => {
      const angularDistance = distance / R; // Angular distance in radians

      const lat1 = toRadians(lat);
      const lng1 = toRadians(lng);

      const lat2 = Math.asin(
        Math.sin(lat1) * Math.cos(angularDistance) +
          Math.cos(lat1) * Math.sin(angularDistance) * Math.cos(bearing)
      );

      const lng2 =
        lng1 +
        Math.atan2(
          Math.sin(bearing) * Math.sin(angularDistance) * Math.cos(lat1),
          Math.cos(angularDistance) - Math.sin(lat1) * Math.sin(lat2)
        );

      return {
        lat: Number(toDegrees(lat2).toFixed(5)),
        lng: Number(toDegrees(lng2).toFixed(5)),
      };
    };

    const mapOptions = {
      gestureHandling: "none", // 'cooperative', 'greedy', 'none', or 'auto'
      mapTypeControl: false,
      streetViewControl: false,
      keyboardShortcuts: false,
    };

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: import.meta.env.VITE_MAP_KEY, // Using Vite environment variable
     
    });

    if (!isLoaded) {
      return (
        <div className={`${!isLoaded? 'text-white-shade-4':''}`}>
          {/* Replace this with your custom loading message, spinner, or component */}
          <h2>.</h2> 
        </div>
      );
    }

    return (
      <div className={`${!isLoaded? 'text-white-shade-4':''}`}>
            <GoogleMap
              onLoad={onMapLoad}
              onUnmount={onUnmount}
              mapContainerStyle={mapStyles}
              center={defaultCenter}
              zoom={13}
              options={mapOptions}
            >
              {!checkEmpty(end) &&
                end.lat != 0 &&
                end.lng != 0 &&
                !checkEmpty(start) &&
                start.lat != 0 &&
                start.lng != 0 && (
                  <Polyline
                    path={pathCoordinates}
                    options={{
                      strokeColor: "#000000", // Red line
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    }}
                  />
                )}

              {!checkEmpty(end) && end.lat != 0 && end.lng != 0 && (
                <OverlayView
                  position={end}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  //  getPixelPositionOffset={() => getPixelPositionOffset(40, 40)}
                >
                  <div className="w-[200px] absolute top-[-15px] left-[-15px]">
                    <div
                      className="mb-1"
                      onClick={() => {
                        endFun();
                      }}
                    >
                      <RedRoundMarker />
                    </div>
                    {endinfo == true ? (
                      <>
                        <div>
                          <RedBellMarker />
                        </div>
                        <div className="	text-sx	  text-center absolute top-[70px] md:top-[78px] left-[-65px] lg:left-[-90px] xl:left-[-60px] w-[150px] h-auto  py-2 px-2 font-[600] rounded-full 	bg-white shadow-2xl flex items-center justify-center	">
                          {end.name}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </OverlayView>
              )}

              {!checkEmpty(start) && start.lat != 0 && start.lng != 0 && (
                <OverlayView
                  position={start}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  //  getPixelPositionOffset={() => getPixelPositionOffset(40, 40)}
                >
                  <div className="w-[200px] absolute top-[-15px] left-[-15px]">
                    <div
                      className="mb-1"
                      onClick={() => {
                        startFun();
                      }}
                    >
                      <GreenRoundMarker />
                    </div>
                    {startinfo == true ? (
                      <>
                        <div>
                          <GreenBellMarker />
                        </div>
                        <div className=" text-center absolute top-[65px] md:top-[75px] left-[-65px] lg:left-[-90px] xl:left-[-60px] w-max-[350px] h-auto py-2 px-2 font-[600] rounded-full text-xs	bg-white shadow-2xl flex items-center justify-center	">
                          {start.name}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </OverlayView>
              )}
            </GoogleMap>
        
      </div>
      
    );
  }
);

export default TrackMap;
