export default function PreferencesLoader({ className }) {
  return (
    <div className={`animate-pulse ${className}`}>
      <div className="sm:bg-neutral-100 sm:w-full sm:rounded-lg relative">
        <div className="grid grid-cols-1 gap-4">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="h-12 md:h-16 bg-gray-300 rounded-lg"
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
