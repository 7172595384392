import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isFilterLoaded: false
}

const apiLoaderData = createSlice({
    name: "apiLoaderData",
    initialState,
    reducers: {
        setIsFilterLoaded(state, actions){
            state.isFilterLoaded = actions.payload;
        },
    }
})

export const apiLoaderDataReducer = apiLoaderData.reducer;
export const { setIsFilterLoaded } = apiLoaderData.actions;