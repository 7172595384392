import { createSlice } from "@reduxjs/toolkit";
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK";

const initialState = {
  pageContent: {},
  orders: {},
  status: "",
  msg: "",
};

const profileDataSlice = createSlice({
  name: "profileData",
  initialState,
  reducers: {
    setProfileData: (state, actions) => {
      state.pageContent = actions.payload;
    },
  },
});

const walletDataSlice = createSlice({
  name: "walletData",
  initialState,
  reducers: {
    setWalletData: (state, actions) => {
      state = actions.payload;
    },
  },
});


export const profileDataReducer = profileDataSlice.reducer;
export const walletDataReducer = walletDataSlice.reducer;


export const { setProfileData } = profileDataSlice.actions;
export const { setWalletData } = walletDataSlice.actions;
