import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import Button from "./components/Elements/Button";
import { LandingPage } from "./routes/RoutesPath";
import ErrorHandle from "./assets/images/MainError.svg";

const ErrorFallBack = ({}) => {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  useEffect(() => {
    // let intervalId = setInterval(() => handleHomeClick(), 20000);

    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);

  const handleHomeClick = () => {
    // localStorage.clear();
    resetBoundary();
    navigate(LandingPage);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center h-screen gap-5">
      <img
        className="mdl:w-[614px] mdl:h-[614pxpx]"
        src={ErrorHandle}
        alt="error image"
      />
      <span className="text-primary font-medium text-lg md:text-p6">
        Something went wrong! ...
      </span>
      <div>
        <Button
          onClick={() => {
            handleHomeClick();
          }}
          className="w-full md:w-max px-6 py-2 md:py-4 text-white text-md font-semibold"
          type={"primary"}
        >
          <span>Go to Home Page</span>
        </Button>
      </div>
    </div>
  );
};

export default ErrorFallBack;
