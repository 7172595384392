import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "../../components/Loader/MenuLoaders/SubHeader";
import GreaterThan from "../../assets/images/chevron-right.png";
import {
  capitalizeFirstWord,
  cardHolderNameRegex,
  cardNumberRegex,
  CVVRegex,
  dateRegex,
  setCartConfigSession,
} from "../../utils";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Layout from "../../layout/Layout";
import {
  CheckoutPage,
  ConfirmPage,
  HomePage,
  LandingPage,
  MenuPage,
  PaymentProcessingPage,
} from "../../routes/RoutesPath";
import MenuLoader from "../../components/Loader/MenuLoaders/MenuLoader";
import MenuBanner from "../menu/MenuBanner";
import Cart from "../../components/Elements/Cart";
import {
  getBasicData,
  initCardPaymentData,
  paymentData,
  processCardPaymentData,
} from "../../services";
import { checkEmpty } from "../../utils/helper";
import mastercard from "../../assets/images/payment_cards/Mastercard.svg";
import PayByCard from "../../components/Icon/PayByCard";
import ApplePay from "../../components/Icon/ApplePay";
import GooglePay from "../../components/Icon/GooglePay";
import ArrowLeftChevron from "../../components/Icon/ArrowLeftChevron";
import AddNew from "../../components/Icon/AddNew";
import InputField from "../../components/Elements/InputField";
import { Button } from "@material-tailwind/react";
import { error, success } from "../../components/notify";
import useBasketValidateAction from "../../hooks/useBasketValidate";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import PaymentIframe from "./PaymentIframe";
import MenuCards from "../../components/Loader/MenuLoaders/MenuCards";
import CartLoader from "../../components/Loader/MenuLoaders/CartLoader";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import useAnalyticsEvents from "../../hooks/useAnalyticsEvents";

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);
  const basketValidateAction = useBasketValidateAction();
  const storeDetails = useSelector((state) => state?.basicdata?.storedetails);
  const storeSlug = storeDetails?.store_Slug;
  const productId = storeDetails?.product_Id;
  const storeName = storeDetails?.store_Name;
  const storeCity = storeDetails?.store_City;
  const storeBannerImage = storeDetails?.store_Banner_Image;

  const [storeData, setStoreData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTopBarPayment, setIsLoadingTopBarPayment] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [pageData, setPageData] = useState("");

  const [showPOTab, setShowPOTab] = useState(false);
  const [currentPOTab, setCurrentPOTab] = useState("pay_card"); //pay_card, pay_apple, pay_google
  const [addNewCard, setAddNewCard] = useState(true);
  const [cartInitData, setCardInitData] = useState({});

  const [cardHolderName, setCardHolderName] = useState("");
  const [cardHolderNameError, setCardHolderNameError] = useState(false);
  const [cardHolderNameErrorMsg, setCardHolderNameErrorMsg] = useState("");

  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardNumberErrorMsg, setCardNumberErrorMsg] = useState("");

  const [cardCVV, setCardCVV] = useState("");
  const [cardCVVError, setCardCVVError] = useState(false);
  const [cardCVVErrorMsg, setCardCVVErrorMsg] = useState("");

  const [cardExpiry, setCardExpiry] = useState("");
  const [cardExpiryError, setCardExpiryError] = useState(false);
  const [cardExpiryErrorMsg, setCardExpiryErrorMsg] = useState("");

  const [basketKey, setBasketKey] = useState("");
  const [prcData, setPrcData] = useState("");
  const [pf, setPf] = useState("");
  const [paymentFrameUrl, setPaymentFrameUrl] = useState("");
  const [paymentProcessingId1, setPaymentProcessingId1] = useState("");
  const [paymentProcessingId2, setPaymentProcessingId2] = useState("");
  const [paymentProcessingId3, setPaymentProcessingId3] = useState("");
  const [paymentFrameProcess, setPaymentFrameProcess] = useState("");
  const [message, setMessage] = useState("");
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const location = useLocation();
  const analyticsEvents = useAnalyticsEvents();
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaOgDescription = document.querySelector(
    'meta[property="og:description"]'
  );
  const metaOgTitle = document.querySelector('meta[property="og:title"]');

  const handleCardHolderName = (event) => {
    const thisValue = event.target.value;
    if (checkEmpty(thisValue)) {
      setCardHolderName(thisValue);
      setCardHolderNameError(true);
      setCardHolderNameErrorMsg("This field is required");
    } else {
      if (thisValue.length <= 50) {
        setCardHolderName(thisValue);
        if (cardHolderNameRegex.test(thisValue)) {
          setCardHolderNameError(false);
          setCardHolderNameErrorMsg("");
        } else {
          setCardHolderNameError(true);
          setCardHolderNameErrorMsg("Invalid format");
        }
      }
    }
  };

  const handleCardNumber = (e) => {
    const thisValue = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = thisValue.replace(/\D/g, "");
    if (inputNumbersOnly.length > 16) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 16);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" ");
    }
    setCardNumber(spacedNumber);
    setCardNumberError(checkEmpty(spacedNumber) ? true : false);
    setCardNumberErrorMsg(
      checkEmpty(spacedNumber) ? "This field is required" : ""
    );
  };

  const handleCardCVV = (event) => {
    const thisValue = event.target.value;
    const formattedInput = thisValue.replace(/\D/g, "");
    if (checkEmpty(formattedInput)) {
      setCardCVV("");
      setCardCVVError(true);
      setCardCVVErrorMsg("This field is required");
    } else if (formattedInput.length <= 4) {
      setCardCVV(formattedInput);
      setCardCVVError(false);
      setCardCVVErrorMsg("");
    }
  };

  const handleCardExpiry = (event) => {
    const thisValue = event.target.value;
    let formattedThisValue = thisValue.replace(/\D/g, "");

    if (checkEmpty(formattedThisValue)) {
      setCardExpiry("");
      setCardExpiryError(true);
      setCardExpiryErrorMsg("This field is required");
    } else if (formattedThisValue.length <= 4) {
      if (formattedThisValue.length > 4) {
        formattedThisValue = formattedThisValue.substr(0, 4);
      }
      const splits = formattedThisValue.match(/.{1,2}/g);
      let formattedThisValueSlashed = "";
      if (splits) {
        formattedThisValueSlashed = splits.join("/");
      }
      console.log(formattedThisValue, dateRegex.test(formattedThisValue));
      setCardExpiry(formattedThisValueSlashed);
      if (
        formattedThisValue.length == 4 &&
        !dateRegex.test(formattedThisValue)
      ) {
        setCardExpiryError(true);
        setCardExpiryErrorMsg("Invalid format");
      } else {
        setCardExpiryError(false);
        setCardExpiryErrorMsg("");
      }
    }
  };

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 80);
  };
  const getBasicDataAPI = async () => {
    try {
      const { status, data } = await getBasicData();
      if (status == 200) {
        if (data.status === "success") {
          if (!checkEmpty(data?.storedetails)) {
            setStoreData(data?.storedetails);
          } else if (
            checkEmpty(data?.storedetails) &&
            checkEmpty(data?.storedetails?.product_Id)
          ) {
            navigate(LandingPage);
          }
          if (
            checkEmpty(data?.basket?.pos_basket_Id) ||
            data?.basket?.status == "Invalid"
          ) {
            dispatch(setBasketData(data?.basket));
            setCartConfigSession(data?.cartConfig);
            if (!checkEmpty(storeSlug) && !checkEmpty(productId)) {
              navigate(MenuPage(storeSlug, productId));
            } else {
              navigate(LandingPage);
            }
          }
        }
      }
    } catch (error) {
      console.error("error msg", error);
    } finally {
    }
  };
  const getPaymentPageContentData = async () => {
    try {
      const { status, data } = await paymentData();
      if (status === 200) {
        if (data.status === "success") {
          setPageData(data);
        }
      }
    } catch (error) {
      console.error("error msg", error);
    } finally {
    }
  };
  const initCardPayment = async () => {
    try {
      const { status, data } = await initCardPaymentData();
      if (status === 200) {
        if (data.status === "success") {
          setCardInitData(data);
          dispatch(setBasketData(data?.basket));
          setCartConfigSession(data?.cartConfig);
          if (data.paymentmode == "Cash") {
            navigate(CheckoutPage());
          } else {
            if (data.gateway == "InFrame") {
              submitpaymentForm();
            } else {
              if (checkEmpty(data?.basketdata?.registrations)) {
                //show saved cards
              } else {
                // hide saved cards
              }
              // if (paymentstep.newCard) {
              //   //show add card form
              // }
            }
          }
        } else if (data?.status == "error") {
          if (!checkEmpty(data?.err_type)) {
            basketValidateAction(data);
          }
        }
      }
    } catch (error) {
      console.error("error msg", error);
    } finally {
    }
  };
  const clearPaymentFormError = async () => {
    setCardHolderNameError(false);
    setCardHolderNameErrorMsg("");

    setCardNumberError(false);
    setCardNumberErrorMsg("");

    setCardCVVError(false);
    setCardCVVErrorMsg("");

    setCardExpiryError(false);
    setCardExpiryErrorMsg("");
  };
  const submitpaymentForm = async (e) => {
    e?.preventDefault();
    let hasError = false;
    if (checkEmpty(cardHolderName)) {
      hasError = true;
      setCardHolderNameError(true);
      setCardHolderNameErrorMsg("This field is required");
    } else if (!cardHolderNameRegex.test(cardHolderName)) {
      hasError = true;
      setCardHolderNameError(true);
      setCardHolderNameErrorMsg("Invalid format");
    }

    if (checkEmpty(cardNumber)) {
      hasError = true;
      setCardNumberError(true);
      setCardNumberErrorMsg("This field is required");
    } else if (!cardNumberRegex.test(cardNumber)) {
      hasError = true;
      setCardNumberError(true);
      setCardNumberErrorMsg("Invalid format");
    }

    if (checkEmpty(cardCVV)) {
      hasError = true;
      setCardCVVError(true);
      setCardCVVErrorMsg("This field is required");
    } else if (!CVVRegex.test(cardCVV)) {
      hasError = true;
      setCardCVVError(true);
      setCardCVVErrorMsg("Invalid format");
    }

    let thisCardExpiry = cardExpiry.replace(/\D/g, "");
    if (checkEmpty(thisCardExpiry)) {
      hasError = true;
      setCardExpiryError(true);
      setCardExpiryErrorMsg("This field is required");
    } else if (!dateRegex.test(thisCardExpiry)) {
      hasError = true;
      setCardExpiryError(true);
      setCardExpiryErrorMsg("Invalid format");
    }

    if (!hasError) {
      clearPaymentFormError();
      let paymentFormObj = {
        inputData: {
          cardholder_name: cardHolderName,
          card_number: cardNumber,
          expiry_date: cardExpiry,
          security_code: cardCVV,
          cardType: "New",
          card_Id: 0,
        },
      };
      setPaymentInProgress(true);
      submitPayment(paymentFormObj);
    }
  };
  const submitPayment = async (paymentFormObj) => {
    analyticsEvents("AddPaymentInfo", {
      page_path: location.pathname,
      payment_type: paymentFormObj.inputData.cardType,
    });
    if (!paymentInProgress) {
      setPaymentInProgress(true);
      paymentFormObj.inputData.cardholder_name =
        paymentFormObj.inputData.cardholder_name.trim();
      paymentFormObj.inputData.card_number =
        paymentFormObj.inputData.card_number.replace(/ /g, "");
      paymentFormObj.inputData.expiry_date =
        paymentFormObj.inputData.expiry_date.replace(/\//g, "");
      try {
        const { status, data } = await processCardPaymentData(paymentFormObj);
        if (status === 200) {
          if (data.status === "success") {
            setPrcData(data.pstatus);
            setBasketKey(data.pos_basket_Key);
            setPaymentProcessingId1(data.pstatus["id1"]);
            setPaymentProcessingId2(data.pstatus["id2"]);
            setPaymentProcessingId3(data.pstatus["id3"]);

            if (data.inv_status == 1) {
              navigate(MenuPage(storeSlug, productId));
            } else if (data.inv_status == 2) {
              navigate(ConfirmPage + data.pos_basket_Key);
            } else if (data.inv_status == 3) {
              navigate(ConfirmPage + data.pos_basket_Key);
            } else if (data.inv_status == 4) {
              setPf(data.pf);
              setPaymentFrameUrl(data.paymentFormUrl);
              setPaymentFrameProcess(true);
            }
          } else if (data.status == "valid_error") {
            data.status = "error";
          } else if (data.status == "error") {
            if (data.inv_status == 1) {
              navigate(MenuPage(storeSlug, productId));
            }
          }

          if (!checkEmpty(data.msg)) {
            if (data.status === "success") {
              success(data.msg);
            } else if (data.status === "error") {
              error(data.msg);
            }
          }
        }
      } catch (error) {
        console.log("error msg", error);
      } finally {
        setPaymentInProgress(false);
        setTimeout(() => {
          setIsLoadingTopBarPayment(false);
        }, 500);
      }
    }
  };
  useEffect(() => {
    if (isLoading || isLoadingTopBarPayment) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 1000);
      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading, isLoadingTopBarPayment]);
  useEffect(() => {
    if (!checkEmpty(storeName)) {
      document.title = `Payment | ${storeName} - ${
        import.meta.env.VITE_PRODUCT_NAME
      }`;
      metaOgTitle.setAttribute(
        "content",
        `Payment | ${storeName} - ${import.meta.env.VITE_PRODUCT_NAME}`
      );
      metaDescription.setAttribute(
        "content",
        `ssssOrder food online from ${storeName}, ${storeCity}. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
      metaOgDescription.setAttribute(
        "content",
        `ssssOrder food online from ${storeName}, ${storeCity}. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
    }
  }, [storeName, storeCity, storeBannerImage]);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  useEffect(() => {
    setIsLoading(true);
    getBasicDataAPI();
    getPaymentPageContentData();
    initCardPayment();
  }, [storeSlug, productId]);

  useEffect(() => {
    setCardHolderName(cartInitData?.basket?.pos_basket_Customer_First_Name);
    setCardNumber("5591 3900 0000 0645"); // challege 3ds - 5591 3900 0000 0645, no iframe success- 5591 3900 0000 0504, payment processing -
    setCardExpiry("12/24");
    setCardCVV("123");
  }, [cartInitData]);

  useEffect(() => {
    if (
      !checkEmpty(pageData) &&
      !checkEmpty(storeData) &&
      !checkEmpty(cartInitData)
    ) {
      setTimeout(() => {
        setIsLoadingTopBarPayment(false);
        setIsLoading(false);
      }, 300);
    }
  }, [pageData, storeData, cartInitData]);

  // This hook is listening an event coming from the Iframe
  useEffect(() => {
    const handler = (e) => {
      var message = e.data;
      setMessage(message);
      if (message == "pmtprocessing") {
        navigate(
          PaymentProcessingPage(
            paymentProcessingId1,
            paymentProcessingId2,
            paymentProcessingId3
          )
        );
      } else if (message == "pmtdecline") {
        error("Payment Declined");
        setPaymentFrameUrl("");
      } else if (message == "pmtconfirm") {
        navigate(ConfirmPage + basketKey);
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [
    basketKey,
    paymentFrameUrl,
    paymentProcessingId1,
    paymentProcessingId2,
    paymentProcessingId3,
  ]);
  return (
    <div>
      <Layout
        className={` ${
          isFixed
            ? "fixed animate-fadedown top-[76px] left-0 shadow-xl px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] overflow-hidden"
            : "animate-fadeup"
        } bg-white w-full z-50 mdl:flex items-center hidden`}
      >
        <Link to={HomePage} className="flex items-center cursor-pointer">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              {capitalizeFirstWord("Home")}
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </Link>
        <div
          onClick={() => {
            navigate(MenuPage(storeSlug, productId));
          }}
          className="flex items-center cursor-pointer"
        >
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              Menu
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </div>
        <div
          onClick={() => {
            navigate(CheckoutPage());
          }}
          className="flex items-center cursor-pointer"
        >
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              {capitalizeFirstWord("Cart")}
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </div>
        <div className="flex items-center">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-active-route">
              Payment
            </span>
          )}
        </div>
      </Layout>

      <div>
        {isLoading ? (
          <MenuLoader
            subClassName={`h-38 sm:h-56 md:h-56 lg:h-60 xl:h-72 relative -z-30`}
          />
        ) : (
          <MenuBanner
            storeSlug={storeSlug}
            productId={productId}
            setIsLoading={setIsLoading}
            storeData={storeData}
          />
        )}

        <div className="flex flex-col mdl:grid mdl:grid-cols-12 mdl:space-x-0 mdl:px-4 xl:px-[60px] menu-scroll sm:mt-6 mdl:mt-8">
          <div className="flex mdl:col-span-8 w-full ">
            {isLoading ? (
              <div className="flex flex-col items-center gap-y-4 w-full px-4 md:w-full md:px-5 mt-4 mdl:mt-0">
                <MenuCards />
                <MenuCards />
                <MenuCards />
                <MenuCards />
              </div>
            ) : (
              <div className="px-4 w-full md:w-full md:px-5 mt-4 mdl:mt-0">
                <div>
                  <div className="sm:w-full sm:flex">
                    <p className="text-black font-medium font-inter text-xl xl:text[22px] 2xl:text-[26px] pb-2">
                      Make Payment
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row">
                    {showPOTab && (
                      <div className="w-full md:w-[35%] lg:w-[290px] bg-[#f7f7f7] md:mr-7 p-6 py-10 rounded-lg">
                        <div className="group flex items-center md:justify-center border-b border-b-[#D7D7D7] pb-5 mb-5 cursor-pointer">
                          <div className="bg-white h-[53px] w-[53px] mr-4 rounded-full flex items-center justify-center border border-primary">
                            <PayByCard />
                          </div>
                          <div>
                            <p className="text-[22px] font-medium text-black mb-0 group-hover:text-primary transition-all duration-200 ease-in-out">
                              Pay by Card
                            </p>
                            <p className="text-dark-shade-8 font-inter mb-0 text-[15px]">
                              Pay with your card
                            </p>
                          </div>
                        </div>

                        <div className="group flex items-center md:justify-center border-b border-b-[#D7D7D7] pb-5 mb-5 cursor-pointer hidden">
                          <div className="bg-white h-[53px] w-[53px] mr-4 rounded-full flex items-center justify-center border border-[#BEBEC8]">
                            <ApplePay />
                          </div>
                          <div>
                            <p className="text-[22px] font-medium text-black mb-0 group-hover:text-primary transition-all duration-200 ease-in-out">
                              Apple Pay
                            </p>
                            <p className="text-dark-shade-8 font-inter mb-0 text-[15px]">
                              Pay with apple pay
                            </p>
                          </div>
                        </div>

                        <div className="group flex items-center md:justify-center cursor-pointer hidden">
                          <div className="bg-white h-[53px] w-[53px] mr-4 rounded-full flex items-center justify-center border border-[#BEBEC8]">
                            <GooglePay />
                          </div>
                          <div>
                            <p className="text-[22px] font-medium text-black mb-0 group-hover:text-primary transition-all duration-200 ease-in-out">
                              Google Pay
                            </p>
                            <p className="text-dark-shade-8 font-inter mb-0 text-[15px]">
                              Pay with google pay
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex-1 w-full">
                      {showPOTab && (
                        <div className="md:hidden flex items-center text-[#465157] mb-4">
                          <ArrowLeftChevron className="w-[12px] h-[12px]" />
                          <span className="ml-3">Back</span>
                        </div>
                      )}

                      {/* <p className="md:hidden text-[22px] font-medium text-black mb-0 group-hover:text-primary transition-all duration-200 ease-in-out">
                      Pay by Card
                    </p> */}
                      {showPOTab && (
                        <p className="text-[#465157] font-inter mb-0 text-[15px]">
                          {!addNewCard
                            ? `Select your card for payment`
                            : `Add new card details`}
                        </p>
                      )}

                      <div className="flex justify-between flex-wrap">
                        {!addNewCard ? (
                          <div className="mt-5">
                            <div className="w-full md:w-[48%] border-2 border-[#C4C4C4] rounded-lg flex items-center px-4 md:px-2 lg:px-5  py-9 mb-6 cursor-pointer hover:border-primary transition-all duration-200 ease-in-out">
                              <img
                                src={mastercard}
                                className="w-[73px] h-[45px] object-contain"
                              />
                              <div className="ml-4 flex-1">
                                <p className="max-w-[90%] text-[18px] font-medium text-dark-shade-8 mb-0  overflow-hidden text-ellipsis whitespace-nowrap">
                                  John Doe
                                </p>
                                <p className="text-dark-shade-8 font-inter mb-0 text-[14px]">
                                  **** 1234
                                </p>
                              </div>
                            </div>
                            <div className="w-full md:w-[48%] border-2 border-[#05B011] rounded-lg flex items-center justify-center px-4 md:px-2 lg:px-5 py-9 mb-6 cursor-pointer">
                              <AddNew />
                              <div className="ml-4">
                                <p className="max-w-[125px] text-black font-inter font-medium mb-0 text-[22px]">
                                  Add New
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {!checkEmpty(paymentFrameUrl) ? (
                              <PaymentIframe
                                paymentFrameUrl={paymentFrameUrl}
                              />
                            ) : (
                              <>
                                <div className="flex w-full flex-col sm:flex-wrap sm:flex-row">
                                  <div className="w-full sm:w-1/2 sm:pr-2 mb-4">
                                    <div className="relative">
                                      <InputField
                                        title="Cardholder Name"
                                        type="text"
                                        placeholder="John Doe"
                                        value={cardHolderName}
                                        onChange={handleCardHolderName}
                                        autoComplete="off"
                                        required={true}
                                      />
                                      {cardHolderNameError && (
                                        <span className="text-primary text-sm absolute top-0 right-0">
                                          {cardHolderNameErrorMsg}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-full sm:w-1/2 sm:pl-2 mb-4">
                                    <div className="relative">
                                      <InputField
                                        title="Card Number"
                                        type="text"
                                        placeholder="XXXX XXXX XXXX XXXX"
                                        value={cardNumber}
                                        onChange={handleCardNumber}
                                        autoComplete="off"
                                        required={true}
                                      />
                                      {cardNumberError && (
                                        <span className="text-primary text-sm absolute top-0 right-0">
                                          {cardNumberErrorMsg}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-full sm:w-1/2 sm:pr-2 mb-4">
                                    <div className="relative">
                                      <InputField
                                        title="Expiry"
                                        type="text"
                                        placeholder="MM/YY"
                                        value={cardExpiry}
                                        onChange={handleCardExpiry}
                                        autoComplete="off"
                                        required={true}
                                      />
                                      {cardExpiryError && (
                                        <span className="text-primary text-sm absolute top-0 right-0">
                                          {cardExpiryErrorMsg}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="w-full sm:w-1/2 sm:pl-2 mb-4">
                                    <div className="relative">
                                      <InputField
                                        title="CVC"
                                        type="text"
                                        placeholder="123"
                                        value={cardCVV}
                                        onChange={handleCardCVV}
                                        autoComplete="off"
                                        required={true}
                                      />
                                      {cardCVVError && (
                                        <span className="text-primary text-sm absolute top-0 right-0">
                                          {cardCVVErrorMsg}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex w-full flex-1">
                                  <div className="w-full gap-4 mt-6">
                                    <Button
                                      onClick={() => {
                                        submitpaymentForm();
                                      }}
                                      className={`text-white w-full bg-primary focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center cursor-pointer`}
                                    >
                                      Process Payment
                                    </Button>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="hidden mdl:block mdl:col-span-4">
            {isLoading ? (
              <CartLoader className={` relative mb-8`} />
            ) : (
              <div className={` relative mb-8 hidden mdl:block w-full`}>
                <Cart isPayment />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
