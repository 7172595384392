const Checkbox = ({ checked, onChange }) => {
  return (
    <div
      onClick={() => {
        onChange();
      }}
    >
      {checked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
        >
          <rect width="19" height="19" rx="4" fill="#54B080" />
          <path
            d="M13 7L7.5 12.5L5 10"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="18"
            height="18"
            rx="3.5"
            fill="white"
            stroke="#BBBBBB"
          />
        </svg>
      )}
    </div>
  );
};

export default Checkbox;
