const CheckoutAddressIcon = ({ className }) => {
  return (
    <svg
      className={`cursor-pointer ${className}`}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="28"
        cy="28"
        r="27"
        fill="white"
        stroke="#D8D8DF"
        strokeWidth="2"
      />
      <path
        d="M31 28C31 29.6569 29.6569 31 28 31C26.3431 31 25 29.6569 25 28C25 26.3431 26.3431 25 28 25C29.6569 25 31 26.3431 31 28Z"
        stroke="#7B7D8F"
        strokeWidth="1.5"
      />
      <path
        d="M37 27.8889C37 32.7981 31.375 39 28 39C24.625 39 19 32.7981 19 27.8889C19 22.9797 23.0294 19 28 19C32.9706 19 37 22.9797 37 27.8889Z"
        stroke="#7B7D8F"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default CheckoutAddressIcon;
