import { useEffect } from "react";
import Layout from "../../layout/Layout";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="overflow-hidden	h-max font-[inter]">
      <Layout className="carousel w-full bg-white space-x-2 lg:space-x-5 2xl:md:pb-8 xl:pd-8 md:pb-6 pb-7 xl:pl-[45px] pl-[15px] sm:pl-5 md:pl-[25px]">
        <div className=" w-full lg:px-40 lg:py-4">
          <div className="row">
            <h2 className="text-3xl	mb-2.5 mt-8 font-medium	">Privacy Policy</h2>
            <p className="mb-2.5 text-sm	text-gray-600">
              Kuick built app as a Free app. This SERVICE is provided by Kuick
              at no cost and is intended for use as is.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              This page is used to inform visitors regarding our policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use our Service.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. we will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible unless otherwise
              defined in this Privacy Policy.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              Information Collection and Use
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information, including but not limited to Full Name, Username,
              Password, Telephone, Mobile, Email, Postal Address. The
              information that we request will be retained by us and used as
              described in this privacy policy.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              The app does use third party services that may collect information
              used to identify you.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              Link to privacy policy of third party service providers used by
              the app
            </p>
            <p className="mb-2.5 text-sm text-blue-shade-3">
              <a
                href="https://www.google.com/policies/privacy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Google Play Services
              </a>
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">Log Data</p>
            <p className="mb-2.5 text-sm text-gray-600">
              we want to inform you that whenever you use our Service, in a case
              of an error in the app we collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              ("IP") address, device name, operating system version, the
              configuration of the app when utilizing our Service, the time and
              date of your use of the Service, and other statistics.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">Cookies</p>
            <p className="mb-2.5 text-sm text-gray-600">
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory. This Service does not use these
              "cookies" explicitly. However, the app may use third party code
              and libraries that use "cookies" to collect information and
              improve their services. You have the option to either accept or
              refuse these cookies and know when a cookie is being sent to your
              device. If you choose to refuse our cookies, you may not be able
              to use some portions of this Service.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              Service Providers
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              we may employ third-party companies and individuals due to the
              following reasons: To facilitate our Service; To provide the
              Service on our behalf; To perform Service-related services; or To
              assist us in analyzing how our Service is used. we want to inform
              users of this Service that these third parties have access to your
              Personal Information. The reason is to perform the tasks assigned
              to them on our behalf. However, they are obligated not to disclose
              or use the information for any other purpose.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">Security</p>
            <p className="mb-2.5 text-sm text-gray-600">
              we value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              Links to Other Sites
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. we have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              Children’s Privacy
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              These Services do not address anyone under the age of 13. we do
              not knowingly collect personally identifiable information from
              children under 13. In the case we discover that a child under 13
              has provided us with personal information, we immediately delete
              this from our servers. If you are a parent or guardian and you are
              aware that your child has provided us with personal information,
              please contact us so that we will be able to do necessary actions.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              Changes to This Privacy Policy
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              we may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. we will
              notify you of any changes by posting the new Privacy Policy on
              this page. These changes are effective immediately after they are
              posted on this page.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">Contact Us</p>
            <p className="mb-2.5 text-sm text-gray-600">
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              By email: support@Kuick.com, support@redoq.com
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Privacy;
