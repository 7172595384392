import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analyticsSetup: {
    gtm: 0,
    pxl: 0,
  },
};

const analyticsData = createSlice({
  name: "analyticsData",
  initialState,
  reducers: {
    setGTM: (state, actions) => {
      state.analyticsSetup.gtm = actions.payload;
    },
    setPXL: (state, actions) => {
      state.analyticsSetup.pxl = actions.payload;
    },
  },
});

export default analyticsData.reducer;
export const { setGTM, setPXL } = analyticsData.actions;
