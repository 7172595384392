import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Layout from "../../layout/Layout";
import FoodCard from "../../components/Cards/FoodCard";
import burgarImage from "../../assets/images/burger.png";
import sandwichImage from "../../assets/images/home/sandwich.png";
import Switch from "../../components/Elements/Switch";
import { sliderSettingsLeft, sliderSettingsRight } from "../../utils";
import { setDeliveryType } from "../../store/userData/userDataSlice";
import Slider from "react-slick";

const switchData = ["Delivery", "Collection"];

const HomeHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const deliveryType = useSelector((state) => state?.userdata?.deliveryType);
  const leftSliderRef = useRef(null);
  const rightSliderRef = useRef(null);
  const carousel = useRef(null);
  const carousel2 = useRef(null);
  const maxScrollHeight = useRef(0);
  const maxScrollHeight2 = useRef(0);

  const [scrollDirection, setScrollDirection] = useState("down");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [scrollDirection2, setScrollDirection2] = useState("down");

  useEffect(() => {
    maxScrollHeight2.current = carousel2.current
      ? carousel2.current.scrollHeight - carousel2.current.offsetHeight
      : 0;
    maxScrollHeight.current = carousel.current
      ? carousel.current.scrollHeight - carousel.current.offsetHeight
      : 0;
  }, []);

  useEffect(() => {
    maxScrollHeight2.current = carousel2.current
      ? carousel2.current.scrollHeight - carousel2.current.offsetHeight
      : 0;
    maxScrollHeight.current = carousel.current
      ? carousel.current.scrollHeight - carousel.current.offsetHeight
      : 0;
  }, [location.pathname]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollDirection === "down") {
        moveNext();
        movePrev2();
      } else {
        movePrev();
        moveNext2();
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [scrollDirection]);

  useEffect(() => {
    if (carousel.current !== null) {
      carousel.current.scrollTop = carousel.current.offsetHeight * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    if (carousel2.current !== null) {
      carousel2.current.scrollTop =
        carousel2.current.offsetHeight * currentIndex2;
    }
  }, [currentIndex2]);

  const isDisabled = (direction) => {
    if (direction === "prev") {
      return currentIndex <= 0;
    }
    if (direction === "next" && carousel.current !== null) {
      return (
        carousel.current.offsetHeight * currentIndex >= maxScrollHeight.current
      );
    }

    return false;
  };

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
      setScrollDirection("down");
    }
  };

  const movePrev2 = () => {
    if (currentIndex2 > 0) {
      setCurrentIndex2((prevState) => prevState - 1);
      setScrollDirection2("down");
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetHeight * currentIndex <= maxScrollHeight.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
      setScrollDirection("up");
    }
  };

  const moveNext2 = () => {
    if (
      carousel2.current !== null &&
      carousel2.current.offsetHeight * currentIndex2 <= maxScrollHeight2.current
    ) {
      setCurrentIndex2((prevState) => prevState + 1);
      setScrollDirection2("up");
    }
  };

  const handleSelectionFromLeftCild = (selection) => {
    if (selection == "upArrow") {
      leftSliderRef?.current?.slickNext();
    } else {
      leftSliderRef?.current?.slickPrev();
    }
  };

  const handleSelectionFromRightCild = (selection) => {
    if (selection == "upArrow") {
      rightSliderRef?.current?.slickNext();
    } else {
      rightSliderRef?.current?.slickPrev();
    }
  };

  return (
    <Layout
      className={`flex bg-bg-pink items-center h-[294px] sm:h-[520px] md:h-[580px] lg:h-[580px] xl:h-[701px] 3xl:h-[750px] px-4 sm:px-7 lg:px-10 justify-between mb-6 xl:mb-8 2xl:mb-10`}
    >
      {/* left section start */}
      <div className="w-3/5 sm:w-[50%] 2xl:w-[60%]">
        <div className="sm:flex sm:space-x-2">
          <div className="text-[24px] sm:text-[32px] lg:text-[32px] xl:text-[46px] 2xl:text-[75px] 3xl:text-[96px] min-[1920px]:text-8xl text-black font-bold">
            Kuick&nbsp;Online{" "}
            <span className="font-bold text-primary">Ordering</span>
          </div>
        </div>
        <div className="flex-col font-normal pb-3 space-y-0 sm:pb-8 xl:pb-12">
          <div className="text-loyalityDate leading-4 sm:leading-5 xl:leading-8 3xl:leading-normal text-[12px] sm:text-[14px] lg:text-[18px] xl:text-[26px] 3xl:text-[42px] xl:pt-2 sm:w-[350px] lg:w-[358px] xl:w-[500px] 2xl:w-[800px] xl:font-normal">
            Choose your current location to enable us to discover restaurants
            near you!
          </div>
        </div>
        {/* buttons start */}
        <Switch
          data={switchData}
          value={deliveryType}
          onChange={(data) => {
            console.log(data);
            dispatch(setDeliveryType(data));
          }}
          className={`sm:w-[93%] lg:w-[65%] xl:w-[70%] 2xl:w-[70%]`}
        />
        {/* buttons end */}
      </div>
      {/* left section start */}

      {/* right section starts */}
      <div className="flex justify-end w-2/5 sm:w-[50%] 2xl:w-2/5 md:w-[550px] xl:pr-0 2xl:pr-8 relative px-0 2xl:mb-[60px] ">
        <div className="block absolute sm:ml-16 md:ml-20 lg:ml-20 xl:ml-20 2xl:ml-20 lg:right-0 xl:right-0 sm:mt-3.5 md:mt-6 xl:-mt-6 2xl:mt-20 bg-gradient-to-tl from-primary-shade-2 to-primary-shade-3 w-[216px] sm:w-[250px] sm:h-[430px] min-[530px]:h-[229px] md:w-[265px] lg:h-[425px] lg:w-[310px] xl:w-[310px] xl:h-[470px] 2xl:w-[512px] 2xl:h-[640px] rounded-[30px]"></div>
        <Slider
          ref={leftSliderRef}
          {...sliderSettingsLeft}
          className="w-[155px] 2xl:w-[235px] hidden sm:block md:block lg:block xl:block 2xl:block lg:mr-2 2xl:mr-12 sm:mt-7 lg:mt-4 2xl:mt-10"
        >
          <div
            ref={carousel}
            style={{
              scrollBehavior: "smooth",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            className="block pt-10 sm:pt-14 md:pt-14 lg:pt-8 xl:pt-10 2xl:pt-24 sm:mr-[2.25rem] 2xl:mr-24 min-[530px]:space-y-8 sm:space-y-16 min-[530px]:h-[300px] min-[640px]:max-[700px]:h-[415px] sm:h-[423px] md:h-[415px] md:mt-4 2xl:h-[670px] min-[530px]:overflow-y-auto transform no-scrollbar"
          >
            <FoodCard
              image={burgarImage}
              title="Burger"
              content="Juicy Delight"
              sendSelectionToParent={handleSelectionFromLeftCild}
            />
            <FoodCard
              image={sandwichImage}
              title="Sandwich"
              content="Flavourful Comfort"
              sendSelectionToParent={handleSelectionFromLeftCild}
            />
          </div>
          <div
            ref={carousel}
            style={{
              scrollBehavior: "smooth",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            className="block pt-10 md:pt-14 sm:pt-14 lg:pt-8 xl:pt-10 2xl:pt-24 sm:mr-[2.25rem] md:mr-8 2xl:mr-24 min-[530px]:space-y-8 sm:space-y-16 min-[530px]:h-[300px] min-[640px]:max-[700px]:h-[415px] sm:h-[423px] md:h-[415px] md:mt-4 2xl:h-[670px] min-[530px]:overflow-y-auto transform no-scrollbar"
          >
            <FoodCard
              image={burgarImage}
              title="Burger"
              content="Juicy Delight"
              sendSelectionToParent={handleSelectionFromLeftCild}
            />
            <FoodCard
              image={sandwichImage}
              title="Sandwich"
              content="Flavourful Comfort"
              sendSelectionToParent={handleSelectionFromLeftCild}
            />
          </div>
          <div
            ref={carousel}
            style={{
              scrollBehavior: "smooth",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            className="block pt-10 md:pt-14 sm:pt-14 lg:pt-8 xl:pt-10 2xl:pt-24 sm:mr-[2.25rem] md:mr-8 2xl:mr-24 min-[530px]:space-y-8 sm:space-y-16 min-[530px]:h-[300px] min-[640px]:max-[700px]:h-[415px] sm:h-[423px] md:h-[415px] md:mt-4 2xl:h-[670px] min-[530px]:overflow-y-auto transform no-scrollbar"
          >
            <FoodCard
              image={burgarImage}
              title="Burger"
              content="Juicy Delight"
              sendSelectionToParent={handleSelectionFromLeftCild}
            />
            <FoodCard
              image={sandwichImage}
              title="Sandwich"
              content="Flavourful Comfort"
              sendSelectionToParent={handleSelectionFromLeftCild}
            />
          </div>
        </Slider>
        <Slider
          ref={rightSliderRef}
          {...sliderSettingsRight}
          className="w-[155px] 2xl:w-[235px] hidden sm:block md:block lg:block xl:block 2xl:block md:mr-6 lg:mr-16 xl:mr-16 2xl:mr-16 sm:-mt-6 md:-mt-10 lg:-mt-4 xl:-mt-14 2xl:-mt-20"
        >
          <div className="sm:mr-4 md:mr-10 space-y-10 sm:space-y-16 md:space-y-12 lg:space-y-16 2xl:space-y-20 pt-8 sm:pt-10 md:pt-10 lg:pt-10 xl:pt-20 2xl:pt-[10rem] md:mt-6 lg:mt-0 2xl:mt-0 xl:-mt-10 2xl:mr-16 sm:h-[423px] md:h-[412px] xl:h-[443px] 2xl:h-[755px] transform">
            <FoodCard
              image={burgarImage}
              title="Burger"
              content="Juicy Delight"
              sendSelectionToParent={handleSelectionFromRightCild}
            />
            <FoodCard
              image={sandwichImage}
              title="Sandwich"
              content="Flavourful Comfort"
              sendSelectionToParent={handleSelectionFromRightCild}
            />
          </div>
          <div className="sm:mr-4 md:mr-10 space-y-10 sm:space-y-16 md:space-y-12 lg:space-y-16 2xl:space-y-20 pt-8 sm:pt-10 md:pt-10 lg:pt-10 xl:pt-20 2xl:pt-[10rem] md:mt-6 lg:mt-0 xl:-mt-10 2xl:mt-0 2xl:mr-16 sm:h-[423px] md:h-[412px] xl:h-[443px] 2xl:h-[755px] transform">
            <FoodCard
              image={burgarImage}
              title="Burger"
              content="Juicy Delight"
              sendSelectionToParent={handleSelectionFromRightCild}
            />
            <FoodCard
              image={sandwichImage}
              title="Sandwich"
              content="Flavourful Comfort"
              sendSelectionToParent={handleSelectionFromRightCild}
            />
          </div>
          <div className="sm:mr-4 md:mr-10 space-y-10 sm:space-y-16 md:space-y-12 lg:space-y-16 2xl:space-y-20 pt-8 sm:pt-10 md:pt-10 lg:pt-10 xl:pt-20 2xl:pt-[10rem] md:mt-6 lg:mt-0 xl:-mt-10 2xl:mt-0 2xl:mr-16 sm:h-[423px] md:h-[412px] xl:h-[443px] 2xl:h-[755px] transform">
            <FoodCard
              image={burgarImage}
              title="Burger"
              content="Juicy Delight"
              sendSelectionToParent={handleSelectionFromRightCild}
            />
            <FoodCard
              image={sandwichImage}
              title="Sandwich"
              content="Flavourful Comfort"
              sendSelectionToParent={handleSelectionFromRightCild}
            />
          </div>
        </Slider>

        <div
          ref={carousel}
          style={{
            scrollBehavior: "smooth",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
          className="block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden pt-10 md:pt-16 sm:pt-16 lg:pt-20 xl:pt-16 2xl:pt-32 sm:mr-[2.25rem] md:mr-8 lg:mr-16 2xl:mr-24 min-[530px]:space-y-8 sm:space-y-16 min-[530px]:h-[300px] min-[640px]:max-[700px]:h-[415px] md:h-[415px] md:mt-4 2xl:h-[670px] min-[530px]:overflow-y-auto transform no-scrollbar"
        >
          <FoodCard
            image={burgarImage}
            title="Burger"
            content="Juicy Delight"
            className="-mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0"
          />
          <FoodCard
            image={sandwichImage}
            title="Sandwich"
            content="Flavourful Comfort"
            className="mt-10 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0"
          />
        </div>
      </div>
    </Layout>
  );
};

export default HomeHeader;
