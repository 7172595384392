import { useEffect, useState, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Button from "../Elements/Button";

const DropdownCart = ({
  data,
  onChange,
  value,
  className,
  type,
  selectDateTime,
  dateChanged,
}) => {
  const [toggle, setToggle] = useState(false);
  const ref = useRef(null);
  const [selectedValue, setSelectedValue] = useState(value || selectDateTime);
  const [disabled, setDisabled] = useState(selectedValue === "Select Date");
  const [showTime, setShowTime] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggle(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  useEffect(() => {
    if (selectedValue === "Select Date") {
      setShowTime(true);
    } else {
      setShowTime(false);
    }
  }, [selectedValue]);

  const handleSelect = (val) => {
    setSelectedValue(val);
    onChange(val);
    setToggle(false);
    setShowTime(val === "Select Date");
  };

  return (
    <div
      className="dropdown flex justify-between w-full mdl:w-42  items-center cursor-pointer px-2 relative"
      ref={ref}
      onClick={() => setToggle(!toggle)}
    >
      <Button
        className={`w-full py-4 sm:py-2 focus:outline-none font-medium rounded-lg text-sm mdl:text-[17px] text-center inline-flex text-dark-shade-8 items-center ${
          className ?? ""
        }`}
      >
        {/* {value} */}
        {dateChanged ? "Select Time" : selectedValue}
      </Button>
      <ChevronDownIcon className="w-3 h-3" />
      {toggle && (
        <div className="absolute top-[43px] left-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full max-h-72 overflow-y-auto">
          <ul
            className="py-2 text-[11px] sm:text-[13px] text-gray-700"
            aria-labelledby="dropdownDefaultButton"
          >
            <li>
              <Button
                onClick={() => handleSelect(selectDateTime)}
                className="w-full text-left block px-2 py-2 hover:bg-gray-100"
              >
                {selectDateTime}
              </Button>
            </li>
            {type === "date" &&
              Object?.keys(data)?.map((val) => (
                <li key={val.key ?? val}>
                  <Button
                    onClick={() => {
                      handleSelect(val.key ?? val);
                      setToggle(false);
                    }}
                    className="w-full text-left block px-2 py-2 hover:bg-gray-100"
                  >
                    {val.value ?? val}
                  </Button>
                </li>
              ))}
            {!showTime &&
              type === "time" &&
              Object?.keys(data)?.map((k) => (
                <li key={k}>
                  <Button
                    onClick={() => {
                      handleSelect(data[k]?.preOrderTime);
                      setToggle(false);
                    }}
                    className="w-full text-left block px-2 py-2 min-w-24 hover:bg-gray-100"
                  >
                    {data[k]?.preOrderTime}
                  </Button>
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownCart;
