import ReactGA from "react-ga4";
import { checkEmpty } from "../utils/helper";
import { useDispatch } from "react-redux";
import { setGTM } from "../store/analyticsData/analyticsDataSlice";
const useSetupGAnalytics = () => {
  const dispatch = useDispatch();
  const setupGAnalytics = async ({ settings }) => {
    var googleTag = [];
    if (checkEmpty(settings) || checkEmpty(settings.gtm)) {
      dispatch(setGTM(0));
      return false;
    }
    googleTag = settings.gtm;
    var gtagId = googleTag[0];
    ReactGA.initialize(gtagId);
    dispatch(setGTM(1));
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  };

  return setupGAnalytics;
};

export default useSetupGAnalytics;
