const CartIcon = ({ className, isCartActive='' }) => {
  return (

    isCartActive=='Cart'?(
<svg  width="24"
className='h-8 w-6'
height="24"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75V0.25ZM5 6.25C4.58579 6.25 4.25 6.58579 4.25 7C4.25 7.41421 4.58579 7.75 5 7.75V6.25ZM20.5 7.75C20.9142 7.75 21.25 7.41421 21.25 7C21.25 6.58579 20.9142 6.25 20.5 6.25V7.75ZM9.25 19.5C9.25 19.9142 8.91421 20.25 8.5 20.25V21.75C9.74264 21.75 10.75 20.7426 10.75 19.5H9.25ZM8.5 20.25C8.08579 20.25 7.75 19.9142 7.75 19.5H6.25C6.25 20.7426 7.25736 21.75 8.5 21.75V20.25ZM7.75 19.5C7.75 19.0858 8.08579 18.75 8.5 18.75V17.25C7.25736 17.25 6.25 18.2574 6.25 19.5H7.75ZM8.5 18.75C8.91421 18.75 9.25 19.0858 9.25 19.5H10.75C10.75 18.2574 9.74264 17.25 8.5 17.25V18.75ZM18.25 19.5C18.25 19.9142 17.9142 20.25 17.5 20.25V21.75C18.7426 21.75 19.75 20.7426 19.75 19.5H18.25ZM17.5 20.25C17.0858 20.25 16.75 19.9142 16.75 19.5H15.25C15.25 20.7426 16.2574 21.75 17.5 21.75V20.25ZM16.75 19.5C16.75 19.0858 17.0858 18.75 17.5 18.75V17.25C16.2574 17.25 15.25 18.2574 15.25 19.5H16.75ZM17.5 18.75C17.9142 18.75 18.25 19.0858 18.25 19.5H19.75C19.75 18.2574 18.7426 17.25 17.5 17.25V18.75ZM5 3.75H17V2.25H5V3.75ZM20.25 7V12H21.75V7H20.25ZM17 15.25H9V16.75H17V15.25ZM5.75 12V3H4.25V12H5.75ZM3 0.25H1V1.75H3V0.25ZM5 7.75H20.5V6.25H5V7.75ZM5.75 3C5.75 1.48122 4.51878 0.25 3 0.25V1.75C3.69036 1.75 4.25 2.30964 4.25 3H5.75ZM9 15.25C7.20507 15.25 5.75 13.7949 5.75 12H4.25C4.25 14.6234 6.37665 16.75 9 16.75V15.25ZM20.25 12C20.25 13.7949 18.7949 15.25 17 15.25V16.75C19.6234 16.75 21.75 14.6234 21.75 12H20.25ZM17 3.75C18.7949 3.75 20.25 5.20507 20.25 7H21.75C21.75 4.37665 19.6234 2.25 17 2.25V3.75Z" fill="#FF0A38"/>
</svg>

    )
    
    :
    (  
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      
      className={className}
    >
      <path
        d="M11 20.5C11 21.3284 10.3284 22 9.5 22C8.67157 22 8 21.3284 8 20.5C8 19.6716 8.67157 19 9.5 19C10.3284 19 11 19.6716 11 20.5Z"
        stroke="#28303F"
        strokeWidth="1.5"
      />
      <path
        d="M20 20.5C20 21.3284 19.3284 22 18.5 22C17.6716 22 17 21.3284 17 20.5C17 19.6716 17.6716 19 18.5 19C19.3284 19 20 19.6716 20 20.5Z"
        stroke="#28303F"
        strokeWidth="1.5"
      />
      <path
        d="M6 4H18C20.2091 4 22 5.79086 22 8V13C22 15.2091 20.2091 17 18 17H10C7.79086 17 6 15.2091 6 13V4ZM6 4C6 2.89543 5.10457 2 4 2H2M6 8H21.5"
        stroke="#28303F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
      )
  
  );
};

export default CartIcon;
