const GooglePay = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <g clipPath="url(#clip0_17120_7718)">
        <path
          d="M4.8773 11.4819L4.21594 13.9509L1.79867 14.002C1.07627 12.6621 0.666504 11.1291 0.666504 9.5C0.666504 7.92467 1.04962 6.43911 1.72872 5.13104H1.72924L3.88129 5.52559L4.82402 7.66472C4.6267 8.23995 4.51916 8.85745 4.51916 9.5C4.51924 10.1974 4.64556 10.8655 4.8773 11.4819Z"
          fill="#FBBB00"
        />
        <path
          d="M19.5005 7.72528C19.6096 8.29996 19.6665 8.89345 19.6665 9.5C19.6665 10.1801 19.595 10.8436 19.4588 11.4835C18.9963 13.6612 17.7879 15.5628 16.114 16.9084L16.1134 16.9079L13.4028 16.7696L13.0192 14.3748C14.1299 13.7233 14.998 12.7039 15.4553 11.4835H10.3754V7.72528H15.5294H19.5005Z"
          fill="#518EF8"
        />
        <path
          d="M16.1134 16.9079L16.1139 16.9084C14.4859 18.217 12.4178 19 10.1665 19C6.54871 19 3.40328 16.9779 1.79871 14.0021L4.87734 11.482C5.6796 13.6231 7.74508 15.1473 10.1665 15.1473C11.2073 15.1473 12.1824 14.8659 13.0191 14.3748L16.1134 16.9079Z"
          fill="#28B446"
        />
        <path
          d="M16.2304 2.18708L13.1528 4.70666C12.2868 4.16538 11.2632 3.8527 10.1665 3.8527C7.69027 3.8527 5.58617 5.4468 4.82409 7.66472L1.72928 5.13104H1.72876C3.30984 2.08269 6.49494 0 10.1665 0C12.4716 0 14.5851 0.821082 16.2304 2.18708Z"
          fill="#F14336"
        />
      </g>
      <defs>
        <clipPath id="clip0_17120_7718">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(0.666504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GooglePay;
