import PropType from "prop-types";
import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/24/solid";

const FoodCard = ({
  image,
  title,
  content,
  className,
  sendSelectionToParent,
}) => {
  const handleSelectionPass = (selection) => {
    sendSelectionToParent(selection);
  };
  return (
    <div
      className={`mx-auto space-y-2 sm:space-x-1  h-max sm:h-36 2xl:h-[244px] 2xl:w-[229px] w-[85px] sm:w-32 bg-white  rounded-xl flex flex-col justify-center items-center relative ${className}`}
    >
      <div className="absolute  -top-6 sm:-top-10 2xl:-top-11">
        <img
          loading="lazy"
          src={image}
          alt=""
          className="sm:w-40 2xl:w-[248px] 2xl:h-[150px] sm:h-24 hover:scale-105"
        />
      </div>
      <div className="flex sm:flex flex-col space-y-1  items-center pt-8 pb-4 ">
        <div className="text-[12px] sm:text-[20px] 2xl:text-[22px] 3xl:text-[26px] font-bold text-black">
          {title}
        </div>
        <div className="text-[10px] sm:text-[14px] 2xl:text-[18px] 3xl:text-lg font-medium text-black text-center">
          {content}
        </div>
      </div>
      <div className="min-[530px]:flex absolute bottom-0 space-x-6 translate-y-2 ">
        <div
          className="text-white bg-black -bottom-7 -ml-3 2xl:ml-[-1.75rem] 2xl:bottom-[-2.25rem]  rounded-full min-[530px]:w-5 min-[530px]:h-5 sm:w-7 sm:h-7 2xl:w-[37px] 2xl:h-[37px] relative rotate-90 cursor-pointer hover:scale-105"
          onClick={() => handleSelectionPass("bottomArrow")}
        >
          <ArrowSmallRightIcon className="min-[530px]:w-3 min-[530px]:h-3 sm:w-4 sm:h-4 2xl:w-6 2xl:h-6 translate-x-1/3 2xl:translate-y-1.5 translate-y-1/3 absolute " />
        </div>
        <div
          className="text-black bg-white shadow-md rounded-full min-[530px]:w-5 min-[530px]:h-5 sm:w-7 sm:h-7 relative rotate-90 2xl:h-[37px] 2xl:w-[36px] cursor-pointer hover:scale-105"
          onClick={() => handleSelectionPass("upArrow")}
        >
          <ArrowSmallLeftIcon className="min-[530px]:w-3 min-[530px]:h-3 sm:w-4 sm:h-4  2xl:w-6 2xl:h-6 2xl:translate-y-2 translate-x-1/3 translate-y-1/3 absolute " />
        </div>
      </div>
      <div className="hidden sm:hidden md:visible lg:visible xl:visible 2xl:visible absolute -bottom-2 space-x-4">
        <div className="text-white bg-black shadow-md rounded-full w-5 h-5 relative rotate-90">
          <ArrowSmallRightIcon className="w-3 h-3 translate-x-1/3 translate-y-1/3 absolute " />
        </div>
        <div className="text-black bg-white shadow-md rounded-full w-5 h-5 relative rotate-90">
          <ArrowSmallLeftIcon className="w-3 h-3 translate-x-1/3 translate-y-1/3 absolute " />
        </div>
      </div>
    </div>
  );
};

FoodCard.propTypes = {
  image: PropType.string.isRequired,
  title: PropType.string.isRequired,
  content: PropType.string.isRequired,
};

export default FoodCard;
