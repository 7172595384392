import { NavLink, useLocation } from "react-router-dom";
import ArrowRightChevron from "../Icon/ArrowRightChevron";

const ProfileNav = ({ title, Icon, to, onclick, className }) => {
  const location = useLocation();
  const isSelected = location.pathname === to;

  const content = (
    <div
      onClick={onclick}
      className={`group flex justify-between items-center ${
        isSelected ? "bg-primary" : "hover:bg-navBg"
      } py-3 px-3 rounded-lg cursor-pointer ${className}`}
    >
      <div className="flex space-x-2 items-center">
        <Icon
          className={`${
            isSelected ? "text-white fill-white" : " "
          } w-3 mdl:w-8 2xl:w-8`}
        />
        <div
          className={`${
            isSelected ? "text-white" : "text-hover-shade"
          } text-base 2xl:text-xl font-medium font-inter leading-normal`}
        >
          {title}
        </div>
      </div>
      <ArrowRightChevron
        className={`${
          isSelected ? "text-white  fill-white" : " "
        } w-2 xl:w-2 2xl:w-2.5`}
      />
    </div>
  );

  return to ? <NavLink to={to}>{content}</NavLink> : content;
};

export default ProfileNav;
