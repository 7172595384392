import { checkEmpty } from "../../utils/helper";

const Button = ({
  key,
  className,
  children,
  onClick,
  type,
  dataDismissTarget,
  ariaLabel,
  title,
  disabled,
  onHover,
}) => {
  const backGroundColor = () => {
    if (disabled && children !== "SEARCH") return "bg-disabled";
    if (type === "primary") return "bg-primary";
    else if (type === "secondary") return "bg-secondary";
    else return "";
  };

  const textColor = () => {
    if (disabled) return "text-white";
    if (type === "primary") return "text-white";
    else if (type === "secondary") return "text-secondary";
  };

  return (
    <button
      key={key}
      className={`${backGroundColor()}
     ${className} rounded-lg`}
      onClick={onClick}
      type={type}
      data-dismiss-target={dataDismissTarget}
      aria-label={ariaLabel}
      title={title}
      disabled={disabled}
      onMouseEnter={() => {
        if (!checkEmpty(onHover)) {
          onHover(true);
        }
      }}
      onMouseLeave={() => {
        if (!checkEmpty(onHover)) {
          onHover(false);
        }
      }}
    >
      {title !== undefined ? (
        <span
          className={`${textColor()} font-semibold text-md 2xl:text-xl text-center`}
        >
          {title}&nbsp;
        </span>
      ) : (
        <></>
      )}
      {children}
    </button>
  );
};

export default Button;
