import { useEffect } from "react";
import Layout from "../../layout/Layout";

const AppRefundPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="overflow-hidden	h-max font-[inter]">
      <Layout className="carousel w-full bg-white space-x-2 lg:space-x-5 2xl:md:pb-8 xl:pd-8 md:pb-6 pb-7 xl:pl-[45px] pl-[15px] sm:pl-5 md:pl-[25px]">
        <div className="w-full lg:px-40 lg:py-4">
          <div className="row">
            <h2 className="text-3xl	mb-2.5 mt-8 font-medium">Refund Policy</h2>
            <p className="mb-2.5 text-sm text-gray-600">
              This page is used to inform visitors regarding our policies with
              the refund and cancellation.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              Refund Policy
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              Sometimes you may require a refund. We are here to help when this
              kind of stuff happens. If you require a refund on a cash order the
              best thing to do is contact the restaurant directly. The money was
              paid to the driver and not to Kuick and we cannot issue a cash
              refund.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              If you have paid online then you are welcome to call us. If your
              order is refunded on the same day the order is placed the refunded
              money will stay on your Kuick account for the remainder of the
              day. This is in case you would like to place another order on that
              day. If no order is placed the money will automatically be
              refunded to your bank account. This may take up to 3 working days
              to appear in your account.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              If the refund is made after the day the order was placed we will
              issue the refund to your bank account. The money may take up to 3
              working days to appear in your account.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              Why do I have to pay a card fee?
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              The restaurant is charged this fee by the credit card company.
              Some restaurants choose to absorb this charge while most choose to
              pass this on to the customer. The fee is typically 50p - 75p per
              transaction. If you come across an unusually high card charge then
              please do inform us about this.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              How can I pay for my order?
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              There are three methods that Kuick accept. You can pay by cash
              direct to the driver. We also accept credit cards and have just
              begun accepting paypal.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              I've paid with paypal/paytm and need a refund
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              If you paid via Paypal with a bank account or Paypal balance the
              refund goes into your paypal account. This is instant. If you paid
              with a bank account and want this returned to your bank account
              you will need to request that paypal transfer this money back to
              your bank account.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              If you have paid via Paypal with a credit or debit card the refund
              will be applied to your bank account. This may take up tp 3
              working days to appear.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              Why do I need to pay a delivery charge?
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              The delivery fee is decided by the restaurants. Some restaurants
              offer free delivery. This can be found on the menu listings. You
              can also search specifically for restaurants that offer free
              delivery by using the filters on the left hand side of the menu
              listings.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              If you think you have been overcharged on the delivery then you
              can fill in the price guarantee claim form. We require that all
              restaurants offer the same prices on Kuick as they do in store.
              You may be entitled to a refund of the delivery charge.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">
              Cancellation
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              You have the right to cancel an order up to the point the order is
              sent to the restaurant. Normally, this is approximately 45 minutes
              before the time you requested delivery or collection of the order.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              Members can cancel an order via their Order History, while guests
              can use the "Cancel order" link in the confirmation email they
              received.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              While every effort is made to ensure that accurate pricing and
              descriptions are maintained, we reserve the right to cancel any
              order that is based on inaccurate information.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              An order may be subsequently cancelled by a restaurant after you
              have received an email stating the order has been accepted. RedoQ
              Ltd. and our partner restaurants reserves the right to cancel any
              order, before or after acceptance, and will notify you immediately
              of any such cancellation.
            </p>
            <p className="mb-2.5 text-sm text-gray-600 font-bold">Contact Us</p>
            <p className="mb-2.5 text-sm text-gray-600">
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us.
            </p>
            <p className="mb-2.5 text-sm text-gray-600">
              By email: support@Kuick.com, support@redoq.com
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default AppRefundPolicy;
