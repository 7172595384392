import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import createContainer from "../createContainer";
import Button from "../../Elements/Button";
import CheckIcon from "../../../assets/images/profile_section/check.png";
import ExclamationIcon from "../../../assets/images/error.svg";
import { Alert, Snackbar } from "@mui/material";
import Collapse from "@mui/material/Collapse";

let timeToDelete = 300;
const container = createContainer();

export default function Notification({
  color = Color.info,
  autoClose,
  onDelete,
  children,
  type,
}) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  // const [isClosing, setIsClosing] = useState(false);

  // useEffect(() => {
  //   if (autoClose) {
  //     // set auto close notification time here
  //     const timeoutId = setTimeout(() => setIsClosing(true), 5000);

  //     return () => {
  //       clearTimeout(timeoutId);
  //     };
  //   }
  // }, [autoClose]);

  // useEffect(() => {
  //   if (isClosing) {
  //     const timeoutId = setTimeout(onDelete, timeToDelete);

  //     return () => {
  //       clearTimeout(timeoutId);
  //     };
  //   }
  // }, [isClosing, onDelete]);

  return createPortal(
    // <div
    //   className={`overflow-hidden max-h-48 transition-max-height ease-out duration-300 [&:not(:last-child)]:mb-2 fixed top-0 left-1/2 transform -translate-x-1/2 mt-3 w-auto min-w-[290px] max-w-[95%] sm:min-w-[290px] md:min-w-[360px] lg:min-w-[450px]  sm:max-w-[80%] md:max-w-[560px] lg:max-w-[650px] py-2 ${
    //     isClosing ? "shrink" : "shrink-0"
    //   }`}
    // >
    //   <div
    //     className={`flex items-center p-4 mb-4 px-3 py-3 pr-12 pl-3 z-[99] h-10 font-bold relative border rounded-lg ${color} ${
    //       isClosing ? "animate-slideOut flex-none" : "animate-slideInTop"
    //     }`}
    //   >
    //     <div className="">
    //       {color == "text-green-800 border-green-300 bg-green-50" ? (
    //         <img className="w-5" loading="lazy" src={CheckIcon} alt="" />
    //       ) : color == "text-red-800 border-red-300 bg-red-50" ? (
    //         <img className="w-6" loading="lazy" src={ExclamationIcon} alt="" />
    //       ) : (
    //         ""
    //       )}
    //     </div>
    //     <div
    //       className="text-[13px] md:text-sm font-semibold ml-3 leading-4
    //     "
    //     >
    //       {children}
    //     </div>
    //     <Button
    //       onClick={() => setIsClosing(true)}
    //       className=" absolute top-3.5 right-3 ms-auto -mx-1.5 -my-1.5 rounded-lg p-1.5 inline-flex items-center justify-center h-6 w-6"
    //       dataDismissTarget="#alert-1"
    //       ariaLabel="Close"
    //     >
    //       <span className="sr-only">Close</span>
    //       <svg
    //         className="w-2.5 h-2.5"
    //         aria-hidden="true"
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 14 14"
    //       >
    //         <path
    //           stroke="currentColor"
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           strokeWidth="2"
    //           d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
    //         />
    //       </svg>
    //     </Button>
    //   </div>
    // </div>
    <div className="mt-3">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className="text-[13px] md:text-sm font-semibold font-inter w-auto min-w-[290px] max-w-[95%] sm:min-w-[290px] md:min-w-[360px] lg:min-w-[450px] sm:max-w-[80%] md:max-w-[560px] lg:max-w-[650px]"
      >
        <Alert onClose={handleClose} severity={type}>
          {children}
        </Alert>
      </Snackbar>
    </div>,
    container
  );
}

export const Color = {
  info: "text-blue-800 border-blue-300 bg-blue-50",
  success: "text-green-800 border-green-300 bg-green-50",
  warning: "text-yellow-800 border-yellow-300 bg-yellow-50",
  error: "text-red-800 border-red-300 bg-red-50",
};
