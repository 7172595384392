import React from "react";

const Briefcase = ({ className }) => {
  return (
    <svg
      viewBox="0 0 17 17"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M13.4583 2.83333H12.6792C12.5148 2.03392 12.0798 1.31562 11.4476 0.799509C10.8153 0.283397 10.0245 0.00103029 9.20833 0H7.79167C6.97552 0.00103029 6.18468 0.283397 5.55245 0.799509C4.92021 1.31562 4.48523 2.03392 4.32083 2.83333H3.54167C2.6027 2.83446 1.70252 3.20796 1.03857 3.87191C0.374625 4.53585 0.00112473 5.43604 0 6.375V13.4583C0.00112473 14.3973 0.374625 15.2975 1.03857 15.9614C1.70252 16.6254 2.6027 16.9989 3.54167 17H13.4583C14.3973 16.9989 15.2975 16.6254 15.9614 15.9614C16.6254 15.2975 16.9989 14.3973 17 13.4583V6.375C16.9989 5.43604 16.6254 4.53585 15.9614 3.87191C15.2975 3.20796 14.3973 2.83446 13.4583 2.83333ZM7.79167 1.41667H9.20833C9.64627 1.41848 10.0729 1.55556 10.43 1.80915C10.787 2.06273 11.057 2.42044 11.203 2.83333H5.797C5.94298 2.42044 6.21296 2.06273 6.57 1.80915C6.92705 1.55556 7.35373 1.41848 7.79167 1.41667ZM3.54167 4.25H13.4583C14.0219 4.25 14.5624 4.47388 14.9609 4.8724C15.3595 5.27091 15.5833 5.81141 15.5833 6.375V8.5H1.41667V6.375C1.41667 5.81141 1.64055 5.27091 2.03906 4.8724C2.43758 4.47388 2.97808 4.25 3.54167 4.25ZM13.4583 15.5833H3.54167C2.97808 15.5833 2.43758 15.3595 2.03906 14.9609C1.64055 14.5624 1.41667 14.0219 1.41667 13.4583V9.91667H7.79167V10.625C7.79167 10.8129 7.86629 10.993 7.99913 11.1259C8.13197 11.2587 8.31214 11.3333 8.5 11.3333C8.68786 11.3333 8.86803 11.2587 9.00087 11.1259C9.13371 10.993 9.20833 10.8129 9.20833 10.625V9.91667H15.5833V13.4583C15.5833 14.0219 15.3595 14.5624 14.9609 14.9609C14.5624 15.3595 14.0219 15.5833 13.4583 15.5833Z"
      />
    </svg>
  );
};

export default Briefcase;
