import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import store from "./store/store.js";
import * as Sentry from "@sentry/react";
const envMode = import.meta.env.MODE;
console.log("envMode---------", envMode);
const hostname = window.location.hostname;
console.log(hostname);
if (
  hostname == "staging.react.kuick.com" ||
  hostname == "prod.react.kuick.com"
) {
  Sentry.init({
    dsn: "https://006165c55b5956c2f98a121f62f7fff0@o1352079.ingest.us.sentry.io/4507899950530560",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // "localhost",
      "https://staging.react.kuick.com/",
      "https://prod.react.kuick.com/",
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// Create a global variable to store the root instance
let root;

// Function to initialize or reuse the root instance
function renderApp() {
  const container = document.getElementById("root");

  if (!root) {
    // Create the root only once
    root = ReactDOM.createRoot(container);
  }

  // Render the App component inside the Provider (with store)
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}

// Initial render of the application
renderApp();
