import { motion } from "framer-motion";

const pageTransition = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
  transition: { duration: 0.5 },
};

const MotionWrapper = ({ children }) => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageTransition}
    >
      {children}
    </motion.div>
  );
};

export default MotionWrapper;

// import { motion } from "framer-motion";

// const pageVariants = {
//   initial: {
//     opacity: 0,
//     x: "-100vw",
//   },
//   in: {
//     opacity: 1,
//     x: 0,
//   },
//   out: {
//     opacity: 0,
//     x: "100vw",
//   },
// };

// const pageTransition = {
//   type: "tween",
//   duration: 0.1,
// };

// const MotionWrapper = ({ children }) => {
//   return (
//     <motion.div
//       initial="initial"
//       animate="in"
//       exit="out"
//       variants={pageVariants}
//       transition={pageTransition}
//     >
//       {children}
//     </motion.div>
//   );
// };

// export default MotionWrapper;
