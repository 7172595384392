import { useState, useEffect } from "react";
import Modal from "react-modal";
import Delete from "../../components/Icon/Delete";
import indianbank from "../../assets/images/profile_section/indianbank.png";

import InputField from "../../components/Elements/InputField";
import axios from "axios";
import { addCardDetails } from "../../config";
import Card from "../../components/Cards/Card";
import { profileData } from "../../services";
import RadioButton from "../../components/Elements/RadioButton";
import Button from "../../components/Elements/Button";
import ButtonLoader from "../../components/Loader/ButtonLoader";
import BankCardLoader from "../../components/Loader/ProfilePageLoaders/BankCardLoader/BankCardLoader";
import {
  ProperNameRegEx,
  cardNumberRegex,
  securityCodeRegex,
  dateRegex,
} from "../../utils";
import { deleteBank, updateBankCardDefaultService } from "../../services";
import { error, success } from "../../components/notify";
import MotionWrapper from "../../components/Animation/MotionWrapper";
import nodata_found from "../../assets/images/no-data-found/profile/bankcards/nodata.png";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import logo_cover from "../../assets/images/default_images/blue_placeholder_square.png";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";
import { checkEmpty } from "../../utils/helper";

const BankCardsList = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [cardList, setCardList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTopBar, setIsLoadingTopBar] = useState(false);
  const [selectedcard, setSelectCard] = useState("");
  const [selectedcardkey, setSelectCardKey] = useState("");

  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBankList();
  }, []);

  useEffect(() => {
    if (isLoading || isLoadingTopBar) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading, isLoadingTopBar]);

  const getBankList = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await profileData();
      if (status === 200) {
        if (data.status === "success") {
          setCardList(data?.pageContent?.customer?.common_customer_card);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("error msg", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBankCard = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await deleteBank(selectedcard);
      if (status === 200) {
        if (data.status === "success") {
          let currentCardList = cardList;
          currentCardList.splice(selectedcardkey, 1);
          setCardList(currentCardList);
          success(data.msg);
        }
      }
    } catch (e) {
      error(e.msg);
    } finally {
      setIsLoading(false);
      closeDeleteModal();
    }
  };

  const openDeleteModal = (card, key) => {
    setSelectCard(card.common_customer_card_Id);
    setSelectCardKey(key);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    deleteBankCard();
  };

  const handleRadioChange = (cardId, key) => {
    setSelectedOption(cardId);
    setSelectCardKey(key);
    updateBankCardDefault(cardId);
  };

  const updateBankCardDefault = async (cardId) => {
    setIsLoadingTopBar(true);
    try {
      const { status, data } = await updateBankCardDefaultService(cardId);
      if (status === 200) {
        if (data.status === "success") {
          setCardList(data?.customer?.common_customer_card);
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
        }
      }
    } catch (e) {
      error(e.msg);
    } finally {
      setIsLoadingTopBar(false);
      closeDeleteModal();
    }
  };

  return (
    <div className="">
      <section className="bankCard">
        {isLoading ? (
          <BankCardLoader
            className={`mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto`}
            subClassName={`grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4`}
            subsubClassName={`h-36`}
          />
        ) : cardList && cardList.length > 0 ? (
          <div className="mt-5 pb-5">
            <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
              {cardList &&
                cardList?.map((thisCard, thisCardKey) => (
                  <Card
                    key={thisCard.common_customer_card_Id}
                    className={`flex items-center border-0 rounded-lg bg-white px-4 py-7`}
                  >
                    <div className="cursor-pointer">
                      <RadioButton
                        name="addressType"
                        value={thisCard.common_customer_card_Id}
                        onChange={() =>
                          handleRadioChange(
                            thisCard.common_customer_card_Id,
                            thisCardKey
                          )
                        }
                        checked={thisCard.is_default == 1 ? true : false}
                        className={`w-5 sm:w-6`}
                      />
                    </div>
                    <div className="flex w-full items-center">
                      <div className="rounded-full border border-loyalityDate w-12 sm:w-16 ml-3 sm:h-16 sm:ml-5 flex justify-center items-center">
                        <div className="w-12 h-12 p-2 md:p-1.5 ">
                          <LazyLoadImageComponent
                            src={thisCard.image || indianbank}
                            alt=""
                            className=""
                            onErrorSrc={logo_cover}
                            loading="lazy"
                            placeholderSrc={logo_cover}
                          />
                        </div>
                      </div>
                      <div className="flex-grow ml-4">
                        <p className="text-black text-mdl mb-1">
                          {thisCard.common_customer_card_Holder_Name}{" "}
                          {thisCard.common_customer_card_Id}
                        </p>
                        <p className="text-loyalityDate font-semibold text-xs">
                          Card Number:
                        </p>
                        <p className="text-bankcard text-md font-semibold">
                          XXXX XXXX XXXX{" "}
                          {thisCard.common_customer_card_Last_Four}
                        </p>
                      </div>
                      <div
                        className="mr-2 w-5 cursor-pointer"
                        onClick={() => openDeleteModal(thisCard, thisCardKey)}
                      >
                        <Delete className={`h-6 w-5 fill-loyalityDate }`} />
                      </div>
                    </div>
                  </Card>
                ))}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center w-full min-h-[calc(100vh_-_17rem)] sm:min-h-[calc(100vh_-_17rem)] sm:h-auto">
            <div className="w-[200px] lg:w-[350px]">
              <img src={nodata_found} alt="No data found image" />
            </div>
          </div>
        )}
      </section>

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        className=" bg-black bg-opacity-50 z-50 "
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <div className="bg-white rounded-lg shadow-lg px-6 pt-4 pb-8 w0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 sm:w-96">
          <div className="text-center p-4">
            <p>Are you sure you want to delete?</p>
            <div className="flex justify-center mt-4">
              <Button
                className="bg-primary text-white font-bold py-2 px-4 mr-4 rounded w-20"
                onClick={handleDeleteConfirm}
              >
                Yes
              </Button>
              <Button
                className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded w-20"
                onClick={closeDeleteModal}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const AddBankCard = ({ handleSaveCardClick }) => {
  const [nameError, setNameError] = useState(false);
  const [cardError, setCardError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [nameEmptyError, setNameEmptyError] = useState(false);
  const [cardEmptyError, setCardEmptyError] = useState(false);
  const [dateEmptyError, setDateEmptyError] = useState(false);
  const [codeEmptyError, setCodeEmptyError] = useState(false);
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [dateNo, setDateNo] = useState("");
  const [code, setCode] = useState("");
  const [inputError, setInputError] = useState("");

  const handleCardChange = (e) => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");
    if (inputNumbersOnly.length > 16) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 16);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);
    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" ");
    }
    setCardNumber(spacedNumber);
    setCardEmptyError(spacedNumber === "");
    setCardError(false);
  };

  const handleNameChange = (event) => {
    const input = event.target.value;
    if (ProperNameRegEx.test(input) || input === "") {
      if (input.length === 0) {
        setName("");
        setNameError(false);
        setNameEmptyError(true);
      } else {
        setName(input);
        setNameError(false);
        setNameEmptyError(false);
      }
    } else {
      // Handle special characters or numbers (not allowed)
      // For example, you might set an error state here
    }
  };

  const handleDateChange = (event) => {
    const input = event.target.value;
    // Remove non-numeric characters
    const formattedInput = input.replace(/\D/g, "");
    if (formattedInput.length === 0) {
      setDateNo("");
      setDateError(false);
      setDateEmptyError(true);
    } else if (formattedInput.length <= 4) {
      setDateNo(formattedInput);
      setDateError(false);
      setDateEmptyError(false);
    }
  };

  const handleCodeChange = (event) => {
    const input = event.target.value;
    // Remove non-numeric characters
    const formattedInput = input.replace(/\D/g, "");
    if (formattedInput.length === 0) {
      setCode("");
      setCodeError(false);
      setCodeEmptyError(true);
      setInputError("");
    } else if (formattedInput.length <= 3) {
      // Limit input to 3 characters
      setCode(formattedInput);
      setCodeError(false);
      setCodeEmptyError(false);
    } else {
    }
  };

  const handleSaveCard = (e) => {
    e.preventDefault();
    let hasError = false;

    if (
      cardNumber.trim() !== "" &&
      cardNumberRegex.test(cardNumber) === false
    ) {
      setCardError(true);
      setCardEmptyError(false);
      hasError = true;
    } else if (cardNumber.trim() === "") {
      setCardEmptyError(true);
      setCardError(false);
      hasError = true;
    }

    if (name === "") {
      setNameEmptyError(true);
      hasError = true;
    }

    if (dateNo === "") {
      setDateEmptyError(true);
      hasError = true;
    }

    if (dateNo.trim() !== "" && dateRegex.test(dateNo) === false) {
      setDateError(true);
      setDateEmptyError(false);
      hasError = true;
    } else if (dateNo.trim() === "") {
      setDateEmptyError(true);
      setDateError(false);
      hasError = true;
    }

    if (code === "") {
      setCodeEmptyError(true);
      hasError = true;
    }

    if (!hasError) {
    } else {
      // error(`Fill all fields marked with '*'`);
    }
  };

  return (
    <div className="mt-5 pb-5 ">
      <section className="addBankCard">
        <div className="space-y-5 md:space-y-0 grid lg:grid-cols-2 my-5 md:my-8 md:gap-x-5 md:gap-y-5">
          <label>
            Card Number<span className="text-red-500">*</span>
            <InputField
              placeholder="1234 5XXX 9XXX 6XXX"
              value={cardNumber}
              onChange={handleCardChange}
            />
            {cardError && (
              <span className="text-primary text-sm">
                Enter a Valid Card Number
              </span>
            )}
            {cardEmptyError && (
              <span className="text-primary text-sm">
                This field is required
              </span>
            )}
          </label>

          <label className="">
            Name on Card<span className="text-red-500">*</span>
            <InputField
              placeholder="Name"
              value={name}
              onChange={handleNameChange}
            />
            {nameError && (
              <span className="text-primary text-sm">{inputError}</span>
            )}
            {nameEmptyError && (
              <span className="text-primary text-sm">
                This field is required
              </span>
            )}
          </label>
          <label className="">
            Expiry Date<span className="text-red-500">*</span>
            <InputField
              placeholder="MMYY"
              value={dateNo}
              onChange={handleDateChange}
            />
            {dateError && (
              <span className="text-primary text-sm">Enter a Valid Date</span>
            )}
            {dateEmptyError && (
              <span className="text-primary text-sm">
                This field is required
              </span>
            )}
          </label>
          <label>
            Security Code<span className="text-red-500">*</span>
            <InputField
              placeholder="XXX"
              value={code}
              onChange={handleCodeChange}
            />
            {codeError && (
              <span className="text-primary text-sm">Invalid code</span>
            )}
            {codeEmptyError && (
              <span className="text-primary text-sm">
                This field is required
              </span>
            )}
          </label>
        </div>

        <div className="mt-10 w-full grid grid-cols-2 gap-4 xl:w-[375px] xl:flex justify-between self-center xl:self-start">
          <Button
            onClick={handleSaveCard}
            className="text-white xl:w-44 bg-primary hover:bg-primary focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center"
          >
            Save
          </Button>
          <Button
            onClick={handleSaveCardClick}
            className="text-border-shade-1 border border-border-shade-1 xl:w-44 bg-white  focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center"
          >
            Cancel
          </Button>
        </div>
      </section>
    </div>
  );
};

const BankCards = ({ isLoading }) => {
  const [showAddBankCard, setShowAddBankCard] = useState(false);

  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);
  const [isLoad, setIsLoad] = useState(false);

  const handleAddBalanceClick = () => {
    setShowAddBankCard(true);
    setIsLoad(true);
    setTimeout(() => {
      setIsLoad(false);
    }, 100);
  };

  const handleSaveCardClick = () => {
    setShowAddBankCard(false);
  };

  useEffect(() => {
    if (isLoad) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoad]);

  return (
    <MotionWrapper>
      <div className="">
        <div className="mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto mdl:p-7 h-auto no-scrollbar">
          {" "}
          {showAddBankCard ? (
            <div>
              <div className="text-lg font-bold sm:text-xl sm:font-semibold sm:font-inter">
                Add Bank Card
              </div>
              <AddBankCard handleSaveCardClick={handleSaveCardClick} />
            </div>
          ) : (
            <div>
              <div className="flex justify-between w-full items-center">
                <div className="text-lg font-bold sm:text-xl sm:font-semibold sm:font-inter ">
                  Bank Cards
                </div>
                {isLoading ? (
                  <ButtonLoader className={``} />
                ) : (
                  <div className="">
                    <Button
                      className="rounded-lg sm:w-max font-medium text-sm md:text-md border px-4 py-1 bg-primary text-white md:px-8 md:py-2 hidden"
                      onClick={handleAddBalanceClick}
                    >
                      + Add New Card
                    </Button>
                  </div>
                )}
              </div>
              <BankCardsList showAddBankCard={showAddBankCard} />
            </div>
          )}
        </div>
      </div>
    </MotionWrapper>
  );
};

export default BankCards;
