const OrderConfirmed = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        d="M31.1424 21.1909H29.8118C29.7523 19.9423 29.5238 18.7378 29.1501 17.5992C30.2144 16.4997 30.8691 15.0021 30.8691 13.3509C30.8691 9.97619 28.1337 7.24083 24.759 7.24083C23.1232 7.24083 21.6371 7.88403 20.5401 8.93107C19.7241 8.65715 18.8742 8.45683 17.9981 8.34163C18.103 8.10291 18.1632 7.83923 18.1632 7.56211C18.1632 6.48819 17.2896 5.61523 16.2163 5.61523C15.1424 5.61523 14.2694 6.48883 14.2694 7.56211C14.2694 7.83923 14.3296 8.10291 14.4345 8.34163C7.97118 9.19155 2.93374 14.5772 2.61951 21.1909H1.28958C0.931185 21.1909 0.640625 21.4808 0.640625 21.8399V22.4901C0.640625 23.4668 0.927345 24.4127 1.47006 25.2268C1.95262 25.9506 2.7603 26.3826 3.63006 26.3826H28.8038C29.6729 26.3826 30.4813 25.95 30.9645 25.2261C31.5065 24.412 31.7933 23.4668 31.7933 22.4901V21.8399C31.7913 21.4815 31.5014 21.1909 31.1424 21.1909ZM24.759 8.83699C27.2518 8.83699 29.2729 10.8581 29.2729 13.3509C29.2729 15.8437 27.2518 17.8648 24.759 17.8648C22.2662 17.8648 20.2451 15.8437 20.2451 13.3509C20.2451 10.8581 22.2656 8.83699 24.759 8.83699ZM16.2157 6.91379C16.5734 6.91379 16.8646 7.20499 16.8646 7.56275C16.8646 7.92051 16.5734 8.21171 16.2157 8.21171C15.8573 8.21171 15.5667 7.92051 15.5667 7.56275C15.5667 7.20499 15.8579 6.91379 16.2157 6.91379ZM16.2157 10.0812C17.3126 10.0812 18.3584 10.236 19.3433 10.5196C18.9005 11.3656 18.6489 12.3288 18.6489 13.3509C18.6489 16.7256 21.3843 19.461 24.759 19.461C25.7773 19.461 26.7373 19.2114 27.5821 18.7711C27.8304 19.5666 27.9961 20.3788 28.0697 21.1916H4.49022C5.4291 14.5752 9.63646 10.0812 16.2157 10.0812ZM29.1622 24.0088C28.9203 24.3711 28.5395 24.5772 28.1043 24.5772H4.2643C3.82975 24.5772 3.42462 24.3608 3.18398 23.9986C2.78463 23.3996 2.83518 22.9983 2.83518 22.9983H29.4777C29.3817 23.5839 29.2563 23.8725 29.1622 24.0088Z"
        fill=""
      />
      <path
        d="M27.9248 11.2631C27.7328 11.0711 27.4211 11.0711 27.2291 11.2631L24.0982 14.394L22.7069 13.0027C22.5149 12.8107 22.2032 12.8107 22.0112 13.0027C21.8192 13.1947 21.8192 13.5063 22.0112 13.6983L23.7507 15.4379C23.8467 15.5339 23.9728 15.5819 24.0982 15.5819C24.2237 15.5819 24.3504 15.5339 24.4464 15.4379L27.9248 11.9588C28.1174 11.7668 28.1174 11.4558 27.9248 11.2631Z"
        fill=""
      />
    </svg>
  );
};

export default OrderConfirmed;
