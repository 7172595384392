const OrderPreparing = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="29"
      viewBox="0 0 30 29"
      className={className}
    >
      <path
        d="M23.7886 27.2326C22.1784 27.2326 23.1966 27.4694 23.1966 26.0486C23.1966 24.9005 21.5921 23.0528 21.267 22.6918C21.0469 22.4499 20.6725 22.432 20.4305 22.6522C20.4165 22.665 20.403 22.6784 20.3909 22.6918C20.0651 23.0528 18.4613 24.8998 18.4613 26.0486C18.4632 26.4659 18.5777 26.8749 18.7928 27.2326H16.9457C17.1608 26.8749 17.2753 26.4659 17.2773 26.0486C17.2773 24.9005 15.6728 23.0528 15.3477 22.6918C15.1275 22.4499 14.7531 22.432 14.5112 22.6522C14.4971 22.665 14.4837 22.6784 14.4715 22.6918C14.1445 23.0528 12.5406 24.9005 12.5406 26.0486C12.5425 26.4659 12.6571 26.8749 12.8721 27.2326H11.0251C11.2401 26.8749 11.3547 26.4659 11.3566 26.0486C11.3566 24.9005 9.75214 23.0528 9.42702 22.6918C9.20686 22.4499 8.83246 22.432 8.59054 22.6522C8.57646 22.665 8.56302 22.6784 8.55086 22.6918C8.2251 23.0528 6.62062 24.8998 6.62062 26.0486C6.62062 27.4637 7.6331 27.2326 6.02862 27.2326C5.97742 27.2326 5.91854 27.2518 5.85518 27.2838C5.26702 27.5846 5.26702 28.448 5.85518 28.7488C5.91854 28.7808 5.97742 28.8 6.02862 28.8H23.7886C23.8501 28.8 23.9205 28.7725 23.9941 28.7277C24.5214 28.4077 24.5214 27.6243 23.9941 27.3043C23.9198 27.2595 23.8494 27.2326 23.7886 27.2326ZM19.8417 25.977C19.8417 25.6614 20.3051 24.9267 20.828 24.2752C21.3509 24.9312 21.8142 25.671 21.8142 25.977C21.8142 26.5216 21.3726 26.9632 20.828 26.9632C20.2833 26.9632 19.8417 26.5216 19.8417 25.977ZM13.9217 25.977C13.9217 25.6614 14.3851 24.9267 14.908 24.2752C15.4309 24.9312 15.8942 25.671 15.8942 25.977C15.8942 26.5216 15.4526 26.9632 14.908 26.9632C14.3633 26.9632 13.9217 26.5216 13.9217 25.977ZM8.00174 25.977C8.00174 25.6614 8.4651 24.9267 8.98798 24.2752C9.51086 24.9312 9.97422 25.671 9.97422 25.977C9.97422 26.5216 9.53262 26.9632 8.98798 26.9632C8.44334 26.9632 8.00174 26.5216 8.00174 25.977Z"
        fill=""
      />
      <path
        d="M26.6986 8.28711H3.12547C1.78531 8.29927 0.644186 9.39559 0.656346 10.7364C0.660826 11.2221 0.653146 11.7994 0.976986 12.2333C1.35587 12.7409 1.84419 13.114 2.36003 13.3271L4.25635 14.1111V15.9837C4.25635 19.1031 5.68483 21.4749 9.58435 21.3117H20.2403C24.0976 21.3233 25.5683 18.9265 25.5683 15.9837V14.1111L27.5133 13.3073C28.0407 13.0897 28.4874 12.7037 28.7549 12.2001C29.1741 11.4103 29.2349 10.3857 28.8381 9.60999C28.4266 8.80423 27.6023 8.29543 26.6986 8.28711ZM2.89507 11.7751C2.26979 11.4993 1.98691 11.0884 2.26339 10.4631C2.46883 9.99783 2.87395 9.96199 3.38147 9.98311H4.25635V12.3364L2.89507 11.7751ZM23.6803 15.9831C23.6803 17.603 22.529 19.4231 20.2403 19.4231L9.58435 19.4871C7.53315 19.4871 6.14435 18.161 6.14435 15.9831V9.98311H23.6803V15.9831ZM26.9296 11.7751L25.5683 12.3358V9.91911L26.6992 9.85511C27.3821 9.82695 27.6029 10.2513 27.6023 10.849C27.6221 11.3566 27.3943 11.569 26.9296 11.7751Z"
        fill=""
      />
      <path
        d="M18.9935 5.06752C17.4991 6.08 17.9074 7.104 18.7311 7.104C19.1337 7.104 19.4601 6.83904 19.4601 6.512C19.4601 5.96736 20.918 5.79584 20.918 4.5408C20.918 2.96 18.4546 3.02528 19.9266 2.03072C21.3993 1.03616 21.0198 0 20.189 0C19.7865 0 19.4601 0.26496 19.4601 0.592C19.4601 1.13664 18.0022 1.30816 18.0022 2.5632C18.0022 3.81824 19.4601 3.99616 19.4601 4.54016C19.4601 4.736 19.3436 4.83072 18.9935 5.06752Z"
        fill=""
      />
      <path
        d="M14.5485 5.06752C13.0534 6.08 13.4624 7.104 14.2861 7.104C14.6886 7.104 15.015 6.83904 15.015 6.512C15.015 5.96736 16.4729 5.79584 16.4729 4.5408C16.4729 2.96 14.0096 3.02528 15.4816 2.03072C16.9536 1.03616 16.5747 0 15.744 0C15.3414 0 15.015 0.26496 15.015 0.592C15.015 1.13664 13.5571 1.30816 13.5571 2.5632C13.5571 3.81824 15.015 3.99616 15.015 4.54016C15.015 4.736 14.8979 4.83072 14.5485 5.06752Z"
        fill=""
      />
      <path
        d="M10.0875 5.06752C8.59248 6.08 9.00144 7.104 9.82512 7.104C10.2277 7.104 10.5541 6.83904 10.5541 6.512C10.5541 5.96736 12.012 5.79584 12.012 4.5408C12.012 2.96 9.54864 3.02528 11.0206 2.03072C12.4926 1.03616 12.1138 0 11.283 0C10.8805 0 10.5541 0.26496 10.5541 0.592C10.5541 1.13664 9.09616 1.30816 9.09616 2.5632C9.09616 3.81824 10.5541 3.99616 10.5541 4.54016C10.5541 4.736 10.437 4.83072 10.0875 5.06752Z"
        fill=""
      />
    </svg>
  );
};

export default OrderPreparing;
