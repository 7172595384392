const Clock = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 18"
    >
      <path
        id="Vector"
        d="M8.50033 8.99935H7.75033C7.75033 9.32217 7.9569 9.60878 8.26315 9.71086L8.50033 8.99935ZM9.25033 5.45768C9.25033 5.04347 8.91454 4.70768 8.50033 4.70768C8.08611 4.70768 7.75033 5.04347 7.75033 5.45768H9.25033ZM10.3882 10.4192C10.7811 10.5502 11.2059 10.3378 11.3368 9.94485C11.4678 9.5519 11.2555 9.12716 10.8625 8.99617L10.3882 10.4192ZM9.25033 8.99935V5.45768H7.75033V8.99935H9.25033ZM8.26315 9.71086L10.3882 10.4192L10.8625 8.99617L8.7375 8.28784L8.26315 9.71086ZM14.8337 8.99935C14.8337 12.4972 11.9981 15.3327 8.50033 15.3327V16.8327C12.8266 16.8327 16.3337 13.3256 16.3337 8.99935H14.8337ZM8.50033 15.3327C5.00252 15.3327 2.16699 12.4972 2.16699 8.99935H0.666992C0.666992 13.3256 4.17409 16.8327 8.50033 16.8327V15.3327ZM2.16699 8.99935C2.16699 5.50155 5.00252 2.66602 8.50033 2.66602V1.16602C4.17409 1.16602 0.666992 4.67312 0.666992 8.99935H2.16699ZM8.50033 2.66602C11.9981 2.66602 14.8337 5.50155 14.8337 8.99935H16.3337C16.3337 4.67312 12.8266 1.16602 8.50033 1.16602V2.66602Z"
      />
    </svg>
  );
};

export default Clock;
