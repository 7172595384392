import storeClosed from "../../../assets/images/storeClosed.svg";
import { checkEmpty } from "../../../utils/helper";
import Button from "../../Elements/Button";

const StoreClosedAlert = ({ onClose, preOrder, openStatus, storeOpenTime }) => {
  return (
    <div className="flex items-center justify-center h-max">
      <div className="bg-black p-4 rounded-lg shadow-lg w-[361px] h-[80px] md:w-[485px] md:h-[94.2px] lg:w-[485px] lg:h-[107.5px] 2xl:w-[582px] 2xl:h-[129px] flex justify-between items-center">
        {/* Your content goes here */}
        <div className="flex flex-row items-center">
          <img
            className="w-12 md:w-[60px] lg:w-[67.5px] 2xl:w-[81px]"
            loading="lazy"
            src={storeClosed}
            alt=""
          />
          <div className="flex flex-col ml-4 md:ml-5">
            <div className="text-xs md:text-[13px] lg:text-sm 2xl:text-md text-primary font-semibold flex flex-start">
              {preOrder === "Yes" && openStatus === "Pre Order"
                ? "Store closed."
                : "Sorry our store is closed now."}
            </div>
            {preOrder === "Yes" && openStatus === "Pre Order" && (
              <div className="text-xs 2xl:text-md text-white font-semibold flex flex-start md:mt-1">
                But you can pre-order
              </div>
            )}
            {!checkEmpty(storeOpenTime) && (
              <p className="text-gray-500 text-xs 2xl:text-sm mt-2 md:mt-3 lg:mt-[14.83px]">
                Opens at {storeOpenTime}
              </p>
            )}
          </div>
        </div>
        <Button
          onClick={() => onClose()}
          className="bg-primary rounded-sm text-xs 2xl:text-sm inline-flex items-center justify-center text-white lg:mr-6 md:mr-4 2xl:mr-7 py-1.5 px-2.5 md:py-2 md:px-3 2xl:py-2.5 2xl:px-4"
        >
          Dismiss
        </Button>
      </div>
    </div>
  );
};

export default StoreClosedAlert;
