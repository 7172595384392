import ArrowLeftChevron from "../../../components/Icon/ArrowLeftChevron";
import { DayTime } from "../../../components/Elements/DayTimes";

const AboutCollectionStart = ({ onSendData, restaurantInfo }) => {
  const handleClick = () => {
    onSendData(true);
  };

  return (
    <div className="flex flex-col overflow-auto mb-10">
      <div
        className="pb-5 pt-5 md:pb-5 md:pt-10 cursor-pointer"
        onClick={handleClick}
      >
        <div className="flex flex-row items-center">
          <div>
            <ArrowLeftChevron className="w-[12px] h-[12px]" />
          </div>
          <span className="ml-2 text-[13.5px] md:text-[16px] font-inter font-medium">
            Collection Starts at
          </span>
        </div>
      </div>
      <div className="w-auto">
        {restaurantInfo?.storeTiming?.Collection?.map((day, ind) => {
          return (
            <DayTime
              key={ind}
              currentDay={restaurantInfo?.cur_day}
              data={day}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AboutCollectionStart;
