import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyLoadImageComponent = ({
  src,
  alt = "",
  className = "",
  onErrorSrc,
  placeholderSrc,
}) => {
  return (
    <LazyLoadImage
      width={"100%"}
      src={src}
      alt={alt}
      className={className}
      onError={(e) => {
        e.target.src = onErrorSrc;
      }}
      loading="lazy"
      placeholderSrc={placeholderSrc}
    />
  );
};

export default LazyLoadImageComponent;
