import { LocationSpot } from "../Icon/LocationSpot";
import { getLocationFromLatLng } from "../../services";
import { error } from "../../components/notify";
import { useState, useEffect } from "react";
import { postcodeRegEx } from "../../utils";
import { removeAllSpaceFromString, setDineConfigSession } from "../../utils";

const LocationLocator = ({ className, onFinished }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);

  // Mock geolocation for testing purposes
  const getCurrentLocation = () => {
    setIsLoading(true);

    const mockPosition = {
      coords: {
        latitude: 51.509865, // Replace with the latitude for ST13JJ
        longitude: -0.118092, // Replace with the longitude for ST13JJ
      },
    };

    // Simulate the success callback with the mock position
    // handlePositionSuccess(mockPosition);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const { status, data } = await getLocationFromLatLng(
          latitude,
          longitude
        );
        if (status === 200) {
          const { locationData } = data;
          onFinished(locationData);
          if (!postcodeRegEx.test(locationData?.postcode)) {
            error("Enter valid Postcode", true);
          }
        } else {
          error("Failed to fetch location data", true);
        }
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
        switch (err.code) {
          case 1:
            error("Permission denied. Please allow permission", true);
            break;
          case 2:
            error("Unable to get location.", true);
            break;
          case 3:
            error("Timeout. Unable to fetch location", true);
            break;
          default:
            error("Something went wrong!! Please try again", true);
        }
      }
    );
  };

  return (
    <div
      className={`${className} flex justify-center items-center bg-primary text-white py-2 rounded-md cursor-pointer`}
      onClick={getCurrentLocation}
    >
      {isLoading || locationLoading ? (
        <div className="flex">
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p className="pl-2">Loading...</p>
        </div>
      ) : (
        <div className="flex">
          <LocationSpot className="w-5 h-5 fill-white" />
          <p className="pl-2">Detect My Location</p>
        </div>
      )}
    </div>
  );
};

export default LocationLocator;
