import ProfileNav from "./ProfileNav";
import UserIcon from "../Icon/UserIcon";
import LocationIcon from "../Icon/LocationIcon";
import BookmarkIcon from "../Icon/BookMarkIcon";
import Card from "../Icon/Card";
import PastorderIcon from "../Icon/PastorderIcon";
import MyReward from "../Icon/MyReward";
// import Filter from "./icon/Outline/Filter";
import WalletIcon from "../Icon/WalletIcon";
import HamburgerIcon from "../Icon/Hamburger";
import LogoutIcon from "../Icon/Logout";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeUserData } from "../../store/userData/userDataSlice";
import { removeSession, removeDismissedPendingOrders } from "../../utils";
import Modal from "react-modal";
import { useState } from "react";
import Button from "../Elements/Button";
import {
  BookmarksPage,
  ProfilePage,
  AddressPage,
  PastOrdersPage,
  RewardsPage,
  PreferencePage,
  WalletPage,
  BankcardsPage,
  HomePage,
} from "../../routes/RoutesPath";

const ProfileNavBar = ({ className, isSideBarFixed }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [profileImage, setProfileImage] = useState("");

  const navigation = useNavigate();

  const handleLogOut = () => {
    removeSession();
    dispatch(removeUserData());
    navigation(HomePage);
    removeDismissedPendingOrders();
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div
      className={`hidden bg-secondary mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto rounded-lg overflow-y-aouto mdl:flex flex-col justify-between no-scrollbar ${className}`}
    >
      <div className="p-7">
        <ProfileNav title="Profile Info" Icon={UserIcon} to={ProfilePage} />
        <ProfileNav title="Address" Icon={LocationIcon} to={AddressPage} />
        <ProfileNav title="Bookmarks" Icon={BookmarkIcon} to={BookmarksPage} />
        <ProfileNav title="Bank Cards" Icon={Card} to={BankcardsPage} />
        <ProfileNav
          title="Past Orders"
          Icon={PastorderIcon}
          to={PastOrdersPage}
        />
        <ProfileNav title="My Rewards" Icon={MyReward} to={RewardsPage} />
        <ProfileNav
          title="Preference"
          Icon={HamburgerIcon}
          to={PreferencePage}
        />
        <ProfileNav title="Wallet" Icon={WalletIcon} to={WalletPage} />
      </div>
      <div className="border-t p-7">
        <ProfileNav
          title="Logout"
          Icon={LogoutIcon}
          onclick={handleOpenModal}
        />
      </div>
      {/* </div> */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className=" bg-black bg-opacity-50 z-50 "
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 sm:w-96">
          <div className="flex items-center justify-center">
            <img
              className="w-16 h-16 rounded-full object-cover"
              src={
                "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
              }
              alt=""
              loading="lazy"
            />
          </div>
          <div className="text-center font-semibold text-mdl">
            <p className="py-6">Are you sure want to log out?</p>
            <div className="flex justify-center">
              <Button
                title="Okay"
                type="fill"
                fillColor="primary"
                className="bg-primary mr-3 py-3 text-white w-1/2"
                onClick={handleLogOut}
              />
              <Button
                className="border-card-border py-3 border w-1/2"
                onClick={handleCloseModal}
                title="Cancel"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileNavBar;
