import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/Navigation/BreadCrumbs";
import React, { useRef } from "react";
import Layout from "../../layout/Layout";
import { useProgress } from "../../hooks/useProgress";

const PrivacyPolicy = () => {
  const Terms = useRef(null);
  const Policy = useRef(null);

  const settings = useSelector((state) => state?.basicdata);
  const [loader, setLoader] = useState(true);
  const [isFixed, setIsFixed] = useState(false);

  const observerTarget = useRef(null);

  const { progressFunction } = useProgress();
  const dispatch = useDispatch();
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 80);
  };

  return (
    <div className="overflow-hidden	h-max font-[inter]">
      {/* pl-4 pt-4 pb-4 pr-4 sm:pr-8 md:lg:pl-8 md:lg:pt-8 md:lg:pb-8  md:lg:pr-14 */}

      <Breadcrumbs
        className={`${
          isFixed
            ? "fixed animate-fadedown top-[76px] left-0 shadow-xl"
            : "animate-fadeup"
        } bg-white w-full z-50 flex items-center px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] overflow-hidden`}
      />

      <Layout className="carousel w-full bg-slate-200 	  space-x-2 lg:space-x-5  2xl:md:pb-8 xl:pd-8 md:pb-6 pb-7 xl:pl-[45px] pl-[15px] sm:pl-5 md:pl-[25px]">
        <div className=" w-full lg:px-40 lg:py-4">
          <div className="row">
            <h2 className="text-3xl	mb-2.5 mt-8 font-medium	">Privacy Policy</h2>
            <p className="mb-2.5 text-sm	text-gray-600">
              We at Kuick understand and respect the importance our customers
              give to their privacy and as such will only collect data in the
              ways that are described herewith, and in a manner that is
              consistent with our rules and regulations.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">1. Cookies </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Cookies and similar files or technologies may be used by our Kuick
              Platform and third-party partners to collect and retain
              information about your use of the Services and third-party
              websites.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              Cookies are little files that websites, apps, online media, and
              adverts place on your browser or device. Cookies and similar
              technologies are used for a variety of purposes, like
              authenticating users, user choices and settings are remembered,
              determining reach and popularity of content and majorly just
              understanding the interests of people who visit our website.{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">2. US</p>

            <p className="mb-2.5 text-sm	text-gray-600">
              Our site is operated by Kuick, a company registered in the United
              Kingdom, whose registered office is at 539A Leek Road,
              Stoke-on-Trent, Staffordshire, United Kingdom, ST1 3ER.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">1. Policy coverage</p>

            <p className="mb-2.5 text-sm	text-gray-600">
              This Privacy Statement only relates to your usage of our website.
              Any other links found on our site are only for your convenience
              and information. We do not recommend or authorise any of the
              products, services, or viewpoints expressed on any other websites.{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              2. Using your information
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              To enable delivery and other services, as well as features to
              personalise your Kuick account; to supply, personalise, maintain,
              and develop our products and services
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              To fulfil our commitments under any contracts we may have with
              you, as well as to supply you with relevant information and
              services
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              Internal operations, such as troubleshooting, data analysis,
              testing, research, statistical, and survey objectives; to
              administrate and enhance the security of our Kuick Platform;
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              To provide you information on services we believe are comparable
              to those you already use, have inquired about, or would be
              interested in. Registered users will be contacted.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              To better understand our users (what they do on our Services, what
              features they like, how they use them, and so on), to improve the
              content and features of our Services, to process and complete your
              transactions, to make special offers, to provide customer service,
              and to process and respond to your queries
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              To develop and review reports and data about our user base and
              Service usage trends, as well as to undertake research on them;
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              3. What information do we collect?
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">• Name</p>
            <p className="mb-2.5 text-sm	text-gray-600">• Address</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              • Contact information (email or phone number)
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">• Delivery address</p>
            <p className="mb-2.5 text-sm	text-gray-600">• IP address</p>
            <p className="mb-2.5 text-sm	text-gray-600">• Operating system</p>
            <p className="mb-2.5 text-sm	text-gray-600">• Web browser details</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              • Demographic information
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              4. How is your information stored?
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              We only keep your personal information as long as we need to, for
              utilising it and for as long as we have your permission. We also
              take suitable measures to protect your data.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              5. Uses of your information
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              We might sometimes share your information with other third parties
              to supply goods and services to you on your behalf. Whenever such
              an occasion arises, we will take all required steps to ensure safe
              and secure handling of your data, in accordance with your rights,
              our rules, and the obligations of the third party.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              We may accumulate statistics about how people use our site, such
              as traffic patterns, user numbers, purchases, and other data. All
              such information will not contain any personally identifying
              information or will not be used in any way to identify you. We may
              share such information with third parties on occasion, such as
              potential investors, affiliates, partners, and advertisements.{" "}
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              We may utilise third-party data processors based outside of the
              European Economic Area on occasion (""the EEA"") (The EEA includes
              all EU member nations as well as Norway, Iceland, and the
              Netherlands.)
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              In certain circumstances, we may be legally required to share
              certain data held by us, which may include your personal data, for
              example, when we are involved in legal proceedings, where we are
              complying with legal requirements, a court order, or a
              governmental authority.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              6. Withholding information
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              You may browse certain areas of the website without providing any
              information at all. However, to be able to properly use all the
              features and functions, you would need to provide certain data or
              allow it’s collection.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              You have the option to limit our use of cookies. In your internet
              browser, you can activate or disable cookies. Most Internet
              browsers accept cookies by default, but this can be adjusted.
              Please refer to the help menu in your browser or the documentation
              that comes with your device for further information. You can
              delete Cookies from your computer or device at any time, but you
              will lose any information that allows you to use our Site more
              quickly and effectively if you do so.
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              7. Changes to our privacy policy
            </p>

            <p className="mb-2.5 text-sm	text-gray-600">
              We might change this privacy policy whenever the need arises. Any
              changes will immediately be informed to you by posting on our site
              and you would need to accept those to continue using our site.{" "}
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default PrivacyPolicy;
