import parse from "html-react-parser";
import { useEffect, useState } from "react";
import ArrowLeftChevron from "../../../components/Icon/ArrowLeftChevron";

const AboutInfo = ({ storeData, onSendData, restaurantInfo }) => {
  const [aboutElement, setAboutElement] = useState(null);

  useEffect(() => {
    setElement();
  }, [restaurantInfo]);

  const setElement = () => {
    setAboutElement(parse(restaurantInfo?.storeContent?.about ?? ""));
  };

  const handleClick = () => {
    onSendData(true);
  };

  return (
    <div className={`flex flex-col pb-40 sm:pb-12`}>
      <div>
        <div
          onClick={handleClick}
          className="flex flex-row items-center cursor-pointer pt-5 md:pt-10"
        >
          <div>
            <ArrowLeftChevron className="w-[12px] h-[12px]" />
          </div>
          <span className="text-sm md:text-md font-inter font-medium ml-1">
            About {storeData?.store_Name}
          </span>
        </div>
        <div className="text-[12px] md:text-[15px] font-inter text-loyalityDate">
          We Make Sure Quality & Healthy Foods
        </div>
      </div>
      <div className="mt-5 text-sm md:text-[15px] font-inter font-medium">
        {aboutElement}
      </div>
    </div>
  );
};

export default AboutInfo;
