import PropTypes from "prop-types";

const InputField = ({
  title="",
  onChange,
  required,
  placeholder,
  value,
  disabled,
  onBlur,
  maxLength,
  autoComplete,
  autoFill,
}) => {
  return (
    <div className="space-y-3 w-full">
      <div>
        <span className=" text-[15px] sm:text-[16px] text-gray-500  font-normal font-inter leading-snug">
          {title}
        </span>
        {required && (
          <span className="text-rose-600 text-[15px] sm:text-[16px] font-normal font-inter leading-snug">
            *
          </span>
        )}
      </div>
      <input
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        autoComplete={autoComplete}
        className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 focus:outline-none block w-full ps-3.5 p-2.5"
      />
    </div>
  );
};
InputField.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
  maxLength: PropTypes.string,
  onBlur: PropTypes.func,
};

export default InputField;
