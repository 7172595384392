// import React from "react";

const GreenBellMarker = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-7 md:w-8  shadow-xl"
      viewBox="0 0 40 46"
      fill="none"
    >
      \
      <path
        d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
        fill="#05B011"
      />
      \
      <path d="M20 46C20 41.6 26.6667 38.1667 30 37H20V46Z" fill="#05B011" />
      \
      <path d="M20 46C20 41.6 13.3333 38.1667 10 37H20V46Z" fill="#05B011" />
      \
      <path
        d="M29 24.8814C29 25.0693 28.9254 25.2494 28.7925 25.3823C28.6597 25.5151 28.4795 25.5897 28.2917 25.5897H12.7083C12.5205 25.5897 12.3403 25.5151 12.2075 25.3823C12.0746 25.2494 12 25.0693 12 24.8814C12 24.6935 12.0746 24.5134 12.2075 24.3805C12.3403 24.2477 12.5205 24.1731 12.7083 24.1731H28.2917C28.4795 24.1731 28.6597 24.2477 28.7925 24.3805C28.9254 24.5134 29 24.6935 29 24.8814ZM13.4167 22.7564H27.5833C27.7712 22.7564 27.9514 22.6818 28.0842 22.5489C28.217 22.4161 28.2917 22.2359 28.2917 22.0481V21.3397C28.2891 19.397 27.5615 17.5252 26.2514 16.0907C24.9412 14.6563 23.1429 13.7624 21.2083 13.5842V12.6435C21.4784 12.4876 21.6895 12.2469 21.8088 11.9588C21.9282 11.6707 21.9491 11.3512 21.8684 11.05C21.7877 10.7488 21.6098 10.4826 21.3624 10.2927C21.115 10.1029 20.8119 10 20.5 10C20.1881 10 19.885 10.1029 19.6376 10.2927C19.3902 10.4826 19.2123 10.7488 19.1316 11.05C19.0509 11.3512 19.0718 11.6707 19.1912 11.9588C19.3105 12.2469 19.5216 12.4876 19.7917 12.6435V13.5842C17.8571 13.7624 16.0588 14.6563 14.7486 16.0907C13.4385 17.5252 12.7109 19.397 12.7083 21.3397V22.0481C12.7083 22.2359 12.783 22.4161 12.9158 22.5489C13.0486 22.6818 13.2288 22.7564 13.4167 22.7564Z"
        fill="white"
      />
      \
    </svg>
  );
};

export default GreenBellMarker;
