import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  pendingList: false,
  dismissedPendingList: false,
};

const pendingOrderSlice = createSlice({
  name: "pendingOrder",
  initialState,
  reducers: {
    setPendingOrders: (state, actions) => {
      const { id, pendingList } = actions.payload;
      state.id = id;
      state.pendingList = pendingList;
    },
    setId: (state, actions) => {
      state.id = actions.payload;
    },
    setPendingList: (state, actions) => {
      state.pendingList = actions.payload;
    },
    setDismissedPendingList: (state, actions) => {
      state.dismissedPendingList = actions.payload;
    },
  },
});

export const pendingOrderReducer = pendingOrderSlice.reducer;
export const {
  setPendingOrders,
  setId,
  setPendingList,
  setDismissedPendingList,
} = pendingOrderSlice.actions;
