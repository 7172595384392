const Home = ({ className }) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path d="M21.7767 8.42128L11.8223 1.10578C11.6304 0.964739 11.3696 0.964739 11.1777 1.10578L1.22335 8.42128C0.980213 8.59999 0.927385 8.94268 1.10543 9.18672C1.28348 9.43077 1.62494 9.48371 1.86804 9.30508L11.5 2.22643L21.132 9.30504C21.2291 9.37647 21.342 9.41084 21.4539 9.41084C21.6219 9.41084 21.7876 9.33324 21.8945 9.18668C22.0726 8.94268 22.0198 8.59999 21.7767 8.42128Z" />
        <path d="M19.139 9.42904C18.8377 9.42904 18.5934 9.67424 18.5934 9.9767V18.6941H14.2284V13.9356C14.2284 12.4255 13.0044 11.1971 11.5 11.1971C9.99568 11.1971 8.77169 12.4256 8.77169 13.9356V18.6941H4.40669V9.97674C4.40669 9.67428 4.16236 9.42908 3.86106 9.42908C3.55976 9.42908 3.31543 9.67428 3.31543 9.97674V19.2418C3.31543 19.5443 3.55976 19.7895 3.86106 19.7895H9.31732C9.60427 19.7895 9.83908 19.567 9.86082 19.2845C9.86213 19.2717 9.86295 19.2577 9.86295 19.2418V13.9356C9.86295 13.0295 10.5973 12.2924 11.5 12.2924C12.4028 12.2924 13.1371 13.0296 13.1371 13.9356V19.2418C13.1371 19.2576 13.138 19.2713 13.1393 19.284C13.1608 19.5667 13.3957 19.7895 13.6828 19.7895H19.139C19.4404 19.7895 19.6847 19.5443 19.6847 19.2418V9.97674C19.6846 9.67424 19.4403 9.42904 19.139 9.42904Z" />
        <path
          d="M21.7767 8.42128L11.8223 1.10578C11.6304 0.964739 11.3696 0.964739 11.1777 1.10578L1.22335 8.42128C0.980213 8.59999 0.927385 8.94268 1.10543 9.18672C1.28348 9.43077 1.62494 9.48371 1.86804 9.30508L11.5 2.22643L21.132 9.30504C21.2291 9.37647 21.342 9.41084 21.4539 9.41084C21.6219 9.41084 21.7876 9.33324 21.8945 9.18668C22.0726 8.94268 22.0198 8.59999 21.7767 8.42128Z"
          strokeWidth="0.2"
        />
        <path
          d="M19.139 9.42904C18.8377 9.42904 18.5934 9.67424 18.5934 9.9767V18.6941H14.2284V13.9356C14.2284 12.4255 13.0044 11.1971 11.5 11.1971C9.99568 11.1971 8.77169 12.4256 8.77169 13.9356V18.6941H4.40669V9.97674C4.40669 9.67428 4.16236 9.42908 3.86106 9.42908C3.55976 9.42908 3.31543 9.67428 3.31543 9.97674V19.2418C3.31543 19.5443 3.55976 19.7895 3.86106 19.7895H9.31732C9.60427 19.7895 9.83908 19.567 9.86082 19.2845C9.86213 19.2717 9.86295 19.2577 9.86295 19.2418V13.9356C9.86295 13.0295 10.5973 12.2924 11.5 12.2924C12.4028 12.2924 13.1371 13.0296 13.1371 13.9356V19.2418C13.1371 19.2576 13.138 19.2713 13.1393 19.284C13.1608 19.5667 13.3957 19.7895 13.6828 19.7895H19.139C19.4404 19.7895 19.6847 19.5443 19.6847 19.2418V9.97674C19.6846 9.67424 19.4403 9.42904 19.139 9.42904Z"
          strokeWidth="0.2"
        />
      </g>
    </svg>
  );
};

export default Home;
