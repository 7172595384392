const Search = ({ className, findStore, fill="#28303F" }) => {
  const findStoreFun = () => {
    // findStore();
  };

  return (
    <svg
      onClick={findStoreFun}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={` cursor-pointer ${className}`}
    >
      <path
        d="M19.0303 17.9697C18.7374 17.6768 18.2626 17.6768 17.9697 17.9697C17.6768 18.2626 17.6768 18.7374 17.9697 19.0303L19.0303 17.9697ZM21.4697 22.5303C21.7626 22.8232 22.2374 22.8232 22.5303 22.5303C22.8232 22.2374 22.8232 21.7626 22.5303 21.4697L21.4697 22.5303ZM21.75 11.5C21.75 5.83908 17.1609 1.25 11.5 1.25V2.75C16.3325 2.75 20.25 6.66751 20.25 11.5H21.75ZM11.5 1.25C5.83908 1.25 1.25 5.83908 1.25 11.5H2.75C2.75 6.66751 6.66751 2.75 11.5 2.75V1.25ZM1.25 11.5C1.25 17.1609 5.83908 21.75 11.5 21.75V20.25C6.66751 20.25 2.75 16.3325 2.75 11.5H1.25ZM11.5 21.75C17.1609 21.75 21.75 17.1609 21.75 11.5H20.25C20.25 16.3325 16.3325 20.25 11.5 20.25V21.75ZM17.9697 19.0303L21.4697 22.5303L22.5303 21.4697L19.0303 17.9697L17.9697 19.0303Z"
        fill={fill}
        strokeWidth={0.5}
      />
    </svg>
  );
};

export default Search;
