// notify/index.js

// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import NotificationsManager from "./NotificationsManager";
import Notification, { Color } from "./Notification";
import createContainer from "./createContainer";

const containerElement = createContainer();
let notify;

ReactDOM.createRoot(document.getElementById("root")).render(
  <NotificationsManager
    setNotify={(notifyFn) => {
      notify = notifyFn;
    }}
  />
  // containerElement,
);

export { Notification, Color };

export function info(children, autoClose) {
  return notify({
    color: Color.info,
    children,
    autoClose,
    type: "info",
  });
}

export function success(children, autoClose) {
  return notify({
    color: Color.success,
    children,
    autoClose,
    type: "success",
  });
}

export function warning(children, autoClose) {
  return notify({
    color: Color.warning,
    children,
    autoClose,
    type: "warning",
  });
}

export function error(children, autoClose) {
  return notify({
    color: Color.error,
    children,
    autoClose,
    type: "error",
  });
}
