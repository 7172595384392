const EditRectangle = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M18.3333 10.0003V15.0003C18.3333 16.8413 16.8409 18.3337 15 18.3337H4.99999C3.15904 18.3337 1.66666 16.8413 1.66666 15.0003V5.00033C1.66666 3.15938 3.15904 1.66699 4.99999 1.66699H9.99999M13.072 3.35262C13.072 3.35262 13.072 4.54453 14.2639 5.73644C15.4558 6.92836 16.6477 6.92836 16.6477 6.92836M7.62888 13.325L10.1319 12.9674C10.493 12.9158 10.8275 12.7485 11.0854 12.4906L17.8396 5.73644C18.4979 5.07817 18.4979 4.01089 17.8396 3.35261L16.6477 2.1607C15.9894 1.50242 14.9221 1.50242 14.2639 2.1607L7.50969 8.91488C7.2518 9.17277 7.08451 9.50736 7.03293 9.86841L6.67535 12.3714C6.59589 12.9277 7.07266 13.4044 7.62888 13.325Z"
        stroke="#FF0A38"
      />
      <defs>
        <clipPath id="clip0_16786_58987">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditRectangle;
