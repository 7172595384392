import { useState, useEffect, useRef } from "react";
import { Drawer } from "@mui/material";
import OrderModal from "../../components/Modal/OrderModal";
import Card from "../../components/Cards/Card";
import { pastordersData } from "../../services";
import Button from "../../components/Elements/Button";
import PastCardLoader from "../../components/Loader/ProfilePageLoaders/PastCardLoader/PastCardLoader";
import MotionWrapper from "../../components/Animation/MotionWrapper";
import { error } from "../../components/notify";
import { viewStatus } from "../../services";
import nodata_found from "../../assets/images/no-data-found/profile/pastorders/nodata.png";
import Modal from "../../components/Modal/index";
import logo_cover from "../../assets/images/default_images/logo_cover.jpg";
// import CloseIcon from "../../assets/images/profile_section/close.png";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";
import { completeDateFormatter } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { TrackFoodPage } from "../../routes/RoutesPath";
import MenuLoader from "../../assets/images/menuImageLoader.png";
import PastOrderCheckCircle from "../../components/Icon/PastOrderCheckCircle";
import { setStoreDetails } from "../../store/basicData/basicDataSlice";

const OrderCardsList = () => {
  // const [selectedOrder, setSelectedOrder] = useState(null);
  const [pastorderDetails, setPastOrdersDetails] = useState([]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(5);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [orderDetails, setOrderDetails] = useState("");
  const [basketStoreDetails, setBasketStoreDetails] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const divRef = useRef(null); // Ref to access the scroll container
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);
  const navigate = useNavigate();
  const [isFirstTimeLoad, setIsFirstTimeLoad] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [hideLoadMore, setHideLoadMore] = useState(false);

  // useEffect(() => {
  //   getPastOrders();
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [skip]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPastOrders();
  }, [skip]);

  useEffect(() => {
    if (isLoader) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
        //  console.log(count);
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoader]);

  // const getPastOrders = async () => {
  //   setIsLoader(true);
  //   try {
  //     const { status, data } = await pastordersData();
  //     if (status === 200) {
  //       if (data.status === "success") {
  //         const orders = data.orders;
  //         setPastOrdersDetails([...pastorderDetails, ...orders]);
  //         console.log("data.orders", data.orders);
  //         setIsLoader(false);
  //         if (orders.length === 0) {
  //           setReachedEnd(true);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("error msg", error);
  //   } finally {
  //     setIsLoader(false);
  //   }
  // };
  const getPastOrders = async () => {
    isFirstTimeLoad ? setIsLoader(true) : setIsLoadMore(true);
    // setIsLoader(true);
    try {
      const { status, data } = await pastordersData(skip, take);
      if (status === 200) {
        if (data.status === "success") {
          const orders = data.orders;
          setPastOrdersDetails((prevItem) => [...prevItem, ...orders]);
          // console.log("data.orders", data.orders);
          setIsLoader(false);
          setIsLoadMore(false);
          if (orders.length < 5) {
            setReachedEnd(true);
            setHideLoadMore(true);
          }
        }
        setIsLoader(false);
        setIsLoadMore(false);
      }
    } catch (error) {
      setIsLoader(false);
      setIsLoadMore(false);
      console.error("error msg", error);
    } finally {
      setIsLoader(false);
      setIsLoadMore(false);
    }
  };

  const loadMoreOrders = () => {
    setIsFirstTimeLoad(false);
    let currentObjCount = pastorderDetails.length;
    setSkip(currentObjCount);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleViewOrder = async (card) => {
    // console.log("cardcard", card);
    const basketKey = card.pos_basket_Key;
    setIsLoader(false);
    try {
      const { status, data } = await viewStatus(basketKey);
      if (status === 200) {
        if (data?.status === "success") {
          //  document.body.style.overflow = "hidden";
          dispatch(setStoreDetails(data?.storedetails));
          setOrderDetails(data?.basket);
          setBasketStoreDetails(data?.storedetails);
          setIsOpen(true);
        }
        setIsLoader(false);
      }
    } catch (e) {
      setIsLoader(false);
      error("error msg");
    } finally {
      setIsLoader(false);
    }
    // setSelectedOrder(pos_basket_Id);
  };

  // const handleCloseModal = () => {
  //   setSelectedOrder(null);
  // };

  const handleClose = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 1000); // Wait for the animation to complete (400ms) before calling onClose
  };

  // const handleScroll = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //     console.log("bottom:", bottom);
  //     console.log("isLoader:", isLoader);
  //     console.log("reachedEnd:", reachedEnd);
  //   if (bottom && !isLoader && !reachedEnd) {
  //     setSkip(skip + 1);
  //   }
  // };

  // const handleScroll = () => {
  //   const bottom =
  //     document.documentElement.scrollHeight -
  //       document.documentElement.scrollTop ===
  //     document.documentElement.clientHeight;
  //   if (bottom && !isLoader && !reachedEnd) {
  //     setSkip(skip + 1);
  //   }
  // };

  // const handleScroll = () => {
  //   if (divRef.current) {
  //     const bottom =
  //       divRef.current.scrollHeight - divRef.current.scrollTop ===
  //       divRef.current.clientHeight;
  //     if (bottom && !isLoader && !reachedEnd) {
  //       setSkip(skip + 1);
  //     }
  //   }
  // };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const trackingFunction = (card) => {
    navigate(TrackFoodPage + card.pos_basket_Key);
  };

  return (
    // <div className="mt-4 pb-5" onScroll={handleScroll}>
    <div
      className="my-4 mdl:overflow-y-auto mdl:overflow-drawer mdl:h-auto overflow-y-scroll no-scrollbar"
      ref={divRef}
    >
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        {orderDetails && (
          <OrderModal
            toggleDrawer={toggleDrawer}
            orderDetails={orderDetails}
            basketStoreDetails={basketStoreDetails}
          />
        )}
      </Drawer>
      {isLoader && !reachedEnd ? (
        <PastCardLoader
          subClassName={`grid grid-cols-1 gap-4`}
          subsubClassName={`h-36`}
          numberShimmerLine="6"
        />
      ) : pastorderDetails?.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 stopp">
          {pastorderDetails &&
            pastorderDetails?.map((card, index) => (
              <Card
                // key={card.id}
                key={`${card?.id}-${index}`}
                className={`rounded-lg border-none bg-white p-4`}
              >
                <div className="hidden xl:flex items-center justify-between">
                  <div className="grid grid-cols-2 sm:flex sm:space-x-6 lg:items-center">
                    <div className="lg:w-[100px] lg:h-[70px] xl:w-32 xl:h-32 flex">
                      <LazyLoadImageComponent
                        src={card?.store_Logo}
                        alt=""
                        className="rounded-lg"
                        onErrorSrc={logo_cover}
                        loading="lazy"
                        placeholderSrc={logo_cover}
                      />
                    </div>
                    <div className="m-0 sm:mt-2 font-inter">
                      <h3 className="font-semibold text-md sm:text-p6 sm:pb-2">
                        {card?.store_Name}
                      </h3>
                      <p className="font-medium text-xs sm:text-md text-loyalityDate pb-2">
                        {/* {card.store_Currency}
                        {card.pos_basket_Total} */}
                        {completeDateFormatter(
                          card?.pos_basket_Order_Date_Pretty
                        )}
                      </p>
                      {/* <p className="text-neutral-400 text-sm my-2">
                        {card.basketData}
                      </p> */}
                      <div
                        className={`flex items-center  ${
                          card?.pos_basket_Status.toLowerCase() === "cooking"
                            ? "bg-light-shade-1"
                            : card?.pos_basket_Status.toLowerCase() ===
                              "ordered"
                            ? "bg-blue-shade-2"
                            : card?.pos_basket_Status.toLowerCase() ===
                                "completed" ||
                              card?.pos_basket_Status.toLowerCase() ===
                                "delivered" ||
                              card?.pos_basket_Status.toLowerCase() === "hide"
                            ? "bg-green-shade-7"
                            : "bg-gray-300"
                        }  w-fit px-2 py-1 rounded-lg`}
                      >
                        {/* <div className="w-4 mr-1">
                          {card.pos_basket_Status.toLowerCase() ===
                          "cooking" ? (
                            <img
                              className="max-w-full"
                              loading="lazy"
                              src={InfoIcon}
                              alt="On the way"
                            />
                          ) : card.pos_basket_Status.toLowerCase() ===
                              "completed" ||
                            card.pos_basket_Status.toLowerCase() ===
                              "delivered" ? (
                            <PastOrderCheckCircle className="fill-green-shade-8 w-4 h-4" />
                          ) : (
                            ""
                          )}
                        </div> */}
                        <p className="font-normal text-xs sm:text-md font-inter">
                          {card?.pos_basket_Status?.toLowerCase() ===
                          "cooking" ? (
                            <span className="text-status-light">
                              {card?.pos_basket_Status}
                            </span>
                          ) : card?.pos_basket_Status?.toLowerCase() ===
                            "ordered" ? (
                            <span className="text-blue-shade-1">
                              {card?.pos_basket_Status}
                            </span>
                          ) : card?.pos_basket_Status?.toLowerCase() ===
                              "completed" ||
                            card?.pos_basket_Status?.toLowerCase() ===
                              "delivered" ||
                            card?.pos_basket_Status?.toLowerCase() ===
                              "hide" ? (
                            <span className="text-green-shade-8">
                              {card?.pos_basket_Delivery_Type == "Delivery"
                                ? "Delivered"
                                : "Completed"}
                            </span>
                          ) : (
                            <span className="text-gray-400">
                              {card?.pos_basket_Status}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-3 mt-5 sm:mt-0">
                    {card && card?.pos_basket_Status !== "Hide" && (
                      <Button
                        className="rounded-lg mr-2 border w-[151px] h-[40px] sm:w-[123px] sm:h-[46px] border-loyalityDate text-sm text-loyalityDate hover:bg-loyalityDate	hover:text-white"
                        onClick={() => trackingFunction(card)}
                      >
                        Track Order
                      </Button>
                    )}

                    <div className="w-max  md:w-[86] md:h-[40px] ">
                      <input
                        id="order-modal"
                        type="checkbox"
                        className="drawer-toggle pdrawer-toggle"
                        checked={isOpen}
                        onChange={toggleDrawer}
                      />
                      <div className="drawer-content">
                        <label
                          htmlFor="order-modal"
                          className="drawer-button space-x-3 md:space-x-2 sm:flex items-center cursor-pointer "
                        >
                          <Button
                            className="rounded-lg cursor-pointer border w-[151px] h-[40px] sm:w-[123px] sm:h-[46px] border-primary text-sm text-primary hover:bg-primary	hover:text-white"
                            onClick={() => {
                              handleViewOrder(card);
                            }}
                          >
                            View Order
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden xs:flex xl:hidden items-center">
                  <div className="w-36 h-36 rounded-lg">
                    <LazyLoadImageComponent
                      src={card?.store_Logo}
                      alt=""
                      className="rounded-lg"
                      onErrorSrc={logo_cover}
                      loading="lazy"
                      placeholderSrc={logo_cover}
                    />
                  </div>
                  <div className="">
                    <div className="ml-5 font-inter">
                      <h3 className="font-semibold text-md">
                        {card?.store_Name}
                      </h3>
                      <p className="font-medium text-xs text-loyalityDate">
                        {/* {card.store_Currency}
                        {card.pos_basket_Total} */}
                        {completeDateFormatter(
                          card?.pos_basket_Order_Date_Pretty
                        )}
                      </p>
                      {/* <p className="text-neutral-400 text-sm my-2">
                        {card.basketData}
                      </p> */}
                      <div
                        className={`flex items-center ${
                          card?.pos_basket_Status?.toLowerCase() === "cooking"
                            ? "bg-light-shade-1"
                            : card?.pos_basket_Status?.toLowerCase() ===
                              "ordered"
                            ? "bg-blue-shade-2"
                            : card?.pos_basket_Status?.toLowerCase() ===
                                "completed" ||
                              card?.pos_basket_Status?.toLowerCase() ===
                                "delivered" ||
                              card?.pos_basket_Status?.toLowerCase() === "hide"
                            ? "bg-green-shade-7"
                            : "bg-gray-300"
                        } xs:my-5 xl:my-0 w-fit px-2 py-1 rounded-lg`}
                      >
                        {/* <div className="w-4 mr-1">
                          {card.pos_basket_Status !==
                          ("hide" || "cooking" || "On the way") ? (
                            <img
                              loading="lazy"
                              className="max-w-full"
                              src={InfoIcon}
                              alt="On the way"
                            />
                          ) : (
                            <img
                              loading="lazy"
                              className="max-w-full"
                              src={CheckIcon}
                              alt="Not on the way"
                            />
                          )}
                        </div> */}
                        <p className="font-normal text-xs font-inter">
                          {card?.pos_basket_Status?.toLowerCase() ===
                          "cooking" ? (
                            <span className="text-status-light">
                              {card?.pos_basket_Status}
                            </span>
                          ) : card?.pos_basket_Status?.toLowerCase() ===
                            "ordered" ? (
                            <span className="text-blue-shade-1">
                              {card?.pos_basket_Status}
                            </span>
                          ) : card?.pos_basket_Status.toLowerCase() ===
                              "completed" ||
                            card?.pos_basket_Status.toLowerCase() ===
                              "delivered" ||
                            card?.pos_basket_Status.toLowerCase() === "hide" ? (
                            <span className="text-green-shade-8">
                              {card?.pos_basket_Delivery_Type == "Delivery"
                                ? "Delivered"
                                : "Completed"}
                            </span>
                          ) : (
                            <span className="text-gray-400">
                              {card?.pos_basket_Status}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex ml-5">
                      {card && card?.pos_basket_Status !== "Hide" && (
                        <Button
                          className="rounded-lg border w-24 h-8 border-loyalityDate text-xs text-loyalityDate mr-2 hover:bg-loyalityDate	hover:text-white"
                          onClick={() => trackingFunction(card)}
                        >
                          Track Order
                        </Button>
                      )}
                      <div className="drawer w-max drawer-end  md:w-[86] md:h-[40px] ">
                        <Button
                          className="rounded-lg border w-24 h-8 border-primary text-xs text-primary hover:bg-primary	hover:text-white"
                          onClick={() => handleViewOrder(card)}
                        >
                          View Order
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block xs:hidden items-center justify-between">
                  <div className="grid grid-cols-2 sm:flex sm:space-x-6">
                    <div className="w-32 h-32 rounded-lg flex">
                      <LazyLoadImageComponent
                        src={card?.store_Logo}
                        alt=""
                        className="rounded-lg"
                        onErrorSrc={logo_cover}
                        loading="lazy"
                        placeholderSrc={logo_cover}
                      />
                    </div>
                    <div className="m-0 sm:mt-2 font-inter">
                      <h3 className="font-semibold text-md sm:text-p6 sm:pb-2">
                        {card?.store_Name}
                      </h3>
                      <p className="font-medium text-xs sm:text-md text-loyalityDate pb-2">
                        {/* {card.store_Currency}
                        {card.pos_basket_Total} */}
                        {card?.pos_basket_Order_Date_Pretty}
                      </p>
                      {/* <p className="text-neutral-400 text-sm my-2">
                        {card.basketData}
                      </p> */}
                      <div
                        className={`flex items-center ${
                          card?.pos_basket_Status.toLowerCase() === "cooking"
                            ? "bg-light-shade-1"
                            : card?.pos_basket_Status?.toLowerCase() ===
                                "completed" ||
                              card?.pos_basket_Status?.toLowerCase() ===
                                "delivered" ||
                              card?.pos_basket_Status?.toLowerCase() === "hide"
                            ? "bg-green-shade-7"
                            : card?.pos_basket_Status?.toLowerCase() ===
                              "ordered"
                            ? "bg-blue-shade-2"
                            : "bg-gray-300"
                        }  w-fit px-2 py-1 rounded-lg`}
                      >
                        {/* <div className="w-4 mr-1">
                          {card.pos_basket_Status !==
                          ("hide" || "cooking" || "On the way") ? (
                            <img
                              className="max-w-full"
                              loading="lazy"
                              src={InfoIcon}
                              alt="On the way"
                            />
                          ) : (
                            <img
                              className="max-w-full"
                              loading="lazy"
                              src={CheckIcon}
                              alt="Not on the way"
                            />
                          )}
                        </div> */}
                        <p className="font-normal text-xs sm:text-md font-inter">
                          {card?.pos_basket_Status?.toLowerCase() ===
                          "cooking" ? (
                            <span className="text-status-light">
                              {card?.pos_basket_Status}
                            </span>
                          ) : card?.pos_basket_Status?.toLowerCase() ===
                            "ordered" ? (
                            <span className="text-blue-shade-1">
                              {card?.pos_basket_Status}
                            </span>
                          ) : card?.pos_basket_Status?.toLowerCase() ===
                              "completed" ||
                            card?.pos_basket_Status?.toLowerCase() ===
                              "delivered" ||
                            card?.pos_basket_Status?.toLowerCase() ===
                              "hide" ? (
                            <span className="text-green-shade-8">
                              {card?.pos_basket_Delivery_Type == "Delivery"
                                ? "Delivered"
                                : "Completed"}
                            </span>
                          ) : (
                            <span className="text-gray-400">
                              {card?.pos_basket_Status}
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-3 mt-5 sm:mt-0">
                    {card && card?.pos_basket_Status !== "Hide" && (
                      <Button
                        className="rounded-lg mr-2 border w-[151px] h-[40px] sm:w-[123px] sm:h-[46px] border-loyalityDate text-sm text-loyalityDate hover:bg-loyalityDate	hover:text-white"
                        onClick={() => trackingFunction(card)}
                      >
                        Track Order
                      </Button>
                    )}
                    <div className="drawer drawer-end w-max  md:w-[86] md:h-[40px] ">
                      <input
                        id="order-modal"
                        type="checkbox"
                        className="drawer-toggle pdrawer-toggle"
                        checked={isOpen}
                        onChange={toggleDrawer}
                      />
                      <div className="drawer-content">
                        <label
                          htmlFor="order-modal"
                          className="drawer-button space-x-3 md:space-x-2 sm:flex items-center cursor-pointer "
                        >
                          <Button
                            className="rounded-lg cursor-pointer border w-[151px] h-[40px] sm:w-[123px] sm:h-[46px] border-primary text-sm text-primary hover:bg-primary	hover:text-white"
                            onClick={() => handleViewOrder(card)}
                          >
                            View Order
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          {isLoadMore ? (
            <div
              className={`grid grid-cols-1 gap-4 h-28 sm:h-40 bg-gray-300 rounded-lg `}
            >
              <div className="flex ">
                <div className="w-32 h-32 rounded-lg flex m-[10px] p-[10px]">
                  <img src={MenuLoader} alt="" srcSet="" />
                </div>
                <div className="mt-10 font-inter">
                  <h3 className="font-semibold text-md sm:text-p6 mb-2 w-40 h-2  rounded-md bg-gray-400 "></h3>
                  <p className="font-medium text-xs sm:text-md bg-gray-400 rounded-md h-2 w-20 mb-2"></p>
                  <div>
                    <div className="w-40 h-2 mr-1 bg-gray-400  rounded-md mb-2"></div>
                    <p className="font-normal text-xs sm:text-md font-inter  rounded-md h-2 w-20 bg-gray-400 mb-2"></p>
                    <div className="w-40 h-2 mr-1 bg-gray-400  rounded-md mb-2"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Button
              className={`text-primary sm:mb-16 md:mb-16 mdl:mb-0 text-xs ${
                hideLoadMore ? "hidden" : "block"
              }`}
              onClick={loadMoreOrders}
            >
              View More Orders
            </Button>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center w-full sm:min-h-[calc(100vh_-_17rem)] sm:h-auto">
          <div className=" lg:w-[350px] md:w-[200px]">
            <img src={nodata_found} alt="No data found image" />
          </div>
        </div>
      )}

      {/* <Modal
        visible={isModalOpen}
        onClose={closeModal}
        className=" bg-black bg-opacity-50 z-50 "
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
        subClassName={`w-[95%]`}
      >
        {orderDetails && (
          <OrderModal orderDetails={orderDetails} className={`h-max py-5`} />
        )}
      </Modal> */}
    </div>
  );
};

const PastOrders = () => {
  const timeOptions = [
    "Most Frequently",
    "Last 7 days",
    "Last One Month",
    "Last Three Month",
    "Last Six Month",
    "Last Year",
    "Last Two Years",
  ];

  const statusOptions = ["Order", "On The Way", "Delivered", "Cancelled Order"];

  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedTimeOption, setSelectedTimeOption] = useState(timeOptions[0]); // Default selected option
  const [selectedOption, setSelectedOption] = useState(null);

  const openFilter = () => {
    setShowTooltip((prev) => !prev);
  };

  const closeFilter = () => {
    setShowTooltip(false);
  };

  const handleTimeOptionSelect = (option) => {
    setSelectedTimeOption(option);
  };

  const handleButtonClick = (index) => {
    setSelectedOption(index);
  };

  return (
    <MotionWrapper>
      <div className="overflow-y-auto">
        {/* {isLoader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
        </div>
      )} */}
        <div className="mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto md:pt-7 md:pl-7 md:pr-7">
          <div className="flex items-center justify-between relative">
            <div className="text-lg font-bold sm:text-xl sm:font-semibold sm:font-inter">
              Past Orders
            </div>
            {/* <div className="block sm:hidden relative bottom-4">
              <div className="absolute w-8 bg-white rounded-lg p-2 right-1 cursor-pointer">
                <img
                  className="max-w-full"
                  src={FilterIcon}
                  alt="Profile"
                  onClick={openFilter}
                />
              </div>
              {showTooltip && (
                <div
                  id="dropdownDots"
                  className="absolute top-9 sm:top-14 right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                >
                  <ul
                    className="text-sm text-gray-700"
                    aria-labelledby="dropdownMenuIconButton"
                  >
                    {timeOptions.map((option, index) => (
                      <li
                        key={index}
                        className={`border-b relative ${
                          index === timeOptions.length - 1 ? "border-none" : ""
                        }`}
                        onClick={closeFilter}
                      >
                        <a
                          href="#"
                          className="block text-black px-4 py-2 hover:bg-gray-100"
                          onClick={() => handleTimeOptionSelect(option)}
                        >
                          {option}
                        </a>
                        <div className=" w-2 absolute top-1/2 transform -translate-y-1/2 right-4">
                          <img
                            className="max-w-full"
                            src={ArrowRight}
                            alt="Profile"
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div> */}
          </div>

          <div className="flex justify-between items-center">
            {/* <div
              className="flex items-center w-full sm:w-max rounded-lg bg-white px-4 py-2 mt-5"
              id="time_WiseOrder"
            >
              {statusOptions.map((option, index) => (
                <Button
                  key={index}
                  className={`rounded-lg w-max font-inter text-xxs lg:text-md px-1.5 py-1 mr-2 ${
                    selectedOption === index
                      ? "bg-primary text-white"
                      : "bg-white text-[#99A5AD] hover:bg-primary hover:text-white"
                  }`}
                  onClick={() => handleButtonClick(index)}
                >
                  {option}
                </Button>
              ))}
            </div>
            <div className="hidden lg:block relative">
              <Button
                className="hidden sm:inline-flex w-max items-center justify-center gap-x-1.5 py-3 px-2 mt-5 rounded-md bg-white text-sm text-[#99A5AD]  ring-gray-300 hover:bg-gray-50"
                onClick={openFilter}
              >
                {selectedTimeOption}
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Button>
              {showTooltip && (
                <div
                  id="dropdownDots"
                  className="absolute top-20 right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                >
                  <ul
                    className="text-sm text-gray-700"
                    aria-labelledby="dropdownMenuIconButton"
                  >
                    {timeOptions.map((option, index) => (
                      <li
                        key={index}
                        className={`border-b relative ${
                          index === timeOptions.length - 1 ? "border-none" : ""
                        }`}
                        onClick={closeFilter}
                      >
                        <a
                          href="#"
                          className="block text-black px-4 py-2 hover:bg-gray-100"
                          onClick={() => handleTimeOptionSelect(option)}
                        >
                          {option}
                        </a>
                        <div className=" w-2 absolute top-1/2 transform -translate-y-1/2 right-6">
                          <img
                            className="max-w-full"
                            src={ArrowRight}
                            alt="Profile"
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div> */}
            {/* <div className="hidden sm:block lg:hidden relative bottom-2.5">
              <div className="absolute w-8 bg-white rounded-lg px-2 py-3 right-1 cursor-pointer">
                <img
                  className="max-w-full"
                  src={FilterIcon}
                  alt="Profile"
                  onClick={openFilter}
                />
              </div>
              {showTooltip && (
                <div
                  id="dropdownDots"
                  className="absolute top-8 sm:top-14 right-5 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                >
                  <ul
                    className="text-sm text-gray-700"
                    aria-labelledby="dropdownMenuIconButton"
                  >
                    {timeOptions.map((option, index) => (
                      <li
                        key={index}
                        className={`border-b relative ${
                          index === timeOptions.length - 1 ? "border-none" : ""
                        }`}
                        onClick={closeFilter}
                      >
                        <a
                          href="#"
                          className="block text-black px-4 py-2 hover:bg-gray-100"
                          onClick={() => handleTimeOptionSelect(option)}
                        >
                          {option}
                        </a>
                        <div className=" w-2 absolute top-1/2 transform -translate-y-1/2 right-6">
                          <img
                            className="max-w-full"
                            src={ArrowRight}
                            alt="Profile"
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div> */}
          </div>
          <div className="space-y-5 xl:grid xl:space-y-0 xl:grid-rows xl:gap-x-5 xl:gap-y-5">
            <OrderCardsList />
          </div>
        </div>
      </div>
    </MotionWrapper>
  );
};

export default PastOrders;
