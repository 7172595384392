import { useState, useEffect, useRef } from "react";
import CheckIcon from "../../assets/images/profile_section/check.png";
import WrongIcon from "../../assets/images/profile_section/wrong.png";
import CloseIcon from "../../assets/images/profile_section/close.png";
import WalletIcon from "../../assets/images/profile_section/wallet.png";
import NoteIcon from "../../assets/images/profile_section/note.png";
import { walletData } from "../../services/index";
import Card from "../../components/Cards/Card";
import Loader from "../../components/Elements/Loader";
import ButtonLoader from "../../components/Loader/ButtonLoader";
import WalletCardLoader from "../../components/Loader/ProfilePageLoaders/WalletCardLoader/WalletCardLoader";
import Modal from "react-modal";
import Button from "../../components/Elements/Button";
import { error, success } from "../../components/notify";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";

const WalletTab = (setActiveTab) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const [wallet, setWalletData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const currency = useSelector(
    (state) => state?.basicdata?.customer?.common_customer_Currency
  );
  const [selectedPayment, setselectedPayment] = useState({
    qris: true,
    mastercard: false,
    dana: false,
    ovo: false,
    cod: false,
  });
  const [paymentModalOpen, setpaymentModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    if (isLoader) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoader]);

  useEffect(() => {
    getWalletData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getWalletData = async () => {
    setIsLoader(true);
    try {
      const { status, data } = await walletData();
      if (status === 200) {
        if (data.status === "success") {
          setWalletBalance(data);
          setWalletData(data?.common_customer_Banking);
        }
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.error("error msg", error);
    } finally {
      setTimeout(() => {
        setIsLoader(false);
      }, 500);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [input1Value, setInput1Value] = useState("");
  const [input2Value, setInput2Value] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setInput1Value("");
    setInput2Value("");
  };

  const handleInputChange1 = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setInput1Value(e.target.value);
    } else {
      error("limit exceeded");
    }
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    if (value.length <= 100) {
      setInput2Value(e.target.value);
    } else {
      error("limit exceeded");
    }
  };

  const handleAddBalance = () => {
    setpaymentModalOpen(true);
    setIsModalOpen(false);
  };

  const openFilter = () => {
    setShowTooltip((prev) => !prev);
  };

  const closeFilter = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative">
      <div className="pb-4 mt-7 mdl:mt-0">
        {isLoader ? (
          <ButtonLoader
            className={`my-6`}
            subClassName={`w-full h-20 flex justify-between items center`}
          >
            <div>
              <div className="w-48 h-2 rounded-md bg-gray-400 mb-2"></div>
              <div className="w-40 h-2 rounded-md bg-gray-400"></div>
            </div>
            <button className="w-20 h-8 rounded-md bg-gray-400"></button>
          </ButtonLoader>
        ) : (
          <section className="flex items-center justify-between rounded-lg bg-failedBg border border-primary p-5 mb-7">
            <div>
              <p className="text-sm sm:text-[16px] font-semibold text-black">
                Total Balance
              </p>
              <p className="text-[24px] sm:text-[26px]  xl:text-p4 font-semibold text-checkText">
                £{walletBalance?.banking_Available_Balance || 0.0}
              </p>
            </div>
            <div>
              <Button
                className="hidden rounded-lg font-medium text-[14px] sm:text-[18px] border bg-primary text-white px-6 sm:py-4 xl:px-8 py-3"
                onClick={openModal}
              >
                + Add Balance
              </Button>
            </div>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Add Balance Modal"
              className=" bg-black bg-opacity-50 z-50 "
              overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
            >
              <div className="bg-white rounded-lg shadow-lg px-6 pt-4 pb-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:w-96">
                <div className="flex justify-between items-center mb-4 ">
                  <div>Add Balance</div>
                  <div onClick={closeModal} className="w-6 cursor-pointer">
                    <img
                      className="max-w-full"
                      loading="lazy"
                      src={CloseIcon}
                      alt=""
                      srcSet=""
                    />
                  </div>
                </div>
                <div className="text-center">
                  <div className="w-20 m-auto mb-4">
                    <img
                      className="max-w-full"
                      loading="lazy"
                      src={WalletIcon}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <h3 className="font-semibold mb-4 text-sm">
                    Enter Amount to add balance in Wallet
                  </h3>
                </div>
                <div className="flex flex-col items-center">
                  <input
                    type="text"
                    autoComplete="off"
                    value={input1Value}
                    onChange={handleInputChange1}
                    placeholder="£ 0.00"
                    className="mb-4 p-2 border border-inputText focus:border-blue-500 focus:outline-none rounded-lg w-64"
                  />
                  <label className="relative mb-4 rounded-lg w-64">
                    <img
                      loading="lazy"
                      src={NoteIcon}
                      alt=""
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
                    />
                    <input
                      type="text"
                      autoComplete="off"
                      value={input2Value}
                      onChange={handleInputChange2}
                      placeholder="Add Notes"
                      className="pl-8 pr-2 py-2.5 w-full h-full border border-inputText focus:border-blue-500 rounded-lg w-64 focus:outline-none"
                    />
                  </label>
                  <div className="flex w-64">
                    <Button
                      onClick={handleAddBalance}
                      className=" w-full px-4 py-2 bg-primary text-white rounded-lg"
                    >
                      Add Balance
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </section>
        )}

        {isLoader ? (
          <WalletCardLoader className="" />
        ) : (
          <section>
            <div>
              <div className="grid grid-cols-1 gap-4 mdl:grid mdl:grid-cols-2 2xl:grid-cols-3">
                {wallet &&
                  wallet.map((item) => (
                    <Card
                      key={item?.id}
                      className={`rounded-lg border-none bg-white p-4`}
                    >
                      <div className="">
                        <div className="sm:flex sm:justify-between ">
                          <p className="text-mdl mdl:text-md pb-2 sm:py-2 text-bankcard font-medium">
                            Order No:{" "}
                            <span className="">
                              {item?.banking_Product_Type_Id}
                            </span>
                          </p>
                          <div
                            className={`flex items-center w-max justify-center py-[5px] pr-[12px] pl-[5px] rounded-full
                      ${
                        item?.banking_Status === "Failed"
                          ? "bg-failedBg"
                          : "bg-checkboxBg"
                      }`}
                          >
                            <div className="w-8">
                              <img
                                className="w-max"
                                loading="lazy"
                                src={
                                  item.banking_Status === "Failed"
                                    ? WrongIcon
                                    : CheckIcon
                                }
                                alt=""
                              />
                            </div>
                            <p
                              className={`ml-2 font-semibold w-16 text-xs
                        ${
                          item?.banking_Status === "Failed"
                            ? "text-wrongText"
                            : "text-checkText"
                        }`}
                            >
                              {item?.banking_Status}
                            </p>
                          </div>
                        </div>
                        <p className="font-semibold text-lg mdl:text-mdl pt-3 text-dark-shade-1 leading-6 line-clamp-2 xl:h-[70px]">
                          {item?.banking_Details}
                        </p>
                        <p className="text-loyalityDate text-md sm:text-mdl mdl:text-sm">
                          {item?.pretty_date}
                        </p>
                        <p
                          className={`text-p5 sm:text-[28px] mdl:text-[21px] text-left pt-4 pr-2 font-semibold leading-5 ${
                            item?.banking_Status === "Completed"
                              ? "text-wrongText"
                              : item.banking_Status === "Refund"
                              ? "text-checkText"
                              : "text-wrongText"
                          }`}
                        >
                          {item?.banking_Operator_Fee}
                          {currency}
                          {item?.banking_Gross}
                        </p>
                      </div>
                    </Card>
                  ))}
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default WalletTab;
