import Quality from "../../assets/images/quality.svg";
import Service from "../../assets/images/service.svg";
import Quantity from "../../assets/images/quantity.svg";
import { StarIcon } from "@heroicons/react/24/solid";
import Button from "../../components/Elements/Button";
import { useState } from "react";
import { submitOrderReviews } from "../../services/index";
import { checkEmpty } from "../../utils/helper";
import Rating from "@mui/material/Rating";
import DrawerHeader from "../../components/Header/DrawerHeader";
import { error, success } from "../../components/notify";

const PendingOrderRating = ({ item, onClose }) => {
  const [isQuality, setIsQuality] = useState(5);
  const [isQuantity, setIsQuantity] = useState(5);
  const [isService, setIsService] = useState(5);
  const [feedback, setFeedback] = useState("");

  const [formData, setFormData] = useState({
    store_feedback_Text: feedback,
    store_feedback_Quality: isQuality,
    store_feedback_Quantity: isQuantity,
    store_feedback_Service: isService,
    pos_basket_Key: item.pos_basket_Key,
  });

  const submitFeedbackData = async () => {
    let formIsValid = true;
    if (checkEmpty(feedback)) {
      formIsValid = true;
    }

    if (formIsValid) {
      const { status, data } = await submitOrderReviews(formData);
      if (status === 200) {
        if (data.status === "success") {
          success("Review submitted successfully");
          onClose();
        } else {
          error("Failed to submit review");
        }
      }
    }
  };

  return (
    <div className="flex flex-col overflow-y-auto no-scrollbar">
      <div className="">
        <DrawerHeader
          title={"Rate your experience"}
          onClose={onClose}
          description={"Let us know what you think about us."}
        />
      </div>
      <div className="mt-5 mdl:mt-0 flex items-center justify-between px-6">
        <div className="flex items-center">
          <img
            className="w-10 h-10 md:w-11 md:h-11 mdl:w-[46px] mdl:h-[46px] rounded-full object-cover "
            loading="lazy"
            src={Quality}
          />
          <div className="ml-2 text-sm md:text-[15px]">Quality</div>
        </div>
        <div className="flex items-center">
          <Rating
            name="quality-rating"
            icon={<StarIcon className="h-6 w-6 text-primary" />}
            emptyIcon={<StarIcon className="h-6 w-6" />}
            precision={1}
            value={isQuality}
            onChange={(event, newValue) => {
              if (!checkEmpty(newValue)) {
                setIsQuality(newValue);
                setFormData({ ...formData, store_feedback_Quality: newValue });
              }
            }}
          />

          <div className="flex text-xs ml-2 text-right w-[65px]">
            ({isQuality}.0) {isQuality == 1 ? "Star" : "Stars"}
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-center justify-between px-6">
        <div className="flex items-center">
          <img
            className="w-10 h-10 md:w-11 md:h-11 mdl:w-[46px] mdl:h-[46px] rounded-full object-cover "
            loading="lazy"
            src={Quantity}
          />
          <div className="ml-2 text-sm md:text-[15px]">Quantity</div>
        </div>
        <div className="flex items-center">
          <Rating
            name="quantity-rating"
            icon={<StarIcon className="h-6 w-6 text-primary" />}
            emptyIcon={<StarIcon className="h-6 w-6" />}
            precision={1}
            value={isQuantity}
            onChange={(event, newValue) => {
              if (!checkEmpty(newValue)) {
                setIsQuantity(newValue);
                setFormData({ ...formData, store_feedback_Quantity: newValue });
              }
            }}
          />
          <div className="flex text-xs ml-2 text-right w-[65px]">
            ({isQuantity}.0) {isQuantity == 1 ? "Star" : "Stars"}
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-row items-center justify-between px-6">
        <div className="flex items-center">
          <img
            className="w-10 h-10 md:w-11 md:h-11 mdl:w-[46px] mdl:h-[46px] rounded-full object-cover "
            loading="lazy"
            src={Service}
          />
          <div className="ml-2 text-sm md:text-[15px]">Service</div>
        </div>
        <div className="flex items-center">
          <Rating
            name="service-rating"
            icon={<StarIcon className="h-6 w-6 text-primary" />}
            emptyIcon={<StarIcon className="h-6 w-6" />}
            precision={1}
            value={isService}
            onChange={(event, newValue) => {
              if (!checkEmpty(newValue)) {
                setIsService(newValue);
                setFormData({ ...formData, store_feedback_Service: newValue });
              }
            }}
          />
          <div className="flex text-xs ml-2 text-right w-[65px]">
            ({isService}.0) {isService == 1 ? "Star" : "Stars"}
          </div>
        </div>
      </div>
      <div className="border-b mt-7 mb-4 mx-2"></div>
      <div className="text-sm font-inter mt-2 px-6 relative">
        <h3 className="font-medium">Anything else you want to add?</h3>
        <textarea
          className="w-full rounded-lg outline-none p-3 mt-[10px] border border-gray-300 focus:border-blue-500 focus:outline-none"
          name="store_feedback_Text"
          placeholder="Tell us more about your overall experience"
          onChange={(e) => {
            setFeedback(e.target.value);
            setFormData({ ...formData, store_feedback_Text: e.target.value });
          }}
        ></textarea>
      </div>
      <Button
        className="bg-primary py-2 mb-5 mt-8 mdl:mt-6 font-inter border text-white text-[16px] mx-6 cursor-pointer"
        onClick={() => submitFeedbackData()}
      >
        Submit Review
      </Button>
    </div>
  );
};

export default PendingOrderRating;
