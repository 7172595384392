import MenuLoader from "../../../../assets/images/menuImageLoader.png";

export default function PastCardLoader({
  className,
  subClassName,
  subsubClassName,
}) {
  return (
    <div
      className={`animate-pulse space-y-4 pb-8 overflow-x-hidden ${className}`}
    >
      <div className={`${subClassName}`}>
        <>
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className={`${subsubClassName} h-28 sm:h-40 bg-gray-300 rounded-lg `}
            >
              <div className="flex">
                <div className="w-32 h-32 rounded-lg flex m-[10px] p-[10px]">
                  <img src={MenuLoader} alt="" srcSet="" />
                </div>
                <div className="mt-10 font-inter">
                  <h3 className="font-semibold text-md sm:text-p6 mb-2 w-40 h-2  rounded-md bg-gray-400 "></h3>
                  <p className="font-medium text-xs sm:text-md bg-gray-400 rounded-md h-2 w-20 mb-2"></p>
                  <div>
                    <div className="w-40 h-2 mr-1 bg-gray-400  rounded-md mb-2"></div>
                    <p className="font-normal text-xs sm:text-md font-inter  rounded-md h-2 w-20 bg-gray-400 mb-2"></p>
                    <div className="w-40 h-2 mr-1 bg-gray-400  rounded-md mb-2"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      </div>
    </div>
  );
}
