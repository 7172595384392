import React from "react";

const Calendar = ({ className }) => {
  return (
    <svg
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Vector">
        <path
          d="M9.17904 0.916667H8.7207V0.458333C8.7207 0.336776 8.67241 0.220197 8.58646 0.134243C8.50051 0.0482886 8.38393 0 8.26237 0C8.14081 0 8.02423 0.0482886 7.93828 0.134243C7.85232 0.220197 7.80404 0.336776 7.80404 0.458333V0.916667H4.13737V0.458333C4.13737 0.336776 4.08908 0.220197 4.00313 0.134243C3.91717 0.0482886 3.80059 0 3.67904 0C3.55748 0 3.4409 0.0482886 3.35495 0.134243C3.26899 0.220197 3.2207 0.336776 3.2207 0.458333V0.916667H2.76237C2.15481 0.917394 1.57233 1.15907 1.14272 1.58868C0.713107 2.0183 0.471431 2.60077 0.470703 3.20833V8.70833C0.471431 9.3159 0.713107 9.89837 1.14272 10.328C1.57233 10.7576 2.15481 10.9993 2.76237 11H9.17904C9.7866 10.9993 10.3691 10.7576 10.7987 10.328C11.2283 9.89837 11.47 9.3159 11.4707 8.70833V3.20833C11.47 2.60077 11.2283 2.0183 10.7987 1.58868C10.3691 1.15907 9.7866 0.917394 9.17904 0.916667ZM1.38737 3.20833C1.38737 2.84366 1.53224 2.49392 1.7901 2.23606C2.04796 1.9782 2.3977 1.83333 2.76237 1.83333H9.17904C9.54371 1.83333 9.89345 1.9782 10.1513 2.23606C10.4092 2.49392 10.554 2.84366 10.554 3.20833V3.66667H1.38737V3.20833ZM9.17904 10.0833H2.76237C2.3977 10.0833 2.04796 9.93847 1.7901 9.68061C1.53224 9.42274 1.38737 9.07301 1.38737 8.70833V4.58333H10.554V8.70833C10.554 9.07301 10.4092 9.42274 10.1513 9.68061C9.89345 9.93847 9.54371 10.0833 9.17904 10.0833Z"
          fill="black"
        />
        <path
          d="M5.9707 7.5625C6.3504 7.5625 6.6582 7.2547 6.6582 6.875C6.6582 6.4953 6.3504 6.1875 5.9707 6.1875C5.59101 6.1875 5.2832 6.4953 5.2832 6.875C5.2832 7.2547 5.59101 7.5625 5.9707 7.5625Z"
          fill="black"
        />
        <path
          d="M3.67904 7.5625C4.05873 7.5625 4.36654 7.2547 4.36654 6.875C4.36654 6.4953 4.05873 6.1875 3.67904 6.1875C3.29934 6.1875 2.99154 6.4953 2.99154 6.875C2.99154 7.2547 3.29934 7.5625 3.67904 7.5625Z"
          fill="black"
        />
        <path
          d="M8.26237 7.5625C8.64207 7.5625 8.94987 7.2547 8.94987 6.875C8.94987 6.4953 8.64207 6.1875 8.26237 6.1875C7.88267 6.1875 7.57487 6.4953 7.57487 6.875C7.57487 7.2547 7.88267 7.5625 8.26237 7.5625Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default Calendar;
