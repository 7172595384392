const LocationPin = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 18"
      fill="none"
      className={className}
    >
      <ellipse
        cx="8.5"
        cy="8.29163"
        rx="2.125"
        ry="2.125"
        stroke="#2A282F"
        strokeWidth="1.5"
      />
      <path
        d="M14.875 8.21292C14.875 11.6903 10.8906 16.0833 8.5 16.0833C6.10938 16.0833 2.125 11.6903 2.125 8.21292C2.125 4.73557 4.97918 1.91663 8.5 1.91663C12.0208 1.91663 14.875 4.73557 14.875 8.21292Z"
        stroke="#2A282F"
        strokeWidth="1"
      />
    </svg>
  );
};

export default LocationPin;
