import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: "",
};

const currentPageData = createSlice({
  name: "currentPageData",
  initialState,
  reducers: {
    setCurrentPage: (state, actions) => {
      state.currentPage = actions.payload;
    },
  },
});

export default currentPageData.reducer;
export const { setCurrentPage } = currentPageData.actions;
