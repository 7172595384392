export default function SideBarLoader({ className = "", subClassName }) {
  return (
    <div className={`${className} animate-pulse `}>
      <div
        className={`rounded-lg font-medium border bg-gray-300 px-8 py-4 ${subClassName}`}
      >
        <div className="w-[80%] items-baseline pr-4 sm:pr-0">
          <div className="bg-gray-300 w-20 h-3 rounded-sm"></div>
        </div>
      </div>
    </div>
  );
}
