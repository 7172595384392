const Checkbox = ({ checked, onChange = () => {} }) => {
  return (
    <div
      onClick={() => {
        onChange();
      }}
    >
      {checked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className=" cursor-pointer"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M3.6 0H14.4C16.3882 0 18 1.61177 18 3.6V14.4C18 16.3882 16.3882 18 14.4 18H3.6C1.61177 18 0 16.3882 0 14.4V3.6C0 1.61178 1.61177 0 3.6 0Z"
            fill="#FF0A38"
          />
          <path
            d="M13 6L6.8125 12.1875L4 9.375"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className=" cursor-pointer"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M4.6 1H15.4C17.3882 1 19 2.61177 19 4.6V15.4C19 17.3882 17.3882 19 15.4 19H4.6C2.61177 19 1 17.3882 1 15.4V4.6C1 2.61178 2.61177 1 4.6 1Z"
            stroke="#28303F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

export default Checkbox;
