import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import blue_placeholder_square from "../../assets/images/default_images/blue_placeholder_square.png";

const Banner = ({ image, className, imageClassName }) => {
  return (
    <div className={` cursor-pointer ${className}`}>
      <LazyLoadImageComponent
        loading="lazy"
        src={image}
        alt=""
        className={imageClassName}
        onErrorSrc={blue_placeholder_square}
        placeholderSrc={blue_placeholder_square}
      />
    </div>
  );
};

export default Banner;
