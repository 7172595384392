import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedIn: false,
  userData: {},
  address: {},
  userCookies: true,
  deliveryType: "Delivery",
  showLogin: false,
  isShowSearch: false,
};

const userData = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, actions) => {
      state.loggedIn = true;
      state.userData = actions.payload;
    },
    removeUserData: (state) => {
      state.loggedIn = false;
      state.address = {};
    },
    setUserAddress: (state, actions) => {
      state.address = actions.payload;
    },
    setDefaultAddress: (state, actions) => {
      state.address.selected = actions.payload;
    },
    setUserCookies: (state, actions) => {
      state.userCookies = actions.payload;
    },
    setDeliveryType: (state, actions) => {
      state.deliveryType = actions.payload;
    },
    setLoginModule: (state, actions) => {
      state.showLogin = actions.payload;
    },
    setIsShowSearch: (state, actions) => {
      state.isShowSearch = actions.payload;
    },
  },
});

export default userData.reducer;
export const {
  setUserData,
  removeUserData,
  setUserAddress,
  setDefaultAddress,
  setUserCookies,
  setDeliveryType,
  setLoginModule,
  setIsShowSearch,
} = userData.actions;
