import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { error } from "../../components/notify";
import {
  fetchCategoryList,
  fetchMenuItem,
  getBasicData,
  getMenu,
  changeBasketType,
} from "../../services";
import Layout from "../../layout/Layout";
import MenuBanner from "./MenuBanner";
import Category from "./CategoryList";
import MenuCard from "../../components/Cards/MenuCard";
import MenuAccordian from "../../components/accordian/MenuAccordian";
import Button from "../../components/Elements/Button";
import useWindowDimensions from "../../hooks/useWindowsDimensons";
import {
  getCartConfigSession,
  getStoresSessions,
  setCartConfigSession,
  setConfigSession,
  setSessionUserId,
  setSsipSession,
  setStoresSessions,
} from "../../utils";
import { AboutPage, HomePage } from "../../routes/RoutesPath";
import { capitalizeFirstWord } from "../../utils";
import GreaterThan from "../../assets/images/chevron-right.png";
import MenuIcon from "../../assets/icons/menu.svg";
import VegIcon from "../../assets/icons/vegIcon.png";
import { checkEmpty } from "../../utils/helper";
import MenuCards from "../../components/Loader/MenuLoaders/MenuCards";
import SubHeader from "../../components/Loader/MenuLoaders/SubHeader";
import MenuLoader from "../../components/Loader/MenuLoaders/MenuLoader";
import SideBarLoader from "../../components/Loader/MenuLoaders/SideBarLoader";
import CartLoader from "../../components/Loader/MenuLoaders/CartLoader";
import FoodHygiene from "../../components/Elements/FoodHygiene";
// import SearchIcon from "../../assets/icons/searchIcon.png";
import SearchIconRed from "../../components/Icon/SearchRed";
import RadioButton from "../../components/Elements/RadioButton";
import Cart from "../../components/Elements/Cart";
import { CheckoutPage } from "../../routes/RoutesPath";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import {
  setBasicData,
  setBasketData,
} from "../../store/basicData/basicDataSlice";
import SearchMenu from "./SearchMenu";
import {
  setLoginModule,
  setIsShowSearch,
} from "../../store/userData/userDataSlice";

const Menu = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const divRef = useRef(null);
  const parentRef = useRef(null);
  const elementRef = useRef([]);
  const menuRef = useRef(null);
  const mobileCategoryRef = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [isFixed, setIsFixed] = useState(false);
  const [isSideBarFixed, setIsSideBareFixed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubLoading, setIsSubLoading] = useState(false);
  const [storeSlug, setStoreSlug] = useState("");
  const [productId, setProductId] = useState("");
  const [storeName, setStoreName] = useState("");
  const [menuData, setMenuData] = useState({});
  const [storeData, setStoreData] = useState([]);
  const [storeDetails, setStoreDetails] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [clickedItem, setClickedItem] = useState(null);
  const [openStatus, setOpenStatus] = useState("");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [hygieneRating, setHygieneRating] = useState(0);
  const [hygieneText, setHygieneText] = useState("");
  const [isVegOnly, setIsVegOnly] = useState(false);
  const [basketType, setBasketType] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [intervalData, setintervalData] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const currency = useSelector(
    (state) => state?.basicdata?.storedetails?.store_Currency
  );
  const basket = useSelector((state) => state?.basicdata?.basket);
  const isLoggedin = useSelector((state) => state?.userdata?.loggedIn);
  const storeStatus = useSelector(
    (state) => state?.basicdata?.storedetails?.store_Open_Status
  );
  const isShowSearch = useSelector((state) => state?.userdata?.isShowSearch);
  const { height } = useWindowDimensions();
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaOgDescription = document.querySelector(
    'meta[property="og:description"]'
  );
  const metaOgTitle = document.querySelector('meta[property="og:title"]');
  const [isApiLoaded, setIsApiLoaded] = useState(false);

  useEffect(() => {
    if (!checkEmpty(storeDetails)) {
      document.title = `${storeDetails?.store_Name}, order online in ${
        storeDetails?.store_City
      } - ${import.meta.env.VITE_PRODUCT_NAME}`;
      metaOgTitle.setAttribute(
        "content",
        `${storeDetails?.store_Name}, order online in ${
          storeDetails?.store_City
        } - ${import.meta.env.VITE_PRODUCT_NAME}`
      );
      metaDescription.setAttribute(
        "content",
        `Order food online from ${storeDetails?.store_Name}, ${
          storeDetails?.store_City
        }. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
      metaOgDescription.setAttribute(
        "content",
        `Order food online from ${storeDetails?.store_Name}, ${
          storeDetails?.store_City
        }. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
    } else {
      document.title = `Order online from ${import.meta.env.VITE_PRODUCT_NAME}`;
      metaOgTitle.setAttribute(
        "content",
        `Order online from ${import.meta.env.VITE_PRODUCT_NAME}`
      );
      metaDescription.setAttribute(
        "content",
        `Order food online. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
      metaOgDescription.setAttribute(
        "content",
        `Order food online. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
    }
  }, [isApiLoaded]);

  useEffect(() => {
    setShowSearch(false);
    dispatch(setIsShowSearch(false));
  }, [location.pathname]);

  useEffect(() => {
    parentRef.current?.scrollIntoView({ behavior: "smooth" });
    parentRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const cartconfig = getCartConfigSession();
    setBasketType(cartconfig?.pos_basket_Delivery_Type);
  }, []);

  useEffect(() => {
    if (!checkEmpty(storeSlug) && !checkEmpty(productId)) getMenuPageContent();
  }, [storeSlug, productId]);

  useEffect(() => {
    setIsLoading(true);
    getStoreDetails();
    const cartconfig = getCartConfigSession();
    setBasketType(cartconfig?.pos_basket_Delivery_Type);
  }, [location.pathname]);

  useEffect(() => {
    let count = 0;
    basket?.basketProducts?.forEach((val) => {
      count += val.pos_basketproduct_Quantity;
    });
    setTotalItems(count);
  }, [basket]);

  useEffect(() => {
    if (isLoading || isSubLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      clearInterval(intervalData);
      dispatch(setProgressData(100));
    }
  }, [isLoading, isSubLoading]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = mobileMenu ? "hidden" : "auto";
  }, [mobileMenu]);

  useEffect(() => {
    if (!checkEmpty(storeDetails)) {
      changeRecentStores();
    }
  }, [storeDetails]);

  useEffect(() => {
    if (categoryData?.length > 0) {
      if (width < 769) {
        window.addEventListener("scroll", handleScroll);
      } else {
        window.addEventListener("wheel", handleScroll);
      }
    }
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categoryData]);

  useEffect(() => {
    if (mobileMenu) {
      const index = categoryData?.findIndex(
        (val) => val.inventory_product_Name === clickedItem
      );
      if (index !== -1) {
        const containerRect = menuRef.current.getBoundingClientRect();
        const item = mobileCategoryRef.current[index];
        if (containerRect && item) {
          // Get the item's position relative to the container
          item.scrollIntoView({
            // behavior: "smooth",
            // block: "center",
            // inline: "center",
          });
        }
      }
    }
  }, [mobileMenu]);

  const changeRecentStores = () => {
    const recentSaved = getStoresSessions() || [];
    const { store_Rating_Counter, store_Cuisines_name, store_Delivery_Time } =
      storeData;
    if (recentSaved?.length > 0) {
      const isExist = recentSaved.findIndex(
        (val) => val.id === storeDetails.product_Id
      );
      if (isExist >= 0) {
        recentSaved.splice(isExist, 1);
        recentSaved.unshift({
          id: storeDetails.product_Id,
          value: {
            ...storeDetails,
            ...{
              store_Rating_Counter,
              store_Cuisines_Name: store_Cuisines_name,
              store_Delivery_Time,
            },
          },
        });
      } else {
        if (recentSaved?.length === 3) {
          recentSaved.pop();
          recentSaved.unshift({
            id: storeDetails.product_Id,
            value: {
              ...storeDetails,
              ...{
                store_Rating_Counter,
                store_Cuisines_Name: store_Cuisines_name,
                store_Delivery_Time,
              },
            },
          });
        } else {
          recentSaved.unshift({
            id: storeDetails.product_Id,
            value: {
              ...storeDetails,
              ...{
                store_Rating_Counter,
                store_Cuisines_Name: store_Cuisines_name,
                store_Delivery_Time,
              },
            },
          });
        }
      }
      setStoresSessions(recentSaved);
    } else {
      setStoresSessions([
        {
          id: storeDetails.product_Id,
          value: {
            ...storeDetails,
            ...{
              store_Rating_Counter,
              store_Cuisines_Name: store_Cuisines_name,
              store_Delivery_Time,
            },
          },
        },
      ]);
    }
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    console.log("-------263--------", scrollTop);
    checkCateogry(scrollTop);
    setIsFixed(scrollTop > 80);
    setIsSideBareFixed(scrollTop > 320);
  };

  const checkCateogry = () => {
    categoryData?.forEach((val, index) => {
      let top = elementRef?.current?.[index]?.getBoundingClientRect().top;
      let bottom = elementRef?.current?.[index]?.getBoundingClientRect().bottom;
      if (top > 150 && top <= 200) {
        setClickedItem(val.inventory_product_Name);
      } else if (bottom > 350 && bottom <= 400) {
        setClickedItem(val.inventory_product_Name);
      }
    });
  };

  const handleClick = (item, inventoryId) => {
    setClickedItem(item);
    setMobileMenu(false);
    const elementPosition =
      elementRef.current[inventoryId]?.getBoundingClientRect().top;
    console.log("--------285--------", elementPosition, window.screenY);
    const offsetPosition =
      elementPosition +
      window.scrollY -
      (isSideBarFixed
        ? 100
        : window.scrollY > 10 && window.scrollY < 320
        ? 180
        : 230);
    console.log(
      "--------285--------",
      elementPosition,
      window.screenY,
      offsetPosition
    );
    setIsFixed(offsetPosition > 60);
    setIsSideBareFixed(offsetPosition > 120);
    document.documentElement.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const getStoreDetails = () => {
    const paths = location?.pathname?.split("/");
    setStoreSlug(paths[2]);
    setProductId(paths[3]);
  };

  const getMenuPageContent = async () => {
    const { status, data } = await getMenu();
    if (status === 200) {
      if (data.status === "success") {
        getMenuItem();
      } else {
        error("Something went wrong!!Please try again");
        setIsLoading(false);
      }
    } else {
      error("Something went wrong!!Please try again");
      setIsLoading(false);
    }
  };

  const getMenuItem = async () => {
    if (!checkEmpty(storeSlug) && !checkEmpty(productId)) {
      const { status, data } = await fetchMenuItem(storeSlug, productId);
      if (status == 200) {
        if (data.status === "success") {
          setSsipSession(data?.store?.store_Config?.ssip);
          setSessionUserId(data?.store?.store_Config?.usrId);
          setStoreData(data?.store);
          getBasicDetails();
        } else {
          error("Something went wrong!!Please try again");
          setIsLoading(false);
          navigate(HomePage);
        }
      } else {
        error("Something went wrong!!Please try again");
        setIsLoading(false);
      }
    }
  };

  const getBasicDetails = async () => {
    const { status, data } = await getBasicData();
    if (status == 200) {
      if (data?.status === "success") {
        setIsApiLoaded(true);
        dispatch(setBasicData(data));
        setCartConfigSession(data?.cartConfig);
        setConfigSession(data?.config);
        setSsipSession(data?.config?.ssip);
        setSessionUserId(data?.config?.usrId);
        setOpenStatus(data?.storedetails?.store_Open_Status);
        setHygieneRating(data?.storedetails?.store_Hygiene_Rating);
        setHygieneText(data?.storedetails?.store_Hygiene_Text);
        if (!checkEmpty(data?.storedetails)) {
          setStoreDetails(data?.storedetails);
          getStoreMenu();
        }
      } else {
        error("Something went wrong!!Please try again");
        setIsLoading(false);
      }
    } else {
      error("Something went wrong!!Please try again");
      setIsLoading(false);
    }
  };

  const getStoreMenu = async () => {
    const { status, data } = await fetchCategoryList();
    if (status == 200) {
      if (data?.status === "success") {
        setStoreName(data?.store_Name);
        setCategoryData(data?.storemenu?.inventories);
        setMenuData(data?.storemenu?.inventory_products);
        setClickedItem(
          data?.storemenu?.inventories?.[0]?.inventory_product_Name
        );
        setIsLoading(false);
      } else {
        error("Something went wrong!!Please try again");
        setIsLoading(false);
      }
    } else {
      error("Something went wrong!!Please try again");
      setIsLoading(false);
    }
  };

  const handleBasketChange = async (type) => {
    setIsSubLoading(true);
    const { status, data } = await changeBasketType(type, "dispatch");
    setIsSubLoading(false);
    if (status === 200) {
      if (data.status === "success") {
        // update basket data and cartconfig
        setBasketType(type);
        dispatch(setBasketData(data?.basket));
        setCartConfigSession(data?.cartConfig);
      } else if (data?.status === "error") {
        /* empty */
      }
    }
  };

  const openLoginModal = () => {
    dispatch(setLoginModule(true));
  };

  const handleCheckoutCart = async () => {
    if (isLoggedin) {
      setIsSubLoading(false);
      navigate(CheckoutPage(storeName), {
        state: { storeSlug, productId },
      });
    } else {
      error(<>You are currently not logged in. Please log in.</>);
      setTimeout(() => {
        openLoginModal();
      }, 100);
    }
  };

  const handleStoreNameClicked = () => {
    const parts = location.pathname.split("/");
    navigate(AboutPage(parts[2], parts[3]));
  };

  const renderMenuData = () => {
    return (
      <div
        className={`mb-menu_margin_bottom mdl:mb-md1_menu_margin_bottom px-menu_padding_left_right md:px-md_menu_padding_left_right ${
          showSearch && isShowSearch
            ? "mdl:px-md1_menu_padding_left_right_search"
            : "mdl:px-md1_menu_padding_left_right"
        } `}
      >
        {Object.keys(menuData ?? {}).map((value, index) => {
          return (
            <div
              key={index}
              className="gap-y-menu_row_gap mdl:gap-y-md1_menu_row_gap grid grid-row-1 pt-menu_padding_top md:pt-md_menu_padding_top pb-menu_padding_bottom "
              ref={(ref) => (elementRef.current[index] = ref)}
            >
              {index !== 0 && (
                <div className="h-[2px] w-full opacity-100 bg-menu-category-border rounded-full mb-4"></div>
              )}
              <p className="font-medium text-md md:text-lg mdl:text-p6 mb-menu_title_margin_bottom capitalize ">
                {menuData[value]?.inventory_product_Name}
              </p>
              {Object.keys(menuData[value]?.product ?? {})?.map((product) => {
                return (
                  <MenuCard
                    key={
                      menuData[value]?.product[product]?.inventory_product_Id
                    }
                    data={menuData[value]?.product[product]}
                    openStatus={openStatus}
                    isVeg={isVegOnly}
                  />
                );
              })}
              {menuData[value]?.category?.map((category) => {
                const {
                  inventory_product_Id,
                  inventory_product_Name,
                  products,
                } = category;
                return (
                  <MenuAccordian
                    key={inventory_product_Id}
                    categoryId={inventory_product_Id}
                    name={inventory_product_Name}
                    products={products?.products}
                    category={products?.category}
                    openStatus={openStatus}
                    isVeg={isVegOnly}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div ref={parentRef}>
      <div onScroll={handleScroll}>
        {!showSearch && !isShowSearch && (
          <Layout
            className={`${
              isFixed
                ? "fixed animate-fadedown top-[76px] left-0 shadow-xl px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px]"
                : "animate-fadeup"
            } bg-white w-full z-50 mdl:flex items-center hidden`}
          >
            <Link to={HomePage} className="flex items-center">
              {isLoading ? (
                <SubHeader />
              ) : (
                <span className="text-xs sm:text-sm font-normal text-grey">
                  {capitalizeFirstWord("Home")}
                </span>
              )}
              <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
            </Link>
            <div
              onClick={handleStoreNameClicked}
              className="flex items-center cursor-pointer"
            >
              {isLoading ? (
                <SubHeader />
              ) : (
                <span className="text-xs sm:text-sm font-normal text-grey">
                  {capitalizeFirstWord(storeName)}
                </span>
              )}
              <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
            </div>
            <Link to={location.pathname} className="flex items-center">
              {isLoading ? (
                <SubHeader />
              ) : (
                <span className="text-xs sm:text-sm font-normal text-active-route">
                  {capitalizeFirstWord("Menu")}
                </span>
              )}
            </Link>
          </Layout>
        )}
        {showSearch && isShowSearch ? (
          <Layout className="w-full flex flex-col justify-center items-center">
            <SearchMenu
              data={menuData}
              searchData={searchData}
              storeName={storeData?.store_Name}
              onSearch={(data) => {
                setSearchData(data);
              }}
              onClose={() => {
                setSearchData([]);
                setShowSearch(false);
              }}
            />
            <div className="w-full md:w-3/4 xl:w-2/5 my-6 gap-y-2 mdl:gap-y-4 grid grid-row-1">
              {searchData?.length === 0 && (
                <div className="-mx-4 md:mx-0">{renderMenuData()}</div>
              )}
              {searchData?.map((val) => {
                return (
                  <MenuCard
                    key={val.inventory_product_Id}
                    data={val}
                    openStatus={openStatus}
                    isVeg={isVegOnly}
                  />
                );
              })}
            </div>
          </Layout>
        ) : (
          <div
            className={`transition-opacity duration-1000 ${
              showSearch && isShowSearch ? "opacity-0" : "opacity-100"
            }`}
          >
            {!isLoading && hygieneRating > -10 && (
              <FoodHygiene rating={hygieneRating} text={hygieneText} />
            )}
            {isLoading ? (
              <MenuLoader
                subClassName={`h-40 sm:h-60 lg:h-64 xl:h-72 relative`}
              />
            ) : (
              <MenuBanner
                storeSlug={storeSlug}
                productId={productId}
                setIsLoading={setIsLoading}
                storeData={storeDetails}
              />
            )}
            {/* Mobile category version */}
            {!isLoading && (
              <>
                <div className="flex justify-between items-center px-3 md:px-8 py-4 mdl:hidden ">
                  {(storeDetails?.store_Delivery === "1" ||
                    storeDetails?.store_Collection === "1") && (
                    <div className="h-full md:py-2 md:px-4 flex items-center justify-evenly gap-x-8  sm:gap-x-8 md:gap-x-6 text-sm md:border-gray-shade-8 md:border rounded-lg">
                      {storeDetails?.store_Delivery === "1" && (
                        <div className="flex items-center gap-x-2">
                          <RadioButton
                            onChange={() => handleBasketChange("Delivery")}
                            checked={basketType === "Delivery"}
                          />
                          <div className="flex flex-col">
                            <label className="tex-xs font-medium">
                              Delivery
                            </label>
                            <label className="tex-xs font-light md:hidden">
                              {storeDetails?.store_Delivery_Time} mins
                            </label>
                          </div>
                        </div>
                      )}
                      {storeDetails?.store_Collection === "1" && (
                        <div className="flex items-center gap-x-2">
                          <RadioButton
                            onChange={() => handleBasketChange("Collection")}
                            checked={basketType === "Collection"}
                          />
                          <div className="flex flex-col">
                            <label className="tex-xs font-medium">
                              Collection
                            </label>
                            <label className="tex-xs font-light md:hidden">
                              {storeDetails?.store_Collection_Time} mins
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex gap-x-1.5">
                    <div
                      onClick={() => setIsVegOnly(!isVegOnly)}
                      className={`${
                        isVegOnly
                          ? "border-green-shade-5 bg-green-shade-6"
                          : "border-gray-shade-8"
                      } border rounded-lg p-2.5 flex items-center justify-center`}
                    >
                      <img src={VegIcon} className="w-5 h-5" />
                    </div>
                    <div
                      onClick={() => {
                        dispatch(setIsShowSearch(true));
                        setShowSearch(true);
                      }}
                      className="border border-gray-shade-8 rounded-lg p-2.5 flex items-center justify-center"
                    >
                      <SearchIconRed className="w-5 h-5" />
                      {/* <img src={SearchIconRed} className="w-5 h-5" alt="" /> */}
                    </div>
                  </div>
                </div>

                <Category
                  onItemClick={handleClick}
                  setIsLoading={setIsLoading}
                  data={categoryData}
                  className={`${
                    isSideBarFixed
                      ? "sticky animate-fadedown z-10 top-16 mb-5 bg-store-card-background"
                      : "relative mb-8"
                  } mb-8 block mdl:hidden w-full`}
                  height={50}
                  currentItem={clickedItem}
                />
              </>
            )}
            {/* Web version category */}
            <div className="flex flex-col mdl:grid mdl:grid-cols-12 mdl:space-x-0 mdl:px-4 xl:px-[60px] menu-scroll mdl:mt-8">
              {isLoading ? (
                <SideBarLoader
                  className="w-full mdl:col-span-2"
                  subClassName={`h-[72vh] mdl:mb-8 mdl:col-span-2 rounded-lg sm:mr-5 mdl:mr-0 hidden mdl:block`}
                />
              ) : (
                <div className="mdl:col-span-2 mdl:w-full">
                  <div className="hidden mdl:w-full h-12 mdl:flex items-center justify-center mb-8">
                    <div className="border-l border-t border-b rounded-l-lg border-gray-shade-8 h-full w-5/6 overflow-hidden">
                      <input
                        onFocus={() => {
                          dispatch(setIsShowSearch(true));
                          setShowSearch(true);
                        }}
                        className="w-full h-full border-none focus:outline-none px-4"
                        placeholder="Search..."
                      />
                    </div>
                    <button
                      onClick={() => {
                        dispatch(setIsShowSearch(true));
                        setShowSearch(true);
                      }}
                      className="w-14 h-full flex items-center justify-center rounded-r-lg border border-gray-shade-8 bg-white"
                    >
                      <SearchIconRed className="w-5 h-5" />
                      {/* <img
                        src={SearchIconRed}
                        className="w-5 h-5 brightness-0	invert"
                        alt=""
                      /> */}
                    </button>
                  </div>
                  <Category
                    onItemClick={handleClick}
                    setIsLoading={setIsLoading}
                    data={categoryData}
                    className={`${
                      isSideBarFixed
                        ? "sticky animate-fadedown top-28 mb-5 sm:right-7 md:right-8 lg:right-12 xl:right-[60px] bg-store-card-background"
                        : "relative mb-8"
                    } mb-8 hidden mdl:block w-full`}
                    height={isFixed ? height - 150 : ""}
                    currentItem={clickedItem}
                  />
                </div>
              )}

              <div className="flex mdl:col-span-6 w-full ">
                <div
                  ref={divRef}
                  className="px-0 gap-y-3 mdl:gap-y-0 grid pb-8 w-full"
                >
                  {isLoading ? (
                    <div className="flex flex-col items-center gap-y-4 w-full px-4 md:w-full md:px-5 mt-4 mdl:mt-0">
                      <MenuCards />
                      <MenuCards />
                      <MenuCards />
                      <MenuCards />
                    </div>
                  ) : (
                    <>
                      <div className="w-full box-border hidden mdl:flex flex-wrap gap-4 pb-8 px-0">
                        {(storeDetails?.store_Delivery === "1" ||
                          storeDetails?.store_Collection === "1") && (
                          <div className="border border-gray-shade-8 h-12 rounded-lg flex items-center justify-evenly gap-x-3 text-sm mdl:px-4">
                            {storeDetails?.store_Delivery === "1" && (
                              <div
                                onClick={() => handleBasketChange("Delivery")}
                                className="flex items-center space-x-2 cursor-pointer"
                              >
                                <RadioButton
                                  onChange={() => {}}
                                  checked={basketType === "Delivery"}
                                />
                                <span className="">Delivery </span>
                              </div>
                            )}
                            {storeDetails?.store_Collection === "1" && (
                              <div
                                onClick={() => handleBasketChange("Collection")}
                                className="flex items-center space-x-2 cursor-pointer"
                              >
                                <RadioButton
                                  onChange={() => {}}
                                  checked={basketType === "Collection"}
                                />
                                <span>Collection </span>
                              </div>
                            )}
                          </div>
                        )}
                        <div
                          onClick={() => setIsVegOnly(!isVegOnly)}
                          className={`${
                            isVegOnly
                              ? "border-green-shade-5 bg-green-shade-6"
                              : "border-gray-shade-8"
                          } border h-12 rounded-lg px-4 flex items-center justify-center gap-x-3 text-mdl cursor-pointer`}
                        >
                          <img src={VegIcon} className="w-5 h-5" />
                          <span className="hidden xl:inline">Veg</span>
                        </div>
                      </div>
                      {renderMenuData()}
                    </>
                  )}
                </div>
              </div>
              <div className="hidden mdl:block mdl:col-span-4">
                {isLoading ? (
                  <CartLoader
                    className={` ${
                      isSideBarFixed
                        ? "sticky top-28 sm:right-7 md:right-8 lg:right-12 xl:right-[60px] bg-store-card-background"
                        : "relative"
                    } mb-8`}
                  />
                ) : (
                  <div
                    style={{ height: isFixed ? height - 150 : height }}
                    className={` ${
                      isSideBarFixed
                        ? "sticky animate-fadedown top-28 mb-5 sm:right-7 md:right-8 lg:right-12 xl:right-[60px]"
                        : "relative mb-8"
                    } hidden mdl:block w-full`}
                  >
                    <Cart />
                  </div>
                )}
              </div>
            </div>
            {/* Mobile version menu */}
            <div>
              <div
                className={`fixed left-1/2 -translate-x-1/2 z-auto ${
                  totalItems > 0 ? "bottom-[130px]" : "bottom-[75px]"
                } mdl:bottom-0 flex flex-col items-center justify-center`}
              >
                {mobileMenu && (
                  <div
                    className="w-screen h-screen bg-transparent"
                    onClick={(event) => {
                      event.stopPropagation();
                      setMobileMenu(false);
                    }}
                  >
                    <div
                      className={`mdl:hidden absolute bottom-10 left-0 right-0 mx-auto w-56 h-52 overflow-y-auto no-scrollbar rounded-lg mb-1 shadow-2xl transform-all ${
                        mobileMenu
                          ? "opacity-100 -translate-y-1"
                          : "opacity-0 translate-y-0"
                      }`}
                    >
                      <ul
                        ref={menuRef}
                        className="text-sm bg-base-200 top-0 left-0 right-0 py-1"
                      >
                        {categoryData?.map((val, index) => {
                          return (
                            <li
                              key={"ipn" + index}
                              ref={(el) =>
                                (mobileCategoryRef.current[index] = el)
                              }
                              className="py-1.5 px-6 hover:bg-light-shade-3 active:bg-light-shade-3"
                              onClick={() => {
                                handleClick(val?.inventory_product_Name, index);
                              }}
                            >
                              <label
                                className={`text-sm ${
                                  clickedItem === val.inventory_product_Name
                                    ? "text-primary"
                                    : "text-black"
                                }`}
                              >
                                {val?.inventory_product_Name}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
                {!isLoading && (
                  <div
                    onClick={() => setMobileMenu(!mobileMenu)}
                    className="mdl:hidden right-0 bottom-2 relative flex items-center border-gray-shade-12 border-2 bg-gray-shade-12 h-11 w-max rounded-[100px] shadow-2xl cursor-pointer"
                  >
                    <div className="w-10 h-10 bg-white rounded-full flex justify-center items-center">
                      <img src={MenuIcon} className="w-6 h-3" />
                    </div>
                    <span className="text-white ml-3 mr-6 text-sm">Menu</span>
                  </div>
                )}
              </div>
              {!isLoading && totalItems > 0 && openStatus !== "Close" && (
                <div
                  className={`fixed left-0 right-0 bottom-[75px] z-auto px-4`}
                >
                  <div className="mdl:hidden bg-primary h-12 md:h-14 rounded-lg flex items-center px-6 w-full mt-2">
                    <Button
                      onClick={() =>
                        storeStatus != "Close" ? handleCheckoutCart() : ""
                      }
                      className="flex w-full justify-between items-center"
                    >
                      <label className="text-white text-sm md:text-md font-semibold">
                        {`${totalItems} ${
                          totalItems > 1 ? "items" : "item"
                        } | ${currency}${
                          basket?.pos_basket_Total > 0
                            ? basket?.pos_basket_Total
                            : 0
                        }`}
                      </label>
                      {storeStatus != "Close" && (
                        <label className="text-white text-sm md:text-md font-semibold cursor-pointer">
                          Checkout
                        </label>
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            {/* </Layout> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
