import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "@mui/material";
import Checkbox from "./Checkbox";
import OfferSection from "./OfferSection";
import ArrowLeftChevron from "../../components/Icon/ArrowLeftChevron";
import CartItem from "./CartItem";
import Button from "./Button";
import emptyCart from "../../assets/images/menu/emptycart.png";
import Diary from "../../assets/icons/diary.svg";
import PercentageIcon from "../../assets/icons/percentage_badge.svg";
import { applyNote, checkOut } from "../../services";
import useWindowDimensions from "../../hooks/useWindowsDimensons";
import { error, success } from "../notify";
import { CheckoutPage, MenuPage } from "../../routes/RoutesPath";
import StoreBanner from "./StoreBanner";
import AllergyModal, { AllergyList } from "../Modal/AllergyModal";
import { checkEmpty, copyObject } from "../../utils/helper";
import DrawerHeader from "../Header/DrawerHeader";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import { setLoginModule } from "../../store/userData/userDataSlice";

const Cart = ({
  isDrawer,
  isCheckout,
  isPayment,
  toggleDrawer,
  instructions,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const storeDetails = useSelector((state) => state?.basicdata?.storedetails);
  const addCount = useSelector(
    (state) => state.basicdata.basket?.basketProducts?.length
  );
  const basket = useSelector((state) => state?.basicdata?.basket);
  const currency = useSelector(
    (state) => state?.basicdata?.storedetails?.store_Currency
  );
  const isLoggedin = useSelector((state) => state?.userdata?.loggedIn);
  const storeStatus = useSelector(
    (state) => state?.basicdata?.storedetails?.store_Open_Status
  );
  const { height } = useWindowDimensions();
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [cartInstructions, setCartInstructions] = useState(
    basket?.pos_basket_Customer_Note
  );
  const [couponCode, setCouponCode] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const [showCouponList, setShowCouponList] = useState(false);
  const [showAllergyList, setShowAllergyList] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showAllergy, setShowAllergy] = useState(false);

  useEffect(() => {
    if (location?.pathname?.includes("cart")) {
      setShowAdditionalInfo(true);
    } else {
      setShowAdditionalInfo(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!checkEmpty(basket?.pos_basket_Customer_Note)) {
      setCartInstructions(basket?.pos_basket_Customer_Note);
    }
  }, [basket]);

  const openLoginModal = () => {
    dispatch(setLoginModule(true));
  };

  const handleApplyCoupon = () => {
    if (isDrawer) {
      setShowCouponList(true);
    } else {
      setShowCoupon(true);
    }
  };

  const handleRemove = () => {
    setShowCoupon(false);
    setCouponCode("");
  };

  const handleCheckout = async () => {
    if (isLoggedin) {
      navigate(CheckoutPage(storeDetails?.store_Name), {
        state: {
          storeSlug: storeDetails?.store_Slug,
          productId: storeDetails?.product_Id,
        },
      });
    } else {
      error(<>You are currently not logged in. Please log in</>);
      setTimeout(() => {
        openLoginModal();
      }, 100);
    }
    if (isDrawer) toggleDrawer();
  };

  const handleInstructions = async () => {
    if (!checkEmpty(cartInstructions)) {
      const { status, data } = await applyNote(cartInstructions?.trim());
      if (status === 200) {
        if (data.status === "success") {
          success(data.msg);
          let obj = copyObject(basket);
          obj.pos_basket_Customer_Note = cartInstructions.trim();
          dispatch(setBasketData(obj));
        }
      }
    } else {
      error("Please enter instructions");
    }
  };

  const handleCouponClose = () => {
    if (isDrawer) {
      setShowCouponList(false);
      setShowAllergyList(false);
    } else {
      setShowCoupon(false);
    }
  };

  return (
    <div
      className={`${
        isDrawer
          ? "max-h-full h-max"
          : "max-h-full h-full overflow-hidden overflow-y-auto"
      } w-full no-scrollbar`}
    >
      <AllergyModal
        visible={showAllergy}
        onClose={() => setShowAllergy(!showAllergy)}
      />

      <Drawer
        anchor="right"
        open={showCoupon}
        onClose={() => {
          handleCouponClose();
        }}
      >
        <OfferSection
          onClose={() => {
            handleCouponClose();
          }}
        />
      </Drawer>
      {isDrawer &&
        (showCouponList || showAllergyList ? (
          <div
            className="text-slate-950 px-6 text-xl font-semibold font-inter my-4 mdl:my-6 flex items-center cursor-pointer"
            onClick={() => {
              setShowCouponList(false);
              setShowAllergyList(false);
            }}
          >
            <ArrowLeftChevron className="w-3 h-3" />
            <div className="ml-1 text-md md:text-[19px] font-inter font-normal">
              {showAllergyList ? "Add allergy" : "Coupons"}
            </div>
          </div>
        ) : (
          <DrawerHeader title={"Cart"} onClose={toggleDrawer} />
        ))}
      {showCouponList && (
        <OfferSection
          isDrawer={isDrawer}
          onClose={() => {
            handleCouponClose();
          }}
        />
      )}
      {showAllergyList && (
        <AllergyList
          isDrawer={true}
          onClose={() => {
            handleCouponClose();
          }}
        />
      )}
      <div
        className={`${
          isDrawer ? "block" : "hidden"
        } text-slate-950 px-6 text-xl font-semibold font-inter pt-0 cursor-pointer`}
      >
        {/* <div className="border-orderDetailBD border w-full mt-4" /> */}
        {!showCouponList && !showAllergyList && addCount > 0 && (
          <StoreBanner
            onClick={() => {
              toggleDrawer();
            }}
          />
        )}
      </div>
      <div
        className={`${
          isDrawer ? "hidden" : "xl:flex rounded-t-lg"
        } bg-white border justify-between px-6 py-4 rounded-t-lg h-16`}
      >
        <span className="text-black font-bold leading-relaxed text-md xl:text-xl">
          Cart
        </span>
        {/* {addCount > 0 && (
          <div
            onClick={() => {
              dispatch(setaddCount(0));
            }}
            className="px-2 rounded-lg shadow-inner border-[2.25px] border-white justify-center items-center  inline-flex"
          >
            <div className="text-right cursor-pointer text-white text-xs 2xl:text-sm font-semibold font-inter">
              Clear Cart
            </div>
          </div>
        )} */}
      </div>
      {addCount > 0 ? (
        !showCouponList &&
        !showAllergyList && (
          <div
            className={`${
              isDrawer
                ? "bg-white border-t mt-4 mb-16 rounded-lg"
                : "bg-store-card-background"
            } xl:max-h-[calc(100%-64px)] xl:h-max xl:mb-0 h-max`}
          >
            <div
              className={`${
                isDrawer
                  ? ""
                  : "bg-store-card-background border-l border-r border-b rounded-b-lg"
              } pt-4 mdl:pt-5 space-y-5 h-max overflow-y-auto mb-10 w-full`}
            >
              {basket?.basketProducts?.map((value, index) => {
                return (
                  <div key={`cartitem${index}`}>
                    <CartItem
                      isPayment={isPayment}
                      key={"cart" + index}
                      value={value}
                      className="px-5"
                    />
                    <div className="h-px w-full bg-card-border"></div>
                  </div>
                );
              })}

              {/* Checkout button */}
              <div className="mt-6 mx-5 xl:bottom-1">
                {basket?.pos_basket_allergy?.length > 0 && (
                  <span
                    className="text-sm text-center font-semibold break-all"
                    onClick={() => {
                      if (isDrawer) {
                        setShowAllergyList(true);
                      } else {
                        setShowAllergy(true);
                      }
                    }}
                  >
                    Allergic to {basket?.pos_basket_allergy?.join(", ")}
                  </span>
                )}
                {/* Note */}
                {showAdditionalInfo && (
                  <div className="bg-white rounded-lg mt-5 border mb-2">
                    <div className="px-3 xl:px-4 py-2 xl:py-4 flex flex-col">
                      <div className="flex space-x-1 xl:space-x-2 text-slate-950 text-sm xl:text-md font-semibold font-inter leading-normal items-center">
                        <div>
                          <img src={Diary} alt="note" />
                        </div>
                        <span>Note</span>
                      </div>
                      <textarea
                        rows={2}
                        value={cartInstructions}
                        onChange={(event) =>
                          setCartInstructions(event.target.value)
                        }
                        className="w-full text-xs xl:text-sm font-normal font-inter xl:pt-2 focus:outline-none 2xl:w-full resize-none"
                        placeholder="Enter your instructions here"
                        // onBlur={handleInstructions}
                        maxLength={300}
                      />
                      <Button
                        onClick={handleInstructions}
                        className="text-stepperCircle self-end text-sm font-semibold font-inter"
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                )}
                {isCheckout && instructions && (
                  <div className="px-0 py-4 space-y-4">
                    {Object.keys(instructions ?? {}).map((val, index) => {
                      return (
                        <div key={"instruct" + index} className="">
                          <div
                            className="flex h-auto items-center cursor-pointer"
                            onClick={() => {
                              if (
                                cartInstructions?.includes(instructions[val])
                              ) {
                                setCartInstructions(
                                  cartInstructions
                                    ?.replace(instructions[val] + ".", "")
                                    .trim()
                                );
                              } else {
                                setCartInstructions(
                                  cartInstructions +
                                    (checkEmpty(cartInstructions) ? "" : " ") +
                                    instructions[val] +
                                    "."
                                );
                              }
                            }}
                          >
                            <Checkbox
                              checked={cartInstructions
                                ?.toLowerCase()
                                .includes(instructions[val].toLowerCase())}
                            />
                            <p className="pl-2 text-[#ABADBD] text-sm font-medium">
                              {instructions[val]}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {showAdditionalInfo && (
                  <div>
                    <div className="bg-white rounded-lg border justify-between my-3">
                      <div className="px-3 flex justify-between items-center">
                        <div className="space-x-3 flex w-11/12">
                          <input
                            type="text"
                            onClick={() => {
                              if (isDrawer) {
                                setShowCouponList(true);
                              } else {
                                setShowCoupon(true);
                              }
                            }}
                            placeholder="Enter Your Coupon Code"
                            className="outline-none text-sm font-bold w-full py-4"
                          />
                        </div>
                        {showCoupon ? (
                          <button
                            className="text-right text-rose-600 text-sm font-semibold font-inter"
                            onClick={handleRemove}
                          >
                            Remove
                          </button>
                        ) : (
                          <button
                            className="text-right text-stepperCircle text-sm font-semibold font-inter"
                            onClick={handleApplyCoupon}
                          >
                            Apply
                          </button>
                        )}
                      </div>
                    </div>
                    {basket?.coupon && (
                      <div className="py-2 mt-1 bg-stepperCircle bg-opacity-25 rounded-lg border border-stepperCircle border-opacity-50 flex space-x-3 px-5">
                        <img loading="lazy" src={PercentageIcon} alt="" />
                        <div className="">
                          <span className="text-stepperCircle text-[13px] font-semibold font-inter capitalize leading-[17px]">
                            £{basket.basketCoupon} Saved!{" "}
                          </span>
                          <span className="text-stepperCircle text-[13px] font-normal font-inter leading-[17px]">
                            using the coupon code{" "}
                            <span className="font-semibold">
                              {basket.coupon}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="bg-white rounded-lg shadow-inner border my-3">
                  <div className="">
                    <div className="h-max overflow-y-auto">
                      {basket?.pos_basket_Charges?.map((val, ind) => {
                        if (val?.type === "pos_basket_Sub_Total") {
                          return (
                            <div
                              key={"charges" + ind}
                              className="flex justify-between items-center px-4 py-2 xl:py-4"
                            >
                              <div className="text-black text-sm xl:text-md font-medium font-inter leading-snug">
                                {val?.label}:
                              </div>
                              <div className="text-right text-black text-sm xl:text-md font-medium font-inter leading-snug">
                                {val?.value}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <React.Fragment key={"charges" + ind}>
                              <div className="border-b "></div>
                              <div className="flex justify-between items-center px-4 py-2">
                                <div className="text-zinc-500 text-xs xl:text-sm font-medium font-inter leading-snug">
                                  {val?.label}
                                </div>
                                <div className="w-[78.56px] text-right text-zinc-500 text-xs xl:text-sm font-medium font-inter leading-snug">
                                  {val?.value}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      })}
                    </div>

                    <div className="border-b "></div>

                    <div className="flex justify-between px-4 py-2 xl:py-4">
                      <div className="text-black text-md font-semibold font-inter leading-normal">
                        Total Payable
                      </div>
                      <div className="w-24 text-right text-black text-md font-semibold font-inter leading-normal">
                        {currency}
                        {basket?.pos_basket_Total}
                      </div>
                    </div>
                  </div>
                </div>
                {!isPayment && !isCheckout && storeStatus != "Close" && (
                  <Button
                    onClick={() => {
                      handleCheckout();
                    }}
                    className={`w-full py-2 xl:py-4 bg-primary rounded-lg  text-white text-base font-semibold font-inter text-center 2xl:text-xl ${
                      showAdditionalInfo ? "mb-3" : "mb-4"
                    }`}
                  >
                    Checkout
                  </Button>
                )}
                {showAdditionalInfo && (
                  <div
                    onClick={() => {
                      if (isDrawer) {
                        setShowAllergyList(true);
                      } else {
                        setShowAllergy(true);
                      }
                    }}
                    className="text-sky-600 cursor-pointer text-xs md:text-sm font-normal 2xl:text-sm font-inter leading-tight pb-3"
                  >
                    Do you have any allergy?
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <div
          style={{ maxHeight: isDrawer ? height - 64 : "" }}
          className={`rounded-b-lg h-[90%] pb-0 w-full justify-center items-start box-border ${
            isDrawer ? "px-3" : "border-l border-r border-b"
          }`}
        >
          <div className="w-full h-full  flex flex-col  items-center	">
            <img
              loading="lazy"
              src={emptyCart}
              className="w-[65%] sm:w-[50%] md:w-[80%] xl:w-[75%] 2xl:w-[70%] 3xl:w-[65%]"
            />
            <div className="text-center">
              <div className="font-bold text-mdl leading-5 ">Empty Cart!</div>
              <div className="text-xs pt-1 font-normal">
                Order food from favourite restaurants near you.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
