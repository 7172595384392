export default function BookMarkCardLoader({
  className,
  subClassName,
  subsubClassName,
}) {
  return (
    <div
      className={`animate-pulse space-y-4 mt-5 pb-8 overflow-x-hidden ${className}`}
    >
      <div className={subClassName}>
        {[...Array(24)].map((_, index) => (
          <div
            key={index}
            className={`${subsubClassName} h-28 bg-gray-300 rounded-lg`}
          ></div>
        ))}
      </div>
    </div>
  );
}
