import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/Navigation/BreadCrumbs";
import React, { useRef } from "react";
import { ColorRing } from "react-loader-spinner";
import Layout from "../../layout/Layout";
import { chatWithAndWhatApp, chatWithAndGmail } from "../../utils";

import { useLocation } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import "tailwindcss/tailwind.css";
import { current } from "@reduxjs/toolkit";

import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";

const FaqDesktop = ({ data, item }) => {
  const [currentArray, setcurrentArray] = useState([]);

  const [step, setStep] = useState(0);

  const { progressFunction } = useProgress();
  const dispatch = useDispatch();
  const [intervalData, setintervalData] = useState(0);
  const [loader, setLoader] = useState(false);

  

  useEffect(() => {
    let data1 = [];

    data?.forEach((val, index) => {
      data1.push({
        title: val.title,
        ref: val.ref,
        show: val.show,
        description: val.description,
      });
    });
    setStep(item);
    if (item == -1) {
      setStep(0);
    }

    setcurrentArray(data1);
  }, [data]);

  const handleClick = (ref, title, step) => {
    let data1 = [];
    setStep(step);
    setLoader(true);
    // ref?.current?.scrollIntoView({ top: 100, behavior: "smooth" });

    data?.forEach((val, index) => {
      if (index == step) {
        data1.push({
          title: val.title,
          ref: val.ref,
          show: true,
          description: val.description,
        });
      } else {
        data1.push({
          title: val.title,
          ref: val.ref,
          show: false,
          description: val.description,
        });
      }
    });
    setcurrentArray(data1);

    setTimeout(() => {
      setLoader(false);
    }, 1000);

    // preventDefault();
  };
  

  return (
    <div className="flex  w-full mt-5 gap-7 "  key='faqd'>
      <div className=" w-[30%]  relative block  ">
        <div className="grid w-full rounded-lg shadow-xl bg-white">
          {currentArray.map((item, key) =>
            key == 8 ? (
              <Link
                key={'faqd'+key}
                to={chatWithAndWhatApp}
                target="_blank"
                rel="noreferrer"
              >
                <div  className="border-b cursor-pointer  bg-white">
                  <div className="flex  px-1 py-1 md:px-5 md:py-3 text-[11px] md:text-[13px] font-medium w-full	">
                    <div className=" w-full">
                      <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                      <div className="flex justify-center w-[20%]"> </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : key == 9 ? (
              <Link
              key={'faqd'+key}
              to={chatWithAndGmail}
               target="_blank"
              >
                <div  className="border-b cursor-pointer  bg-white">
                  <div className="flex  px-1 py-1 md:px-5 md:py-3 text-[11px] md:text-[13px] font-medium w-full	">
                    <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                    <div className="w-[20%]"> </div>
                  </div>
                </div>
              </Link>
            ) : key == 10 ? (
                <Link
                key={'faqd'+key}
                to="tel:+44 178 281 7644"
                >
                  <div  className=" cursor-pointer  bg-white rounded-br-md	rounded-bl-md	">
                    <div className="flex  px-1 py-1 md:px-5 md:py-3 text-[11px] md:text-[13px] font-medium w-full	">
                      <p dangerouslySetInnerHTML={{ __html: item.title }}></p>
                      <div className="w-[20%]"> </div>
                    </div>
                  </div>
                </Link>
            ) : (
              <div
                 key={'faqd'+key}
                href="javascript:void(0)"
                onClick={() => handleClick(item.ref, item.title, key)}
              >
                <div
                  className={`border-b cursor-pointer  ${
                    step == key ? "bg-slate-200" : "bg-white"
                  }`}
                >
                  <div className="flex  px-1 py-1 md:px-5 md:py-3 text-[11px] md:text-[13px] font-medium w-full	">
                    <div className=" flex w-full">
                      <div className="flex w-[90%]"> {item.title}</div>
                      <div className="flex w-[20%] justify-end	">
                        <ChevronRightIcon
                          className={` w-4 h-4 2xl:w-5 2xl:h-5`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className="flex w-[70%] h-[540px]	 overflow-y-auto"  >
        <div className="grid w-full rounded-lg shadow-xl " key='faqdin'>
          {currentArray.map((item, key) =>
            item.show == true ? (
              <div ref={item.ref}  key={'faqd'+key}>
                <div  dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            ) : (
              null
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqDesktop;
