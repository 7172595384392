export const RightArrow = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-right">
        <g id="vuesax/outline/arrow-right">
          <g id="arrow-right_2">
            <path
              id="Vector"
              d="M14.43 18.9157C14.24 18.9157 14.05 18.8457 13.9 18.6957C13.61 18.4057 13.61 17.9257 13.9 17.6357L19.44 12.0957L13.9 6.5557C13.61 6.2657 13.61 5.7857 13.9 5.4957C14.19 5.2057 14.67 5.2057 14.96 5.4957L21.03 11.5657C21.32 11.8557 21.32 12.3357 21.03 12.6257L14.96 18.6957C14.81 18.8457 14.62 18.9157 14.43 18.9157Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M20.33 12.8457H3.5C3.09 12.8457 2.75 12.5057 2.75 12.0957C2.75 11.6857 3.09 11.3457 3.5 11.3457H20.33C20.74 11.3457 21.08 11.6857 21.08 12.0957C21.08 12.5057 20.74 12.8457 20.33 12.8457Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
