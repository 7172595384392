const OrderPlaced = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        d="M20.847 17.2846H10.2048C9.7464 17.2846 9.375 16.9132 9.375 16.4548C9.375 15.9964 9.7464 15.625 10.2048 15.625H20.847C21.3054 15.625 21.6768 15.9964 21.6768 16.4548C21.6768 16.9132 21.3054 17.2846 20.847 17.2846Z"
        fill=""
      />
      <path
        d="M20.847 20.5268H10.2048C9.7464 20.5268 9.375 20.1554 9.375 19.697C9.375 19.2386 9.7464 18.8672 10.2048 18.8672H20.847C21.3054 18.8672 21.6768 19.2386 21.6768 19.697C21.6768 20.1554 21.3054 20.5268 20.847 20.5268Z"
        fill=""
      />
      <path
        d="M18.7486 23.7709H12.3064C11.848 23.7709 11.4766 23.3995 11.4766 22.9411C11.4766 22.4827 11.848 22.1113 12.3064 22.1113H18.7486C19.207 22.1113 19.5784 22.4827 19.5784 22.9411C19.5784 23.3995 19.207 23.7709 18.7486 23.7709Z"
        fill=""
      />
      <path
        d="M25.4655 0.845703H5.5809C4.9917 0.845703 4.5117 1.3251 4.5117 1.9149V2.6733H4.1199C3.3993 2.6727 2.8125 3.2595 2.8125 3.9807C2.8125 4.7019 3.3993 5.2881 4.1199 5.2881H4.5117V7.0839H4.1199C3.3993 7.0839 2.8125 7.6707 2.8125 8.3913C2.8125 9.1119 3.3993 9.6987 4.1199 9.6987H4.5117V11.4945H4.1199C3.3993 11.4945 2.8125 12.0813 2.8125 12.8019C2.8125 13.5225 3.3993 14.1093 4.1199 14.1093H4.5117V15.9051H4.1199C3.3993 15.9051 2.8125 16.4919 2.8125 17.2125C2.8125 17.9337 3.3993 18.5199 4.1199 18.5199H4.5117V20.3157H4.1199C3.3993 20.3157 2.8125 20.9025 2.8125 21.6231C2.8125 22.3443 3.3993 22.9305 4.1199 22.9305H4.5117V24.7263H4.1199C3.3993 24.7263 2.8125 25.3131 2.8125 26.0337C2.8125 26.7543 3.3993 27.3411 4.1199 27.3411H4.5117V28.0995C4.5117 28.6887 4.9911 29.1687 5.5809 29.1687H25.4649C26.0541 29.1687 26.5341 28.6893 26.5341 28.0995V1.9143C26.5347 1.3251 26.0547 0.845703 25.4655 0.845703ZM4.3335 26.5401H3.9039C3.6213 26.5401 3.4335 26.3097 3.4335 26.0271C3.4335 25.7439 3.6207 25.4715 3.9039 25.4715H4.3335V26.5401ZM4.3905 22.1487H3.9609C3.6783 22.1487 3.4905 21.9183 3.4905 21.6357C3.4905 21.3525 3.6777 21.0801 3.9609 21.0801H4.3905V22.1487ZM4.3905 17.7195H3.9609C3.6783 17.7195 3.4905 17.4891 3.4905 17.2065C3.4905 16.9233 3.6777 16.6509 3.9609 16.6509H4.3905V17.7195ZM4.3905 13.2951H3.9609C3.6783 13.2951 3.4905 13.0647 3.4905 12.7821C3.4905 12.4989 3.6777 12.2265 3.9609 12.2265H4.3905V13.2951ZM4.3197 8.7867H3.9579C3.7197 8.7867 3.5619 8.5929 3.5619 8.3547C3.5619 8.1165 3.7197 7.8867 3.9579 7.8867H4.3197V8.7867ZM4.3197 4.3629H3.9579C3.7197 4.3629 3.5619 4.1691 3.5619 3.9309C3.5619 3.6927 3.7197 3.4629 3.9579 3.4629H4.3197V4.3629ZM25.0683 27.2937C25.0683 27.4125 24.9717 27.5091 24.8529 27.5091H6.1929C6.0741 27.5091 5.9775 27.4125 5.9775 27.2937V2.7201C5.9775 2.6013 6.0741 2.5047 6.1929 2.5047H24.8529C24.9717 2.5047 25.0683 2.6013 25.0683 2.7201V27.2937Z"
        fill=""
      />
    </svg>
  );
};

export default OrderPlaced;
