const CheckCircle = ({ checked, onChange, className }) => {
  return (
    <div
      onClick={() => {
        onChange();
      }}
    >
      {checked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
        >
          <rect width="19" height="19" rx="4" fill="#54B080" />
          <path
            d="M13 7L7.5 12.5L5 10"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="21"
          height="21"
          className={className}
          viewBox="0 0 21 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 1000006017">
            <circle
              id="Ellipse 2861"
              cx="10.5"
              cy="10.5"
              r="10.5"
              fill="#54B080"
            />
            <path
              id="Vector"
              d="M14 8L8.5 13.5L6 11"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      )}
    </div>
  );
};

export default CheckCircle;
