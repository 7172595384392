const Gmail = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 21"
      fill="none"
    >
      <rect
        x="1.66797"
        y="3"
        width="16.6667"
        height="15"
        rx="4"
        stroke="white"
      />
      <path
        d="M1.66797 6.33337L7.50252 11.001C8.9634 12.1697 11.0392 12.1697 12.5001 11.001L18.3346 6.33337"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Gmail;
