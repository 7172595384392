import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progress: 0,
};

const progressData = createSlice({
  name: "progressData",
  initialState,
  reducers: {
    setProgressData: (state, actions) => {
      state.progress = actions.payload;
    },
  },
});

export default progressData.reducer;
export const { setProgressData } = progressData.actions;
