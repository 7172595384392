import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import MenuCard from "../Cards/MenuCard";
import { getSubMenu } from "../../services";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";

const MenuAccordian = ({
  categoryId,
  name,
  products,
  category,
  openStatus,
  isVeg = false,
}) => {
  const [collapsed, setCollapsed] = useState(
    products?.length || category?.length
  );
  const [data, setData] = useState(products);
  const [categories, setCategories] = useState(category);
  const [isLoading, setIsLoading] = useState(false);
  const [intervalData, setintervalData] = useState(0);
  const { progressFunction } = useProgress();
  const dispatch = useDispatch();

  useEffect(() => {
    setData(products);
  }, [products]);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);
      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      clearInterval(intervalData);
      dispatch(setProgressData(100));
    }
  }, [isLoading]);

  const handleCollapsed = async () => {
    if (data) {
      setCollapsed(!collapsed);
    } else {
      if (!products?.length && !categories?.length) {
        setIsLoading(true);
        const { status, data } = await getSubMenu(categoryId);
        setIsLoading(false);
        if (status === 200) {
          if (data.status === "success") {
            setData(data?.allSubmenu?.products);
            setCategories(data?.allSubmenu?.category);
            setCollapsed(true);
          }
        }
      } else {
        setCollapsed(!collapsed);
      }
    }
  };

  return (
    <div className="w-full mb-3">
      <div
        className="flex justify-between items-center w-full pb-0 cursor-pointer"
        onClick={(event) => {
          event.preventDefault();
          handleCollapsed();
        }}
      >
        <span className="text-sm mdl:text-lg font-medium text-black capitalize">
          {name}
        </span>
        <div
          className={`${
            collapsed ? "rotate-0" : "-rotate-90"
          } transform transition-all duration-300 cursor-pointer`}
        >
          <ChevronDownIcon className="w-4 h-4 xl:w-5 xl:h-5" />
        </div>
      </div>
      {collapsed && (
        <div className="transform animate-slide grid grid-rows-1 gap-y-0 mt-0">
          {data?.map((val) => {
            const { inventory_product_Id } = val;
            return (
              <MenuCard
                key={inventory_product_Id}
                data={val}
                openStatus={openStatus}
                isVeg={isVeg}
              />
            );
          })}
          {categories?.map((val) => {
            const { inventory_product_Id, inventory_product_Name, products } =
              val;
            return (
              <MenuAccordian
                key={inventory_product_Id}
                categoryId={inventory_product_Id}
                name={inventory_product_Name}
                products={products?.products}
                category={products?.category}
                openStatus={openStatus}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MenuAccordian;
