import { useEffect, useState } from "react";
import WalletTab from "./WalletTab";
import LoyaltyTab from "./LoyaltyTab";
import MotionWrapper from "../../components/Animation/MotionWrapper";


const WalletTabs = () => {
  const [activeTab, setActiveTab] = useState("Wallet");
  return (
    <>
      <div
        className="flex w-max font-medium text-[18px] sm:text-[20px] xl:text-[24px] mdl:pt-6 mdl:pl-7 relative"
        style={{ borderCollapse: "collapse" }}
      >
        <div className="w-[220px] h-[3px] bg-[#d4d4d4] rounded-2xl overflow-hidden absolute bottom-[-5px]">
          <div
            className={`w-[110px] h-[3px] rounded-xl transition-all duration-100 ease-linear bg-black ${
              activeTab == "Loyalty"
                ? "translate-x-[110px]"
                : "translate-x-[0px]"
            }`}
          ></div>
        </div>
        <div
          className="cursor-pointer px-6 py-2"
          style={{ marginBottom: "-2px" }}
          onClick={() => setActiveTab("Wallet")}
        >
          Wallet
        </div>
        <div
          className="cursor-pointer px-4 py-2"
          style={{ marginBottom: "-2px" }}
          onClick={() => setActiveTab("Loyalty")}
        >
          Loyalty
        </div>
      </div>
      <MotionWrapper>
        <div className="">
          <div className="mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto mdl:p-7 h-auto no-scrollbar pb-16">
            {" "}
            <div>
              {activeTab === "Wallet" && (
                <WalletTab setActiveTab={setActiveTab} />
              )}
              {activeTab === "Loyalty" && (
                <LoyaltyTab setActiveTab={setActiveTab} />
              )}
            </div>
          </div>
        </div>
      </MotionWrapper>
    </>
  );
};

export default WalletTabs;
