export const LocationSpot = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M9.99543 22V19.9909C7.90259 19.7565 6.10693 18.89 4.60845 17.3916C3.10997 15.8931 2.24353 14.0974 2.00913 12.0046H0V9.99543H2.00913C2.24353 7.90259 3.10997 6.10693 4.60845 4.60845C6.10693 3.10997 7.90259 2.24353 9.99543 2.00913V0H12.0046V2.00913C14.0974 2.24353 15.8931 3.10997 17.3916 4.60845C18.89 6.10693 19.7565 7.90259 19.9909 9.99543H22V12.0046H19.9909C19.7565 14.0974 18.89 15.8931 17.3916 17.3916C15.8931 18.89 14.0974 19.7565 12.0046 19.9909V22H9.99543ZM11 18.032C12.9422 18.032 14.5997 17.3455 15.9726 15.9726C17.3455 14.5997 18.032 12.9422 18.032 11C18.032 9.05784 17.3455 7.4003 15.9726 6.0274C14.5997 4.65449 12.9422 3.96804 11 3.96804C9.05784 3.96804 7.4003 4.65449 6.0274 6.0274C4.65449 7.4003 3.96804 9.05784 3.96804 11C3.96804 12.9422 4.65449 14.5997 6.0274 15.9726C7.4003 17.3455 9.05784 18.032 11 18.032ZM11 15.0183C9.89498 15.0183 8.94901 14.6248 8.1621 13.8379C7.37519 13.051 6.98174 12.105 6.98174 11C6.98174 9.89498 7.37519 8.94901 8.1621 8.1621C8.94901 7.37519 9.89498 6.98174 11 6.98174C12.105 6.98174 13.051 7.37519 13.8379 8.1621C14.6248 8.94901 15.0183 9.89498 15.0183 11C15.0183 12.105 14.6248 13.051 13.8379 13.8379C13.051 14.6248 12.105 15.0183 11 15.0183ZM11 13.0091C11.5525 13.0091 12.0255 12.8124 12.419 12.419C12.8124 12.0255 13.0091 11.5525 13.0091 11C13.0091 10.4475 12.8124 9.97451 12.419 9.58105C12.0255 9.1876 11.5525 8.99087 11 8.99087C10.4475 8.99087 9.97451 9.1876 9.58105 9.58105C9.1876 9.97451 8.99087 10.4475 8.99087 11C8.99087 11.5525 9.1876 12.0255 9.58105 12.419C9.97451 12.8124 10.4475 13.0091 11 13.0091Z"
        fill=""
      />
    </svg>
  );
};
