import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addCount: 0,
  finalProduct: {},
  tempProduct: {},
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setaddCount(state, action) {
      state.addCount = action.payload;
    },
    setTempProduct(state, action) {
      state.tempProduct = action.payload;
    },
    setFinalProduct(state, action) {
      state.finalProduct = action.payload;
    },
    setTempProductLine(state, action) {
      state.tempProduct.productline = action.payload;
    },
    setFinalProductLine(state, action) {
      state.finalProduct.productline = action.payload;
    },
    setFinalProductLineKey(state, action) {
      if (state.finalProduct.hasOwnProperty("productline")) {
        state.finalProduct.productline = {};
      } else {
      }
      state.finalProduct.productline[action.payload.curProductlineKey] =
        action.payload.product;
    },
  },
});

export const menuReduce = menu.reducer;
export const {
  setaddCount,
  setTempProduct,
  setFinalProduct,
  setTempProductLine,
  setFinalProductLine,
  setFinalProductLineKey,
} = menu.actions;
