export default function ReviewLoader() {
  return (
    <div className={`animate-pulse`}>
      <div className="sm:bg-neutral-100 sm:w-full sm:rounded-lg relative">
        <div className="grid grid-cols-1 gap-4">
          <div className="h-36 bg-gray-300 rounded"></div>
          <div className="h-36 bg-gray-300 rounded"></div>
          <div className="h-36 bg-gray-300 rounded"></div>
          <div className="h-36 bg-gray-300 rounded"></div>
          <div className="h-36 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  );
}
