const ArrowRight = ({ className, height, width }) => {
  return (
    <svg
      className={`${className} rotate-180`}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1" clipPath="url(#clip0_6142_51423)">
        <path
          id="Vector"
          d="M9.00021 18C13.9707 18 18 13.9707 18 9.00021C18 4.02972 13.9707 0 9.00021 0C4.02972 0 0 4.02972 0 9.00021C0.00548363 13.9682 4.03183 17.9945 9.00021 18ZM9.00021 0.900276C13.4737 0.900276 17.1001 4.52668 17.1001 9.00021C17.1001 13.4737 13.4737 17.1001 9.00021 17.1001C4.52668 17.1001 0.900276 13.4737 0.900276 9.00021C0.905338 4.52879 4.52879 0.905338 9.00021 0.900276Z"
        />
        <path
          id="Vector_2"
          d="M10.0545 12.4883C10.2418 12.652 10.5262 12.633 10.6894 12.4457C10.8531 12.2584 10.8337 11.974 10.6468 11.8108L7.43387 8.99982L10.6468 6.18847C10.8342 6.02478 10.8531 5.74044 10.6894 5.55355C10.5258 5.36624 10.2414 5.34726 10.0545 5.51094L6.4547 8.66106C6.35683 8.74628 6.30114 8.86988 6.30114 8.99982C6.30114 9.12976 6.35725 9.25294 6.4547 9.33858L10.0545 12.4883Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_6142_51423">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="matrix(-1 0 0 -1 18 18)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRight;
