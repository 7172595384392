export default function RewardLoader() {
  return (
    <div className="animate-pulse grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-3 3xl:grid-cols-4 2xl:gap-6 xl:px-0 2xl:px-3 mt-4 md:-mx-3 sm:px-2">
      {[...Array(16)].map((_, index) => (
        <div
          key={index}
          className="bg-no-repeat bg-gray-300 bg-center bg-cover h-40 w-full cursor-pointer rounded-md"
        ></div>
      ))}
    </div>
  );
}
