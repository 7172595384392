export default function StoreCardLoader({}) {
  return (
    <div className="w-full grid grid-cols-2 gap-3 md:gap-5 md:grid-cols-3 lg:grid-cols-4">
      <div className="bg-store-card-background overflow-hidden border rounded-lg w-full ">
        <div className=" shadow rounded-md p-4 max-w-sm w-full mx-auto h-48 md:h-64	xl:h-72">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          </div>

          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
        </div>
      </div>
      <div className="bg-store-card-background overflow-hidden border rounded-lg w-full">
        <div className=" shadow rounded-md p-4 max-w-sm w-full mx-auto h-48 md:h-64	xl:h-72">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          </div>

          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
        </div>
      </div>
      <div className="bg-store-card-background overflow-hidden border rounded-lg w-full">
        <div className=" shadow rounded-md p-4 max-w-sm w-full mx-auto h-48 md:h-64	xl:h-72">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          </div>

          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
        </div>
      </div>
      <div className="bg-store-card-background overflow-hidden border rounded-lg w-full md:hidden lg:block">
        <div className=" shadow rounded-md p-4 max-w-sm w-full mx-auto h-48 md:h-64	xl:h-72">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-200 rounded"></div>
              <div className="space-y-3">
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
          </div>

          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
          <div className="h-2 bg-slate-200 rounded"></div>
          <div className="h-2 my-3 bg-slate-200 rounded"></div>
        </div>
      </div>
    </div>
  );
}
