const SubHeader = ({ className = "" }) => {
  return (
    <div
      className={`${className} animate-pulse rounded-lg flex justify-between w-full h-3 grow`}
    >
      <div className="w-[70%] items-baseline pr-4 sm:pr-0">
        <div className="bg-gray-300 w-20 h-3 rounded-sm"></div>
      </div>
    </div>
  );
};

export default SubHeader;
