import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumbs } from "../../components/Navigation/BreadCrumbs";
import React, { useRef } from "react";
import Layout from "../../layout/Layout";

const TermsConditions = () => {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 80);
  };

  return (
    <div className="overflow-hidden	h-max font-[inter]">
      <Breadcrumbs
        className={`${
          isFixed
            ? "fixed animate-fadedown top-[76px] left-0 shadow-xl"
            : "animate-fadeup"
        } bg-white w-full z-50 flex items-center px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] overflow-hidden`}
      />

      <Layout className="carousel w-full bg-slate-200 	  space-x-2 lg:space-x-5  2xl:md:pb-8 xl:pd-8 md:pb-6 pb-7 xl:pl-[45px] pl-[15px] sm:pl-5 md:pl-[25px]">
        <div className=" w-full lg:px-40 lg:py-4">
          <div className="row ng-binding">
            <h2 className="text-3xl	mb-2.5 mt-8 font-medium	">
              Terms &amp; Conditions
            </h2>
            <p className="mb-2.5 text-sm	text-gray-600">
              The following expressions have the following meanings in these
              Terms and Conditions, unless the context necessitates otherwise:
              "Takeaway" denotes the food outlet from which you desire to place
              an order.{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              "Kuick/us" denotes Kuick, a company registered in Stoke-on-Trent
              with a registered office at 539A Leek Road, Stoke-on-Trent, ST13ER{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              "Website" means Kuick.com
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              "Application" refers to the Kuick.com mobile app that you use to
              place food orders.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              These Terms &amp; Conditions apply to any things we sell and will
              form the basis of our transaction with you.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              1. All vouchers granted by Kuick of 539A Leek Road,
              Stoke-on-Trent, ST13ER are subject to the following terms and
              restrictions(VoucherTerms) (Kuick).
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              2. In addition, each coupon will be subject to additional terms
              and conditions (Specific Voucher Terms), which can be found at
              https://www.facebook.com/Kuickuk/www.Kuick.com
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3. Unless mentioned otherwise in the Specific Voucher Terms,
              vouchers are only valid for one usage.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4. Vouchers are only valid online and are subject to availability
              with participating eateries.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              5. Only one voucher can be used per order, unless stated
              otherwise.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              6. Vouchers can only be used on orders placed on Kuick's website
              or app.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              7. Each registered Kuick online customer account is restricted to
              one voucher. For every person, only one registered Kuick online
              customer account may be created.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              8. The voucher's expiration date is stated, and Kuick will not
              accept the voucher after that date. When the amount of redemptions
              per voucher has been reached, Kuick has the right to refuse to
              accept any vouchers, regardless of their expiration date. The
              maximum number of redemptions allowed for each voucher is 2,000.
              Where this number is different, specific redemption usage will be
              published for each coupon.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              9. Kuick maintains the right to declare any voucher or discount
              void, cancel, suspend, change, or remove it at any moment without
              notice if it becomes necessary.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              10. Kuick will not be liable to any customer for any loss
              resulting from the cancellation, suspension, rejection, or
              withdrawal of any voucher, or from a customer's failure or
              inability to use a voucher for any reason.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              11. Where it becomes necessary, Kuick maintains the right to amend
              these Voucher Terms and any of the Specific Voucher Terms at any
              time without previous notice.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              12. Vouchers are non-transferable and non-exchangeable, and no
              cash equivalent will be offered or refunded.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              13. No other voucher, promotion, discount, or offer can be
              combined with a voucher.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              14. Kuick reserves the right to refuse or cancel a voucher if it
              thinks, in its sole discretion, that the voucher is being used in
              violation of these Voucher Terms and/or the Specific Voucher
              Terms.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              15. Will the personal information provided while using a voucher
              be treated in accordance with Kuick’s privacy policy.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              16. Any orders placed using a voucher are subject to Kuick's usual
              terms and conditions of sale, as well as the website terms of use
              for utilising Kuick's website.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              17. English law governs these Voucher Terms and the Specific
              Voucher Terms, and the parties submit to the exclusive
              jurisdiction of the courts of England and Wales.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              General Terms and Conditions for the Price Draw
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              1. All prize draws run by Kuick of 539A Leek Road, Stoke-on-Trent,
              ST13ER are subject to the following terms and conditions (Terms).
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              2. Prize draws will be subject to additional terms and conditions
              (Relevant Terms) that are specific to each prize draw and can be
              found on the prize draw homepage.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3. By entering, entrants are deemed to have accepted and consented
              to be bound by these Terms as well as any Additional Terms. Kuick
              maintains the right to refuse entry to this and future prize
              draws, as well as refuse to award the prize to anybody who
              violates these Terms and/or any Specific Terms.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4. Employees of Kuick, its connected, holding, or subsidiary
              companies, and their families, agents, or any third party directly
              associated with the prize draws are not eligible to enter the
              prize draws. Apart from that all UK residents above the age of 18
              may enter.{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              5. By participating in a prize draw, the participant certifies
              that they are eligible to do so and that they are eligible to
              claim any prize they may win. Kuick may request proof of
              eligibility from the contestant.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              6. If the relevant prize is not accepted or claimed within the
              time period mentioned in the applicable Specific Terms, Kuick
              maintains the right to redraw a prize draw winner.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              7. The winner's award is non-transferable, non-exchangeable, and
              there is no monetary alternative.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              8. Entrants must ensure that they are able to receive the prize,
              asset out, and in compliance with these Terms and any applicable
              Specific Terms before entering.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              9. If circumstances beyond Kuick's control necessitate it, Kuick
              maintains the right to substitute the prize with an alternative
              prize of equal or greater value.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              10. If it becomes necessary, Kuick maintains the right to declare
              any prize draw void, cancel, postpone, change, or withdraw it at
              any moment without previous written notice.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              11.Kuick's decision on any aspect of any prize draw is final and
              binding, and there will be no correspondence concerning it.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              12. Except where it is caused by the negligence of Kuick, its
              agents or distributors or their employees, Kuick, its agents or
              distributors will not be responsible or liable to compensate the
              winner or accept any liability for any loss, damage, personal
              injury, or death occurring as a result of taking up the prize, to
              the extent permitted by law.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Statutory rights of the entrant remain unaffected.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              13. Where it becomes necessary, Kuick maintains the right to amend
              any of these Terms and any of the Specific Terms at any time
              without previous written notice.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              14. Any personal information provided during a Kuick prize draw
              will be processed in accordance with Kuick's privacy policy..
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              15. Any orders submitted are subject to Kuick's usual terms and
              conditions of sale, as well as the website terms of use for
              utilising Kuick's website.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              16. Prize drawings will be governed by English law, and entrants
              agree to submit to the sole jurisdiction of the English and Welsh
              courts.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">Our Service</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              We are an internet gateway that serves as a conduit between
              consumers and the food providers featured on our site. We are just
              functioning as an agent in the legal contract for the purchase of
              products and food delivery service between you, the customer, and
              the food supplier with whom you place your order.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">Contract</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              You will receive an acknowledgement e-mail and/or a webpage
              verifying receipt of your order after you put it. This email
              and/or webpage will only serve as an acknowledgement of your order
              and will not be construed as acceptance.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              A contract between us will not be established until we send you
              confirmation that the items you bought have been shipped to you
              via the medium you used to place the order (SMS, e-mail, or other
              relevant digital means). Only the products reported as being
              shipped at the time of dispatch will be included in the contract.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Price and Availability{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              All orders are contingent on availability and pricing
              confirmation. Dispatch times may vary depending on availability
              and any delays caused by postal delays or force majeure, for which
              we shall not be held liable. You must be over the age of 18 and
              have a valid credit or debit card issued by a bank that we accept
              in order to enter into a contract with Kuick.com. Any request made
              by you may be refused by Kuick.com. We will notify you via email,
              SMS, or the website if your order is accepted. When you place an
              order, you represent and warrant that all information you supply
              to us is true and accurate, that you are the authorised user of
              the credit or debit card used to place your order, and that you
              have adequate money to cover the cost of the items.{" "}
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              You will receive an acknowledgement e-mail and/or a webpage
              verifying receipt of your order after you put it. This email
              and/or webpage will only serve as an acknowledgement of your order
              and will not be construed as acceptance. A contract between the
              Takeaway and Kuick will not be established until we send you
              confirmation that the products you purchased have been shipped to
              you via the method you used to place the order (SMS, e-mail, or
              other relevant digital means). Only the products reported as being
              shipped at the time of dispatch will be included in the contract.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              While we make every effort to guarantee that all facts,
              descriptions, and prices on this Website/Application are correct,
              mistakes may occur. If we uncover an error in the pricing of any
              products you've ordered, we'll let you know as quickly as possible
              and give you the choice of reconfirming or cancelling your order
              at the correct price.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              If we are unable to contact you, your order will be cancelled. You
              will not be eligible for a refund from Kuick.com if you cancel and
              have already paid for the goods. Additional delivery charges will
              be applied; these charges will be clearly indicated where
              applicable and included in the 'Total Cost.'
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">Placing an Order</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              1. Confirm the takeaway's operating hours, as orders will not be
              completed outside of these hours.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              2. Choose the items you want to order by clicking the "Add to
              Basket" option next to each one.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              3. By selecting the "View Basket" option, you can evaluate the
              things you've selected at any moment.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              4. When you've decided what you want to order, click the
              "checkout" button to finish your purchase. You will then be
              directed to a secure webpage where you will be asked to provide
              delivery and payment information. To avoid any issues, please
              double-check your purchase before submitting it.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              5. Select "Place My Order" from the menu.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              6. If you receive a confirmation email, it implies your order has
              been accepted.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              7. Once you receive confirmation of dispatch through email or SMS,
              the contract with the takeout of your choice is formed.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              8. Please inform us as soon as possible if you make a mistake
              during the ordering procedure. Please contact the Kuick Customer
              Support team to amend or cancel your order, and they will do their
              best to accommodate your request. Kuick will make contact with the
              Takeaway on your behalf, however we cannot guarantee that your
              request will be accepted because food production may already be in
              progress.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">Delivery estimates</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              The collection and delivery times indicated are simply estimates;
              neither Kuick nor the Takeaway can guarantee a precise delivery
              time, but will make every effort to deliver within the estimated
              delivery time specified when you place your order.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Order Cancellation and Modifications
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              'You will not be able to cancel your order once payment has been
              taken from your account, and you will not be eligible for a
              refund.' Please contact the Kuick Customer Support team to amend
              or cancel your order, and they will do their best to accommodate
              your request.Kuick will make contact with the Takeaway on your
              behalf, however we cannot guarantee that your request will be
              accepted because food production may already be in progress.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">Feedback</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              If the order you received does not meet your expectations, you can
              contact the Takeaway via the Kuick website or mobile app's
              feedback page. Subject to the Kuick Customer Support team's
              approval, submitted feedback will be reviewed and displayed on the
              respective Takeaway's profile page.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              If you are dissatisfied with the quality of the takeaway's goods
              or service and desire to seek compensation, you should contact the
              takeaway directly to make your complaint and, if appropriate,
              follow the takeaway's own complaint processes. If you are unable
              to contact the takeaway or the takeaway refuses to deal with your
              complaint, you may contact the Kuick Customer Support Team, as the
              provider of this Website/Application, on (0)1782 817 644
            </p>
            within 48 hours of placing your Order, and one of the Kuick Customer
            Support Members will contact the takeaway on your behalf to request
            compensation.<p className="mb-2.5 text-sm	text-gray-600"></p>
            <p className="mb-2.5 text-sm	text-gray-600">Customer Service</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              The Kuick Customer Support team will make every effort to assist
              you and address any questions you may have about your order. You
              can contact the Kuick Customer Support staff by going to the
              Website and filling out the contact form.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Terms of Service and Privacy Policy
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Our Privacy Policy and Website/Application Terms of Use, both of
              which may be found at Terms of Use, govern your use of our
              Website/Application. This reference incorporates our Privacy
              Policy and Website/Application Terms of Use into these Terms and
              Conditions.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">Variation</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Kuick.com reserves the right, in its sole discretion, to change,
              remove, or vary the Services and/or any page of this Website at
              any time and without notice.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">Invalidity</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              If any provision of these Terms and Conditions is deemed by a
              court or other authority to be unlawful, void, or otherwise
              unenforceable, that part will be struck out (s). It shall be
              considered severed from the rest of these Terms and Conditions,
              with the remainder remaining legal and enforceable.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">Waiver</p>
            <p className="mb-2.5 text-sm	text-gray-600">
              If you violate these terms and we do nothing, we will still be
              able to use our rights and remedies in the future if you violate
              these terms again.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Jurisdiction and the Law
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              These Terms and Conditions, as well as any contractual or other
              relationship between you and us, will be governed by and construed
              in accordance with the laws of England and Wales.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              If you are a consumer, you will benefit from any obligatory legal
              provisions in your home country. Nothing in the preceding clause
              limits or restricts your consumer rights to rely on those
              provisions.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              If you are a consumer, you will benefit from any obligatory legal
              provisions in your home country. Nothing in the preceding clause
              limits or restricts your consumer rights to rely on those
              provisions.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Any disagreement, controversy, action, or claim between you and us
              relating to these Terms and Conditions, or our relationship
              (whether contractual or otherwise), will be decided by the courts
              of England, Wales, Scotland, or Northern Ireland, as determined by
              your residence.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Address: 539A Leek Road, Stoke-on-Trent, ST13ER -Tell: (0)1782 817
              644
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Kuick company registered in United Kingdom.
            </p>
            <p className="mb-2.5 text-sm	text-gray-600">
              Website: www.Kuick.com
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default TermsConditions;
