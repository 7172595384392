import { useState, useEffect } from "react";
import { profileData, getUpdatedPreference } from "../../services";
import { success, error } from "../../components/notify";
import PreferencesLoader from "../../components/Loader/ProfilePageLoaders/PreferencesLoader/PreferencesLoader";
import MotionWrapper from "../../components/Animation/MotionWrapper";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useDispatch } from "react-redux";

const PreferenceList = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { progressFunction } = useProgress();
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreferences();
  }, []);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading]);

  const [checkedSms, setCheckedSms] = useState("");
  const [checkedMail, setCheckedMail] = useState("");

  const [ischeckmubmit, setischeckmubmit] = useState(false);


  const getPreferences = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await profileData();
      if (status === 200) {
        if (data.status === "success") {
          const customer = data?.pageContent?.customer;
          setCheckedSms(customer?.common_customer_Subscribe_Sms);
          setCheckedMail(customer?.common_customer_Subscribe_Email);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching preferences:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatePreferenceData = async (key, value) => {
  //  setIsLoading(true);
    try {
      const { status, data } = await getUpdatedPreference(key, value ? 1 : 0);
      if (status === 200) {
        if (data.status === "success") {
          success(data.msg);
        }
       // setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      error(e.msg);
      // Handle error, e.g., show an error message to the user
    }
  };

  const handleToggleSMS = () => {
    // setischeckmubmit(true);
    // setTimeout(() => {
    //   setischeckmubmit(false);
    // }, 1000);
    const newValue = !checkedSms;
    setCheckedSms(newValue);
    updatePreferenceData("common_customer_Subscribe_Sms", newValue);
  };

  const handleToggleMail = () => {
    // setischeckmubmit(true);
    // setTimeout(() => {
    //   setischeckmubmit(false);
    // }, 1000);
    const newValue = !checkedMail;
    setCheckedMail(newValue);
    updatePreferenceData("common_customer_Subscribe_Email", newValue);
  };

  return (
    <div className="mt-4 pb-9">
      {isLoading ? (
        <PreferencesLoader />
      ) : (
        <div>
          <section className="flex items-center justify-between rounded-lg bg-white p-4 mb-4">
            <div>
              <p className="text-md text-black">Receive Promotional SMS</p>
            </div>
            <div className="flex items-center">
              <input
                id="toggleSms"
                type="checkbox"
                className="sr-only"
                checked={checkedSms}
                disabled={ischeckmubmit}
                onChange={handleToggleSMS}
              />
              <label
                htmlFor="toggleSms"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <div
                    className={`w-10 h-4 rounded-full shadow-inner ${
                      checkedSms ? "bg-toggleBg" : "bg-gray-400"
                    }`}
                  ></div>
                  <div
                    className={`dot absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition ${
                      checkedSms
                        ? "transform translate-x-full bg-toggleDot"
                        : "bg-white"
                    }`}
                  ></div>
                </div>
              </label>
            </div>
          </section>
          <section className="flex items-center justify-between rounded-lg bg-white p-4">
            <div>
              <p className="text-md text-black">Receive Promotional Email</p>
            </div>
            <div className="flex items-center">
              <input
                id="toggleMail"
                type="checkbox"
                className="sr-only"
                checked={checkedMail}
                disabled={ischeckmubmit}
                onChange={handleToggleMail}
              />
              <label
                htmlFor="toggleMail"
                className="flex items-center cursor-pointer"
              >
                <div className="relative">
                  <div
                    className={`w-10 h-4 rounded-full shadow-inner ${
                      checkedMail ? "bg-toggleBg" : "bg-gray-400"
                    }`}
                  ></div>
                  <div
                    className={`dot absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition ${
                      checkedMail
                        ? "transform translate-x-full bg-toggleDot"
                        : "bg-white"
                    }`}
                  ></div>
                </div>
              </label>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

const Preference = () => {
  return (
    <MotionWrapper>
      <div className="">
        <div className="mdl:min-h-[calc(100vh_-_10.5rem)] mdl:h-auto mdl:p-7">
          <div className="flex items-center justify-between">
            <div className="text-lg font-bold sm:text-xl sm:font-semibold sm:font-inter">
              Preference
            </div>
          </div>
          <div className="space-y-5 xl:grid xl:space-y-0 xl:grid-rows xl:gap-x-5 xl:gap-y-5">
            <PreferenceList />
          </div>
        </div>
      </div>
    </MotionWrapper>
  );
};

export default Preference;
