const Card = ({ className }) => {
  return (
    <svg
      viewBox="0 0 33 34"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M26 16V16.75H26.75V16H26ZM6 16H5.25V16.75H6V16ZM12 22.75C12.4142 22.75 12.75 22.4142 12.75 22C12.75 21.5858 12.4142 21.25 12 21.25V22.75ZM10 21.25C9.58579 21.25 9.25 21.5858 9.25 22C9.25 22.4142 9.58579 22.75 10 22.75V21.25ZM10 8.75H22V7.25H10V8.75ZM25.25 12V22H26.75V12H25.25ZM22 25.25H10V26.75H22V25.25ZM6.75 22V12H5.25V22H6.75ZM10 25.25C8.20507 25.25 6.75 23.7949 6.75 22H5.25C5.25 24.6234 7.37665 26.75 10 26.75V25.25ZM25.25 22C25.25 23.7949 23.7949 25.25 22 25.25V26.75C24.6234 26.75 26.75 24.6234 26.75 22H25.25ZM22 8.75C23.7949 8.75 25.25 10.2051 25.25 12H26.75C26.75 9.37665 24.6234 7.25 22 7.25V8.75ZM10 7.25C7.37665 7.25 5.25 9.37665 5.25 12H6.75C6.75 10.2051 8.20507 8.75 10 8.75V7.25ZM6 12.75H26V11.25L6 11.25L6 12.75ZM25.25 12V16H26.75V12H25.25ZM26 15.25H6V16.75H26V15.25ZM6.75 16V12H5.25V16H6.75ZM12 21.25H10V22.75H12V21.25Z" />
    </svg>
  );
};

export default Card;
