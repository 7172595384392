import React from "react";

export const Location = ({ className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="18"
      viewBox="0 0 15 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 4.31357C6.9099 4.31357 6.33305 4.48217 5.84241 4.79805C5.35176 5.11393 4.96934 5.56291 4.74352 6.0882C4.5177 6.61349 4.45862 7.19151 4.57374 7.74915C4.68886 8.3068 4.97302 8.81903 5.39028 9.22108C5.80755 9.62312 6.33917 9.89691 6.91793 10.0078C7.49669 10.1188 8.09659 10.0618 8.64177 9.84424C9.18695 9.62666 9.65292 9.2582 9.98076 8.78545C10.3086 8.31269 10.4836 7.75689 10.4836 7.18832C10.4836 6.42589 10.1692 5.69468 9.60972 5.15556C9.05019 4.61644 8.2913 4.31357 7.5 4.31357ZM7.5 8.62569C7.20495 8.62569 6.91653 8.54139 6.6712 8.38345C6.42588 8.22551 6.23467 8.00102 6.12176 7.73838C6.00885 7.47573 5.97931 7.18672 6.03687 6.9079C6.09443 6.62908 6.23651 6.37296 6.44514 6.17194C6.65377 5.97092 6.91959 5.83402 7.20896 5.77856C7.49834 5.7231 7.7983 5.75156 8.07088 5.86036C8.34347 5.96915 8.57646 6.15338 8.74038 6.38976C8.9043 6.62613 8.99179 6.90403 8.99179 7.18832C8.99179 7.56953 8.83462 7.93514 8.55486 8.2047C8.27509 8.47426 7.89565 8.62569 7.5 8.62569Z" />
      <path d="M7.5 17.25C6.87191 17.2531 6.2522 17.1111 5.69274 16.836C5.13328 16.5609 4.65037 16.1607 4.28444 15.6688C1.44182 11.8907 0 9.05044 0 7.22641C0 5.30985 0.790176 3.47178 2.1967 2.11657C3.60322 0.761352 5.51088 0 7.5 0C9.48912 0 11.3968 0.761352 12.8033 2.11657C14.2098 3.47178 15 5.30985 15 7.22641C15 9.05044 13.5582 11.8907 10.7156 15.6688C10.3496 16.1607 9.86672 16.5609 9.30726 16.836C8.7478 17.1111 8.12809 17.2531 7.5 17.25ZM7.5 1.5689C5.94288 1.57061 4.45004 2.16737 3.34898 3.22826C2.24793 4.28914 1.62858 5.72753 1.6268 7.22785C1.6268 8.67241 3.03879 11.3438 5.60169 14.7496C5.81926 15.0384 6.1047 15.2734 6.43464 15.4355C6.76459 15.5975 7.12966 15.682 7.5 15.682C7.87034 15.682 8.23541 15.5975 8.56536 15.4355C8.8953 15.2734 9.18073 15.0384 9.39831 14.7496C11.9612 11.3438 13.3732 8.67241 13.3732 7.22785C13.3714 5.72753 12.7521 4.28914 11.651 3.22826C10.55 2.16737 9.05712 1.57061 7.5 1.5689Z" />
    </svg>
  );
};
