const LocationIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`cursor-pointer ${className}`}
      viewBox="0 0 34 34"
    >
      <path
        d="M18.25 16C18.25 17.2426 17.2426 18.25 16 18.25V19.75C18.0711 19.75 19.75 18.0711 19.75 16H18.25ZM16 18.25C14.7574 18.25 13.75 17.2426 13.75 16H12.25C12.25 18.0711 13.9289 19.75 16 19.75V18.25ZM13.75 16C13.75 14.7574 14.7574 13.75 16 13.75V12.25C13.9289 12.25 12.25 13.9289 12.25 16H13.75ZM16 13.75C17.2426 13.75 18.25 14.7574 18.25 16H19.75C19.75 13.9289 18.0711 12.25 16 12.25V13.75ZM24.25 15.8889C24.25 18.0928 22.9651 20.7033 21.1927 22.8014C20.3165 23.8387 19.3496 24.7157 18.4183 25.3274C17.4717 25.9492 16.6339 26.25 16 26.25V27.75C17.0536 27.75 18.1845 27.2756 19.2418 26.5811C20.3145 25.8766 21.3867 24.8962 22.3386 23.7694C24.2224 21.5393 25.75 18.5941 25.75 15.8889H24.25ZM16 26.25C15.3661 26.25 14.5283 25.9492 13.5817 25.3274C12.6504 24.7157 11.6835 23.8387 10.8073 22.8014C9.03493 20.7033 7.75 18.0928 7.75 15.8889H6.25C6.25 18.5941 7.77757 21.5393 9.66144 23.7694C10.6133 24.8962 11.6855 25.8766 12.7582 26.5811C13.8155 27.2756 14.9464 27.75 16 27.75V26.25ZM7.75 15.8889C7.75 11.4027 11.4348 7.75 16 7.75V6.25C10.624 6.25 6.25 10.5567 6.25 15.8889H7.75ZM16 7.75C20.5652 7.75 24.25 11.4027 24.25 15.8889H25.75C25.75 10.5567 21.376 6.25 16 6.25V7.75Z"
        // strokeWidth="1.5"
        // strokeLinejoin="round"
      />
    </svg>
  );
};

export default LocationIcon;
