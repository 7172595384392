import { useState, useEffect } from "react";
import Notification from "./Notification";

export default function NotificationsManager({ setNotify }) {
  let [notifications, setNotifications] = useState([]);

  let createNotification = ({ color, autoClose, children, type }) => {
    setNotifications((prevNotifications) => {
      return [
        ...prevNotifications,
        {
          children,
          color,
          autoClose,
          type,
          id: prevNotifications.length,
        },
      ];
    });
  };

  useEffect(() => {
    setNotify(({ color, autoClose, children, type }) =>
      createNotification({ color, autoClose, children, type })
    );
  }, [setNotify]);

  let deleteNotification = (id) => {
    const filteredNotifications = notifications.filter(
      (_, index) => id !== index,
      []
    );
    setNotifications(filteredNotifications);
  };

  return notifications.map(({ id, ...props }, index) => (
    <Notification
      key={id}
      onDelete={() => deleteNotification(index)}
      {...props}
    />
  ));
}
