import React from "react";

const Mobile = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 56 56"
      fill="none"
    >
      <circle
        cx="28"
        cy="28"
        r="27"
        fill="white"
        stroke="#D8D8DF"
        strokeWidth="2"
      />
      <path
        d="M37 35V33.3541C37 32.5363 36.5021 31.8008 35.7428 31.4971L33.7086 30.6835C32.7429 30.2971 31.6422 30.7156 31.177 31.646L31 32C31 32 28.5 31.5 26.5 29.5C24.5 27.5 24 25 24 25L24.354 24.823C25.2844 24.3578 25.7029 23.2571 25.3165 22.2914L24.5029 20.2572C24.1992 19.4979 23.4637 19 22.6459 19H21C19.8954 19 19 19.8954 19 21C19 29.8366 26.1634 37 35 37C36.1046 37 37 36.1046 37 35Z"
        stroke="#7B7D8F"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Mobile;
