const CheckoutDetaisEdit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M16 8.5V13C16 14.6569 14.6569 16 13 16H4C2.34315 16 1 14.6569 1 13V4C1 2.34315 2.34315 1 4 1H8.5M11.2648 2.51706C11.2648 2.51706 11.2648 3.58978 12.3375 4.66251C13.4102 5.73523 14.4829 5.73523 14.4829 5.73523M6.366 11.4922L8.61872 11.1704C8.94367 11.1239 9.24479 10.9734 9.4769 10.7413L15.5557 4.6625C16.1481 4.07006 16.1481 3.10951 15.5557 2.51706L14.4829 1.44434C13.8905 0.851888 12.9299 0.851888 12.3375 1.44434L6.25873 7.5231C6.02663 7.7552 5.87606 8.05633 5.82964 8.38128L5.50783 10.634C5.43631 11.1346 5.8654 11.5637 6.366 11.4922Z"
        stroke="#108DE8"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CheckoutDetaisEdit;
