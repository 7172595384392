const ApplePay = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1762 0.0621174C11.329 0.248185 10.3869 0.759982 9.80444 1.35063C8.91152 2.25613 8.4507 3.33965 8.4507 4.53351V4.8154L8.90892 4.79102C9.84587 4.74116 10.5064 4.41925 11.2491 3.65052C12.2029 2.66319 12.6459 1.64611 12.6459 0.443289V0L12.5339 0.0040706C12.4723 0.00635014 12.3113 0.0324427 12.1762 0.0621174ZM11.7091 5.07466C11.1644 5.16039 10.5329 5.35232 9.55041 5.73068C8.98993 5.94654 8.94113 5.9581 8.59326 5.9577C8.28379 5.95733 8.17594 5.93791 7.90085 5.83322C6.07012 5.13629 5.99057 5.11573 5.11085 5.11227C4.49819 5.10983 4.40284 5.11952 4.03151 5.22185C3.51774 5.36347 2.74049 5.74138 2.31922 6.05445C1.2057 6.88197 0.3797 8.30114 0.0960972 9.87402C-0.0220604 10.5294 -0.0332203 11.8748 0.0735329 12.5985C0.43884 15.0748 1.27254 17.0611 2.76456 19.0097C3.64139 20.1549 4.49481 20.7723 5.2833 20.8317C5.72143 20.8647 6.03562 20.7906 7.07713 20.4088C7.93873 20.0929 8.28815 20.0165 8.87837 20.0147C9.46297 20.013 9.79422 20.0874 10.6796 20.4195C11.4619 20.713 11.8381 20.8003 12.32 20.8C13.4404 20.7994 14.1554 20.2637 15.3611 18.5213C16.0147 17.5768 16.576 16.518 16.8758 15.6641L17 15.3102L16.7271 15.1587C15.7805 14.6331 15.1547 14.0332 14.7401 13.2543C14.4125 12.6388 14.263 12.0728 14.2121 11.2552C14.15 10.2578 14.3871 9.32885 14.9012 8.55609C15.1468 8.18692 15.7013 7.6297 16.1034 7.34813C16.3089 7.20424 16.4235 7.09856 16.4061 7.06913C16.2013 6.72423 15.6688 6.18199 15.273 5.91528C14.4399 5.35386 13.3721 5.04173 12.3233 5.05309C12.0415 5.05614 11.7651 5.06587 11.7091 5.07466Z"
        fill="black"
      />
    </svg>
  );
};

export default ApplePay;
