const BookmarkIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 34"
      className={`${className}`}
    >
      <path d="M11.2 25.6L11.65 26.2H11.65L11.2 25.6ZM14.8 22.9L14.35 22.3H14.35L14.8 22.9ZM17.2 22.9L17.65 22.3L17.2 22.9ZM20.8 25.6L20.35 26.2L20.8 25.6ZM8 12.25C7.58579 12.25 7.25 12.5858 7.25 13C7.25 13.4142 7.58579 13.75 8 13.75V12.25ZM24 13.75C24.4142 13.75 24.75 13.4142 24.75 13C24.75 12.5858 24.4142 12.25 24 12.25V13.75ZM7.25 10V24H8.75V10H7.25ZM11.65 26.2L15.25 23.5L14.35 22.3L10.75 25L11.65 26.2ZM16.75 23.5L20.35 26.2L21.25 25L17.65 22.3L16.75 23.5ZM24.75 24V10H23.25V24H24.75ZM22 7.25H10V8.75H22V7.25ZM24.75 10C24.75 8.48122 23.5188 7.25 22 7.25V8.75C22.6904 8.75 23.25 9.30964 23.25 10H24.75ZM20.35 26.2C22.1629 27.5597 24.75 26.2661 24.75 24H23.25C23.25 25.0301 22.074 25.618 21.25 25L20.35 26.2ZM15.25 23.5C15.6944 23.1667 16.3056 23.1667 16.75 23.5L17.65 22.3C16.6722 21.5667 15.3278 21.5667 14.35 22.3L15.25 23.5ZM7.25 24C7.25 26.2661 9.8371 27.5597 11.65 26.2L10.75 25C9.92595 25.618 8.75 25.0301 8.75 24H7.25ZM8.75 10C8.75 9.30964 9.30964 8.75 10 8.75V7.25C8.48122 7.25 7.25 8.48122 7.25 10H8.75ZM8 13.75H24V12.25H8V13.75Z" />
    </svg>
  );
};

export default BookmarkIcon;
