const Delete = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="21"
      height="24"
      viewBox="0 0 21 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6686 3.68828H14.6811V2.44695C14.6811 2.16986 14.6256 1.90386 14.5259 1.64895C14.4261 1.39403 14.271 1.16128 14.0715 0.972865C13.872 0.784448 13.6503 0.629281 13.3954 0.518448C13.1405 0.418698 12.8745 0.363281 12.5974 0.363281H8.44114C8.16406 0.363281 7.89806 0.418698 7.64314 0.518448C7.3993 0.629281 7.16656 0.773365 6.97814 0.972865C6.78972 1.17236 6.63456 1.39403 6.52372 1.64895C6.42397 1.90386 6.36856 2.16986 6.36856 2.44695V3.68828H1.38105C0.926638 3.68828 0.549805 4.06511 0.549805 4.51953C0.549805 4.97395 0.926638 5.35078 1.38105 5.35078H2.26772L3.25414 21.1889C3.33172 22.5521 4.35139 23.6383 5.74789 23.6383H15.3017C16.7093 23.6383 17.7068 22.541 17.7955 21.1999L18.7819 5.35078H19.6686C20.123 5.35078 20.4998 4.97395 20.4998 4.51953C20.4998 4.06511 20.123 3.68828 19.6686 3.68828ZM8.03106 2.44695C8.03106 2.39153 8.04214 2.33611 8.06431 2.2807C8.08647 2.22528 8.11972 2.18095 8.15297 2.1477C8.18622 2.11445 8.24164 2.0812 8.28597 2.05903C8.34139 2.03686 8.38572 2.02578 8.44114 2.02578H12.5974C12.6528 2.02578 12.7082 2.03686 12.7526 2.05903C12.808 2.0812 12.8523 2.11445 12.8856 2.1477C12.9188 2.18095 12.9521 2.23636 12.9742 2.2807C12.9964 2.33611 13.0075 2.38045 13.0075 2.43586V3.68828H8.01997V2.44695H8.03106ZM16.133 21.0891C16.0997 21.6654 15.7229 21.9758 15.3017 21.9758H5.74789C5.31564 21.9758 4.93881 21.6654 4.91664 21.1002V21.0891L3.93022 5.35078H17.1194L16.133 21.0891Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5217 7.01172C10.0673 7.01172 9.69043 7.38855 9.69043 7.84297V19.4805C9.69043 19.9349 10.0673 20.3117 10.5217 20.3117C10.9761 20.3117 11.3529 19.9349 11.3529 19.4805V7.84297C11.3529 7.38855 10.9761 7.01172 10.5217 7.01172Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75504 7.01197C6.28954 7.03414 5.93487 7.41097 5.95704 7.87647L6.37821 19.514C6.38929 19.9684 6.77721 20.3341 7.24271 20.312C7.69712 20.3009 8.06287 19.913 8.04071 19.4475L7.60846 7.80997C7.59737 7.35556 7.20946 7.00089 6.75504 7.01197Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.44 7.80997L13.0188 19.4475C13.0077 19.9019 13.3624 20.2898 13.8168 20.312C14.2712 20.3231 14.6591 19.9684 14.6813 19.514L15.1025 7.87647C15.1136 7.42205 14.7589 7.03414 14.3045 7.01197C13.839 7.00089 13.4511 7.35556 13.44 7.80997Z"
      />
    </svg>
  );
};

export default Delete;
