// import React from "react";

const RedBellMarker = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-7 md:w-8"
      viewBox="0 0 47 54"
      fill="none"
    >
      <path
        d="M47 23.4783C47 36.4449 36.4787 46.9565 23.5 46.9565C10.5213 46.9565 0 36.4449 0 23.4783C0 10.5116 10.5213 0 23.5 0C36.4787 0 47 10.5116 47 23.4783Z"
        fill="#FF0A38"
      />
      <path
        d="M23.5 54C23.5 48.8348 31.3333 44.8043 35.25 43.4348H23.5V54Z"
        fill="#FF0A38"
      />
      <path
        d="M23.5 54C23.5 48.8348 15.6667 44.8043 11.75 43.4348H23.5V54Z"
        fill="#FF0A38"
      />
      <path
        d="M24 11C21.0838 11.0032 18.2879 12.1432 16.2258 14.1701C14.1636 16.1969 13.0035 18.9451 13 21.8116C13 24.5958 15.1932 28.953 19.5189 34.7618C20.0338 35.4553 20.7081 36.0194 21.4868 36.4084C22.2655 36.7973 23.1266 37 24 37C24.8734 37 25.7345 36.7973 26.5132 36.4084C27.2919 36.0194 27.9661 35.4553 28.4811 34.7618C32.8068 28.953 35 24.5958 35 21.8116C34.9965 18.9451 33.8364 16.1969 31.7742 14.1701C29.7121 12.1432 26.9162 11.0032 24 11ZM24 26.1211C23.1281 26.1211 22.2758 25.867 21.5508 25.3908C20.8259 24.9147 20.2608 24.2379 19.9272 23.4461C19.5935 22.6543 19.5062 21.783 19.6763 20.9424C19.8464 20.1018 20.2663 19.3297 20.8828 18.7237C21.4993 18.1177 22.2848 17.705 23.14 17.5378C23.9951 17.3706 24.8815 17.4564 25.687 17.7843C26.4925 18.1123 27.181 18.6677 27.6654 19.3803C28.1498 20.093 28.4084 20.9308 28.4084 21.7878C28.4084 22.9371 27.9439 24.0393 27.1172 24.8519C26.2905 25.6646 25.1692 26.1211 24 26.1211Z"
        fill="white"
      />
    </svg>
  );
};

export default RedBellMarker;
