export default function ButtonLoader({ className, subClassName, children }) {
  return (
    <div className={`${className}`}>
      <button
        className={`animate-pulse rounded-lg w-48 font-medium border bg-gray-300 px-8 py-4 ${subClassName}`}
      >
        {children}
      </button>
    </div>
  );
}
