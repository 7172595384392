import { useState } from "react";
import { checkEmpty } from "../../utils/helper";
import useDebounce from "../../hooks/useDebounce";
import CrossIcon from "../../assets/images/CrossIcon.png";
import NoData from "../../assets/images/no-data-found/menu-search/nodata.png";
import ArrowLeftChevron from "../../components/Icon/ArrowLeftChevron";

const SearchMenu = ({ data, searchData, storeName, onSearch, onClose }) => {
  const [text, setText] = useState("");
  const [loading, setIsLoading] = useState(false);

  // DeBounce Function
  useDebounce(
    () => {
      handleSearchData();
    },
    [data, text],
    800
  );

  const categoryRecursion = (category) => {
    let result = [];
    category?.forEach((cat) => {
      if (!checkEmpty(category?.category)) {
        result = result.concat(categoryRecursion(category?.category));
      }
      cat?.products?.products?.forEach((catProd) => {
        if (
          catProd?.inventory_product_Name
            ?.toLowerCase()
            .includes(text?.toLowerCase())
        ) {
          result.push(catProd);
        }
      });
    });
    return result;
  };

  const handleSearchData = () => {
    if (text?.length > 2) {
      let result = [];
      Object.keys(data ?? {})?.forEach((val) => {
        if (!checkEmpty(data[val]?.category)) {
          result = result.concat(categoryRecursion(data[val]?.category));
        }
        if (!checkEmpty(data[val]?.product)) {
          Object.keys(data[val]?.product ?? {}).forEach((prod) => {
            if (
              data[val]?.product[prod]?.inventory_product_Name
                ?.toLowerCase()
                .includes(text?.toLowerCase())
            ) {
              result.push(data[val]?.product[prod]);
            }
          });
        }
      });
      onSearch(result);
      setIsLoading(false);
    } else {
      onSearch([]);
    }
  };

  return (
    <>
      <div className="w-full md:w-3/4 xl:w-2/5 border border-orderDetail rounded-lg flex items-center justify-between overflow-hidden">
        <button
          onClick={() => {
            setText("");
            onClose();
          }}
          className="w-[10%] flex items-center justify-center h-10 cursor-pointer"
        >
          <ArrowLeftChevron className="w-2 h-4" />
        </button>
        <input
          className={`focus:outline-none py-3 md:py-4 ${
            text?.length > 0 ? "w-4/5" : "w-full"
          }`}
          placeholder={`Search in ${storeName}`}
          maxLength={40}
          value={text}
          onChange={(event) => {
            setIsLoading(true);
            setText(event.target.value);
          }}
        />
        {text?.length > 0 && (
          <button
            className="w-[10%] flex items-center justify-center"
            onClick={() => {
              setText("");
            }}
          >
            <img
              alt="cross"
              className="w-6 h-6 mdl:w-7 mdl:h-7"
              src={CrossIcon}
            />
          </button>
        )}
      </div>
      {!loading && searchData?.length === 0 && text?.trim().length !== 0 && (
        <>
          <img className="w-[300px]" src={NoData} />
          <span className="text-primary text-mdl font-semibold">
            No result found!
          </span>
          <div className="border-[0.5px] border-card-border mt-3 w-full" />
        </>
      )}
    </>
  );
};

export default SearchMenu;
