import { useEffect } from "react";
import Chips from "./Chips";
import { checkEmpty } from "../../utils/helper";
import { Tooltip } from "@material-tailwind/react";

const Tags = ({ data, className = "" }) => {
  if (checkEmpty(data)) {
    return null; // Render nothing if data is empty
  }

  return (
    <div className={`flex flex-nowrap w-full ${className}`}>
      {data?.map((v, ind) => {
        if (data?.length > 0 && ind < 3) {
          return (
            <Chips
              key={"take" + ind}
              value={v}
              className="mr-2 mb-1.5 bg-secondary px-1.5 sm:px-3"
              valueClassName="text-[12px] sm:text-sm font-medium font-inter align-middle text-chip-text-color"
            />
          );
        }
      })}
      <Tooltip className="mx-4 mdl:mx-6" content={data?.join(",")}>
        <span className="ml-2 flex items-center -mt-1">
          {data?.length > 3 ? `+${data?.length - 3}` : ""}
        </span>
      </Tooltip>
    </div>
  );
};

export default Tags;
