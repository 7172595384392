import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuPage } from "../../routes/RoutesPath";
import { formatPostcode } from "../../utils/helper";
import GreaterThan from "../../assets/images/chevron-right.png";
import { setIsShowSearch } from "../../store/userData/userDataSlice";

const StoreBanner = ({ onClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeDetails = useSelector((state) => state?.basicdata?.storedetails);

  return (
    <div
      className="my-2 flex justify-between items-center"
      onClick={() => {
        onClick();
        navigate(MenuPage(storeDetails?.store_Slug, storeDetails?.product_Id));
        dispatch(setIsShowSearch(false));
      }}
    >
      <div className={`flex items-center`}>
        <div className="w-12 h-12 border-[0.6px] border-orderDetailBD rounded-[4px] p-0.5">
          <img className="rounded-[4px]" src={storeDetails?.store_Logo} />
        </div>
        <div className="px-3 flex flex-col">
          <span className="text-md font-semibold">
            {storeDetails?.store_Name}
          </span>
          <span className="text-[10px] md:text-xxs lg:text-xs -mt-2 font-medium">
            {storeDetails?.store_Address_Line_1 + ", "}
            {storeDetails?.store_Address_Line_2 + ", "}
            {formatPostcode(storeDetails?.store_Postcode ?? "") + ", "}
            {storeDetails?.store_City}
          </span>
        </div>
      </div>
      <img src={GreaterThan} className="brightness-0 w-4 h-4" />
    </div>
  );
};

export default StoreBanner;
