import CartIcon from "../Icon/Cart";
import WalletIcon from "../Icon/WalletIcon";
import UserIcon from "../Icon/UserIcon";
import Cross from "../../assets/images/CrossIcon.png";
import Home from "../Icon/Home";
import LogoutIcon from "../Icon/Logout";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import {
  WalletPage,
  HomePage,
  LoginPage,
  BookmarksPage,
  ProfilePage,
  AddressPage,
  PastOrdersPage,
  RewardsPage,
  PreferencePage,
  BankcardsPage,
} from "../../routes/RoutesPath";
import { removeSession, removeDismissedPendingOrders } from "../../utils";
import { useSelector } from "react-redux";
import { profileData } from "../../services/index";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import Modal from "react-modal";
import Button from "../Elements/Button";
import { useDispatch } from "react-redux";
import {
  removeUserData,
  setLoginModule,
} from "../../store/userData/userDataSlice";
import { NavLink } from "react-router-dom";
import LocationIcon from "../Icon/LocationIcon";
import BookmarkIcon from "../Icon/BookMarkIcon";
import Card from "../Icon/Card";
import PastorderIcon from "../Icon/PastorderIcon";
import MyReward from "../Icon/MyReward";
import HamburgerIcon from "../Icon/Hamburger";
import LoginModal from "../Modal/LoginModal";
import Cart from "../Elements/Cart";
import HeaderDrawer from "../Header/HeaderDrawer";
import { checkDoSticky, checkEmpty, formatPostcode } from "../../utils/helper";
import useHomeClick from "../../hooks/useHomeClick";

const BottomMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedin = useSelector((state) => state.userdata.loggedIn);
  const location = useLocation();

  const [menuActive, setmenuActive] = useState("Home");

  const [profileDetails, setProfileDetails] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const userDetails = useSelector((state) => state?.basicdata?.customer);
  // const allUserDetails = useSelector((state) => state?.userdata);
  //const userDetails = allUserDetails?.userData?.customer;

  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const basket = useSelector((state) => state?.basicdata?.basket);

  const { handleHomeRedirect } = useHomeClick();
  const [postcode, setPostcode] = useState("");
  const locationData = useSelector((state) => state?.basicdata?.locationData);

  useEffect(() => {
    if (isLoggedin) {
      // getProfileData();
    }
  }, []);

  const urlPath = window?.location?.pathname;

  useEffect(() => {
    let urlPathArray = urlPath.split("/");
    if (urlPathArray.includes("profile")) {
      setmenuActive("Profile");
      if (urlPathArray.includes("wallet")) {
        setmenuActive("Wallet");
      }
    } else if (urlPathArray.includes("stores")) {
      setmenuActive("Home");
    }
  }, [urlPath]);

  useEffect(() => {
    let count = 0;
    basket?.basketProducts?.forEach((val) => {
      count += val.pos_basketproduct_Quantity;
    });
    setTotalItems(count);
  }, [basket]);

  const activeInactiveProvider = (selection) => {
    if (selection == "Home") {
      setmenuActive("Home");

      const pathname = location?.pathname?.toLocaleLowerCase();
      const pathParts = pathname.split("/");
      let newpostcode = "";
      if (pathParts.includes("stores")) {
        const postcodeIndex = pathParts.indexOf("stores") + 1;
        setPostcode(pathParts[postcodeIndex]?.toLowerCase());
        newpostcode = pathParts[postcodeIndex]?.toLowerCase();
      } else {
        setPostcode(locationData?.postcodePretty?.toLowerCase());
        newpostcode = locationData?.postcodePretty?.toLowerCase();
      }

      const landingPagePath = `/`;
      if (!checkEmpty(newpostcode)) {
        handleHomeRedirect(newpostcode);
      } else {
        navigate(landingPagePath);
      }
    } else if (selection == "Cart") {
      setmenuActive("Cart");
    } else if (selection == "Profile") {
      setmenuActive("Profile");
    } else {
      setmenuActive("Wallet");
      if (isLoggedin) {
        navigate(WalletPage);
      } else {
        openLoginModal();
        setmenuActive("Home");
      }
    }
  };

  const handleLogOut = () => {
    removeSession();
    dispatch(removeUserData());
    setModalIsOpen(false);
    navigate(HomePage);
    removeDismissedPendingOrders();
  };
  const menuOpenModal = () => {
    // setMenuOpen(true);
  };
  const closeDrawer = () => {
    setIsProfileDrawerOpen(false);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenModal = () => {
    closeDrawer();
    setModalIsOpen(true);
    setIsProfileDrawerOpen(false);
  };

  useEffect(() => {
    // navigate(HomePage);
  }, []);

  const openLoginModal = () => {
    dispatch(setLoginModule(true));
  };

  const ToFaq = () => {
    navigate("/frequently/asked/questions");
    closeDrawer();
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <div className="h-16 w-full flex mdl:hidden justify-around bg-white rounded-t-lg shadow-black shadow-2xl bottom-0 left-0 fixed z-50 text-xs">
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
          <Cart isDrawer toggleDrawer={toggleDrawer} />
        </Drawer>

        <Drawer anchor="right" open={isProfileDrawerOpen} onClose={closeDrawer}>
          <ProfileDrawer
            closeDrawer={closeDrawer}
            userDetails={userDetails}
            handleOpenModal={handleOpenModal}
            ToFaq={ToFaq}
          />
        </Drawer>

        <div
          className={`flex flex-col items-center mt-2 cursor-pointer hover:scale-105 ${
            menuActive == "Home" ? "text-primary" : ""
          }`}
          onClick={() => activeInactiveProvider("Home")}
        >
          <Home
            className={`h-8 w-6 ${
              menuActive == "Home" ? "fill-primary" : "fill-black"
            }`}
          />
          <span className="-mt-1">Home</span>
        </div>
        <div
          className={`flex flex-col items-center mt-2 cursor-pointer hover:scale-105 relative ${
            menuActive == "Cart" ? "text-primary" : ""
          }`}
          onClick={() => activeInactiveProvider("Cart")}
        >
          <div className="" onClick={() => toggleDrawer()}>
            <div className=" flex flex-col w-max items-center md:mb-0">
              <CartIcon
                className={`h-8 w-6 ${
                  menuActive == "Cart"
                    ? "stroke-primary"
                    : "stroke-black-shade-5"
                }`}
                isCartActive={menuActive}
              />
              {totalItems > 0 && (
                <div
                  className="h-5 w-5 rounded-full bg-stepperCircle text-white flex items-center justify-center absolute ml-8"
                  style={{ fontSize: "10px" }}
                >
                  {totalItems}
                </div>
              )}
              <span className="-mt-1 text-center">Cart</span>
            </div>
          </div>
        </div>

        <div
          className={`flex flex-col items-center mt-2 cursor-pointer hover:scale-105 ${
            menuActive == "Profile" ? "text-primary" : ""
          }`}
          onClick={() => {
            if (isLoggedin) {
              activeInactiveProvider("Profile");
              setIsProfileDrawerOpen(true);
              menuOpenModal();
            } else {
              openLoginModal();
            }
          }}
        >
          <UserIcon
            className={`h-8 w-10 ${
              menuActive == "Profile" ? "fill-primary" : "fill-black"
            }`}
          />
          <span className="-mt-1">Profile</span>
        </div>

        <div
          className={`flex flex-col items-center mt-2 cursor-pointer hover:scale-105 ${
            menuActive == "Wallet" ? "text-primary" : ""
          }`}
          onClick={() => activeInactiveProvider("Wallet")}
        >
          <WalletIcon
            className={`h-8 w-10 ${
              menuActive == "Wallet" ? "fill-primary" : "fill-black"
            }`}
          />
          <span className="-mt-1">Wallet</span>
        </div>
      </div>
      {isLoggedin && (
        <div className="drawer drawer-end ">
          <div className="drawer-content flex items-center">
            {/* Page content here */}
            {/* <label htmlFor="my-drawer-4" onClick={menuOpenModal}>
              <Filter className="w-6 md:hidden" />
            </label> */}
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className=" bg-black bg-opacity-50 z-50 "
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-80">
          <div className="flex items-center justify-center">
            <img
              loading="lazy"
              className="w-16 h-16 rounded-full object-cover"
              src={
                "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
              }
              alt=""
            />
          </div>
          <div className="text-center font-semibold text-mdl">
            <p className="py-6">Are you sure want to log out?</p>
            <div className="">
              <Button
                title="Okay"
                type="fill"
                fillColor="primary"
                className="bg-primary py-2 text-white w-full mb-3"
                onClick={handleLogOut}
              />
              <Button
                className="border-card-border py-2 border w-full"
                onClick={handleCloseModal}
                title="Cancel"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BottomMenu;

const ProfileDrawer = ({
  closeDrawer,
  userDetails,
  handleOpenModal,
  ToFaq,
}) => {
  return (
    <div className="w-full">
      <ul className="menu p-4 md:w-[448px] w-full min-h-full bg-white text-base-content">
        {/* prifile image and user name */}
        <div className="flex justify-between items-center">
          <div className="space-x-3 flex items-center">
            <img
              className="w-14 h-14 rounded-full object-cover "
              loading="lazy"
              src={
                userDetails?.profileImage ||
                "https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
              }
            />
            <div className="flex flex-col">
              <p className="text-neutral-800 text-base font-normal font-inter leading-snug">
                {userDetails && userDetails?.common_customer_First_Name}{" "}
              </p>
              <p className="text-neutral-800 text-sm font-normal font-inter leading-snug">
                {userDetails && userDetails?.common_customer_Telephone}
              </p>
            </div>
          </div>
          <div className="cursor-pointer" onClick={closeDrawer}>
            <img src={Cross} className="w-6  mdl:w-8" />
          </div>
        </div>

        {/* Sidebar content here */}
        <div className="pt-5 sm:h-[calc(100vh-215px)] sm:overflow-y-auto md:h-[calc(100vh-215px)] md:overflow-y-auto">
          <DrawerItem
            title="Profile Info"
            Icon={UserIcon}
            to={ProfilePage}
            onClick={closeDrawer}
            bottomborder="1"
          />
          <DrawerItem
            title="Address"
            Icon={LocationIcon}
            to={AddressPage}
            onClick={closeDrawer}
            bottomborder="1"
          />
          <DrawerItem
            title="Bookmarks"
            Icon={BookmarkIcon}
            to={BookmarksPage}
            onClick={closeDrawer}
            bottomborder="1"
          />
          <DrawerItem
            title="Bank Cards"
            Icon={Card}
            to={BankcardsPage}
            onClick={closeDrawer}
            bottomborder="1"
          />
          <DrawerItem
            title="Past Orders"
            Icon={PastorderIcon}
            to={PastOrdersPage}
            onClick={closeDrawer}
            bottomborder="1"
          />
          <DrawerItem
            title="My Rewards"
            Icon={MyReward}
            to={RewardsPage}
            onClick={closeDrawer}
            bottomborder="1"
          />
          <DrawerItem
            title="Preference"
            Icon={HamburgerIcon}
            to={PreferencePage}
            onClick={closeDrawer}
            bottomborder="1"
          />
          <DrawerItem
            title="Wallet"
            Icon={WalletIcon}
            to={WalletPage}
            onClick={closeDrawer}
            bottomborder="1"
          />
          <div className="absolute bottom-5 w-[90%] sm:w-[95%] md:w-[91%]">
            <DrawerItem
              title="Logout"
              to="#"
              Icon={LogoutIcon}
              onClick={handleOpenModal}
            />
            <div className="border-b border-divider mt-2"></div>
            <div className="flex items-center justify-between  mt-6 px-3">
              <p
                className="cursor-pointer text-gray-shade-11 text-sm"
                onClick={() => ToFaq()}
              >
                FAQ
              </p>
              {/* <div className="mx-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="6"
              viewBox="0 0 5 6"
              fill="none"
            >
              <circle cx="2.5" cy="3" r="2.5" fill="#D9D9D9" />
            </svg>
          </div> */}
              <p
                className="cursor-pointer text-gray-shade-11 text-sm"
                onClick={() => ToFaq()}
              >
                Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
};

const DrawerItem = ({ to, title, Icon, onClick, bottomborder = 0 }) => {
  return (
    <>
      <NavLink
        to={to}
        style={{ margin: "0!important" }}
        className={`group flex justify-between items-center m-0   ${
          location.pathname === to ? "bg-primary text-white" : ""
        } hover:bg-primary hover:fill-white py-1.5 px-2 rounded-lg mx-0 my-2`}
        onClick={onClick}
      >
        <>
          <div className="w-full m-0 group flex space-x-6 items-center ">
            <Icon
              className={`${
                location.pathname === to ? "text-white fill-white" : " "
              } w-7 2xl:w-8 2xl:h-8`}
            />
            <div
              className={`${
                location.pathname === to ? "text-white" : "text-zinc-700"
              } group-hover:text-white text-base font-medium font-inter leading-normal`}
            >
              {title}
            </div>
          </div>
          <ChevronRightIcon
            className={`${
              location.pathname === to ? "text-white" : ""
            } group-hover:text-white w-5 h-5`}
          />
        </>
      </NavLink>
      {bottomborder == 1 && (
        <div className="border-b border-divider mx-2 mt-2"></div>
      )}
    </>
  );
};
