import { useState, useEffect } from "react";
import { loyaltyStoreData } from "../../services/index";
import { error } from "../../components/notify";
import LazyLoadImageComponent from "../../components/Image/LazyLoadImage";
import logo_cover from "../../assets/images/default_images/logo_cover.jpg";
import MedalIcon from "../../assets/images/profile_section/medal.png";
import { loyaltyData } from "../../services/index";
import Card from "../../components/Cards/Card";
import DrawerHeader from "../../components/Header/DrawerHeader";

const LoyaltyTabDrawer = ({ isDrawerOpen, item, onClose }) => {
  const [loyaltyDetails, setLoyaltyDetails] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [loyaltyStorePoints, setLoyaltyStorePoints] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(3);

  useEffect(() => {
    handleViewPoints(item);
  }, [isDrawerOpen]);

  const handleViewPoints = async (item) => {
    if (isDrawerOpen) {
      setLoyaltyDetails(item);
      const productId = item.product_Id;
      setIsLoader(false);
      try {
        const { status, data } = await loyaltyStoreData(skip, take, productId);
        if (status === 200) {
          if (data.status === "success") {
            setLoyaltyStorePoints(data?.common_customer_point);
            setIsOpen(true);
          }
          setIsLoader(false);
        }
      } catch (e) {
        setIsLoader(false);
        error("error msg");
      } finally {
        setIsLoader(false);
      }
    }
  };

  return (
    <div className="w-full">
      <DrawerHeader title={"Loyalty"} onClose={onClose} />
      <section className="w-full px-6">
        <div className="flex items-center justify-between rounded-lg bg-bg-pink border border-primary p-4 mt-8 mb-3 w-full">
          <div className="flex w-[70%]">
            <div className="w-[60px] flex">
              <LazyLoadImageComponent
                src={loyaltyDetails?.store_Logo}
                alt=""
                className="rounded-lg"
                onErrorSrc={logo_cover}
                loading="lazy"
                placeholderSrc={logo_cover}
              />
            </div>
            <div className="ml-3 w-24">
              <h3 className="mt-2 text-md font-medium truncate w-20">
                {loyaltyDetails?.store_Name}
              </h3>
              <p className="my-1 text-xs font-medium text-[#767373] truncate w-20">
                {loyaltyDetails?.store_Address}
              </p>
            </div>
          </div>
          <div className="flex w-[30%]">
            <div className="flex justify-center items-center bg-white rounded-full w-12 h-12">
              <div className="w-5">
                <img
                  className="max-w-full"
                  src={MedalIcon}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div className="ml-4">
              <p className="font-semibold text-black text-xs">Loyalty Points</p>
              <p className="font-semibold text-checkText text-sm">
                {loyaltyDetails?.common_customer_point_Balance}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="px-6">
        <div className="grid grid-cols-1">
          {loyaltyStorePoints &&
            loyaltyStorePoints.map((item) => (
              <>
                <Card
                  key={item}
                  className={`rounded-lg border-none bg-white py-4`}
                >
                  <div className="flex justify-between">
                    <div className="">
                      <p className="font-bold pb-2">
                        {item?.common_customer_point_Description}
                      </p>
                      <p className="text-loyalityDate">{item?.pretty_date}</p>
                      <div className="flex items-center mt-4">
                        <p
                          className={`text-p6 font-semibold  leading-5 ${
                            item?.common_customer_point_Operator.includes("-")
                              ? "text-wrongText"
                              : "text-checkText"
                          }`}
                        >
                          {item?.common_customer_point_Operator}{" "}
                          {item?.common_customer_point_Value}
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
                <div className="border-b border-divider mb-[2px]"></div>
              </>
            ))}
        </div>
      </section>
    </div>
  );
};

export default LoyaltyTabDrawer;
