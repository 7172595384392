const FoodHygiene = ({ rating, text }) => {
  return (
    <div className="relative">
      <div className="absolute rounded-t-lg hidden md:block bg-green-shade-5 w-40 lg:w-48 right-[25px] sm:right-7 md:right-8 lg:right-12 xl:right-[60px] top-8">
        <div className="flex flex-col justify-center items-center pt-2">
          <label className="text-sm lg:text-md">Food Hygiene Rating</label>
          <div className="border-black border w-full"></div>
          <div className="flex items-center justify-evenly w-full pb-3">
            {new Array(5).fill(0).map((_val, ind) => {
              return (
                <div
                  key={"foodhy" + ind}
                  className="flex flex-col items-center justify-center"
                >
                  <div className="w-3 h-3">
                    {ind + 1 === rating && (
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.91039 11.0137L0.025857 0.821364L11.7949 0.821364L5.91039 11.0137Z"
                          fill="black"
                        />
                      </svg>
                    )}
                  </div>
                  <div
                    className={`${
                      ind + 1 === rating
                        ? "bg-black text-white"
                        : "bg-white text-black"
                    } w-6 lg:w-8 h-6 lg:h-8 rounded-full border-black border flex items-center justify-center text-md font-medium`}
                  >
                    {ind + 1}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full h-7 bg-black text-white text-center text-md flex items-center justify-center">
          {text}
        </div>
      </div>
    </div>
  );
};

export default FoodHygiene;
