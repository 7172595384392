import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { deleteAddress } from "../../config";
import { profileData, getBasicDineData } from "../../services";
import { success, error } from "../notify";
import nodata_found from "../../assets/images/no-data-found/profile/address/nodata.png";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";
import {
  setUserAddress,
  setUserData,
} from "../../store/userData/userDataSlice";
import { checkEmpty, prepareProfileAddressFormat } from "../../utils/helper";

const AddressCardProfile = ({
  showTooltip,
  openEdit,
  activeAddressId,
  setNewAddress,
  setisFormubmit,
  setSelectedOption,
  setPostcode,
  setHouseNo,
  setStreetName,
  setCity,
  setLandmark,
  clearAdd,
  setIsLoading,
  setShowTooltip,
  addressDetails,
  personalDetails,
  addressData,
  setShowButton,
  setIsLoadingTopBar,
  setAddressDetails,
  setSelectedLat,
  setSelectedLng,
  setCustomOption,
  isLoading,
}) => {
  const tooltipRef = useRef(null); // Add a ref for the tooltip

  const { progressFunction } = useProgress();
  const dispatch = useDispatch();
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 1000);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      window.clearInterval(intervalData);
    }
  }, [isLoading]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowTooltip]);

  const EditDetails = async (addressData) => {
    setNewAddress(true);
    setisFormubmit(false);
    if (
      addressData.common_customer_address_Label == "Home" ||
      addressData.common_customer_address_Label == "Work"
    ) {
      setSelectedOption(addressData?.common_customer_address_Label);
      setCustomOption(addressData?.common_customer_address_Label);
    } else {
      setSelectedOption("Others");
      setCustomOption(addressData?.common_customer_address_Label);
    }
    setPostcode(addressData?.common_customer_address_Postcode);
    setHouseNo(addressData?.common_customer_address_Line_1);
    setStreetName(addressData?.common_customer_address_Line_2);
    setCity(addressData?.common_customer_address_City);
    setSelectedLat(addressData?.common_customer_address_Latitude);
    setSelectedLng(addressData?.common_customer_address_Longitude);
    setLandmark("");
  };

  const deleteDetails = async (addressKey, common_customer_address_Id) => {
    setIsLoadingTopBar(true);

    try {
      const response = await axios.post(deleteAddress, {
        inputData: {
          common_customer_address_Id: common_customer_address_Id,
        },
      });
      if (response.data.status === "success") {
        success(response.data.msg);
        clearAdd();

        setAddressDetails((prevItems) => {
          const newItems = [...prevItems]; // Create a new array
          newItems.splice(addressKey, 1); // Remove item from the new array
          return newItems;
        });
        dispatch(
          setUserAddress({
            address: response?.data?.customer?.common_customer_Address,
          })
        );
      }
    } catch (e) {
      error("Add Address Error");
    } finally {
      setIsLoadingTopBar(false);
    }
  };

  return (
    <div className="">
      <section className="addressCard">
        <div className="pb-5">
          <div className="grid grid-cols-1 gap-4">
            {addressDetails.length == 0 && !isLoading ? (
              <div className="flex justify-center items-center w-full sm:min-h-[calc(100vh_-_25rem)] sm:h-auto">
                <div className=" lg:w-[350px] md:w-[200px]">
                  <img src={nodata_found} alt="No data found image" />
                </div>
              </div>
            ) : (
              addressDetails &&
              addressDetails.map((address, addressKey) => (
                <div
                  key={address?.common_customer_address_Id}
                  className={`bg-white w-full rounded-lg px-4 py-6`}
                >
                  <div className="flex items-center justify-between relative">
                    <div
                      className={`px-5 py-1 text-white text-mdl w-max ${
                        address?.common_customer_address_Label === "Home"
                          ? "bg-homeBtn"
                          : "bg-primary"
                      } w-max rounded-[5px]`}
                    >
                      {address?.common_customer_address_Label}
                    </div>
                    {/* three dots */}
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        openEdit(address?.common_customer_address_Id)
                      }
                    >
                      <svg
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5938 15.5C12.5938 17.1023 13.8977 18.4062 15.5 18.4062C17.1023 18.4062 18.4062 17.1023 18.4062 15.5C18.4062 13.8977 17.1023 12.5938 15.5 12.5938C13.8977 12.5938 12.5938 13.8977 12.5938 15.5Z"
                          fill="black"
                        />
                        <path
                          d="M12.5938 25.1875C12.5938 26.7898 13.8977 28.0938 15.5 28.0938C17.1023 28.0938 18.4062 26.7898 18.4062 25.1875C18.4062 23.5852 17.1023 22.2812 15.5 22.2812C13.8977 22.2812 12.5938 23.5852 12.5938 25.1875Z"
                          fill="black"
                        />
                        <path
                          d="M12.5938 5.8125C12.5938 7.41481 13.8977 8.71875 15.5 8.71875C17.1023 8.71875 18.4062 7.41481 18.4062 5.8125C18.4062 4.21019 17.1023 2.90625 15.5 2.90625C13.8977 2.90625 12.5938 4.21019 12.5938 5.8125Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    {showTooltip &&
                      activeAddressId ===
                        address?.common_customer_address_Id && (
                        <div
                          id="dropdownDots"
                          ref={tooltipRef} // Attach the ref here
                          className="absolute top-8 right-5 z-10 py-1 px-1 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
                        >
                          <ul
                            className="text-sm text-gray-700"
                            aria-labelledby="dropdownMenuIconButton"
                          >
                            <li
                              className={"border-none"}
                              onClick={() => {
                                EditDetails(address);
                                setShowButton(false);
                              }}
                            >
                              <a
                                href="#"
                                className="block text-black px-4 py-2 hover:bg-gray-100 hover:rounded-lg"
                              >
                                Edit
                              </a>
                            </li>
                            <li
                              className={"border-none"}
                              onClick={() =>
                                deleteDetails(
                                  addressKey,
                                  address?.common_customer_address_Id
                                )
                              }
                            >
                              <a
                                href="#"
                                className="block text-black px-4 py-2 hover:bg-gray-100 hover:rounded-lg"
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                  </div>
                  <div className="grid grid-cols-1 gap-y-2 mt-4 font-inter">
                    <div className="text-md">
                      {prepareProfileAddressFormat(address)}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddressCardProfile;
