import {
  basicDineData,
  basicData,
  login,
  pageContent,
  walletstmt,
  pastorders,
  addressFromLatLng,
  sendDownloadLinkSMS,
  sendLinkMAIL,
  storeFilters,
  findStore,
  storeData,
  storeMenu,
  bookmark,
  reviews,
  postCode,
  productGroups,
  insertOnlyProduct,
  actBusket,
  submenu,
  cardList,
  complexmenu,
  allergyList,
  addAllergy,
  pointstmt,
  applyCustomerNote,
  basicDetails,
  changeBasketOption,
  finishOrder,
  basketdetails,
  orderstatus,
  service,
  viewOrder,
  insertComplexProduct,
  insertSingleProduct,
  complexGroups,
  complexsubmenu,
  // addCardDetails,
  deleteBankCard,
  updateCardDefault,
  couponChecked,
  addAddress,
  updatePreference,
  updateBookmark,
  poinstorestmt,
  profileUpdate,
  deleteBasket,
  submitCheckoutResendOtp,
  submitBasketOtp,
  updateDispatchTime,
  address,
  submitReviews,
  unsubscribeUrl,
  pendingOrders,
  initCardPayment,
  processCardPayment,
  kuickAnalytics,
  submitOrderReview,
  getcoupons,
  checkcoupon,
  paymentStatus,
  versioncheck,
} from "../config";
import { getAxiosWithHeaders } from "../utils";

export const getBasicDineData = () =>
  getAxiosWithHeaders()
    .get(basicDineData)
    .then((res) => {
      return res;
    })
    .catch((err) => new Error(err));

export const getBasicData = () =>
  getAxiosWithHeaders()
    .get(basicData)
    .then((res) => res)
    .catch((err) => new Error(err));

export const loginCall = (
  countryCode,
  mobileNumber,
  OTP,
  resend,
  resendMode,
  pageName,
  name,
  referalCode,
  ctoken = ""
) =>
  getAxiosWithHeaders()
    .post(login, {
      inputData: {
        formdata: {
          common_customer_Country_Code: countryCode,
          common_customer_Username: mobileNumber,
          common_customer_OTP: pageName == "reotp" ? "" : OTP,
          common_customer_Name: name,
          common_customer_Referred_Code: referalCode,
          common_customer_Id: "",
          common_customer_OTP_Resend: resend,
          common_customer_OTP_Mode: resendMode,
          gtoken: "",
        },
        appName: import.meta.env.VITE_APP_NAME,
        page: pageName,
        deviceData: null,
        fireBaseId: null,
        ctoken: ctoken,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const updateProfile = (
  name,
  mobileNumber,
  otpmobile,
  otpmobileResend,
  mail,
  otpmail,
  otpmailResend,
  otpvoice
) =>
  getAxiosWithHeaders()
    .post(profileUpdate, {
      inputData: {
        common_customer_First_Name: name,
        common_customer_Telephone: mobileNumber,
        common_customer_Telephone_OTP: otpmobile,
        common_customer_Telephone_OTP_Resend: otpmobileResend,
        common_customer_Email: mail,
        common_customer_Email_OTP: otpmail,
        common_customer_Email_OTP_Resend: otpmailResend,
        common_customer_OTP_Voice: otpvoice,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const profileData = () =>
  getAxiosWithHeaders()
    .post(pageContent, {
      inputData: {
        page: "Profile",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const walletData = () =>
  getAxiosWithHeaders()
    .post(walletstmt, {
      inputData: { skip: 0, take: 3 },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const loyaltyData = () =>
  getAxiosWithHeaders()
    .post(pointstmt, {
      inputData: { skip: 0, take: 3 },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const loyaltyStoreData = (skip, take, product_Id) =>
  getAxiosWithHeaders()
    .post(poinstorestmt, {
      inputData: { skip: skip, take: take, product_Id: product_Id },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const pastordersData = (skip, take) =>
  getAxiosWithHeaders()
    .post(pastorders, {
      inputData: { skip: skip, take: take },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getLocationFromLatLng = (latitude, longitude) =>
  getAxiosWithHeaders()
    .post(addressFromLatLng, { inputData: { latitude, longitude } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getStoreData = (postcode = "", searchText = "", page = "") =>
  getAxiosWithHeaders()
    .post(pageContent, {
      inputData: {
        page: page != "" ? page : "DineHome,Storelist",
        postcode,
        searchText,
        cuisine: {},
        cuisines: {},
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const sendLinkSMS = (country, mobile, ctoken) =>
  getAxiosWithHeaders()
    .post(sendDownloadLinkSMS, {
      inputData: {
        country,
        mobile,
        ctoken,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const sendEmail = (email) =>
  getAxiosWithHeaders()
    .post(sendLinkMAIL, {
      inputData: {
        email,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getSearchFilter = (inputData) =>
  getAxiosWithHeaders()
    .post(storeFilters, {
      inputData,
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const searchStore = (searchval) =>
  getAxiosWithHeaders()
    .post(findStore, { inputData: { searchval } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getMenu = () =>
  getAxiosWithHeaders()
    .post(pageContent, {
      inputData: {
        page: "Menu",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const fetchMenuItem = (storeSlug, productId) =>
  getAxiosWithHeaders()
    .post(storeData, {
      inputData: {
        storeslug: storeSlug,
        product_Id: productId,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const fetchBannerItem = () =>
  getAxiosWithHeaders()
    .get(basicDineData)
    .then((res) => res)
    .catch((err) => new Error(err));

export const fetchCategoryList = () =>
  getAxiosWithHeaders()
    .get(storeMenu)
    .then((res) => res)
    .catch((err) => new Error(err));

export const addBookMark = (product_Id) =>
  getAxiosWithHeaders()
    .post(bookmark, { inputData: { product_Id } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getAbout = () =>
  getAxiosWithHeaders()
    .post(pageContent, { inputData: { page: "About" } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getReview = (skip, take) =>
  getAxiosWithHeaders()
    .post(reviews, { inputData: { skip: skip, take: take } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const cart = () =>
  getAxiosWithHeaders()
    .post(pageContent, { inputData: { page: "Cart" } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getPostCode = (postcode) =>
  getAxiosWithHeaders()
    .post(postCode, {
      inputData: { postcode },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getProfile = () =>
  getAxiosWithHeaders()
    .post(pageContent, { inputData: { page: "Profile" } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getProductGroups = (inventory_product_Id) =>
  getAxiosWithHeaders()
    .post(productGroups, { inputData: { inventory_product_Id } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const insertProductToCart = (inventory_product_Id) =>
  getAxiosWithHeaders()
    .post(insertOnlyProduct, { inputData: { inventory_product_Id } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const actBasketProduct = (inputData) =>
  getAxiosWithHeaders()
    .post(actBusket, { inputData })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getSubMenu = (inventory_product_Id) =>
  getAxiosWithHeaders()
    .post(submenu, { inputData: { inventory_product_Id } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const fetchBankList = () =>
  getAxiosWithHeaders()
    .post(cardList)
    .then((res) => res)
    .catch((err) => new Error(err));

export const getComplexMenu = (inventory_product_Id) =>
  getAxiosWithHeaders()
    .post(complexmenu, { inputData: { inventory_product_Id } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getAllergyList = () =>
  getAxiosWithHeaders()
    .post(allergyList, { inputData: {} })
    .then((res) => res)
    .catch((err) => new Error(err));

export const addAllergys = (store_allergy) =>
  getAxiosWithHeaders()
    .post(addAllergy, { inputData: { store_allergy } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const applyNote = (pos_basket_Customer_Note) =>
  getAxiosWithHeaders()
    .post(applyCustomerNote, { inputData: { pos_basket_Customer_Note } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const checkOut = () =>
  getAxiosWithHeaders()
    .post(pageContent, { inputData: { page: "Cart" } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const addBasicDetails = (
  pos_basket_Customer_First_Name,
  pos_basket_Customer_Telephone,
  pos_basket_Customer_Email,
  sms_subscribed,
  email_subscribed
) =>
  getAxiosWithHeaders()
    .post(basicDetails, {
      inputData: {
        pos_basket_Customer_First_Name,
        pos_basket_Customer_Telephone,
        pos_basket_Customer_Email,
        sms_subscribed,
        email_subscribed,
        common_customer_OTP_Voice: "",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const checkoutSubmitResendOtp = (
  pos_basket_Customer_First_Name,
  pos_basket_Customer_Telephone,
  pos_basket_Customer_Email,
  sms_subscribed,
  email_subscribed
) =>
  getAxiosWithHeaders()
    .post(submitCheckoutResendOtp, {
      inputData: {
        pos_basket_Customer_First_Name,
        pos_basket_Customer_Telephone,
        pos_basket_Customer_Email,
        sms_subscribed,
        email_subscribed,
        common_customer_OTP_Voice: "",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const changeBasketMode = (data_Value) =>
  getAxiosWithHeaders()
    .post(changeBasketOption, {
      inputData: data_Value,
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const changeBasketType = (data_Id, data_Act) =>
  getAxiosWithHeaders()
    .post(changeBasketOption, {
      inputData: { data_Id, data_Act },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

// export const orderFinish = () =>
//   getAxiosWithHeaders()
//     .post(finishOrder)
//     .then((res) => res)
//     .catch((err) => new Error(err));

export const serviceOrder = () =>
  getAxiosWithHeaders()
    .post(service)
    .then((res) => res)
    .catch((err) => new Error(err));

export const orderFinish = () =>
  getAxiosWithHeaders()
    .post(finishOrder)
    .then((res) => res)
    .catch((err) => new Error(err));
export const initCardPaymentData = () =>
  getAxiosWithHeaders()
    .post(initCardPayment)
    .then((res) => res)
    .catch((err) => new Error(err));
export const processCardPaymentData = (paymentFormObj) =>
  getAxiosWithHeaders()
    .post(processCardPayment, paymentFormObj)
    .then((res) => res)
    .catch((err) => new Error(err));
export const getPaymentStatus = (payload) =>
  getAxiosWithHeaders()
    .post(paymentStatus, payload)
    .then((res) => res)
    .catch((err) => new Error(err));
export const updateKuickAnalyticsData = (inputData) =>
  getAxiosWithHeaders()
    .post(kuickAnalytics, inputData)
    .then((res) => res)
    .catch((err) => new Error(err));
export const confirmOrder = () =>
  getAxiosWithHeaders()
    .post(pageContent, { inputData: { page: "Confirm" } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const basketDetails = (pos_basket_Key) =>
  getAxiosWithHeaders()
    .post(basketdetails, {
      inputData: {
        pos_basket_Key,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const orderStatus = (pos_basket_Key) =>
  getAxiosWithHeaders()
    .post(orderstatus, {
      inputData: {
        pos_basket_Key,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const viewStatus = (pos_basket_Key) =>
  getAxiosWithHeaders()
    .post(viewOrder, {
      inputData: {
        pos_basket_Key,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const orderStatusPageContent = () =>
  getAxiosWithHeaders()
    .post(pageContent, { inputData: { page: "OrderStatus" } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const addComplexProduct = (inputData) =>
  getAxiosWithHeaders()
    .post(insertComplexProduct, {
      inputData,
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const deleteBank = (common_customer_card_Id) =>
  getAxiosWithHeaders()
    .post(deleteBankCard, {
      inputData: {
        common_customer_card_Id,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));
export const updateBankCardDefaultService = (common_customer_card_Id) =>
  getAxiosWithHeaders()
    .post(updateCardDefault, {
      inputData: {
        common_customer_card_Id,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const addSingleProduct = (inputData) =>
  getAxiosWithHeaders()
    .post(insertSingleProduct, {
      inputData,
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const couponScratch = (coupon_Id) =>
  getAxiosWithHeaders()
    .post(couponChecked, {
      inputData: {
        coupon_Id,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getComplexGroups = (inventory_product_Id) =>
  getAxiosWithHeaders()
    .post(complexGroups, {
      inputData: { inventory_product_Id },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const addAddressDetails = ({
  common_customer_address_City,
  common_customer_address_Label,
  common_customer_address_Latitude,
  common_customer_address_Line_1,
  common_customer_address_Line_2,
  common_customer_address_Longitude,
  common_customer_address_Postcode,
  common_customer_address_Country,
  common_customer_address_County,
  common_customer_address_Id,
}) =>
  getAxiosWithHeaders()
    .post(addAddress, {
      inputData: {
        common_customer_address_City,
        common_customer_address_Label,
        common_customer_address_Latitude,
        common_customer_address_Line_1,
        common_customer_address_Line_2,
        common_customer_address_Longitude,
        common_customer_address_Postcode,
        common_customer_address_Country,
        common_customer_address_County,
        common_customer_address_Id,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getComplexSubMenu = (inventory_product_Id) =>
  getAxiosWithHeaders()
    .post(complexsubmenu, { inputData: { inventory_product_Id } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getUpdatedPreference = (key, val) =>
  getAxiosWithHeaders()
    .post(updatePreference, { inputData: { key, val } })
    .then((res) => res)
    .catch((err) => new Error(err));

export const removeBookmarkData = (product_Id) =>
  getAxiosWithHeaders()
    .post(updateBookmark, {
      inputData: {
        product_Id,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const deleteBasketComponent = (data_Id) =>
  getAxiosWithHeaders()
    .post(deleteBasket, {
      inputData: {
        data_Id,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const submitCheckoutBasketOtp = (customer_Telephone_Otp) =>
  getAxiosWithHeaders()
    .post(submitBasketOtp, {
      inputData: {
        customer_Telephone_Otp,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const cartData = () =>
  getAxiosWithHeaders()
    .post(pageContent, {
      inputData: {
        page: "Cart",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));
export const paymentData = () =>
  getAxiosWithHeaders()
    .post(pageContent, {
      inputData: {
        page: "Payment",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));
export const confirmData = () =>
  getAxiosWithHeaders()
    .post(pageContent, {
      inputData: {
        page: "Confirm",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));
export const paymentProcessingData = () =>
  getAxiosWithHeaders()
    .post(pageContent, {
      inputData: {
        page: "Processing",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const dispatchTimeData = (pos_basket_Delivery_Due_Date) =>
  getAxiosWithHeaders()
    .post(updateDispatchTime, {
      inputData: {
        pos_basket_Delivery_Due_Date,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const cartSubmitAddress = (
  pos_basket_Customer_Billing_Address_Line_1,
  pos_basket_Customer_Billing_Address_Line_2,
  pos_basket_Customer_Billing_City,
  pos_basket_Customer_Billing_Postcode
) =>
  getAxiosWithHeaders()
    .post(address, {
      inputData: {
        pos_basket_Customer_Billing_Address_Line_1,
        pos_basket_Customer_Billing_Address_Line_2,
        pos_basket_Customer_Billing_City,
        pos_basket_Customer_Billing_Postcode,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const submitFeedback = (inputData) =>
  getAxiosWithHeaders()
    .post(submitReviews, {
      inputData: inputData,
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const unsubscribe = (emailOrPhone) =>
  getAxiosWithHeaders()
    .post(unsubscribeUrl, {
      inputData: {
        common_customer_Username: emailOrPhone,
        common_customer_Username_Type: "",
        common_customer_Step: "forgot",
        pos_customer_Username_Type: "subhoProf",
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const pendingOrdersList = () =>
  getAxiosWithHeaders()
    .post(pendingOrders)
    .then((res) => res)
    .catch((err) => new Error(err));

export const submitOrderReviews = (data) =>
  getAxiosWithHeaders()
    .post(submitOrderReview, {
      inputData: {
        pos_basket_Key: data.pos_basket_Key,
        store_feedback_Text: data.store_feedback_Text,
        store_feedback_Quality: data.store_feedback_Quality,
        store_feedback_Quantity: data.store_feedback_Quantity,
        store_feedback_Service: data.store_feedback_Service,
      },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const getCoupons = () =>
  getAxiosWithHeaders()
    .post(getcoupons, {
      inputData: { skip: 0 },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const checkCoupon = (barcode) =>
  getAxiosWithHeaders()
    .post(checkcoupon, {
      inputData: { coupon: { barcode, ver_email: "", ver_otp: "" } },
    })
    .then((res) => res)
    .catch((err) => new Error(err));

export const versionControl = () =>
  getAxiosWithHeaders()
    .get(versioncheck)
    .then((res) => res)
    .catch((err) => new Error(err));
