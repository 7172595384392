import { capitalizeFirstWord } from "../../utils";

const Switch = ({ data, value, onChange, className }) => {
  return (
    <div
      className={`flex h-8 md:h-10 xl:h-12 min-[1920]:h-20 2xl:w-[470px] 3xl:w-[800px] 2xl:h-14 3xl:h-16 cursor-pointer bg-white rounded-md border border-primary  overflow-hidden} ${className}`}
    >
      {data.map((val, ind) => (
        <div
          onClick={() => onChange(val)}
          key={`switch${ind}`}
          className={`w-1/2 h-full text-[12px] 2xl:text-sm 3xl:text-[26px] font-medium   flex justify-center items-center ${
            val === value ? "bg-primary text-white " : " text-primary"
          }  ${ capitalizeFirstWord(val)=='Delivery' ? 'rounded-l' : ' rounded-r'} `}
        >
          {capitalizeFirstWord(val)}
        </div>
      ))}
    </div>
  );
};

export default Switch;
