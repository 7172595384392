import { useState } from "react";

const ReadMore = ({ text, limit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const visibleText = isExpanded
    ? text
    : text.slice(0, limit) + (text.length > limit ? "..." : "");

  return (
    <div>
      <p className="break-all">
        {visibleText}
        {text.length > limit && (
          <button onClick={handleToggle} className="text-primary text-xxs">
            {isExpanded ? "" : "more"}
          </button>
        )}
      </p>
    </div>
  );
};

export default ReadMore;
