import { useEffect, useState } from "react";
import Tabs from "../../components/Navigation/Tabs";
import Checkbox from "../../components/Elements/Checkbox";
import Button from "../../components/Elements/Button";
import { setProgressData } from "../../store/progressData/progressDataSlice";
import { checkEmpty } from "../../utils/helper";
import { useProgress } from "../../hooks/useProgress";
import { useSelector, useDispatch } from "react-redux";
import {
  setFilterItems,
  setHomeLoader,
} from "../../store/basicData/basicDataSlice";
import { setIsFilterLoaded } from "../../store/apiLoaderData/apiLoaderDataSlice";
import { copyObject } from "../../utils/helper";
import DrawerHeader from "../../components/Header/DrawerHeader";
import useWindowsDimenons from "../../hooks/useWindowsDimensons";

const RestaurantFilter = ({ onChange, postcode, filtertoggleDrawer }) => {
  const { height } = useWindowsDimenons();
  const filter = useSelector((state) => state?.basicdata?.filter);
  const filterItemsStore = useSelector(
    (state) => state?.basicdata?.filterItems
  );
  const [filterList, setFilterList] = useState({});
  const [selectedTab, setSelectedTab] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { progressFunction } = useProgress();
  const dispatch = useDispatch();
  const [intervalData, setintervalData] = useState(0);

  useEffect(() => {
    if (filterItemsStore) {
      setFilterList(filterItemsStore);
    } else {
      setFilterList({});
    }
  }, []);

  // LOADER PROGRESS BAR
  useEffect(() => {
    if (isLoading) {
      let count = 0;
      const intervalVariable = setInterval(() => {
        if (count < 90) {
          dispatch(setProgressData(count));
        } else if (count >= 90) {
          dispatch(setProgressData(90));
          window.clearInterval(intervalVariable);
        }
        count = count + 10;
      }, 10);

      setintervalData(intervalVariable);
      progressFunction(intervalVariable);
    } else {
      dispatch(setProgressData(100));
      clearInterval(intervalData);
    }
  }, [isLoading]);

  // Listen to the change of store in redux
  useEffect(() => {
    setFilterList(copyObject(filterItemsStore ?? {}));
  }, [filterItemsStore]);

  // listen to the change of the tabs
  useEffect(() => {
    setSelectedTab(filter[0]?.key);
    if (filter.length) {
      if (!checkEmpty(filterItemsStore)) {
        setFilterList(filterItemsStore);
      } else {
        let res = {};
        filter.forEach((val) => {
          res[val.key] = [];
          val.data.forEach((v) => {
            res[val.key].push({ id: v.Id, checked: false, name: v.Name });
          });
        });
        setFilterList(res);
      }
    }
  }, [filter]);

  const handleCheckbox = (key, id) => {
    let res = copyObject(filterList);
    const itemIndex = res[key].findIndex((value) => value.id === id);

    if (itemIndex !== -1) {
      const item = res[key][itemIndex];
      item.checked = !item.checked;
      item.timestamp = item.checked ? new Date().toISOString() : null; // Add timestamp when checked, remove when unchecked
      setFilterList(res);
    } else {
      console.error(`Item with id ${id} not found in key ${key}`);
    }
  };

  const handleApplyFilter = async () => {
    setIsLoading(true);
    let res = copyObject(filterList);
    res.offers = [...new Set(res?.offers)];
    res.tags = [...new Set(res?.tags)];
    res.cuisines = [...new Set(res?.cuisines)];
    const inputData = {};
    filter.forEach((val) => {
      const { key } = val;
      inputData[key] = [];
      res[key]?.forEach((value) => {
        if (value.checked) {
          inputData[key].push(value.id);
        }
      });
    });
    dispatch(setIsFilterLoaded(true));
    dispatch(setFilterItems(filterList));
    onChange(inputData);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  const clearFilters = async () => {
    dispatch(setHomeLoader(true));
    filtertoggleDrawer();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let res = {};
    filter.forEach((val) => {
      res[val.key] = [];
      val.data.forEach((v) => {
        res[val.key].push({ id: v.Id, checked: false, name: v.Name });
      });
    });
    setFilterList(res);
    dispatch(setHomeLoader(false));
    dispatch(setFilterItems(res));
  };

  return (
    <div className="no-scrollbar w-full h-screen overflow-hidden">
      <DrawerHeader title="Filters" onClose={filtertoggleDrawer} />
      <div className="px-4 md:px-6 h-full">
        <Tabs
          tabs={filter}
          onChange={(key) => setSelectedTab(key)}
          className={`mt-4`}
        />
        {filter?.map((val) => {
          const { key } = val;
          return (
            key === selectedTab && (
              <div
                key={key}
                style={{
                  maxHeight: height ? height - 230 : "",
                }}
                className="grid grid-cols-2 gap-4 py-4 no-scrollbar h-auto overflow-auto "
              >
                {filterList[key]?.map((v) => {
                  return (
                    <div
                      onClick={() => handleCheckbox(key, v.id)}
                      key={v.id}
                      className="flex items-center h-auto cursor-pointer "
                    >
                      <Checkbox
                        checked={v.checked}
                        id={`checkId${v.id}`}
                        onChange={() => handleCheckbox(key, v.id)}
                      />
                      <p className="pl-2">{v.name}</p>
                    </div>
                  );
                })}
              </div>
            )
          );
        })}
      </div>

      <div className="absolute w-full bottom-0 p-5 flex flex-col justify-center">
        <Button
          onClick={handleApplyFilter}
          className="text-white bg-primary focus:outline-none rounded-lg text-sm justify-center items-center px-5 py-2.5 text-center mb-3"
        >
          Apply Filters
        </Button>
        <Button
          onClick={clearFilters}
          className="text-border-shade-1 border border-border-shade-1 w-[100%] bg-white focus:outline-none rounded-lg text-sm justify-center items-center px-5 py-2.5 text-center"
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default RestaurantFilter;
