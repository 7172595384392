export default function HomeLoader({ className }) {
  return (
    <div className={`animate-pulse overflow-x-hidden ${className}`}>
      <div className="px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] carousel w-full  space-x-2 lg:space-x-3 sm:pt-7 sm:pb-8 py-6 xl:py-8 2xl:py-10 ">
        <div
          className={` w-[390px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[140px] xl:h-[160px] min-[1920px]:h-[274px] 2xl:h-[234px] object-contain bg-gray-300 rounded-lg`}
        ></div>
        <div
          className={`hidden xs:block w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[140px] xl:h-[160px] min-[1920px]:h-[274px] 2xl:h-[234px] object-contain bg-gray-300 rounded-lg`}
        ></div>
        <div
          className={`hidden md:block w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[140px] xl:h-[160px] min-[1920px]:h-[274px] 2xl:h-[234px] object-contain bg-gray-300 rounded-lg`}
        ></div>
        <div
          className={`hidden xs:block w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[140px] xl:h-[160px] min-[1920px]:h-[274px] 2xl:h-[234px] object-contain bg-gray-300 rounded-lg`}
        ></div>
      </div>

      <div className="px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] carousel w-full space-x-4 lg:space-x-4 py-16 2xl:py-24">
        <div
          className={`relative flex justify-center w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[200px] md:h-[250px] xl:h-[320px] 2xl:h-[350px]  object-contain bg-gray-300 rounded-lg`}
        >
          <div className="absolute rounded-full justify-center flex -top-12 w-[110px] h-[110px] sm:h-24 sm:w-24 md:-top-16 md:w-32 md:h-32 xl:w-40 xl:h-40 2xl:h-52 2xl:-top-20 2xl:w-52 z-10  bg-gray-400 "></div>
          <div className="absolute top-20 sm:top-20 xl:top-32 2xl:top-40">
            {[...Array(4)].map((_, index) => (
              <p
                key={index}
                className="bg-gray-400 w-28 h-2 md:w-28 xl:w-40 md:h-2 xl:h-3 rounded-sm mb-2"
              ></p>
            ))}
          </div>
        </div>
        <div
          className={`relative flex justify-center w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[200px] md:h-[250px] xl:h-[320px]  2xl:h-[350px] object-contain bg-gray-300 rounded-lg`}
        >
          <div className="absolute rounded-full justify-center flex -top-12 w-[110px] h-[110px] sm:h-24 sm:w-24 md:-top-16 md:w-32 md:h-32 xl:w-40 xl:h-40 2xl:h-52 2xl:-top-20 2xl:w-52 z-10  bg-gray-400 "></div>
          <div className="absolute top-20 sm:top-20 xl:top-32 2xl:top-40">
            {[...Array(4)].map((_, index) => (
              <p
                key={index}
                className="bg-gray-400 w-28 h-2 md:w-28 xl:w-40 md:h-2 xl:h-3 rounded-sm mb-2"
              ></p>
            ))}
          </div>
        </div>
        <div
          className={`hidden xs:flex relative  justify-center w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[200px] md:h-[250px] xl:h-[320px]  2xl:h-[350px] object-contain bg-gray-300 rounded-lg`}
        >
          <div className="absolute rounded-full justify-center flex -top-12 w-[110px] h-[110px] sm:h-24 sm:w-24 md:-top-16 md:w-32 md:h-32 xl:w-40 xl:h-40 2xl:h-52 2xl:-top-20 2xl:w-52 z-10  bg-gray-400 "></div>
          <div className="absolute top-20 sm:top-20 xl:top-32 2xl:top-40">
            {[...Array(4)].map((_, index) => (
              <p
                key={index}
                className="bg-gray-400 w-28 h-2 md:w-28 xl:w-40 md:h-2 xl:h-3 rounded-sm mb-2"
              ></p>
            ))}
          </div>
        </div>
        <div
          className={`hidden sm:flex relative  justify-center w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[200px] md:h-[250px] xl:h-[320px]  2xl:h-[350px] object-contain bg-gray-300 rounded-lg`}
        >
          <div className="absolute rounded-full justify-center flex -top-12 w-[110px] h-[110px] sm:h-24 sm:w-24 md:-top-16 md:w-32 md:h-32 xl:w-40 xl:h-40 2xl:h-52 2xl:-top-20 2xl:w-52 z-10  bg-gray-400 "></div>
          <div className="absolute top-20 sm:top-20 xl:top-32 2xl:top-40">
            {[...Array(4)].map((_, index) => (
              <p
                key={index}
                className="bg-gray-400 w-28 h-2 md:w-28 xl:w-40 md:h-2 xl:h-3 rounded-sm mb-2"
              ></p>
            ))}
          </div>
        </div>
        <div
          className={`hidden lg:flex relative  justify-center w-[328px] xl:w-[377px] 2xl:w-[425px] min-[1920px]:w-[585px] h-[200px] md:h-[250px] xl:h-[320px]  2xl:h-[350px] object-contain bg-gray-300 rounded-lg`}
        >
          <div className="absolute rounded-full justify-center flex -top-12 w-[110px] h-[110px] sm:h-24 sm:w-24 md:-top-16 md:w-32 md:h-32 xl:w-40 xl:h-40 2xl:h-52 2xl:-top-20 2xl:w-52 z-10  bg-gray-400 "></div>
          <div className="absolute top-20 sm:top-20 xl:top-32 2xl:top-40">
            {[...Array(4)].map((_, index) => (
              <p
                key={index}
                className="bg-gray-400 w-28 h-2 md:w-28 xl:w-40 md:h-2 xl:h-3 rounded-sm mb-2"
              ></p>
            ))}
          </div>
        </div>
      </div>

      <div className="px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] carousel overflow-x-auto  w-full space-x-4 lg:space-x-4 pb-8 2xl:pb-24">
        <div
          className={`cursor-pointer h-[150px] sm:h-60 2xl:h-72 w-44 sm:w-60 2xl:w-[267px] bg-gray-300 rounded-lg flex flex-col justify-center items-center ${className}`}
        >
          <div className="h-24 sm:h-40 w-24 sm:w-40 2xl:h-44 2xl:w-44 mx-auto bg-gray-400 rounded-full"></div>
          <p className="bg-gray-400 w-16 h-2 md:w-28 xl:w-28 md:h-2 xl:h-3 rounded-sm mt-4"></p>
        </div>
        <div
          className={`cursor-pointer h-[150px] sm:h-60 2xl:h-72 w-44 sm:w-60 2xl:w-[267px] bg-gray-300 rounded-lg flex flex-col justify-center items-center ${className}`}
        >
          <div className="h-24 sm:h-40 w-24 sm:w-40 2xl:h-44 2xl:w-44 mx-auto bg-gray-400 rounded-full"></div>
          <p className="bg-gray-400 w-16 h-2 md:w-28 xl:w-28 md:h-2 xl:h-3 rounded-sm mt-4"></p>
        </div>
        <div
          className={`hidden xs:flex cursor-pointer h-[150px] sm:h-60 2xl:h-72 w-44 sm:w-60 2xl:w-[267px] bg-gray-300 rounded-lg flex-col justify-center items-center ${className}`}
        >
          <div className="h-24 sm:h-40 w-24 sm:w-40 2xl:h-44 2xl:w-44 mx-auto bg-gray-400 rounded-full"></div>
          <p className="bg-gray-400 w-16 h-2 md:w-28 xl:w-28 md:h-2 xl:h-3 rounded-sm mt-4"></p>
        </div>
        <div
          className={`hidden md:flex cursor-pointer h-[150px] sm:h-60 2xl:h-72 w-44 sm:w-60 2xl:w-[267px] bg-gray-300 rounded-lg flex-col justify-center items-center ${className}`}
        >
          <div className="h-24 sm:h-40 w-24 sm:w-40 2xl:h-44 2xl:w-44 mx-auto bg-gray-400 rounded-full"></div>
          <p className="bg-gray-400 w-16 h-2 md:w-28 xl:w-28 md:h-2 xl:h-3 rounded-sm mt-4"></p>
        </div>
        <div
          className={`hidden lg:flex cursor-pointer h-[150px] sm:h-60 2xl:h-72 w-44 sm:w-60 2xl:w-[267px] bg-gray-300 rounded-lg flex-col justify-center items-center ${className}`}
        >
          <div className="h-24 sm:h-40 w-24 sm:w-40 2xl:h-44 2xl:w-44 mx-auto bg-gray-400 rounded-full"></div>
          <p className="bg-gray-400 w-16 h-2 md:w-28 xl:w-28 md:h-2 xl:h-3 rounded-sm mt-4"></p>
        </div>
      </div>

     
    </div>
  );
}
