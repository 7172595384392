import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "../../components/Loader/MenuLoaders/SubHeader";
import GreaterThan from "../../assets/images/chevron-right.png";
import { capitalizeFirstWord, setCartConfigSession } from "../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import Layout from "../../layout/Layout";
import {
  CheckoutPage,
  ConfirmPage,
  HomePage,
  LandingPage,
  MenuPage,
  PaymentPage,
  TrackFoodPage,
} from "../../routes/RoutesPath";
import MenuLoader from "../../components/Loader/MenuLoaders/MenuLoader";
import CheckoutLoader from "../../components/Loader/CheckoutPageLoaders/CheckoutLoader";
import MenuBanner from "../menu/MenuBanner";
import {
  changeBasketMode,
  getBasicData,
  getPaymentStatus,
  orderFinish,
  paymentProcessingData,
} from "../../services";
import { checkEmpty } from "../../utils/helper";
import Lottie from "lottie-react";
import paymentProcessing from "../../assets/images/payment-processing.json";
import { setBasketData } from "../../store/basicData/basicDataSlice";
import useBasketValidateAction from "../../hooks/useBasketValidate";
import { error, success } from "../../components/notify";
import useAnalyticsEvents from "../../hooks/useAnalyticsEvents";

const PaymentProcess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id1, id2, id3 } = useParams();
  const routeParams = useParams();
  const basket = useSelector((state) => state?.basicdata?.basket);
  const storeDetails = useSelector((state) => state?.basicdata?.storedetails);
  const storeSlug = storeDetails?.store_Slug;
  const productId = storeDetails?.product_Id;
  const storeName = storeDetails?.store_Name;
  const storeCity = storeDetails?.store_City;
  const storeBannerImage = storeDetails?.store_Banner_Image;
  const [isLoadingTopBar, setIsLoadingTopBar] = useState(false);

  const [storeData, setStoreData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const [pageData, setPageData] = useState("");
  const basketValidateAction = useBasketValidateAction();
  const curPage = useSelector((state) => state?.curPage?.currentPage);
  const analyticsEvents = useAnalyticsEvents();
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaOgDescription = document.querySelector(
    'meta[property="og:description"]'
  );
  const metaOgTitle = document.querySelector('meta[property="og:title"]');

  const isSticky = () => {
    const scrollTop = window.scrollY;
    setIsFixed(scrollTop > 60);
  };
  const getBasicDataAPI = async () => {
    setIsLoading(true);
    try {
      const { status, data } = await getBasicData();
      if (status == 200) {
        if (data.status === "success") {
          if (!checkEmpty(data?.storedetails)) {
            setStoreData(data?.storedetails);
          } else if (
            checkEmpty(data?.storedetails) &&
            checkEmpty(data?.storedetails?.product_Id)
          ) {
            navigate(LandingPage);
          }
          if (
            checkEmpty(data?.basket?.pos_basket_Id) ||
            data?.basket?.status == "Invalid"
          ) {
            dispatch(setBasketData(data?.basket));
            setCartConfigSession(data?.cartConfig);
            if (!checkEmpty(storeSlug) && !checkEmpty(productId)) {
              navigate(MenuPage(storeSlug, productId));
            } else {
              navigate(LandingPage);
            }
          }
        }
      }
    } catch (error) {
      //setIsLoading(false);
      console.error("error msg", error);
    } finally {
    }
  };
  const getPaymentProcessingPageContentData = async () => {
    try {
      const { status, data } = await paymentProcessingData();
      if (status === 200) {
        if (data.status === "success") {
          setPageData(data);
        }
      }
    } catch (error) {
      console.error("error msg", error);
    } finally {
    }
  };

  const payByCash = async () => {
    changeBasketOption("payment", "Cash");
  };
  const changeBasketOption = async (type, val) => {
    try {
      const { status, data } = await changeBasketMode({
        data_Id: val,
        data_Act: type,
      });
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
          dispatch(setBasketData(data?.basket));
          setCartConfigSession(data?.cartConfig);
          handleFinishOrder();
        } else if (data?.status == "error") {
          if (!checkEmpty(data?.msg)) {
            error(data?.msg);
          }
        }
      }
    } catch (data) {
      error(data.msg);
    } finally {
    }
  };

  const handleFinishOrder = async () => {
    try {
      setIsLoadingTopBar(true);
      const { status, data } = await orderFinish();
      if (status === 200) {
        if (data.status === "success") {
          if (!checkEmpty(data.msg)) {
            success(data.msg);
          }
          let basketKey = data?.pos_basket_Key;
          if (data.paymentmode == "Card") {
            setIsLoadingTopBar(false);
            navigate(PaymentPage);
          } else if (data.paymentmode == "Cash") {
            setIsLoadingTopBar(false);
            navigate(ConfirmPage + basketKey);
          }
        } else if (data.status == "error") {
          if (!checkEmpty(data.msg)) {
            error(data.msg);
          }
          setIsLoadingTopBar(false);
          if (!checkEmpty(data.err_type)) {
            basketValidateAction(data);
            if (!checkEmpty(data.msg)) {
              error(data.msg);
            }
          }
        }
      }
    } catch (error) {
      console.log("error msg", error);
    } finally {
    }
  };

  const getPaymentStat = async () => {
    try {
      let thisPayload = {};
      thisPayload = routeParams;
      const { status, data } = await getPaymentStatus({
        inputData: thisPayload,
      });
      if (status === 200) {
        if (data.status === "success") {
          navigate(ConfirmPage + data.key);
        } else if (data.status == "error") {
          if (checkEmpty(data.err_key)) {
            if (data.err_key == "declined") {
              if (!checkEmpty(data.msg)) {
                error(data.msg);
              }
              setTimeout(() => {
                navigate(PaymentPage);
              }, 1500);
            }
          }
        }
      }
    } catch (error) {
      console.log("error msg", error);
    } finally {
      if (curPage == "Processing") {
        getPaymentStat();
      }
    }
  };

  useEffect(() => {
    if (!checkEmpty(storeName)) {
      document.title = `Payment Processing | ${storeName} - ${
        import.meta.env.VITE_PRODUCT_NAME
      }`;
      metaOgTitle.setAttribute(
        `Payment Processing | ${storeName} - ${
          import.meta.env.VITE_PRODUCT_NAME
        }`
      );
      metaDescription.setAttribute(
        "content",
        `Order food online from ${storeName}, ${storeCity}. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
      metaOgDescription.setAttribute(
        "content",
        `Order food online from ${storeName}, ${storeCity}. Get a chance to win voucher on every order. Super fast food delivery, Live Order tracking, Exclusive discounts & Offers Only on ${
          import.meta.env.VITE_PRODUCT_NAME
        }.`
      );
    }
  }, [storeName, storeCity, storeBannerImage]);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  useEffect(() => {
    setIsLoading(true);
    getBasicDataAPI();
    setTimeout(() => {
      getPaymentProcessingPageContentData();
    }, 1000);
  }, [storeSlug, productId]);

  useEffect(() => {
    setTimeout(() => {
      getPaymentStat();
    }, 1000);
  }, [routeParams, id1, id2, id3]);

  useEffect(() => {
    if (!checkEmpty(pageData) && !checkEmpty(storeData)) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [pageData, storeData]);

  return (
    <div>
      <Layout
        className={`bg-secondary ${
          isFixed
            ? "sticky animate-fadedown pb-2 top-16 left-0 shadow-xl px-[25px] sm:px-7 md:px-8 lg:px-12 xl:px-[60px] transition duration-300"
            : "relative py-2 transition duration-30"
        } bg-white w-full z-10 mdl:flex items-center hidden`}
      >
        <Link to={HomePage} className="flex items-center cursor-pointer">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              {capitalizeFirstWord("Home")}
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </Link>
        <div
          onClick={() => {
            navigate(MenuPage(storeSlug, productId));
          }}
          className="flex items-center cursor-pointer"
        >
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              Menu
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </div>
        <div
          onClick={() => {
            navigate(CheckoutPage());
          }}
          className="flex items-center cursor-pointer"
        >
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-grey">
              Cart
            </span>
          )}
          <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
        </div>
        <div className="flex items-center">
          {isLoading ? (
            <SubHeader />
          ) : (
            <span className="text-xs sm:text-sm font-normal text-active-route">
              Payment Processing
            </span>
          )}
        </div>
      </Layout>
      {isLoading ? (
        <>
          <MenuLoader
            subClassName={`h-38 sm:h-56 md:h-56 lg:h-60 xl:h-72 relative -z-30`}
          />
          <Layout className="flex mt-[30px] md:mt-[30px]">
            <div className="xl:pt-0 space-y-5 w-full xl:w-[98%] mb-4 mdl:mr-[2%] sm:mb-11">
              <CheckoutLoader className="block" />
            </div>
          </Layout>
        </>
      ) : (
        <div>
          <MenuBanner
            storeSlug={storeSlug}
            productId={productId}
            setIsLoading={setIsLoading}
            storeData={storeData}
          />
          <Layout className="flex flex-col mt-[30px] md:mt-[30px]">
            <div className="flex flex-col m-auto items-center">
              <div className=" lg:text-[19px]  md:text-[16px]   text-[12px]  text-slate-950	font-semibold		">
                {" "}
                Verifying Payment. Please Wait...{" "}
              </div>
              <div className=" lg:text-[16px]     text-[13px]  text-slate-950	font-medium		">
                {" "}
                We are waiting for payment confirmation.{" "}
              </div>
              <Lottie
                style={{ flex: 1 }}
                animationData={paymentProcessing}
                loop={true}
              />
            </div>
            <div className="flex flex-col md:flex-row mb-5 space-y-4 md:space-y-0 space-x-0 md:space-x-4 m-auto">
              <Button
                onClick={() => {
                  navigate(CheckoutPage());
                }}
                className={`text-white w-auto bg-gray-500 focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center`}
              >
                Go Back To Checkout
              </Button>

              <Button
                onClick={() => {
                  navigate(PaymentPage);
                }}
                className={`text-white w-auto bg-black focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center`}
              >
                Pay By Different Card
              </Button>
              <Button
                onClick={() => {
                  payByCash();
                }}
                className={`text-white w-auto bg-primary focus:outline-none rounded-lg text-sm inline-flex justify-center items-center px-5 py-2.5 text-center`}
              >
                Pay By Cash
              </Button>
            </div>
          </Layout>
        </div>
      )}
    </div>
  );
};

export default PaymentProcess;
