import { Link } from "react-router-dom";
import { capitalizeFirstWord } from "../../utils";
import GreaterThan from "../../assets/images/chevron-right.png";
import { HomePage, ProfilePage } from "../../routes/RoutesPath";

export function BreadcrumbsProfile({ className }) {
  return (
    <div className={`bg-secondary ${className}`}>
      <Link  to={HomePage} className="flex items-center">
        <span className="text-xs sm:text-sm font-normal text-grey">Home</span>
        <img src={GreaterThan} className="w-4 h-4" loading="lazy" />
      </Link>
      <Link  to={ProfilePage} className="flex items-center ">
        <span className="text-xs sm:text-sm font-normal text-active-route">Profile</span>
      </Link>
    </div>
  );
}
