const ArrowLeftChevron = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 12 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5637 0.43625C12.1454 1.01792 12.1454 1.96889 11.5637 2.55979L3.62216 10.5L11.5637 18.4402C12.1454 19.0219 12.1454 19.9729 11.5637 20.5638C10.9819 21.1454 10.0308 21.1454 9.43979 20.5638L0.436322 11.5618C-0.145441 10.9801 -0.145441 10.0291 0.436322 9.43823L9.43979 0.43625C10.0308 -0.145417 10.9819 -0.145417 11.5637 0.43625Z"
      />
    </svg>
  );
};

export default ArrowLeftChevron;
